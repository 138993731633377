<template>
  <section class=" flex justify-center md:block md:justify-end md:mr-10">

    <div class="container-formulario">
      <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Alterar Item do Orçamento:</h1>

        <router-link :to="`/orcamento/${orcamentoId}`" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

      <div class="w-full block justify-center mb-8">
        <div class="flex flex-wrap gap-2 justify-center">

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.perfis
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaPerfis()">
            Perfis
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.componentes
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaComponentes()">
            Componentes
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.vidros
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaVidros()">
            Vidros
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.servicos
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaServicos()">
            Serviços
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.variaveis
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaVariaveis()">
            Variáveis
          </button>

          <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
            opcoes.formulas
              ? 'bg-screenCor text-corBase border border-corBase'
              : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
            @click.prevent="ativaFormulas()">
            Fórmulas
          </button>

        </div>
      </div>

      <!-- <div class="nav-principal-projetos pt-6 pb-6 justify-around">
        <button @click.prevent="ativaPerfis()">
          Perfis
        </button>

        <button @click.prevent="ativaComponentes()">
          Componentes
        </button>

        <button @click.prevent="ativaVidros()">
          Vidros
        </button>

        <button @click.prevent="ativaServicos()">
          Serviços
        </button>

        <button @click.prevent="ativaVariaveis()">
          Variáveis
        </button>

        <button @click.prevent="ativaFormulas()">
          Formulas
        </button>
      </div> -->

      <AlterarPerfilOrcamento v-if="opcoes.perfis" :perfis="perfis" />

      <AlterarComponenteOrcamento v-if="opcoes.componentes" :componentes="componentes" />

      <AlterarVidroOrcamento v-if="opcoes.vidros" :vidros="vidros" />

      <AlterarServicoOrcamento v-if="opcoes.servicos" :servicos="servicos" />

      <VisualizarVariaveisOrcamento v-if="opcoes.variaveis" :id="this.itemOrcamento.id" />

      <VisualizarFormulasOrcamento v-if="opcoes.formulas" :formulas="formulas" />
    </div>

  </section>
</template>

<script>
import axios from 'axios'
import AlterarPerfilOrcamento from './AlterarPerfilOrcamento.vue'
import AlterarComponenteOrcamento from './AlterarComponenteOrcamento.vue'
import AlterarVidroOrcamento from './AlterarVidroOrcamento.vue'
import AlterarServicoOrcamento from './AlterarServicoOrcamento.vue'
import VisualizarVariaveisOrcamento from './VisualizarVariaveisOrcamento.vue'
import VisualizarFormulasOrcamento from './VisualizarFormulasOrcamento.vue'

export default {
  name: 'AlterarItemOrcamento',

  data() {
    return {
      orcamentoId: this.$store.state.orcamentoIdAtual,
      itemOrcamentoId: this.$route.params.id,

      perfis: [],
      componentes: [],
      vidros: [],
      servicos: [],
      variaveis: [],
      formulas: [],
      itemOrcamento: {},

      opcoes: {}
    }
  },

  components: {
    AlterarPerfilOrcamento,
    AlterarComponenteOrcamento,
    AlterarVidroOrcamento,
    AlterarServicoOrcamento,
    VisualizarVariaveisOrcamento,
    VisualizarFormulasOrcamento
  },

  created() {
    this.getItemOrcamento()

    this.opcoes = {
      perfis: false,
      componentes: false,
      vidros: false,
      servicos: false,
      variaveis: false,
      formulas: false,
    }
  },

  methods: {

    desativaTodasOpcoes() {
      this.opcoes.perfis = false
      this.opcoes.componentes = false
      this.opcoes.vidros = false
      this.opcoes.servicos = false
      this.opcoes.variaveis = false
      this.opcoes.formulas = false
    },

    ativaPerfis() {
      this.desativaTodasOpcoes()

      this.opcoes.perfis = true
    },

    ativaComponentes() {
      this.desativaTodasOpcoes()

      this.opcoes.componentes = true
    },

    ativaVidros() {
      this.desativaTodasOpcoes()

      this.opcoes.vidros = true
    },

    ativaServicos() {
      this.desativaTodasOpcoes()

      this.opcoes.servicos = true
    },

    ativaVariaveis() {
      this.desativaTodasOpcoes()

      this.opcoes.variaveis = true
    },

    ativaFormulas() {
      this.desativaTodasOpcoes()

      this.opcoes.formulas = true
    },

    async getItemOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`orcamentos/item-orcamento/${this.itemOrcamentoId}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        console.log(response.data)

        this.itemOrcamento = response.data
        console.log(this.itemOrcamento)

        this.getPerfisOrcamento()
        this.getComponentesOrcamento()
        this.getVidrosOrcamento()
        this.getServicosOrcamento()
        this.getFormulasOrcamento()
        this.getVariaveisOrcamento()

      } catch (error) {
        console.error(error)
      }
    },

    async getPerfisOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`orcamentos/perfis-orcamento/?orcamento=${this.itemOrcamento.orcamento.id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.perfis = response.data.results

      } catch (error) {
        console.error(error)
      }
    },

    async getComponentesOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`orcamentos/componentes-orcamento/?orcamento=${this.itemOrcamento.orcamento.id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.componentes = response.data.results

      } catch (error) {
        console.error(error)
      }
    },

    async getVidrosOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`orcamentos/vidros-orcamento/?orcamento=${this.itemOrcamento.orcamento.id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.vidros = response.data.results

      } catch (error) {
        console.error(error)
      }
    },

    async getServicosOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`orcamentos/servicos-orcamento/?orcamento=${this.itemOrcamento.orcamento.id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.servicos = response.data.results

      } catch (error) {
        console.error(error)
      }
    },

    async getFormulasOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`projeto/formula-projeto/?projeto=${this.itemOrcamento.projeto.id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.formulas = response.data.results

      } catch (error) {
        console.error(error)
      }
    },

    async getVariaveisOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`projeto/formula-projeto/?projeto=${this.itemOrcamento.projeto.id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        this.variaveis = response.data.results

      } catch (error) {
        console.error(error)
      }
    },
  }
}

</script>

<style scoped lang="scss"></style>
