<template>
    <div>
        <div class="form-group">
            <h2 class="text-lg text-corBase font-bold pb-0 pt-2">Cores:</h2>
            <div class="flex flex-col gap-6 md:flex-row pb-6">
                <!-- Componentes -->
                <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Componentes:</label>
                    <v-select v-model="obra.lista_cor_componente" :options="lista_cor_componente" label="descricao"
                        placeholder="Escolha uma opção" class="bg-white"
                        :multiple="true" />
                </div>

                <!-- Perfis -->
                <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Perfis:</label>
                    <v-select v-model="obra.lista_cor_perfil" :options="lista_cor_perfil" label="descricao"
                        placeholder="Escolha uma opção" class="bg-white"
                        :multiple="true" />
                </div>

                <!-- Vidros -->
                <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Vidros:</label>
                    <v-select v-model="obra.lista_vidro" :options="lista_cor_vidro" label="descricao"
                        placeholder="Escolha uma opção" class="bg-white"
                        :multiple="true" />
                </div>

                <!-- Chapas -->
                <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Chapas:</label>
                    <v-select v-model="obra.lista_cor_chapa" :options="lista_cor_chapa" label="descricao"
                        placeholder="Escolha uma opção" class="bg-white"
                        :multiple="true" />
                </div>
            </div>
        </div>

        <div class="form-group">
            <!-- <h2 class="text-lg text-corBase font-bold pb-0 pt-2">Contramarco</h2> -->
            <div class="flex flex-col gap-6 md:flex-row pb-6">

                <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Contramarco:</label>
                    <v-select v-model="obra.contramarco" :options="lista_contramarco_arremate" label="label"
                        placeholder="Escolha uma opção" class="bg-white" :reduce="contramarco => contramarco.value"
                        :clearable="false" />
                </div>

                <div v-if="obra.contramarco" class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Medida do Contramarco:</label>
                    <v-select v-model="obra.medida_contramarco" :options="lista_medida_contramarco" label="label"
                        placeholder="Escolha uma opção" class="bg-white"
                        :reduce="medida_contramarco => medida_contramarco.value" :clearable="false" />
                </div>

                <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Perfil de Arremate:</label>
                    <v-select v-model="obra.arremate" :options="lista_contramarco_arremate" label="label"
                        placeholder="Escolha uma opção" class="bg-white" :reduce="arremate => arremate.value"
                        :clearable="false" />
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios'

export default {
    name: 'EspecificacoesGerais',

    components: {
        vSelect,
    },

    props: {
        obra: Object
    },
    data() {
        return {
            lista_cor_componente: [],
            lista_cor_perfil: [],
            lista_cor_vidro: [],
            lista_cor_chapa: [],

            lista_contramarco_arremate: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false }
            ],

            lista_medida_contramarco: [
                { label: 'Interna', value: 'Interna' },
                { label: 'Externa', value: 'Externa' }
            ],
        }
    },

    created() {
        this.getListaCorComponente()
        this.getListaCorPerfil()
        this.getListaCorVidro()
        this.getListaCorChapa()
    },

    methods: {

        async getListaCorComponente() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-produto-base/?tipo_produto=Componente&ativo=True&page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_cor_componente = response.data.results.map(response => {
                    return {
                        id: response.id,
                        descricao: response.descricao
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getListaCorPerfil() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-produto-base/?tipo_produto=Perfil&ativo=True&page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_cor_perfil = response.data.results.map(response => {
                    return {
                        id: response.id,
                        descricao: response.descricao
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getListaCorVidro() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/vidro/?ativo=True&page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_cor_vidro = response.data.results.map(response => {
                    return {
                        id: response.id,
                        descricao: response.descricao
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getListaCorChapa() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-produto-base/?tipo_produto=Chapa&ativo=True&page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_cor_chapa = response.data.results.map(response => {
                    return {
                        id: response.id,
                        descricao: response.descricao
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

    }
}
</script>

<style scoped></style>