<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr class="border-b">
          <th scope="col" class="px-6 py-2 text-center">#</th>
          <th scope="col" class="px-6 py-2 text-center">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-2 text-start">Opções</th>
          <th scope="col" class="px-6 py-2 text-start">Perfil</th>
          <th scope="col" class="px-6 py-2 text-start">Imagem</th>
          <th scope="col" class="px-6 py-2 text-start">Descrição</th>
          <th scope="col" class="px-6 py-2 text-start">Peso</th>
          <th scope="col" class="px-6 py-2 text-start">Condição</th>
          <th scope="col" class="px-6 py-2 text-start">Corte</th>
          <th scope="col" class="px-6 py-2 text-start">QTDE/Fórmula</th>
          <th scope="col" class="px-6 py-2 text-start">Medida/Fórmula</th>
          <th scope="col" class="px-6 py-2 text-start">Posição</th>
          <th scope="col" class="px-6 py-2 text-start">Observação</th>
          <th scope="col" class="px-28 py-2 text-start">Cor</th>
          <th scope="col" class="px-12 py-2 text-start">Fase</th>
          <th scope="col" class="px-10 py-2 text-start">Aplicação</th>
        </tr>
      </thead>

      <!-- Linhas de Perfis -->
      <draggable :list="perfis" tag="tbody" handle=".handle" itemKey="id">
        <template v-slot:item="{ element, index }">
          <tr :key="index" class="escopo-tabela">
            <th scope="col" class="text-center font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              <div class="handle cursor-pointer">
                <span>☰</span> <!-- Handle para arrastar -->
                {{ element.index }}
              </div>
            </th>
            <td scope="col" class="text-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="element.selected">
            </td>
            <td class="w-full text-center">
              <i class="fa-solid fa-magnifying-glass cursor-pointer mr-2"
                @click.prevent="toggleMenuProdutos(element.id)"></i>
              <i class="fa-solid fa-copy cursor-pointer" @click.prevent="toggleMenuCopiar(element)"></i>
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.perfil_id" :options="perfisPesquisados" label="codigo_base"
                class="bg-white w-32" :clearable="false" @input="(s) => buscarPerfis(s.target.value)"
                @keydown.enter="atualizarUnicoPerfil(element.perfil_id, element.index)">
                <template v-slot:option="option">
                  <div @click.prevent="atualizarUnicoPerfil(option, element.index)">
                    <span class="font-bold">
                      {{ option.codigo_base }}
                    </span> -
                    {{ option.nome_base }}
                  </div>
                </template>
              </v-select>
              Cor Base: {{ element.perfil_id.cor_base?.nome || 'S/C' }} <br>
              Cor selecionada: {{ element.cor?.nome }} <br>
            </td>
            <td scope="col" class="text-center text-xs flex justify-center items-center">
              <div>
                <img v-if="element.perfil_id.imagem" :src="element.perfil_id.imagem.url_documento"
                  class="cursor-pointer w-10 h-10" @click="openPreviewImage(element.perfil_id.imagem.url_documento)">
                <img v-else
                  src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                  alt="Sem foto" class="w-10 h-10">
              </div>
            </td>

            <td scope="col" class="text-center text-xs">
              {{ element.perfil_id.nome_base }}
            </td>
            <td scope="col" class="text-center text-xs">
              {{ element.perfil_id.peso_base }}
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.condicao">
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.corte" class="input-campos">
                <option v-for="corte in ['45/45', '45/90', '90/45', '90/90']" :key="corte" :value="corte">
                  {{ corte }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.qtd_formula">
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.medida_formula">
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.posicao" class="input-campos">
                <option v-for="posicao in ['H', 'L', 'V']" :key="posicao" :value="posicao">
                  {{ posicao }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.observacao">
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.cor" :options="coresPerfil" label="nome" class="bg-white w-full custom-width"
                :disabled="element.perfil_id.cor_base?.nome == element.cor?.nome" placeholder="Escolha uma opção" />
              Tem cor igual: {{ element.perfil_id.cor_base?.nome == element.cor?.nome }}
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.fase_esquadria" :options="fasesEsquadria" label="descricao"
                class="bg-white w-full custom-width" placeholder="Escolha uma opção" />
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.aplicacao" class="input-campos">
                <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                  {{ aplicacao }}
                </option>
              </select>
            </td>
          </tr>
        </template>
      </draggable>

      <tr v-if="adicionarNovoPerfil" class="escopo-tabela">
        <td class="w-full"></td>
        <td class="w-full"></td>
        <td class="w-full text-center">
          <i class="fa-solid fa-magnifying-glass cursor-pointer" @click.prevent="toggleMenuProdutos()"></i>
        </td>
        <td class="w-full">
          <v-select v-model="perfilSelecionado" label="codigo_base" @input="(s) => buscarPerfis(s.target.value)"
            :options="perfisPesquisados" @select="onSelect()">
            <template v-slot:option="option">
              <div class="w-full" @click.prevent="onSelect(option)">
                <span class="font-bold">
                  {{ option.codigo_base }}
                </span> -
                {{ option.nome_base }}
              </div>
            </template>
          </v-select>
        </td>
        <td scope="col" class="text-center text-xs flex justify-center items-center">
          <div>
            <img v-if="novoPerfil.imagem" :src="novoPerfil.imagem" class="cursor-pointer w-10 h-10"
              @click.prevent="openPreviewImage(novoPerfil.imagem)">
            <img v-else
              src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
              alt="Sem foto" class="w-10 h-10">
          </div>
        </td>
        <td class="w-full text-center">
          {{ novoPerfil.descricao }}
        </td>
        <td class="w-full text-center">
          {{ novoPerfil.peso }}
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.condicao">
        </td>
        <td class="w-full">
          <select v-model="novoPerfil.corte" class="input-campos">
            <option v-for="corte in ['45/45', '45/90', '90/45', '90/90']" :key="corte" :value="corte">
              {{ corte }}
            </option>
          </select>
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.qtd_formula">
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.medida_formula">
        </td>
        <td class="w-full">
          <select v-model="novoPerfil.posicao" class="input-campos">
            <option v-for="posicao in ['H', 'L', 'V']" :key="posicao" :value="posicao">
              {{ posicao }}
            </option>
          </select>
        </td>
        <td class="w-full">
          <input class="input-campos" v-model="novoPerfil.observacao">
        </td>
        <td class="w-full">
          <v-select v-model="novoPerfil.cor" :options="coresPerfil" label="nome" class="bg-white w-full custom-width"
            :disabled="corProprioPerfil" :reduce="cor => cor.id" placeholder="Escolha uma opção" />
          Tem cor igual: {{ corProprioPerfil }}
        </td>
        <td class="w-full">
          <v-select v-model="novoPerfil.fase_esquadria" :options="fasesEsquadria" label="descricao"
            class="bg-white w-full custom-width" :reduce="fase => fase.id" :clearable="false"
            placeholder="Escolha uma opção" />
        </td>
        <td class="w-full">
          <select v-model="novoPerfil.aplicacao" class="input-campos">
            <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
              {{ aplicacao }}
            </option>
          </select>
        </td>
      </tr>

    </table>
    <div v-if="adicionarNovoPerfil" class="flex w-full justify-end itens-center mt-6 px-4 mb-5">
      <button class="btn-cancelar" @click.prevent="togglePerfil">
        Cancelar
      </button>
      <button class="btn-salvar" @click.prevent="postNovoPerfil">
        Adicionar
      </button>
    </div>
    <div v-else class="flex justify-center">
      <button class="text-ativo" @click.prevent="togglePerfil">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>

  <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">
    <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">
      <button class="botoes bg-red-500 w-full sm:w-auto" @click.prevent="verificarExclusao()">
        Excluir Selecionados
      </button>
      <button class="botoes bg-blue-500 w-full sm:w-auto" @click.prevent="alterarSelecionados()">
        Alterar Selecionados
      </button>
      <button class="botoes bg-orange-500 w-full sm:w-auto" @click.prevent="exportarPerfis()">
        Exportar Perfis
      </button>
      <button class="botoes bg-yellow-500 w-full sm:w-auto" @click="triggerFileInput">
        Importar Perfis
      </button>
    </div>

    <input type="file" ref="fileInput" class="hidden" @change="importarPerfis" accept=".csv" />

    <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end">
      <button class="btn-salvar w-full sm:w-auto" @click.prevent="atualizarPerfis(perfis)">
        Salvar/Atualizar
      </button>
    </div>
  </div>


  <div v-if="erroModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
      <p class="text-gray-700">{{ erroMessage }}</p>
      <div class="mt-6">
        <button
          class="bg-red-500 border text-white px-4 py-2 rounded hover:bg-screenCor hover:text-red-500 hover:border-red-500"
          @click="fecharErroModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>

  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="fecharModalConfirmacao" @confirmarExclusao="excluirSelecionados" />

  <alterar-perfis v-if="modalAlterarSelecionados === true" @fecharModal="toggleAlterarSelecionados()"
    @atualizarLista="atualizarLista()" :perfis="perfisSelecionados" :coresPerfil="coresPerfil"
    :fasesEsquadria="fasesEsquadria" />

  <copiar-item-lista v-if="modalCopiar" produto="Perfil" @fecharModal="toggleMenuCopiar()"
    @atualizarLista="atualizarLista()" :itemParaCopiar="itemParaCopiar" />

  <modal-produtos v-if="modalProdutos === true" produto="Perfil" :itemListaSelecionado="itemListaSelecionado"
    @itemNovoProduto="onSelect" @atualizarLista="atualizarLista()" @fecharModal="toggleMenuProdutos()" />

  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

  <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

  <erro-rapido v-if="erro === true" :mensagem="erroMessage" />

  <sucesso-rapido v-if="sucesso === true" :mensagem="sucessoMessage" />

</template>

<script>
import axios from 'axios'
import Search from '@/utils/Search.vue'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
import AlterarPerfis from '@/components/midais/Editar/projeto/alterar_selecionados/AlterarPerfis.vue'
import ModalProdutos from '@/components/cadastros_projetos_componentes/alterar_projeto/ModalProdutos.vue'
import CopiarItemLista from '@/components/midais/Editar/projeto/CopiarItemLista.vue'
import draggable from 'vuedraggable'
import Search2 from '@/utils/Search2.vue'
import vSelect from "vue-select";
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue'
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue'
import Papa from "papaparse";

export default {
  name: "PerfilProjeto",

  props: ['perfis', 'projeto_id'],

  emits: ['atualizarPerfis', 'getListaPerfisProjeto', 'atualizarUnicoPerfil'],

  data() {
    return {
      perfilSelecionado: [],
      perfisPesquisados: [],
      fasesEsquadria: [],
      coresPerfil: [],
      preview_imagem: '',

      novoPerfil: {
        projeto_id: this.projeto_id,
        perfil_id: null,
        descricao: '',
        peso: '',
        corte: '',
        qtd_formula: '',
        medida_formula: '',
        posicao: '',
        condicao: '',
        cor: '',
        observacao: '',
        fase_esquadria: '',
        aplicacao: 'A Definir',
        index: this.perfis.length + 1
      },

      checkAll: false,
      modal_confirmacao: false,

      modalProdutos: false,
      itemListaSelecionado: '',

      modalCopiar: false,
      itemParaCopiar: [],

      adicionarNovoPerfil: false,

      modalAlterarSelecionados: false,
      perfisSelecionados: [],

      exibirErros: false,
      erros: {},
      erro: false,
      erroModal: false,
      erroMessage: '',

      sucesso: false,
      sucessoMessage: '',
    }
  },

  components: {
    Search,
    MensagemConfirmacaoExclusao,
    AlterarPerfis,
    ModalProdutos,
    CopiarItemLista,
    draggable,
    Search2,
    vSelect,
    PreviewImage,
    ListagemErros,
    ErroRapido,
    SucessoRapido,
  },

  created() {
    this.buscaFases()
    this.buscaCoresPerfil()
  },

  computed: {
    checkSome() {
      return this.perfis.some(perfil => perfil.selected)
    }
  },

  methods: {

    async buscaCoresPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(`produtos/cor-perfil/?page_size=9999`, {
          headers: { Authorization: `Token ${token}` }
        });
        this.coresPerfil = response.data.results;
      } catch (error) {
        console.error(error);
      }
    },

    async buscaFases() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(`projeto/fase-esquadria/?page_size=9999`, {
          headers: { Authorization: `Token ${token}` }
        });
        this.fasesEsquadria = response.data.results;

        const faseDefault = this.fasesEsquadria.find(item => item.descricao === 'A Definir');
        this.novoPerfil.fase_esquadria = faseDefault ? faseDefault.id : null;
      } catch (error) {
        console.error(error);
      }
    },

    async buscarPerfis(searchQuery) {
      if (searchQuery.length > 2) {
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest');

          const response = await axios.get(`produtos/produto/?tipo_produto=Perfil&filtro_base=${searchQuery}&page_size=9999`, {
            headers: { Authorization: `Token ${token}` }
          });
          this.perfisPesquisados = response.data.results;
        } catch (error) {
          console.error(error);
        }
      } else {
        this.perfisPesquisados = [];
      }
    },

    onSelect(perfil) {
      console.log(perfil)
      this.perfilSelecionado = `${perfil.codigo_base}`

      console.log(perfil.cor_base?.nome)

      console.log(this.coresPerfil)

      if (perfil.cor_base?.nome) {
        // Busca a cor correspondente no array `coresPerfil`
        const corEncontrada = this.coresPerfil.find(cor => cor.nome === perfil.cor_base.nome);

        // Se encontrar, define o campo `novoComponente.cor` como o ID da cor encontrada
        if (corEncontrada) {
          this.novoPerfil.cor = corEncontrada.id;
          this.corProprioPerfil = true
          alert('achou a cor')
        } else {
          console.warn(`Cor com o nome "${perfil.cor_base.nome}" não encontrada em coresPerfil.`);
          this.corProprioPerfil = false
          alert('n achou a cor')
        }
      }

      this.novoPerfil.perfil_id = perfil.id
      this.novoPerfil.descricao = perfil.nome_base
      this.novoPerfil.peso = perfil.peso_base
      this.novoPerfil.imagem = perfil.imagem?.url_documento
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // SEM PAPAPARSE
    // exportarPerfis() {
    //   const headers = [
    //     "Código",
    //     "Condição",
    //     "Corte",
    //     "QTDE/Fórmula",
    //     "Medida/Fórmula",
    //     "Posição",
    //     "Observação",
    //     "Cor",
    //     "Fase Montagem",
    //     "Aplicação",
    //   ];

    //   const escapeCSV = (value) => {
    //     if (value == null) return ""; // Tratamento para valores nulos ou undefined
    //     const escaped = value.toString().replace(/"/g, '""'); // Escape de aspas duplas
    //     return `"${escaped}"`; // Envolver em aspas duplas para garantir compatibilidade
    //   };

    //   const rows = this.perfis.map((perfil) => [
    //     escapeCSV(perfil.perfil_id?.codigo_base),
    //     escapeCSV(perfil.condicao),
    //     escapeCSV(perfil.corte),
    //     escapeCSV(perfil.qtd_formula),
    //     escapeCSV(perfil.medida_formula),
    //     escapeCSV(perfil.posicao),
    //     escapeCSV(perfil.observacao),
    //     escapeCSV(perfil.cor?.nome),
    //     escapeCSV(perfil.fase_esquadria?.descricao),
    //     escapeCSV(perfil.aplicacao),
    //   ]);

    //   const csvContent = [headers, ...rows]
    //     .map((row) => row.join(",")) // Junta os valores com vírgula
    //     .join("\r\n"); // Usa \r\n para maior compatibilidade

    //   const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // Adiciona BOM para encoding UTF-8
    //   const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });

    //   const link = document.createElement("a");
    //   link.href = URL.createObjectURL(blob);
    //   link.download = "perfis.csv";
    //   document.body.appendChild(link); // Garante que o link esteja no DOM
    //   link.click();
    //   document.body.removeChild(link); // Remove o link após o download
    // },

    // Método para importar os dados


    exportarPerfis() {
      if (!this.perfis || this.perfis.length === 0) {
        console.warn("Nenhum perfil disponível para exportação.");
        return;
      }

      const headers = [
        "Código",
        "Condição",
        "Corte",
        "QTDE/Fórmula",
        "Medida/Fórmula",
        "Posição",
        "Observação",
        "Cor",
        "Fase Montagem",
        "Aplicação",
      ];

      const data = this.perfis.map((perfil) => ({
        "Código": perfil.perfil_id.codigo_base || "",
        "Condição": perfil.condicao || "",
        "Corte": perfil.corte || "",
        "QTDE/Fórmula": perfil.qtd_formula || "",
        "Medida/Fórmula": perfil.medida_formula || "",
        "Posição": perfil.posicao || "",
        "Observação": perfil.observacao || "",
        "Cor": perfil.cor?.nome || "",
        "Fase Montagem": perfil.fase_esquadria?.descricao || "",
        "Aplicação": perfil.aplicacao || "",
      }));

      const csvContent = Papa.unparse(data, {
        quotes: true,
        delimiter: ",",
        header: true,
        skipEmptyLines: true,
      });

      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "perfis.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    importarPerfis(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;

        const parsedData = Papa.parse(content, {
          header: true,
          skipEmptyLines: false,
          transform: (value) => (value === "" ? null : value),
        });

        const rows = parsedData.data;

        console.log("Dados importados:", rows);

        this.erros = { erros: [] };

        let perfisProcessados = [];

        for (const [index, row] of rows.entries()) {
          const perfil = {
            codigo: row["Código"]?.trim() || null,
            condicao: row["Condição"]?.trim() || null,
            corte: row["Corte"]?.trim() || null,
            qtd_formula: row["QTDE/Fórmula"]?.trim() || null,
            medida_formula: row["Medida/Fórmula"]?.trim() || null,
            posicao: row["Posição"]?.trim() || null,
            observacao: row["Observação"]?.trim() || null,
            cor: row["Cor"]?.trim() || null,
            fase_esquadria: row["Fase Montagem"]?.trim() || null,
            aplicacao: row["Aplicação"]?.trim() || null,
          };

          console.log("Perfil importado:", perfil);

          const camposFaltando = [];
          if (!perfil.codigo) camposFaltando.push("Código");
          if (!perfil.corte) camposFaltando.push("Corte");
          if (!perfil.qtd_formula) camposFaltando.push("QTDE/Fórmula");
          if (!perfil.medida_formula) camposFaltando.push("Medida/Fórmula");
          if (!perfil.fase_esquadria) camposFaltando.push("Fase Montagem");

          if (camposFaltando.length > 0) {
            this.erros.erros.push(
              `Linha ${index + 2}: Campos obrigatórios ausentes: ${camposFaltando.join(", ")}`
            );
            continue;
          }

          perfisProcessados.push(perfil);
        }

        if (this.erros.erros.length > 0) {
          console.error("Erro(s) encontrado(s):", this.erros);
          this.exibirErros = true;
          return;
        }

        this.$store.dispatch("setLoading", true);

        try {
          // Executar requisições em paralelo para melhorar o desempenho
          await Promise.all(
            perfisProcessados.map(async (perfil, index) => {
              try {
                const codigoResponse = await this.getIDPerfil(perfil.codigo);
                perfil.perfil_id = codigoResponse?.data?.results[0]?.id || null;

                if (perfil.cor) {
                  const corResponse = await this.getIDCor(perfil.cor);
                  perfil.cor_id = corResponse?.data?.results[0]?.id || null;
                } else {
                  perfil.cor_id = null;
                }

                if (perfil.fase_esquadria) {
                  const faseResponse = await this.getIDFaseMontagem(perfil.fase_esquadria);
                  perfil.fase = faseResponse?.data?.results[0]?.id || null;
                } else {
                  perfil.fase = null;
                }

                console.log(`Perfil ${index + 1} processado:`, perfil);

                this.novoPerfil = {
                  projeto_id: this.projeto_id,
                  perfil_id: perfil.perfil_id,
                  descricao: perfil.descricao,
                  peso: perfil.peso,
                  corte: perfil.corte,
                  qtd_formula: perfil.qtd_formula,
                  medida_formula: perfil.medida_formula,
                  posicao: perfil.posicao,
                  cor: perfil.cor_id,
                  condicao: perfil.condicao,
                  observacao: perfil.observacao,
                  fase_esquadria: perfil.fase,
                  aplicacao: perfil.aplicacao,
                };

                await this.postNovoPerfil();
              } catch (error) {
                console.error(`Erro ao processar perfil na linha ${index + 2}:`, error);
                this.erros.erros.push(`Linha ${index + 2}: Erro ao buscar dados para o perfil.`);
              }
            })
          );

          this.$store.dispatch("setLoading", false);

          if (this.erros.erros.length > 0) {
            console.error("Erro(s) encontrado(s):", this.erros);
            this.exibirErros = true;
          } else {
            this.sucesso = true;
            this.sucessoMessage = "Perfis importados e cadastrados com sucesso.";

            setTimeout(() => {
              this.sucesso = false;
              this.sucessoMessage = false;
            }, 3000);
          }
        } catch (error) {
          console.error("Erro geral ao importar perfis:", error);
          this.erros.erros.push("Erro inesperado ao processar perfis.");
          this.exibirErros = true;
        }
      };

      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
      reader.readAsText(file);
    },

    async getIDPerfil(codigo) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/produto/?codigo_base=${codigo}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDCor(cor) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/cor-perfil/?cor=${cor}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDFaseMontagem(fase) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`projeto/fase-esquadria/?fase=${fase}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },


    selectAll() {
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
      this.perfis.forEach((perfil) => {
        perfil.selected = this.checkAll;
      });
    },

    verificarExclusao() {
      if (this.checkSome || this.checkAll) {
        this.modal_confirmacao = true;
      } else {
        this.erroModal = true;
        this.erroMessage = 'Selecione pelo menos um Perfil para ser excluído.';
      }
    },

    async deletarPerfis(perfisADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      const perfisADeletarPromisses = perfisADeletar.map(perfil =>
        axios.patch(`projeto/projeto-perfil/${perfil.id}/`, { deletado: 'True' }, {
          headers: { Authorization: `Token ${token}` }
        })
      );

      await Promise.all(perfisADeletarPromisses);
      this.$emit('getListaPerfisProjeto');
    },

    excluirSelecionados() {
      if (this.checkAll) {
        this.deletarPerfis(this.perfis.filter((perfil) => perfil.selected));
        this.modal_confirmacao = false;
        this.checkAll = false;
        return;
      }

      const perfisADeletar = this.perfis.filter(perfil => perfil.selected);
      const perfisAAtualizar = this.perfis.filter(perfil => !perfisADeletar.includes(perfil));

      this.deletarPerfis(perfisADeletar);
      this.modal_confirmacao = false;
      this.atualizarPerfis(perfisAAtualizar);
    },

    fecharModalConfirmacao() {
      this.modal_confirmacao = false;
    },


    atualizarUnicoPerfil(perfil, index) {
      const perfilProjetoAAtualizarId = this.perfis[index - 1].id;

      this.perfisPesquisados = [];
      this.$emit('atualizarUnicoPerfil', perfil.id, perfilProjetoAAtualizarId);
    },

    atualizarPerfis(perfisAAtualizar) {
      perfisAAtualizar.forEach((perfil, index) => {
        perfil.index = index + 1;
        perfil.fase_esquadria = perfil.fase_esquadria?.id || '';
        perfil.cor = perfil.cor?.id || '';
      });

      this.$emit('atualizarPerfis', perfisAAtualizar);
    },

    alterarSelecionados() {
      if (this.checkSome || this.checkAll) {
        this.toggleAlterarSelecionados();
        this.perfisSelecionados = this.perfis.filter(perfil => perfil.selected);
      } else {
        this.erroModal = true;
        this.erroMessage = 'Selecione pelo menos um Perfil para ser alterado.';
      }
    },

    fecharErroModal() {
      this.erroModal = false;
    },

    toggleAlterarSelecionados() {
      this.modalAlterarSelecionados = !this.modalAlterarSelecionados;
    },

    toggleMenuProdutos(itemId) {
      this.itemListaSelecionado = itemId;
      this.modalProdutos = !this.modalProdutos;
    },

    toggleMenuCopiar(item) {
      this.modalCopiar = !this.modalCopiar;
      this.itemParaCopiar = { ...item };
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },


    async postNovoPerfil() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        await axios.post('projeto/projeto-perfil/', this.novoPerfil, {
          headers: { Authorization: `Token ${token}` }
        });

        this.sucesso = true;
        this.sucessoMessage = 'Perfil criado com sucesso!';
        setTimeout(() => this.sucesso = false, 3000);

        this.adicionarNovoPerfil = false;
        this.perfisPesquisados = [];
        this.limparCampos();
        this.atualizarLista();
      } catch (error) {
        console.error(error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    togglePerfil() {
      this.adicionarNovoPerfil = !this.adicionarNovoPerfil;
      this.limparCampos();
    },


    limparCampos() {
      this.perfilSelecionado = [];
      // Object.assign(this.novoPerfil, {
      //   perfil_id: '',
      //   descricao: '',
      //   peso: '',
      //   imagem: '',
      //   corte: '',
      //   qtd_formula: '',
      //   medida_formula: '',
      //   posicao: '',
      //   condicao: '',
      //   cor: '',
      //   observacao: '',
      // });

      this.novoPerfil.perfil_id = ''
      this.novoPerfil.descricao = ''
      this.novoPerfil.peso = ''
      this.novoPerfil.imagem = ''
      this.novoPerfil.corte = ''
      this.novoPerfil.qtd_formula = ''
      this.novoPerfil.medida_formula = ''
      this.novoPerfil.posicao = ''
      this.novoPerfil.condicao = ''
      this.novoPerfil.cor = ''
      this.novoPerfil.observacao = ''

      this.novoPerfil.aplicacao = 'A Definir'

      const faseDefault = this.fasesEsquadria.find(item => item.descricao === 'A Definir');
      this.novoPerfil.fase_esquadria = faseDefault ? faseDefault.id : null;
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },

    atualizarLista() {
      this.$emit('getListaPerfisProjeto');
    }
  }

}
</script>

<style scoped>
.botoes {
  @apply px-4 py-1 text-white
}

.input-campos {
  @apply w-full
}
</style>