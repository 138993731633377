<template>
  <div class="container-formulario">

    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Precificação de Vidro</h1>
      </div>

      <router-link to="/precificacao" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <!-- Botões de navegação -->
    <div class="nav-buttons">
      <button class="nav-button" :class="{ 'active': mostrarDados }" @click.prevent="toggleTela('dados')">
        Margens da Tabela
      </button>

      <button class="nav-button" :class="{ 'active': mostrarProdutos }" @click.prevent="toggleTela('produtos')">
        Produtos da Tabela
      </button>
    </div>

    <!-- Exibição Condicional -->
    <DadosVidro v-if="mostrarDados" />
    <ProdutosTabelaVidro v-if="mostrarProdutos" />

  </div>
</template>

<script>
import DadosVidro from './DadosVidro.vue';
import ProdutosTabelaVidro from './ProdutosTabelaVidro.vue';

export default {
  components: {
    DadosVidro,
    ProdutosTabelaVidro
  },
  data() {
    return {
      mostrarDados: true,
      mostrarProdutos: false,
    };
  },
  methods: {
    toggleTela(tela) {
      if (tela === 'dados') {
        this.mostrarDados = true;
        this.mostrarProdutos = false;
      } else {
        this.mostrarDados = false;
        this.mostrarProdutos = true;
      }
    }
  }
};
</script>

<style scoped>
/* Estilização geral dos botões */
.nav-buttons {
  @apply flex gap-2 w-full justify-center items-center;
}

.nav-button {
  @apply px-4 py-2 text-white rounded-md border border-transparent bg-corBase transition duration-300 hover:bg-screenCor hover:text-corBase;
}

/* Estilização do botão ativo */
.nav-button.active {
  @apply bg-screenCor border-corBase text-corBase
}
</style>
