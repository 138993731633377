<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr class="border-b">
          <th scope="col" class="px-6 py-2 text-center">#</th>
          <th scope="col" class="px-6 py-2 text-center">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll" />
              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-2 text-start">Opções</th>
          <th scope="col" class="px-6 py-2 text-start">Localização</th>
          <th scope="col" class="px-6 py-2 text-start">Condição</th>
          <th scope="col" class="px-6 py-2 text-start">Posição</th>
          <th scope="col" class="px-6 py-2 text-start">Largura</th>
          <th scope="col" class="px-6 py-2 text-start">Altura</th>
          <th scope="col" class="px-6 py-2 text-start">Quantidade</th>
          <th scope="col" class="px-6 py-2 text-start">Observação</th>
          <th scope="col" class="px-10 py-2 text-start">Lado</th>
          <th scope="col" class="px-10 py-2 text-start">Furação</th>
          <th scope="col" class="px-12 py-2 text-center">Fase</th>
          <th scope="col" class="px-10 py-2 text-start">Aplicação</th>
          <th scope="col" class="px-6 py-2 text-start whitespace-nowrap">Acréscimo (%)</th>
          <th scope="col" class="px-6 py-2 text-start whitespace-nowrap">Acréscimo (R$)</th>
        </tr>
      </thead>

      <!-- Linhas de Vidros -->
      <draggable :list="vidros" tag="tbody" handle=".handle" itemKey="id">
        <template v-slot:item="{ element, index }">
          <tr :key="index" class="escopo-tabela">
            <th scope="col" class="text-center font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              <div class="handle cursor-pointer">
                <span>☰</span> <!-- Handle para arrastar -->
                {{ element.index }}
              </div>
            </th>
            <td scope="col" class="text-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                v-model="element.selected" />
            </td>
            <td scope="col" class="text-center text-base">
              <i class="fa-solid fa-copy cursor-pointer" @click.prevent="toggleMenuCopiar(element)"></i>
            </td>
            <td scope="col" class="text-start">
              <v-select v-model="element.area_vidro" :options="listaAreasVidro" label="descricao"
                class="bg-white w-full custom-width" placeholder="Escolha uma opção" />
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.condicao" />
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.posicao" />
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.largura" />
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.altura" />
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.qtd_formula" />
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.observacao" />
            </td>
            <td scope="col" class="text-start text-xs">
              <select class="input-campos" v-model="element.lado">
                <option v-for="lado in ['A Definir', 'Externo', 'Interno']" :key="lado" :value="lado">
                  {{ lado }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <select class="input-campos" v-model="element.furacao">
                <option v-for="furacao in ['A Definir', 'Sim', 'Não']" :key="furacao" :value="furacao">
                  {{ furacao }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.fase_esquadria" label="descricao" :options="fasesEsquadria"
                class="bg-white w-full custom-width" placeholder="Escolha uma opção" />
            </td>
            <td scope="col" class="text-start text-xs">
              <select class="input-campos" v-model="element.aplicacao">
                <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                  {{ aplicacao }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.acrescimoPorcentagem" />
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.acrescimoReais" />
            </td>
          </tr>
        </template>
      </draggable>

      <!-- Adicionar Novo Vidro -->
      <tr v-if="adicionarNovoVidro" class="escopo-tabela">
        <td></td>
        <td></td>
        <td></td>
        <td>
          <v-select v-model="novoVidro.area_vidro" :options="listaAreasVidro" label="descricao" class="bg-white w-full"
            placeholder="Escolha uma opção" :reduce="area => area.id" />
        </td>
        <td><input class="input-campos" v-model="novoVidro.condicao" /></td>
        <td><input class="input-campos" v-model="novoVidro.posicao" /></td>
        <td><input class="input-campos" v-model="novoVidro.largura" /></td>
        <td><input class="input-campos" v-model="novoVidro.altura" /></td>
        <td><input class="input-campos" v-model="novoVidro.qtd_formula" /></td>
        <td><input class="input-campos" v-model="novoVidro.observacao" /></td>
        <td>
          <select class="input-campos" v-model="novoVidro.lado">
            <option v-for="lado in ['A Definir', 'Externo', 'Interno']" :key="lado" :value="lado">
              {{ lado }}
            </option>
          </select>
        </td>
        <td>
          <select class="input-campos" v-model="novoVidro.furacao">
            <option v-for="furacao in ['A Definir', 'Sim', 'Não']" :key="furacao" :value="furacao">
              {{ furacao }}
            </option>
          </select>
        </td>
        <td class="w-full">
          <v-select v-model="novoVidro.fase_esquadria" :options="fasesEsquadria" label="descricao"
            class="bg-white w-full custom-width" :reduce="fase => fase.id" :clearable="false"
            placeholder="Escolha uma opção" />
        </td>
        <td>
          <select class="input-campos" v-model="novoVidro.aplicacao">
            <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
              {{ aplicacao }}
            </option>
          </select>
        </td>
        <td><input type="number" min="0" class="input-campos" v-model="novoVidro.acrescimoPorcentagem" /></td>
        <td><input type="number" min="0" class="input-campos" v-model="novoVidro.acrescimoReais" /></td>
      </tr>
    </table>

    <!-- Botões de Ação -->
    <div v-if="adicionarNovoVidro" class="flex justify-end items-center gap-4 mt-6 px-4 mb-5">
      <button class="btn-cancelar" @click.prevent="toggleVidro">Cancelar</button>
      <button class="btn-salvar" @click.prevent="postNovoVidro">Adicionar</button>
    </div>
    <div v-else class="flex justify-center">
      <button class="text-ativo" @click.prevent="toggleVidro">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>

  </div>

  <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">
    <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">
      <button class="px-4 py-1 text-white bg-red-500 w-full sm:w-auto" @click.prevent="verificarExclusao()">
        Excluir Selecionados
      </button>
      <button class="px-4 py-1 text-white bg-blue-500 w-full sm:w-auto" @click.prevent="alterarSelecionados()">
        Alterar Selecionados
      </button>
      <button class="px-4 py-1 text-white bg-orange-500 w-full sm:w-auto" @click.prevent="exportarVidros()">
        Exportar Vidros
      </button>
      <button class="px-4 py-1 text-white bg-yellow-500 w-full sm:w-auto" @click="triggerFileInput">
        Importar Vidros
      </button>
    </div>

    <input type="file" ref="fileInput" class="hidden" @change="importarVidros" accept=".csv" />

    <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end">
      <button class="btn-salvar w-full sm:w-auto" @click.prevent="atualizarVidros(vidros)">
        Salvar/Atualizar
      </button>
    </div>
  </div>

  <div v-if="erroModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
      <p class="text-gray-700">{{ erroMessage }}</p>
      <div class="mt-6">
        <button
          class="bg-red-500 border text-white px-4 py-2 rounded hover:bg-screenCor hover:text-red-500 hover:border-red-500"
          @click="fecharErroModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>

  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="fecharModalConfirmacao" @confirmarExclusao="excluirSelecionados" />

  <alterar-vidros v-if="modalAlterarSelecionados === true" @fecharModal="toggleAlterarSelecionados()"
    @atualizarLista="atualizarLista()" :vidros="vidrosSelecionados" :listaAreasVidro="listaAreasVidro"
    :fasesEsquadria="fasesEsquadria" />

  <copiar-item-lista v-if="modalCopiar" produto="Vidro" @fecharModal="toggleMenuCopiar()"
    @atualizarLista="atualizarLista()" :itemParaCopiar="itemParaCopiar" />

  <ListagemErros v-if="exibirErros == true" :erros="erros" @fecharModal="toggleErros()" />

  <erro-rapido v-if="erro === true" :mensagem="erroMessage" />

  <sucesso-rapido v-if="sucesso === true" :mensagem="sucessoMessage" />

</template>


<script>
import Search from '@/utils/Search.vue'
import axios from 'axios'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue'
import AlterarVidros from '@/components/midais/Editar/projeto/alterar_selecionados/AlterarVidros.vue'
import CopiarItemLista from '@/components/midais/Editar/projeto/CopiarItemLista.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue'
import draggable from 'vuedraggable'
import vSelect from 'vue-select';
import Papa from "papaparse";

export default {
  name: "VidroProjeto",

  props: ['vidros', 'projeto_id'],

  emits: ['atualizarVidros', 'getListaVidrosProjeto', 'atualizarUnicoVidro'],

  data() {
    return {
      abrir: Boolean,
      novosVidros: [],
      fasesEsquadria: [],
      vidroSelecionado: [],
      vidrosPesquisados: [],

      checkAll: false,
      modal_confirmacao: false,

      listaAreasVidro: [],

      vidroBase: '',

      novoVidro: {
        projeto_id: this.projeto_id,
        vidro_id: '',
        area_vidro: '',
        qtd_formula: '',
        furacao: 'A Definir',
        aplicacao: 'A Definir',
        condicao: '',
        posicao: '',
        lado: 'A Definir',
        acrescimoPorcentagem: '',
        acrescimoReais: '',
        observacao: '',
        index: this.vidros.length + 1
      },

      adicionarNovoVidro: false,

      erro: false,
      erroModal: false,
      erroMessage: '',

      modalCopiar: false,
      itemParaCopiar: [],

      vidrosSelecionados: [],

      modalAlterarSelecionados: false,

      exibirErros: false,
      erros: {},

      sucesso: false,
      sucessoMessage: '',
    }
  },

  components: {
    Search,
    MensagemConfirmacaoExclusao,
    ListagemErros,
    AlterarVidros,
    CopiarItemLista,
    ErroRapido,
    SucessoRapido,
    draggable,
    vSelect
  },

  computed: {
    checkSome() {
      return this.vidros.some(vidro => vidro.selected)
    }
  },

  created() {
    this.getListaAreaVidros()
    this.getVidroBase()
    this.getFasesEsquadria()
  },


  methods: {

    async getListaAreaVidros() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`projeto/area-vidro/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.listaAreasVidro = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    async getVidroBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`produtos/produto/?tipo_produto=Vidro&ativo=True`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.vidroBase = response.data.results[0].id
        this.novoVidro.vidro_id = this.vidroBase
        console.log(this.novoVidro.vidro_id)
      } catch (error) {
        console.error(error)
      }
    },

    async getFasesEsquadria() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`projeto/fase-esquadria/?page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.fasesEsquadria = response.data.results;

        console.log(this.fasesEsquadria)

        const faseDefault = this.fasesEsquadria.find(item => item.descricao === 'A Definir');
        console.log(faseDefault)
        this.novoVidro.fase_esquadria = faseDefault ? faseDefault.id : null;
      } catch (error) {
        console.error(error)
      }
    },


    selectAll() {
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
      this.vidros.forEach((vidro) => {
        vidro.selected = this.checkAll;
      });
    },

    verificarExclusao() {
      if (this.checkSome || this.checkAll) {
        this.modal_confirmacao = true
      } else {
        this.erroModal = true
        this.erroMessage = 'Selecione pelo menos um Vidro para ser excluído.'
      }
    },

    excluirSelecionados() {
      if (this.checkAll) {
        this.deletarVidros(this.vidros.filter((vidro) => vidro.selected))
        this.modal_confirmacao = false
        this.checkAll = false
        return
      }
      const vidrosADeletar = this.vidros.filter((vidro) => vidro.selected)

      const vidrosAAtualizar = this.vidros.filter(vidro =>
        !vidrosADeletar.some(deletar => deletar.id === vidro.id)
      )

      this.deletarVidros(vidrosADeletar)
      this.modal_confirmacao = false
      this.atualizarVidros(vidrosAAtualizar)
    },

    async deletarVidros(vidrosADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const vidrosADeletarPromisses = []

      for (const vidro of vidrosADeletar) {
        vidrosADeletarPromisses.push(
          axios.patch(`projeto/projeto-vidro/${vidro.id}/`, { deletado: 'True' }, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(vidrosADeletarPromisses)
      this.$emit('getListaVidrosProjeto')
    },

    fecharModalConfirmacao() {
      this.modal_confirmacao = false
    },

    toggleMenuCopiar(item) {
      this.modalCopiar = !this.modalCopiar;
      this.itemParaCopiar = { ...item };
    },


    atualizarUnicoVidro(vidro, index) {
      const vidroProjetoAAtualizarId = this.vidros[index - 1].id

      this.vidrosPesquisados = []
      this.$emit('atualizarUnicoVidro', vidro.id, vidroProjetoAAtualizarId)
    },

    atualizarVidros(vidrosAAtualizar) {
      vidrosAAtualizar.forEach((vidro, index) => {
        vidro.index = index + 1
      })

      this.$emit('atualizarVidros', vidrosAAtualizar)
    },

    alterarSelecionados() {
      if (this.checkSome || this.checkAll) {
        this.vidrosSelecionados = this.vidros.filter(vidro => vidro.selected)
        console.log(this.vidrosSelecionados)
        this.toggleAlterarSelecionados()
      } else {
        this.erroModal = true
        this.erroMessage = 'Selecione pelo menos um Vidro para ser alterado.'
      }
    },

    fecharErroModal() {
      this.erroModal = false
    },

    toggleAlterarSelecionados() {
      this.modalAlterarSelecionados = !this.modalAlterarSelecionados
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // SEM PAPAPARSE
    // exportarVidros() {
    //   const headers = [
    //     "Localização",
    //     "Condição",
    //     "Posição",
    //     "Largura",
    //     "Altura",
    //     "Quantidade",
    //     "Observação",
    //     "Lado",
    //     "Furação",
    //     "Fase",
    //     "Aplicação",
    //     "Acréscimo (%)",
    //     "Acréscimo (R$)",
    //   ];

    //   console.log(this.vidros)
    //   const rows = this.vidros.map((vidro) => [
    //     vidro.area_vidro?.descricao || "",
    //     vidro.condicao || "",
    //     vidro.posicao || "",
    //     vidro.largura || "",
    //     vidro.altura || "",
    //     vidro.qtd_formula || "",
    //     vidro.observacao || "",
    //     vidro.lado || "",
    //     vidro.furacao || "",
    //     vidro.fase_esquadria?.descricao || "",
    //     vidro.aplicacao || "",
    //     vidro.acrescimoPorcentagem || "",
    //     vidro.acrescimoReais || "",
    //   ]);

    //   const csvContent = [headers, ...rows]
    //     .map((row) => row.join(","))
    //     .join("\n");

    //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    //   const link = document.createElement("a");
    //   link.href = URL.createObjectURL(blob);
    //   link.download = "vidros.csv";
    //   link.click();
    // },

    // Método para importar os dados

    exportarVidros() {
      if (!this.vidros || this.vidros.length === 0) {
        console.warn("Nenhum vidro disponível para exportação.");
        return;
      }

      const headers = [
        "Localização",
        "Condição",
        "Posição",
        "Largura",
        "Altura",
        "Quantidade",
        "Observação",
        "Lado",
        "Furação",
        "Fase",
        "Aplicação",
        "Acréscimo (%)",
        "Acréscimo (R$)",
      ];

      const data = this.vidros.map((vidro) => ({
        "Localização": vidro.area_vidro?.descricao || "",
        "Condição": vidro.condicao || "",
        "Posição": vidro.posicao || "",
        "Largura": vidro.largura || "",
        "Altura": vidro.altura || "",
        "Quantidade": vidro.qtd_formula || "",
        "Observação": vidro.observacao || "",
        "Lado": vidro.lado || "",
        "Furação": vidro.furacao || "",
        "Fase": vidro.fase_esquadria?.descricao || "",
        "Aplicação": vidro.aplicacao || "",
        "Acréscimo (%)": vidro.acrescimoPorcentagem || "",
        "Acréscimo (R$)": vidro.acrescimoReais || "",
      }));

      const csvContent = Papa.unparse(data, {
        quotes: true,
        delimiter: ",",
        header: true,
        skipEmptyLines: true,
      });

      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "vidros.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    importarVidros(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;

        const parsedData = Papa.parse(content, {
          header: true,
          skipEmptyLines: false,
          transform: (value) => (value === "" ? null : value),
        });

        const rows = parsedData.data;

        console.log("Dados importados:", rows);

        this.erros = { erros: [] };

        for (const [index, row] of rows.entries()) {
          const vidros = {
            area_vidro: row["Localização"]?.trim() || null,
            condicao: row["Condição"]?.trim() || null,
            posicao: row["Posição"]?.trim() || null,
            largura: row["Largura"]?.trim() || null,
            altura: row["Altura"]?.trim() || null,
            qtd_formula: row["Quantidade"]?.trim() || null,
            observacao: row["Observação"]?.trim() || null,
            lado: row["Lado"]?.trim() || null,
            furacao: row["Furação"]?.trim() || null,
            fase_esquadria: row["Fase"]?.trim() || null,
            aplicacao: row["Aplicação"]?.trim() || null,
            acrescimoPorcentagem: row["Acréscimo (%)"]?.trim() || null,
            acrescimoReais: row["Acréscimo (R$)"]?.trim() || null,
          };

          console.log("Vidro importado:", vidros);

          const camposFaltando = [];
          if (!vidros.area_vidro) camposFaltando.push("Localização");
          if (!vidros.fase_esquadria) camposFaltando.push("Fase Esquadria");
          if (!vidros.largura) camposFaltando.push("Largura");
          if (!vidros.altura) camposFaltando.push("Altura");
          if (!vidros.qtd_formula) camposFaltando.push("Quantidade");

          if (camposFaltando.length > 0) {
            this.erros.erros.push(
              `Linha ${index + 2}: Campos obrigatórios ausentes: ${camposFaltando.join(", ")}`
            );
            continue;
          }

          this.$store.dispatch("setLoading", true);
          try {
            if (vidros.area_vidro) {
              const areaVidroResponse = await this.getIDAreaVidro(vidros.area_vidro);
              vidros.area_vidro = areaVidroResponse?.data?.results[0]?.id || null;
            } else {
              vidros.area_vidro = null;
            }

            if (vidros.fase_esquadria) {
              const faseResponse = await this.getIDFaseMontagem(vidros.fase_esquadria);
              vidros.fase_esquadria = faseResponse?.data?.results[0]?.id || null;
            } else {
              vidros.fase_esquadria = null;
            }

            console.log("Vidro Processado:", vidros);

            this.novoVidro = {
              projeto_id: this.projeto_id,
              vidro_id: this.vidroBase,
              area_vidro: vidros.area_vidro,
              condicao: vidros.condicao,
              posicao: vidros.posicao,
              largura: vidros.largura,
              altura: vidros.altura,
              qtd_formula: vidros.qtd_formula,
              observacao: vidros.observacao,
              lado: vidros.lado,
              furacao: vidros.furacao,
              fase_esquadria: vidros.fase_esquadria,
              aplicacao: vidros.aplicacao,
              acrescimoPorcentagem: vidros.acrescimoPorcentagem,
              acrescimoReais: vidros.acrescimoReais,
            };

            await this.postNovoVidro();
            this.$store.dispatch("setLoading", false);
          } catch (error) {
            console.error(`Erro ao processar o Vidro na linha ${index + 2}:`, error);
            this.erros.erros.push(`Linha ${index + 2}: Erro ao buscar dados para o Vidro.`);
          }
        }

        if (this.erros.erros.length > 0) {
          console.error("Erro(s) encontrado(s):", this.erros);
          this.exibirErros = true;
        } else {
          this.sucesso = true;
          this.sucessoMessage = "Vidros importados e cadastrados com sucesso.";

          setTimeout(() => {
            this.sucesso = false;
            this.sucessoMessage = false;
          }, 3000);
        }
      };

      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
      reader.readAsText(file);
    },


    async getIDAreaVidro(codigo) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`projeto/area-vidro/?area_vidro=${codigo}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDFaseMontagem(codigo) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`projeto/fase-esquadria/?fase=${codigo}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    toggleVidro() {
      this.adicionarNovoVidro = !this.adicionarNovoVidro
      this.limparCampos()
    },

    async postNovoVidro() {
      try {
        console.log(this.novoVidro);
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        await axios.post('projeto/projeto-vidro/', this.novoVidro, {
          headers: {
            Authorization: `Token ${token}`
          }
        });

        // Resetando variáveis após a requisição
        this.adicionarNovoVidro = false;
        this.novosVidros = [];
        this.$emit('getListaVidrosProjeto');
        this.limparCampos();

      } catch (error) {
        console.error(error)
        if (error.response && error.response.data) {
          this.erros = error.response.data;
        } else {
          this.erros = { geral: ["Ocorreu um erro desconhecido."] };
        }
        this.toggleErros()
        console.log(this.exibirErros)
      }
    },

    atualizarLista() {
      this.$emit('getListaVidrosProjeto')
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },


    limparCampos() {
      this.novoVidro.area_vidro = ''
      this.novoVidro.largura = ''
      this.novoVidro.altura = ''
      this.novoVidro.qtd_formula = ''
      this.novoVidro.condicao = ''
      this.novoVidro.posicao = ''
      this.novoVidro.acrescimoPorcentagem = ''
      this.novoVidro.acrescimoReais = ''
      this.novoVidro.observacao = ''

      this.novoVidro.lado = 'A Definir'
      this.novoVidro.furacao = 'A Definir'
      this.novoVidro.aplicacao = 'A Definir'

      const faseDefault = this.fasesEsquadria.find(item => item.descricao === 'A Definir');
      this.novoVidro.fase_esquadria = faseDefault ? faseDefault.id : null;
    }
  }

}
</script>

<style scoped>
.input-campos {
  @apply w-full
}

.custom-width {
  width: 200px;
}
</style>