<template>

    <div class="container-formulario">
  
      <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
      <div class="container-cabecalho flex justify-between mb-4">
        <div>
          <h1 class="text-xl text-corBase font-bold">Métodos de Pagamento</h1>
        </div>
  
        <router-link to="/financeiro" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
  
      </div>
  
      <div class="flex items-center gap-3 mb-10">
  
        <div class="flex gap-8">
          <div>
            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
    text-white hover:text-corBase py-2 px-4" @click="getListaMetodosPagamento(1)">Pesquisar
            </button>
          </div>
        </div>
        <div>
          <button @click="$router.push(`/metodos-pagamento/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4 ">Adicionar
          </button>
        </div>
      </div>
  
      <!-- Container da exibiçao da lista de pessoas -->
      <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
          <thead class="cabecalho-tabela">
            <tr>
              <th scope="col" class="p-4">
                <div class="flex items-center">
                  <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
  
                  <label class="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" class="px-6 py-3">
                #
              </th>
              <th class="px-6 w-full py-3">
                Método
              </th>
              <th scope="col" class="px-6 py-3">
                Status
              </th>
              <th scope="col" class="px-10 py-3">
                Ação
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tipo, index) in lista_metodos_pagamento" :key="index" class="escopo-tabela">
  
              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="tipo.selected">
                  <label class="sr-only">checkbox</label>
                </div>
              </td>
              <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                {{ index + 1 }}
              </th>
              <td class="px-6 py-2 text-xs">
                {{ tipo.metodo }}
              </td>
  
              <template v-if="tipo.ativo === true">
                <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                  <i class="fa-solid fa-circle-check  text-ativo"></i>
                </td>
              </template>
              <template v-if="tipo.ativo === false">
                <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                  <i class="fa-solid fa-circle-xmark text-inativo"></i>
                </td>
              </template>
  
              <td class="px-6 py-2 text-xs">
                <a @click="selecionarMetodoEditar(tipo)" href="#" class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
              </td>
  
            </tr>
          </tbody>
        </table>
  
        <Pagination v-if="lista_metodos_pagamento.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
          @change-page="mudarPagina" />
      </div>
    </div>
    
    <EditarMetodoPagamento v-if="mostrarModal" :dadosMetodo="metodoSelecionado" @fecharModal="toggleModal()"></EditarMetodoPagamento>
  </template>
  
  <script>
  import axios from "axios";
  import Pagination from "@/utils/Pagination.vue";
  import EditarMetodoPagamento from "@/components/midais/Editar/tipo/EditarMetodoPagamento.vue";
  
  export default {
    name: "ListaMetodosPagamento",
  
    components: {
      Pagination,
      EditarMetodoPagamento
    },
  
    data() {
      return {
        lista_metodos_pagamento: [],
  
        checkAll: false,
        // varival que inicializa o numero de paginas
        paginaAtual: 1,
        countItens: '',

        metodoSelecionado: null,
        mostrarModal: false,
  
        error: [],
      }
    },
  
    computed: {
      minhaEmpresa() {
        return this.$store.state.minhaEmpresa;
      },
  
      isadmin() {
        return this.$store.state.isAdminUser
      },
    },
  
    created() {
  
    },
  
    methods: {
  
      selectAll() {
        // Atualiza o estado dos demais checkboxes
        const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
          checkbox.checked = this.checkAll;
        });
      },
  
  
      async getListaMetodosPagamento(pagina) {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
  
        this.$store.dispatch("setLoading", true);
  
        try {
          const response = await axios.get(`financeiro/metodos-pagamento/?page=${pagina}`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          });
  
          this.lista_metodos_pagamento = response.data.results;
          console.log(this.lista_metodos_pagamento)
          this.countItens = response.data.count;
  
        } catch (error) {
          console.error("Erro ao buscar a lista de métodos de pagamento:", error);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      },
  
      mudarPagina(pagina) {
        this.paginaAtual = pagina;
        this.getListaMetodosPagamento(pagina);
      },

      selecionarMetodoEditar(metodo){
      this.metodoSelecionado = metodo
      this.toggleModal()
    },

    toggleModal(){
      this.mostrarModal = !this.mostrarModal
    },
  
    }
  }
  </script>
  
  <style lang="scss"></style>