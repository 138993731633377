<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr class="border-b">
          <th scope="col" class="px-6 py-2 text-center">#</th>
          <th scope="col" class="px-6 py-2 text-center">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-2 text-start">Opções</th>
          <th scope="col" class="px-6 py-2 text-start">Componente</th>
          <th scope="col" class="px-6 py-2 text-start">Imagem</th>
          <th scope="col" class="px-6 py-2 text-start">Descrição</th>
          <th scope="col" class="px-6 py-2 text-start">Condição</th>
          <th scope="col" class="px-6 py-2 text-start">Quantidade</th>
          <th scope="col" class="px-6 py-2 text-start">Medida</th>
          <th scope="col" class="px-6 py-2 text-start">Observação</th>
          <th scope="col" class="px-28 py-2 text-start">Cor</th>
          <th scope="col" class="px-12 py-2 text-start">Fase</th>
          <th scope="col" class="px-10 py-2 text-start">Aplicação</th>
        </tr>
      </thead>


      <draggable :list="componentes" tag="tbody" handle=".handle" itemKey="id">
        <template v-slot:item="{ element, index }">
          <tr :key="index" class="escopo-tabela">
            <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              <div class="handle cursor-pointer">
                <span>☰</span> <!-- Handle para arrastar -->
                {{ element.index }}
              </div>
            </th>
            <td class="text-center">
              <div class="selec">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="element.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="w-full text-center">
              <i class="fa-solid fa-magnifying-glass cursor-pointer mr-2"
                @click.prevent="toggleMenuProdutos(element.id)"></i>
              <i class="fa-solid fa-copy cursor-pointer" @click.prevent="toggleMenuCopiar(element)"></i>
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.componente_id" :options="componentesPesquisados" label="codigo_base"
                class="bg-white w-32" :clearable="false" @input="(s) => buscarComponentes(s.target.value)"
                @keydown.enter="atualizarUnicoComponente(element.componente_id, element.index)">
                <template v-slot:option="option">
                  <div @click.prevent="atualizarUnicoComponente(option, element.index)">
                    <span class="font-bold">
                      {{ option.codigo_base }}
                    </span> -
                    {{ option.nome_base }}
                  </div>
                </template>
              </v-select>
              Medição: {{ element.componente_id.unidade_medida?.medicao }} <br>
              Cor Base: {{ element.componente_id.cor_base?.nome || 'S/C' }} <br>
              Cor selecionada: {{ element.cor?.nome }} <br>
            </td>
            <td scope="col" class="text-center text-xs flex justify-center items-center">
              <img v-if="element.componente_id.imagem" :src="element.componente_id.imagem.url_documento"
                class="cursor-pointer w-10 h-10" @click="openPreviewImage(element.componente_id.imagem.url_documento)">

              <img v-else
                src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                class="w-10 h-10">
            </td>
            <td scope="col" class="text-center text-xs">
              {{ element.componente_id.nome_base }}
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.condicao">
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.qtd_formula">
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.medida"
                :disabled="!element.componente_id.unidade_medida?.medicao"
                :class="{ 'bg-red-500 cursor-not-allowed': !element.componente_id.unidade_medida?.medicao }" />
            </td>
            <td scope="col" class="text-start text-xs">
              <input class="input-campos" v-model="element.observacao">
            </td>
            <td scope="col" class="text-start text-xs">
              <v-select v-model="element.cor" :options="coresComponente" label="nome"
                :disabled="element.componente_id.cor_base?.nome == element.cor?.nome"
                class="bg-white w-full custom-width" placeholder="Escolha uma opção" />
              Tem cor igual: {{ element.componente_id.cor_base?.nome == element.cor?.nome }}
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.fase_esquadria" class="input-campos">
                <option v-for="fase in fasesEsquadria" :value="fase" :key="fase.id">
                  {{ fase.descricao }}
                </option>
              </select>
            </td>
            <td scope="col" class="text-start text-xs">
              <select v-model="element.aplicacao" class="input-campos">
                <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                  {{ aplicacao }}
                </option>
              </select>
            </td>

          </tr>
        </template>
      </draggable>


      <tbody>
        <tr v-if="adicionarNovoComponente" class="escopo-tabela">
          <td class="w-full"></td>
          <td class="w-full"></td>
          <td class="w-full text-center">
            <i class="fa-solid fa-magnifying-glass cursor-pointer" @click.prevent="toggleMenuProdutos()"></i>
          </td>
          <td class="w-full">
            <v-select v-model="componenteSelecionado" label="codigo_base" :clearable="false"
              @input="(s) => buscarComponentes(s.target.value)" :options="componentesPesquisados" @select="onSelect()">
              <template v-slot:option="option">
                <div class="w-full" @click.prevent="onSelect(option)">
                  <span class="font-bold">
                    {{ option.codigo_base }}
                  </span> -
                  {{ option.nome_base }}
                </div>
              </template>
            </v-select>
            Medição: {{ novoComponente.medicao }}
          </td>
          <td scope="col" class="text-center text-xs flex justify-center items-center">
            <div>
              <img v-if="novoComponente.imagem" :src="novoComponente.imagem" class="cursor-pointer w-10 h-10"
                @click.prevent="openPreviewImage(novoComponente.imagem)">
              <img v-else
                src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                alt="Sem foto" class="w-10 h-10">
            </div>
          </td>
          <td class="w-full text-center">
            {{ novoComponente.descricao }}
          </td>
          <td class="w-full">
            <input class="input-campos" v-model="novoComponente.condicao">
          </td>
          <td class="w-full">
            <input class="input-campos" v-model="novoComponente.qtd_formula">
          </td>
          <td class="w-full">
            <input class="input-campos" v-model="novoComponente.medida" :disabled="!novoComponente.medicao"
              :class="{ 'cursor-not-allowed': !novoComponente.medicao }" />
          </td>
          <td class="w-full">
            <input class="input-campos" v-model="novoComponente.observacao">
          </td>
          <td class="w-full">
            <v-select v-model="novoComponente.cor" :options="coresComponente" label="nome"
              :disabled="corProprioComponente" class="bg-white w-full custom-width" :reduce="cor => cor.id"
              placeholder="Escolha uma opção" />
            Tem cor igual: {{ corProprioComponente }}
          </td>
          <td class="w-full">
            <v-select v-model="novoComponente.fase_esquadria" :options="fasesEsquadria" label="descricao"
              class="bg-white w-full custom-width" :reduce="fase => fase.id" :clearable="false"
              placeholder="Escolha uma opção" />
          </td>

          <td class="w-full">
            <select v-model="novoComponente.aplicacao" class="input-campos">
              <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                {{ aplicacao }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="adicionarNovoComponente" class="flex w-full justify-end itens-center mt-6 px-4 mb-5">
      <button class="btn-cancelar" @click.prevent="toggleComponente">Cancelar</button>
      <button class="btn-salvar" @click.prevent="postNovoComponente">Adicionar</button>
    </div>

    <div v-else class="flex justify-center">
      <button class="text-ativo" @click.prevent="toggleComponente">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>


  <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">
    <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">
      <button class="botoes bg-red-500 w-full sm:w-auto" @click.prevent="verificarExclusao()">
        Excluir Selecionados
      </button>
      <button class="botoes bg-blue-500 w-full sm:w-auto" @click.prevent="alterarSelecionados()">
        Alterar Selecionados
      </button>
      <button class="botoes bg-orange-500 w-full sm:w-auto" @click.prevent="exportarComponentes()">
        Exportar Componentes
      </button>
      <button class="botoes bg-yellow-500 w-full sm:w-auto" @click="triggerFileInput">
        Importar Componentes
      </button>
    </div>

    <!-- Input de arquivo oculto -->
    <input type="file" ref="fileInput" class="hidden" @change="importarComponentes" accept=".csv" />

    <!-- Botão Salvar -->
    <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end">
      <button class="btn-salvar w-full sm:w-auto" @click.prevent="atualizarComponentes(componentes)">
        Salvar/Atualizar
      </button>
    </div>
  </div>

  <div v-if="erroModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
      <p class="text-gray-700">{{ erroMessage }}</p>
      <div class="mt-6">
        <button
          class="bg-red-500 border text-white px-4 py-2 rounded hover:bg-screenCor hover:text-red-500 hover:border-red-500"
          @click="fecharErroModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>

  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="fecharModalConfirmacao" @confirmarExclusao="excluirSelecionados" />

  <modal-produtos v-if="modalProdutos === true" produto="Componente" :itemListaSelecionado="itemListaSelecionado"
    @itemNovoProduto="onSelect" @atualizarLista="atualizarLista()" @fecharModal="toggleMenuProdutos()" />

  <copiar-item-lista v-if="modalCopiar" produto="Componente" @fecharModal="toggleMenuCopiar()"
    @atualizarLista="atualizarLista()" :itemParaCopiar="itemParaCopiar" />

  <alterar-componentes v-if="modalAlterarSelecionados === true" :componentes="componentesSelecionados"
    :fasesEsquadria="fasesEsquadria" @fecharModal="toggleAlterarSelecionados()" @atualizarLista="atualizarLista()" />

  <ListagemErros v-if="exibirErros == true" :erros="erros" @fecharModal="toggleErros()" />

  <erro-rapido v-if="erro === true" :mensagem="erroMessage" />

  <sucesso-rapido v-if="sucesso === true" :mensagem="sucessoMessage" />

</template>

<script>
import Search from '@/utils/Search.vue';
import axios from 'axios'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
import ModalProdutos from '@/components/cadastros_projetos_componentes/alterar_projeto/ModalProdutos.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import CopiarItemLista from '@/components/midais/Editar/projeto/CopiarItemLista.vue';
import AlterarComponentes from '@/components/midais/Editar/projeto/alterar_selecionados/AlterarComponentes.vue';
import draggable from 'vuedraggable'
import Search2 from '@/utils/Search2.vue';
import vSelect from "vue-select";
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';
import Papa from "papaparse";

export default {
  name: "ComponenteProjeto",

  props: ['componentes', 'projeto_id'],

  emits: ['atualizarComponentes', 'getListaComponentesProjeto', 'atualizarUnicoComponente'],

  components: {
    Search,
    MensagemConfirmacaoExclusao,
    ModalProdutos,
    ListagemErros,
    ErroRapido,
    SucessoRapido,
    CopiarItemLista,
    AlterarComponentes,
    draggable,
    Search2,
    vSelect,
    PreviewImage
  },

  computed: {
    checkSome() {
      return this.componentes.some(componente => componente.selected)
    }
  },

  data() {
    return {

      novoComponente: {
        projeto_id: this.projeto_id,
        componente_id: '',
        qtd_formula: '',
        medida: '',
        condicao: '',
        observacao: '',
        cor: '',
        fase_esquadria: '',
        aplicacao: 'A Definir',
        imagem: '',
        descricao: '',
        medicao: '',
        index: this.componentes.length + 1
      },

      corProprioComponente: false,

      novosComponentes: [],
      componenteSelecionado: [],
      componentesPesquisados: [],
      coresComponente: [],
      fasesEsquadria: [],
      preview_imagem: '',
      itemParaCopiar: [],

      modalCopiar: false,

      modalProdutos: false,

      checkAll: false,
      modal_confirmacao: false,

      exibirErros: false,
      erros: {},

      adicionarNovoComponente: false,

      modalAlterarSelecionados: false,

      erro: false,
      erroModal: false,
      erroMessage: '',
      sucesso: false,
      sucessoMessage: '',
    }
  },

  created() {
    this.buscaFases()
    this.buscaCoresComponente()
  },

  methods: {

    async buscaCoresComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(`produtos/cor-componente/?page_size=9999`, {
          headers: { Authorization: `Token ${token}` }
        });
        this.coresComponente = response.data.results;
        console.log(this.coresComponente)
      } catch (error) {
        console.error(error);
      }
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    atualizarUnicoComponente(componente, index) {
      const componenteProjetoAAtualizarId = this.componentes[index - 1].id

      this.componentesPesquisados = []
      this.$emit('atualizarUnicoComponente', componente.id, componenteProjetoAAtualizarId)

    },

    atualizarComponentes(componentesAAtualizar) {
      componentesAAtualizar.forEach((componente, index) => {
        componente.index = index + 1
        componente.fase_esquadria = componente.fase_esquadria ? componente.fase_esquadria.id : ''
      })

      this.$emit('atualizarComponentes', componentesAAtualizar)
    },

    onSelect(componente) {
      console.log(componente)
      this.componenteSelecionado = `${componente.codigo_base}`

      console.log(componente.cor_base?.nome)

      console.log(this.coresComponente)

      if (componente.cor_base?.nome) {
        // Busca a cor correspondente no array `coresComponente`
        const corEncontrada = this.coresComponente.find(cor => cor.nome === componente.cor_base.nome);

        // Se encontrar, define o campo `novoComponente.cor` como o ID da cor encontrada
        if (corEncontrada) {
          this.novoComponente.cor = corEncontrada.id;
          this.corProprioComponente = true
          alert('achou a cor')
        } else {
          console.warn(`Cor com o nome "${componente.cor_base.nome}" não encontrada em coresComponente.`);
          this.corProprioComponente = false
          alert('n achou a cor')
        }
      }


      this.novoComponente.medida = ''
      this.novoComponente.componente_id = componente.id
      this.novoComponente.descricao = componente.nome_base
      this.novoComponente.imagem = componente.imagem?.url_documento
      this.novoComponente.medicao = componente.unidade_medida?.medicao
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
      this.componentes.forEach((componente) => {
        componente.selected = this.checkAll;
      });
    },

    verificarExclusao() {
      if (this.checkSome || this.checkAll) {
        this.modal_confirmacao = true
      } else {
        this.erroModal = true
        this.erroMessage = 'Selecione pelo menos um Componente para ser excluído.'
      }
    },

    excluirSelecionados() {
      if (this.checkAll) {
        this.deletarComponentes(this.componentes.filter((componente) => componente.selected))
        this.modal_confirmacao = false
        this.checkAll = false
        return
      }
      const componentesADeletar = this.componentes.filter((componente) => {
        if (componente.selected) {
          return componente
        }
      })

      const componentesAAtualizar = this.componentes.filter(componente =>
        !componentesADeletar.some(deletar => deletar.id === componente.id)
      )

      this.deletarComponentes(componentesADeletar)
      this.modal_confirmacao = false
      this.atualizarComponentes(componentesAAtualizar)
    },

    async deletarComponentes(componentesADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const componentesADeletarPromisses = []

      for (const componente of componentesADeletar) {

        componentesADeletarPromisses.push(
          axios.patch(`projeto/projeto-componente/${componente.id}/`, { deletado: 'True' }, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(componentesADeletarPromisses)

      this.$emit('getListaComponentesProjeto')
    },


    async buscarComponentes(searchQuery) {
      if (searchQuery.length > 2) {
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest')

          const response = await axios.get(`produtos/produto/?tipo_produto=Componente&filtro_base=${searchQuery}&page_size=9999`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })

          this.componentesPesquisados = response.data.results
        } catch (error) {
          console.error(error);
        }
      } else {
        this.componentesPesquisados = []
      }
    },

    toggleComponente() {
      this.adicionarNovoComponente = !this.adicionarNovoComponente
      this.limparCampos()
    },

    limparCampos() {
      this.componenteSelecionado = [];
      this.novoComponente.componente_id = ''
      this.novoComponente.descricao = ''
      this.novoComponente.imagem = ''
      this.novoComponente.medicao = ''

      this.novoComponente.condicao = ''
      this.novoComponente.qtd_formula = ''
      this.novoComponente.medida = ''
      this.novoComponente.observacao = ''
      this.novoComponente.cor = ''
      this.novoComponente.aplicacao = 'A Definir'

      const faseDefault = this.fasesEsquadria.find(item => item.descricao === 'A Definir');
      this.novoComponente.fase_esquadria = faseDefault ? faseDefault.id : null;
    },

    async buscaFases() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`projeto/fase-esquadria/?page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.fasesEsquadria = response.data.results;

        const faseDefault = this.fasesEsquadria.find(item => item.descricao === 'A Definir');
        console.log(faseDefault)
        this.novoComponente.fase_esquadria = faseDefault ? faseDefault.id : null;
      } catch (error) {
        console.error(error)
      }
    },

    async postNovoComponente() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        await axios.post('projeto/projeto-componente/', this.novoComponente, {
          headers: { Authorization: `Token ${token}` }
        });

        this.sucesso = true;
        this.sucessoMessage = 'Componente criado com sucesso!';
        setTimeout(() => this.sucesso = false, 3000);

        this.adicionarNovoComponente = false;
        this.componentesPesquisados = [];
        this.limparCampos();
        this.atualizarLista();
      } catch (error) {
        console.error(error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

    toggleMenuProdutos(itemId) {
      // alert('Menu ' + index)
      this.itemListaSelecionado = itemId
      this.modalProdutos = !this.modalProdutos
    },

    atualizarLista() {
      // alert('atualizar')
      this.$emit('getListaComponentesProjeto')
    },

    fecharModalConfirmacao() {
      this.modal_confirmacao = false
    },

    alterarSelecionados() {
      if (this.checkSome || this.checkAll) {
        this.toggleAlterarSelecionados()
        this.componentesSelecionados = this.componentes.filter(componente => componente.selected)
        console.log(this.componentesSelecionados)
      } else {
        this.erroModal = true
        this.erroMessage = 'Selecione pelo menos um Componente para ser alterado.'
      }
    },

    fecharErroModal() {
      this.erroModal = false
    },

    toggleAlterarSelecionados() {
      this.modalAlterarSelecionados = !this.modalAlterarSelecionados
    },

    toggleMenuCopiar(item) {
      this.modalCopiar = !this.modalCopiar;
      this.itemParaCopiar = { ...item };
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // SEM PAPAPARSE CASO DÊ ERRO
    // exportarComponentes() {
    //   const headers = [
    //     "Código",
    //     "Condição",
    //     "Quantidade",
    //     "Medida",
    //     "Observação",
    //     "Cor",
    //     "Fase Montagem",
    //     "Aplicação",
    //   ];

    //   const escapeCSV = (value) => {
    //     if (value == null) return ""; // Evita valores null/undefined
    //     const escaped = value.toString().replace(/"/g, '""'); // Escapa aspas duplas
    //     return `"${escaped}"`; // Envolve em aspas duplas
    //   };

    //   const rows = this.componentes.map((componente) => [
    //     escapeCSV(componente.componente_id?.codigo_base),
    //     escapeCSV(componente.condicao),
    //     escapeCSV(componente.qtd_formula),
    //     escapeCSV(componente.medida),
    //     escapeCSV(componente.observacao),
    //     escapeCSV(componente.cor?.nome),
    //     escapeCSV(componente.fase_esquadria?.descricao),
    //     escapeCSV(componente.aplicacao),
    //   ]);

    //   const csvContent = [headers, ...rows]
    //     .map((row) => row.join(",")) // Junta os valores com vírgula
    //     .join("\r\n"); // Usa \r\n para compatibilidade com Windows

    //   const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // Adiciona BOM UTF-8 para compatibilidade
    //   const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });

    //   const link = document.createElement("a");
    //   link.href = URL.createObjectURL(blob);
    //   link.download = "componentes.csv";
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // },

    exportarComponentes() {
      if (!this.componentes || this.componentes.length === 0) {
        console.warn("Nenhum componente disponível para exportação.");
        return;
      }

      const headers = [
        "Código",
        "Condição",
        "Quantidade",
        "Medida",
        "Observação",
        "Cor",
        "Fase Montagem",
        "Aplicação",
      ];

      const data = this.componentes.map((componente) => ({
        "Código": componente.componente_id?.codigo_base || "",
        "Condição": componente.condicao || "",
        "Quantidade": componente.qtd_formula || "",
        "Medida": componente.medida || "",
        "Observação": componente.observacao || "",
        "Cor": componente.cor?.nome || "",
        "Fase Montagem": componente.fase_esquadria?.descricao || "",
        "Aplicação": componente.aplicacao || "",
      }));

      // 🔥 Gera CSV formatado corretamente
      const csvContent = Papa.unparse(data, {
        quotes: true,
        delimiter: ",",
        header: true,
        skipEmptyLines: true,
      });

      const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // 🔥 Adiciona BOM UTF-8 para compatibilidade
      const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "componentes.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },


    // Método para importar os dados

    importarComponentes(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;

        const parsedData = Papa.parse(content, {
          header: true,
          skipEmptyLines: false,
          transform: (value) => (value === "" ? null : value),
        });

        const rows = parsedData.data;

        console.log("Dados importados:", rows);

        this.erros = { erros: [] };

        for (const [index, row] of rows.entries()) {
          const componente = {
            codigo: row["Código"]?.trim() || null,
            condicao: row["Condição"]?.trim() || null,
            qtd_formula: row["Quantidade"]?.trim() || null,
            medida: row["Medida"]?.trim() || null,
            observacao: row["Observação"]?.trim() || null,
            cor: row["Cor"]?.trim() || null,
            fase_esquadria: row["Fase Montagem"]?.trim() || null,
            aplicacao: row["Aplicação"]?.trim() || null,
          };

          console.log("Componente importado:", componente);

          const camposFaltando = [];
          if (!componente.codigo) camposFaltando.push("Código");
          if (!componente.qtd_formula) camposFaltando.push("Quantidade");
          if (!componente.observacao) camposFaltando.push("Observação");
          if (!componente.fase_esquadria) camposFaltando.push("Fase Montagem");

          if (camposFaltando.length > 0) {
            this.erros.erros.push(
              `Linha ${index + 2}: Campos obrigatórios ausentes: ${camposFaltando.join(", ")}`
            );
            continue;
          }

          this.$store.dispatch("setLoading", true);
          try {
            // Buscar ID do Código
            const codigoResponse = await this.getIDComponente(componente.codigo);
            componente.componente_id = codigoResponse?.data?.results[0]?.id || null;

            // Buscar ID da Cor somente se ela for fornecida
            if (componente.cor) {
              const corResponse = await this.getIDCor(componente.cor);
              componente.cor_id = corResponse?.data?.results[0]?.id || null;
            } else {
              componente.cor_id = null;
            }

            // Buscar ID da Fase de Montagem somente se ela for fornecida
            if (componente.fase_esquadria) {
              const faseResponse = await this.getIDFaseMontagem(componente.fase_esquadria);
              componente.fase = faseResponse?.data?.results[0]?.id || null;
            } else {
              componente.fase = null;
            }

            console.log("Componente Processado:", componente);

            this.novoComponente = {
              projeto_id: this.projeto_id,
              componente_id: componente.componente_id,
              condicao: componente.condicao,
              qtd_formula: componente.qtd_formula,
              medida: componente.medida,
              observacao: componente.observacao,
              cor: componente.cor_id,
              fase_esquadria: componente.fase,
              aplicacao: componente.aplicacao,
            };

            await this.postNovoComponente();
            this.$store.dispatch("setLoading", false);
          } catch (error) {
            console.error(`Erro ao processar o componente na linha ${index + 2}:`, error);
            this.erros.erros.push(`Linha ${index + 2}: Erro ao buscar dados para o componente.`);
          }
        }

        if (this.erros.erros.length > 0) {
          console.error("Erro(s) encontrado(s):", this.erros);
          this.exibirErros = true;
        } else {
          this.sucesso = true;
          this.sucessoMessage = "Componentes importados e cadastrados com sucesso.";

          setTimeout(() => {
            this.sucesso = false;
            this.sucessoMessage = false;
          }, 3000);
        }
      };

      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
      reader.readAsText(file);
    },

    async getIDComponente(codigo) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/produto/?codigo_base=${codigo}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDCor(cor) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/cor-componente/?cor=${cor}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDFaseMontagem(fase) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`projeto/fase-esquadria/?fase=${fase}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

  }
}
</script>

<style scoped>
.botoes {
  @apply px-4 py-1 text-white
}
</style>