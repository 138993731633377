<template>
    <div class="space-y-4">
        <div class="flex justify-center">
            <label for="arquivo"
                class="cursor-pointer bg-gray-400 text-white py-2 px-4 rounded-lg font-semibold shadow-md hover:bg-gray-500 hover:shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1">
                Clique aqui para adicionar um arquivo
            </label>
        </div>
        <input type="file" class="hidden" @change="adicionarArquivo" accept=".pdf, .jpg, .jpeg, .png" multiple id="arquivo">

        <div class="flex flex-wrap gap-4 mt-4 items-center justify-center">
            <div v-for="(arquivo, index) in obra.arquivos" :key="index"
                class="relative flex items-center p-4 bg-gray-100 rounded-lg shadow-lg cursor-pointer hover:bg-gray-200 transition duration-300 w-full max-w-xs sm:w-[48%] lg:w-[30%]">

                <!-- Ícone de exclusão no topo -->
                <div class="absolute flex flex-col top-2 right-2 text-corBase cursor-pointer">
                    <i class="fa-solid fa-x" @click.prevent="excluirArquivo(arquivo)"></i>
                    <i class="fa-solid fa-book-open" @click.prevent="abrirArquivo(arquivo)"></i>
                </div>

                <div class="text-3xl mr-4 text-corBase flex-shrink-0">
                    <i class="fa-solid fa-file-pdf"></i>
                </div>

                <div class="flex items-center gap-2">
                    <div class="flex-1 overflow-hidden">
                        <div class="flex justify-between items-center">
                            <input type="text" v-model="arquivo.name" maxlength="50" @input="editouNome()"
                                class="input-campos w-full text-gray-700 font-medium" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
    </div>

</template>


<script>
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import axios from "axios"

export default {
    name: 'Documentos',
    props: ["obra"],
    emits: ["atualizarLista"],
    data() {
        return {
            erro: false,
            erroMessage: '',

            editando: false,
            novoNome: '',
        }
    },
    components: {
        ErroRapido
    },
    methods: {

        editouNome(){
            console.log('editounome')
        },

        adicionarArquivo(event) {
            const novosArquivos = Array.from(event.target.files);

            // Lista de tipos de arquivos permitidos
            const tiposPermitidos = [
                "application/pdf",
                "image/png",
                "image/jpeg",
                "image/jpg" // Alguns navegadores podem interpretar JPEG como JPG
            ];

            // Filtra os arquivos válidos
            const arquivosValidos = novosArquivos.filter(arquivo =>
                tiposPermitidos.includes(arquivo.type)
            ).map(arquivo => ({
                file: arquivo,
                name: arquivo.name,
                url_documento_temporaria: URL.createObjectURL(arquivo)
            }));

            // Identifica arquivos inválidos
            const arquivosInvalidos = novosArquivos.filter(arquivo => !tiposPermitidos.includes(arquivo.type));

            // Exibe mensagem de erro se houver arquivos inválidos
            if (arquivosInvalidos.length > 0) {
                this.erro = true;
                this.erroMessage = 'Alguns arquivos não foram adicionados por não serem do tipo permitido (PDF, PNG ou JPEG).';
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = '';
                }, 3000);
            }

            // Adiciona os arquivos válidos ao objeto `obra`
            if (this.obra.arquivos) {
                this.obra.arquivos = [...this.obra.arquivos, ...arquivosValidos];
            } else {
                this.obra.arquivos = arquivosValidos;
            }

            console.log(this.obra.arquivos);
        },


        abrirArquivo(arquivo) {
            if (arquivo.url_documento) {
                window.open(arquivo.url_documento, "_blank");
            } else {
                console.log(arquivo)
                const url = URL.createObjectURL(arquivo.file);
                window.open(url, '_blank');
            }
        },

        async excluirArquivo(arquivo) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                if (arquivo.id) {
                    const response = await axios.patch(`obra/documentos-obra/${arquivo.id}/`, { 'ativo': 'False' }, {
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });
                    console.log("Arquivo desativado:", response.data);
                    this.$emit('atualizarLista')
                } else {
                    const index = this.obra.arquivos.indexOf(arquivo);
                    if (index !== -1) {
                        this.obra.arquivos.splice(index, 1);
                    }
                    console.log("Arquivo removido da lista local.");
                }
            } catch (error) {
                console.error("Erro ao excluir o arquivo:", error);
                this.erro = true
                this.erroMessage = 'Ocorreu um erro ao excluir o documento.'
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = ''
                }, 3000);
            }
        }

    }
};
</script>

<style scoped></style>