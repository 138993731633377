<template>
    <div>
        <!-- Margem de Lucro Geral -->
        <div class="mt-4">
            <div class="w-full bg-gray-200 p-4 text-left border font-bold text-lg border-corBase/60">
                Margens Gerais
            </div>
            <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                <div class="flex w-full gap-2 mb-4 mt-4">
                    <div class="w-full">
                        <label class="font-bold">% Margens Venda Projeto:</label>
                        <input v-model="margem_lucro_geral_projeto" type="number" step="0.01" class="input-form">
                    </div>

                    <div class="w-full">
                        <label class="font-bold">% Margens Venda Item:</label>
                        <input v-model="margem_lucro_geral_item" type="number" step="0.01" class="input-form">
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-4">
            <div class="w-full bg-gray-200 p-4 text-left border font-bold text-lg border-corBase/60">
                Margens por Fornecedor
            </div>
            <div class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                <div class="flex gap-4 items-center mb-2">
                    <div class="w-full">
                        <label>Fornecedor:</label>
                        <v-select v-model="novoFornecedor" :options="lista_fornecedores" label="nome_razao"
                            :reduce="fornecedor => fornecedor.id" placeholder="Escolha um fornecedor"></v-select>
                    </div>

                    <div class="w-1/2">
                        <label>% Margens Venda Projeto:</label>
                        <input v-model="novaMargemLucroProjeto" type="number" step="0.01" class="input-form">
                    </div>

                    <div class="w-1/2">
                        <label>% Margens Venda Item:</label>
                        <input v-model="novaMargemLucroItem" type="number" step="0.01" class="input-form">
                    </div>

                    <button @click="adicionarFornecedor" class="text-ativo font-bold w-1/3">
                        <i class="fas fa-plus-circle"></i> Adicionar
                    </button>
                </div>

                <!-- Lista de fornecedores adicionados -->
                <div v-for="(item, index) in fornecedores" :key="index" class="border p-4 rounded-md mb-2 bg-gray-100">
                    <div class="flex gap-4 items-center">
                        <div class="w-full">
                            <span class="w-full"><strong>{{ item.nome_razao }}</strong></span>
                        </div>
                        <div class="w-1/2">
                            <input v-model="item.margem_lucro_projeto" type="number" step="0.01" class="input-form">
                        </div>
                        <div class="w-1/2">
                            <input v-model="item.margem_lucro_item" type="number" step="0.01" class="input-form">
                        </div>
                        <button @click="removerFornecedor(item)" class="font-bold w-1/3">
                            <i class="fa-solid fa-x cursor-pointer"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <!-- Botão de salvar -->
        <div class="mt-6 flex justify-end">
            <button @click="salvarMargens"
                class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4">
                Salvar Margens
            </button>
        </div>
    </div>

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
    <SucessoRapido v-if="sucesso" mensagem="Margens atualizadas com sucesso!" />
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios';
import ListagemErros from '../toasts/toast_erro/ListagemErros.vue';
import SucessoRapido from '../toasts/toast_sucesso/SucessoRapido.vue';

export default {
    name: 'DadosVidro',

    components: {
        vSelect,
        ListagemErros,
        SucessoRapido,
    },

    data() {
        return {
            lista_fornecedores: [],
            margem_lucro_geral_projeto: '',
            margem_lucro_geral_item: '',
            margem_lucro_geral_projeto_existe: false,
            fornecedores: [],
            novoFornecedor: null,
            novaMargemLucroProjeto: '',
            novaMargemLucroItem: '',

            sucesso: false,
            erros: [],
            exibirErros: false,
        };
    },

    created() {
        this.getListaFornecedores();
        this.getMargensExistentes();
    },

    methods: {

        // Buscar a lista de fornecedores disponíveis
        async getListaFornecedores() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?produto_fornecedor=Vidro&atividade=Fornecedor&page=1`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                this.lista_fornecedores = response.data.results.map(fornecedor => ({
                    id: fornecedor.pessoa.id,
                    nome_razao: fornecedor.nome || fornecedor.razao_social,
                }));

                this.getSemFornecedor();
            } catch (error) {
                console.error(error);
            }
        },

        async getSemFornecedor() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const nome_razao = encodeURIComponent('Fornecedor Indefinido');
                const response = await axios.get(`pessoas/?nome_razao=${nome_razao}&atividade=Fornecedor`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                const semFornecedor = response.data.results[0];
                if (semFornecedor) {
                    this.lista_fornecedores.unshift({
                        id: semFornecedor.id,
                        nome_razao: semFornecedor.nome_razao
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },

        // Buscar margens já existentes
        async getMargensExistentes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                this.$store.dispatch("setLoading", true);
                // Buscar margem geral
                const margemResponse = await axios.get(`precificacao/margem-lucro-vidro/`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                if (margemResponse.data.results.length > 0) {
                    this.margem_lucro_geral_projeto_existe = true
                    this.margem_lucro_geral_projeto = margemResponse.data.results[0].margem_lucro_geral_projeto;
                    this.margem_lucro_geral_item = margemResponse.data.results[0].margem_lucro_geral_item;
                }

                // Buscar margens por fornecedor
                const fornecedorResponse = await axios.get(`precificacao/margem-lucro-fornecedor-vidro/?ativo=True`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                this.fornecedores = fornecedorResponse.data.results.map(item => ({
                    id: item.id,
                    fornecedor: item.fornecedor.id,
                    nome_razao: item.fornecedor.nome_razao,
                    margem_lucro_projeto: item.margem_lucro_projeto,
                    margem_lucro_item: item.margem_lucro_item,
                }));
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // Adicionar novo fornecedor com margem de lucro
        adicionarFornecedor() {
            if (!this.novoFornecedor) {
                this.erros = { adicionar_margem_fornecedor: "Selecione um fornecedor e insira a margem de lucro." }
                this.toggleErros()
                return;
            }

            // Verifica se o fornecedor já está na lista
            if (this.fornecedores.some(item => item.fornecedor === this.novoFornecedor)) {
                this.erros = { fornecedor: "Este fornecedor já foi adicionado." }
                this.toggleErros()
                return;
            }

            if ((this.novaMargemLucroProjeto == 0 || !this.novaMargemLucroProjeto) || (this.novaMargemLucroItem == 0 || !this.novaMargemLucroItem)) {
                this.erros = { adicionar_margem_fornecedor: "Insira uma margem de lucro válida." }
                this.toggleErros()
                return;
            }

            // Adiciona fornecedor na lista
            const fornecedorObj = this.lista_fornecedores.find(f => f.id === this.novoFornecedor);
            this.fornecedores.push({
                fornecedor: this.novoFornecedor,
                nome_razao: fornecedorObj.nome_razao,
                margem_lucro_projeto: this.novaMargemLucroProjeto,
                margem_lucro_item: this.novaMargemLucroItem,
            });

            // Limpa os campos
            this.novoFornecedor = null;
            this.novaMargemLucroProjeto = '';
            this.novaMargemLucroItem = '';
            this.erro = false;
        },

        async removerFornecedor(fornecedor) {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                this.$store.dispatch("setLoading", true);

                const response = await axios.patch(`precificacao/margem-lucro-fornecedor-vidro/${fornecedor.id}/`, {
                    "ativo": 'false'
                }, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                console.log(response)
                this.getMargensExistentes()
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // Salvar margens gerais e por fornecedor
        async salvarMargens() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                // Validação da margem geral
                if (this.margem_lucro_geral_projeto_existe) {
                    if ((!this.margem_lucro_geral_projeto || this.margem_lucro_geral_projeto == 0) ||
                        !this.margem_lucro_geral_item || this.margem_lucro_geral_item == 0) {
                        this.erros = { margem_geral: "Insira uma margem de lucro válida." };
                        this.toggleErros();
                        return;
                    }
                    await axios.patch(`precificacao/margem-lucro-vidro/1/`, {
                        margem_lucro_geral_projeto: this.margem_lucro_geral_projeto,
                        margem_lucro_geral_item: this.margem_lucro_geral_item
                    }, {
                        headers: { 'Authorization': `Token ${token}` }
                    });
                } else {
                    await axios.post(`precificacao/margem-lucro-vidro/`, {
                        margem_lucro_geral_projeto: this.margem_lucro_geral_projeto,
                        margem_lucro_geral_item: this.margem_lucro_geral_item
                    }, {
                        headers: { 'Authorization': `Token ${token}` }
                    });
                }

                // Salvar ou atualizar margens por fornecedor
                for (const item of this.fornecedores) {
                    if ((!item.margem_lucro_projeto || item.margem_lucro_projeto == 0) ||
                        !item.margem_lucro_item || item.margem_lucro_item == 0) {
                        // Busca o nome do fornecedor na lista para exibir na mensagem de erro
                        const fornecedorNome = this.lista_fornecedores.find(f => f.id === item.fornecedor)?.nome_razao || "Fornecedor desconhecido";

                        this.erros = { margem_lucro_fornecedor: `Insira uma margem de lucro válida para o fornecedor ${fornecedorNome}.` };
                        this.toggleErros();
                        return;
                    }

                    if (item.id) {
                        await axios.patch(`precificacao/margem-lucro-fornecedor-vidro/${item.id}/`, {
                            fornecedor: item.fornecedor,
                            margem_lucro_projeto: item.margem_lucro_projeto,
                            margem_lucro_item: item.margem_lucro_item,
                        }, {
                            headers: { 'Authorization': `Token ${token}` }
                        });
                    } else {
                        await axios.post(`precificacao/margem-lucro-fornecedor-vidro/`, {
                            fornecedor: item.fornecedor,
                            margem_lucro_projeto: item.margem_lucro_projeto,
                            margem_lucro_item: item.margem_lucro_item,
                        }, {
                            headers: { 'Authorization': `Token ${token}` }
                        });
                    }
                }

                this.sucesso = true;
                setTimeout(() => this.sucesso = false, 3000);
            } catch (error) {
                console.error(error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' };
                this.toggleErros();
            }
            this.getMargensExistentes()
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        }

    }
};
</script>


<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}
</style>
