<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[9999]">
            <div class="bg-white rounded-lg max-w-[90%] shadow-lg p-6 max-h-[80vh] overflow-y-auto ">
                <!-- Cabeçalho do modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-green-600">Selecionar Cor</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <div class="container-modelo-tabela">
                    <table class="container-tabela">
                        <thead class="cabecalho-tabela">
                            <tr class="border-b">
                                <th scope="col" class="px-4 py-3">
                                    Selecionar
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    Descrição
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Código
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(cor, index) in lista_cores" :key="index" class="escopo-tabela">
                                <td class="px-4 py-3">
                                    <div class="flex justify-center items-center">
                                        <i class="fa-solid fa-check cursor-pointer text-xl"
                                            @click.prevent="selecionarCor(cor)"></i>
                                    </div>
                                </td>
                                <td class="px-6 py-3">
                                    {{ cor.codigo || '-' }}
                                </td>
                                <td class="px-6 py-3">
                                    {{ cor.descricao || '-' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination v-if="lista_cores.length > 0" :offset="paginaAtual" :total="countItens"
                        :limit="20" @change-page="mudarPagina" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios"
import Pagination from "@/utils/Pagination.vue";

export default {
    name: 'ModalSelecionarCor',

    emits: ['selecionarCor', 'fecharModal'],

    data() {
        return {
            lista_cores: [],

            paginaAtual: 1,
            countItens: '',

        }
    },

    components: { Pagination },

    created() {
        this.getListaCoresBase(1)
    },

    methods: {
        async getListaCoresBase(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                const response = await axios.get(`produtos/cor-produto-base/?produto_nome=Perfil&page=${pagina}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_cores = response.data.results
                this.countItens = response.data.count
            } catch (error) {
                console.error(error)
            }
        },

        selecionarCor(cor) {
            this.$emit('selecionarCor', cor)
            this.$emit('fecharModal')
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getListaCoresBase(pagina);
        },

    }

}
</script>

<style scoped></style>