<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Patente</h1>

      <router-link to="/produtos/patente" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarPatente">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="flex flex-col md:flex-row gap-3 my-6 w-full ">

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Descrição</label>
          <input type="text" v-model="descricao" class="input-form-chapa" required>
        </div>

        <div class="campo-chapa">
          <label class="text-xl text-corBase">Produto:</label>
          <v-select v-model="tipo_produto" label="text" class="w-full z-10" :options="lista_produtos_sistema"
            :multiple="true" />
        </div>

      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Salvar</button>
      </div>

    </form>
  </div>
  <MensagemSucesso v-if="sucesso == true" rotas="/produtos/patente" mensagem="Patente Cadastrada com Sucesso!" />

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import vSelect from "vue-select";

export default {
  name: "CadastroEspessua",

  components: {
    MensagemSucesso,
    vSelect,
  },


  data() {
    return {
      sucesso: false,
      descricao: '',
      produtos: '',

      lista_produtos_sistema: []
    }
  },

  created() {
    this.getProdutosSistema()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    async getProdutosSistema() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get('produtos/produtos-sistema', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        this.lista_produtos_sistema = response.data.results.map(item => ({
          id: item.id,
          nome: item.nome
        }));
      } catch (error) {
        console.error("Erro ao buscar produtos do sistema:", error);
      }
    },

    async cadastrarPatente() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const produtoIds = Array.isArray(this.tipo_produto)
        ? this.tipo_produto.map(produto => produto.id)
        : [];

      const data = {
        ativo: this.ativo,
        codigo: this.codigo,
        descricao: this.descricao,
        produto_aplicado: produtoIds
      };

      this.sucesso = false
      this.$store.dispatch("setLoading", true);
      await axios.post('produtos/patente/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data);
          this.sucesso = true
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.$store.dispatch("setLoading", false);
        })

    },


  }

}
</script>

<style lang="scss"></style>