<template>
    <div>
        <div class="container-modal-editar">
            <div class="sombra-modal">
                <div class="conteudo-modal-editar">
                    <form class="form-editar text-white" @submit.prevent="putDadosEditado">
                        <div class="flex justify-end">
                            <button @click.prevent="$emit('fecharModal')">
                                <i class="fas fa-close text-2xl text-corBase"></i>
                            </button>
                        </div>
                        <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Função Colaborador</h2>

                        <div>
                            <div class="md:flex md:gap-12">


                                <div class="flex flex-col mt-2 w-full justify-start">
                                    <label class="text-base text-corBase font-bold mt-2">Descrição:</label>
                                    <input type="text" required class="input-campos text-base" v-model="funcao.descricao">
                                </div>

                                <div class="flex flex-col mt-2 w-full justify-start ">
                                    <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                                    <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                                        <i v-if="funcao.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                                        <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                                    </span>
                                </div>

                            </div>

                        </div>

                        <!--Botão-->
                        <div class="btn-formulario-modal">
                            <button class="btn-formulario-salvar" type="submit">
                                Salvar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <SucessoRapido v-if="sucesso" mensagem="Função atualizada com sucesso!" />
            <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao atualizar Cor." />
            <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";

export default {
    name: "EditarFuncoesColaborador",
    components: {
        ErroRapido,
        SucessoRapido,
        ListagemErros,
        vSelect,
    },
    data() {
        return {
            statusModal: false,
            sucesso: false,
            erro: false,

            erros: [],
            exibirErros: false,

        }
    },

    props: ["funcao"],

    emits: ["fecharModal", "atualizarLista"],

    created() {
        console.log(this.funcao)
    },

    methods: {


        toggleAtivo() {
            this.funcao.ativo = !this.funcao.ativo
        },

        async putDadosEditado() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');


            // Dados para atualizar
            const dadosAtualizados = {
                ativo: this.funcao.ativo,
                descricao: this.funcao.descricao,
            };

            try {
                // Atualiza os dados na tabela base
                const response = await axios.patch(`rh/funcao-colaborador/${this.funcao.id}/`, dadosAtualizados, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                console.log(response);

                // // Exibe sucesso
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit("fecharModal");
                    this.$emit("atualizarLista");
                }, 3000);
            } catch (error) {
                console.error('Erro ao atualizar função:', error);
                this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
                this.toggleErros();

            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

    }
}
</script>

<style lang="scss"></style>