<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
        <div class="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full sm:w-full md:w-2/3 lg:w-1/3 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Produtos Base</h2>
            </div>

            <div class="space-y-4">
                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label for="corte" class="block text-sm font-medium text-corBase mb-1">Tipo:</label>
                        <v-select v-model="tipo" :options="lista_tipo_produto" label="descricao" class="bg-white w-full"
                            placeholder="Escolha uma opção" :reduce="tipo => tipo.id" />
                    </div>

                    <div class="text-left w-full">
                        <label for="posicao" class="block text-sm font-medium text-corBase mb-1">Cor:</label>
                        <v-select v-model="cor" :options="lista_cores" label="descricao" class="bg-white w-full"
                            placeholder="Escolha uma opção" :reduce="cor => cor.id" :multiple="true" />
                    </div>
                </div>

                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Unidade Medida:</label>
                        <v-select :options="lista_unidade_medida" v-model="unidade_medida" label="nome"
                            placeholder="Escolha uma opção" :reduce="unidade_medida => unidade_medida.id" />
                    </div>

                    <div class="text-left w-full">
                        <label for="aplicacao" class="block text-sm font-medium text-corBase mb-1">Linha
                            Aplicação:</label>
                        <v-select :options="lista_linha_aplicacao" v-model="linha" label="linha"
                            placeholder="Escolha uma opção" :multiple="true" :reduce="linha => linha.linha" />
                    </div>
                </div>

                <div class="w-full flex gap-2">
                    <div class="flex flex-col text-center items-center w-full">
                        <label for="fase_esquadria" class="block text-sm font-medium text-corBase mb-1">Status:</label>
                        <div class="cursor-pointer flex items-center justify-center w-6 h-6"
                            @click.prevent="toggleAtivo">
                            <i :class="ativo ? 'fa-solid fa-circle-check text-ativo' : 'fa-solid fa-circle-xmark text-inativo'"
                                class="text-2xl"></i>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>

            <SucessoRapido v-if="sucesso === true" mensagem="Produtos atualizados com sucesso!" />
            <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar Produtos." />

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'AlterarSelecionadosProdutoBase',
    data() {
        return {
            tipo: '',
            cor: [],
            unidade_medida: '',
            linha: '',
            linhaId: '',
            ativo: true,

            lista_tipo_produto: [],
            lista_cores: [],
            lista_unidade_medida: [],
            lista_linha_aplicacao: [],

            sucesso: false,
            erro: false,
        };
    },

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
    },

    props: ['produtos', 'tipo_produto'],

    emits: ['fecharModal', 'atualizarLista'],

    created() {
        this.getListaCores()
        this.getListaLinhaAplicacao()
        this.getListaUnidadeMedida()
        this.getListaTipoProdutoBase()
    },

    methods: {

        async getListaCores() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            axios.get(`produtos/cor-produto-base/?tipo_produto=${this.tipo_produto.toLowerCase()}&ativo=True&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.lista_cores = response.data.results;
                })
                .catch(error => {
                    console.error(error);
                })
        },

        async getListaLinhaAplicacao() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            axios.get(`projeto/linha-sistema/?ativo=True&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.lista_linha_aplicacao = response.data.results;
                })
                .catch(error => {
                    console.error(error);
                })
        },

        async getListaUnidadeMedida() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            axios.get(`produtos/unidade-medida/?produto=${this.tipo_produto}&ativo=True&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.lista_unidade_medida = response.data.results;
                })
                .catch(error => {
                    console.error(error);
                });

        },

        async getListaTipoProdutoBase() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            // this.$store.dispatch("setLoading", true);

            try {
                const response = await axios.get(`/produtos/tipo-produto-base/?produto=${this.tipo_produto}&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                this.lista_tipo_produto = response.data.results;
                console.log(this.lista_tipo_produto);

            } catch (error) {
                console.error("Erro ao buscar tipos de produto base:", error);
            }
        },

        toggleAtivo() {
            this.ativo = !this.ativo
        },

        async salvarAlteracoes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                console.log('PRODUTOS')
                console.log(this.produtos)

                let linhaIds = [];
                if (this.linha) {
                    linhaIds = await this.getLinhaID(this.linha); // Obtém os IDs das linhas
                }

                const patchPromises = this.produtos.map(produto => {

                    const camposAtualizados = {};

                    if (this.tipo) {
                        camposAtualizados.produto = this.tipo;
                        console.log(camposAtualizados.produto)
                    }
                    if (this.cor) {
                        camposAtualizados.cor_base = this.cor;
                        console.log(camposAtualizados.cor_base)
                    }
                    if (this.unidade_medida) {
                        camposAtualizados.unidade_medida = this.unidade_medida;
                        console.log(camposAtualizados.unidade_medida)
                    }
                    if (linhaIds.length > 0) {
                        camposAtualizados.linha = linhaIds;
                        console.log('LINHAS:', camposAtualizados.linha);
                    }
                    if (this.ativo !== undefined) {
                        camposAtualizados.ativo = this.ativo;
                        console.log(camposAtualizados.ativo)
                    }


                    if (Object.keys(camposAtualizados).length === 0) {
                        return Promise.resolve();
                    }

                    return axios.patch(
                        `produtos/produto/${produto.id}/`,
                        camposAtualizados,
                        {
                            headers: {
                                'Authorization': `Token ${token}`,
                            },
                        }
                    );
                });

                // Aguarda todas as requisições serem resolvidas
                await Promise.all(patchPromises);

                // Exibe mensagem de sucesso
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal');
                    this.$emit('atualizarLista');
                }, 3000);
            } catch (error) {
                // Tratamento de erros
                console.error('Erro ao salvar alterações:', error);
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },

        async getLinhaID(linhas) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            const linhaIds = []; // Lista para armazenar os IDs das linhas

            for (const linha of linhas) {
                console.log(linha)
                const nome = linha
                try {
                    // Verificar se a linha existe
                    const response = await axios.get(`produtos/linha-produto-base/?nome=${nome}`, {
                        headers: {
                            'Authorization': `Token ${token}`,
                        },
                    });

                    if (response.data.results.length > 0) {
                        // Se a linha já existe, pega o ID
                        linhaIds.push(response.data.results[0].id);
                    } else {
                        // Caso contrário, cria a linha e pega o ID
                        const createdResponse = await axios.post(
                            '/produtos/linha-produto-base/',
                            { nome },
                            {
                                headers: {
                                    'Authorization': `Token ${token}`,
                                },
                            }
                        );
                        linhaIds.push(createdResponse.data.id);
                    }
                } catch (error) {
                    console.error(`Erro ao processar a linha ${nome}:`, error);
                }
            }

            return linhaIds; // Retorna a lista de IDs das linhas
        }

    },

};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}
</style>