<template>
    <div>
        <div>
            <label>
                Seu sistema origem:
            </label>
            <div>
                <ul>
                    <li v-for="origem in proprio_tenant_origem" :key="origem.id">
                        {{ origem.tenant_origem?.name || 'ManagerGlass' }} 
                        <i class="fa-solid fa-right-long"></i>
                        {{ origem.tenant_destino?.name || 'ManagerGlass' }} 
                    </li>
                </ul>
            </div>
        </div>

        <div>
            <label>
                Seu sistema destino:
            </label>
            <div>
                <ul>
                    <li v-for="origem in proprio_tenant_origem" :key="origem.id">
                        {{ origem.tenant_destino?.name || 'ManagerGlass' }} 
                        <i class="fa-solid fa-right-long"></i>
                        {{ origem.tenant_origem?.name || 'ManagerGlass' }} 
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'SessaoControleIntegracao',

    data() {
        return {
            tenant: [],

            proprio_tenant_origem: [],
            proprio_tenant_destino: [],


        }
    },

    created() {
        this.getTenantId()
    },

    methods: {
        async getTenantId() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                this.$store.dispatch("setLoading", true);

                const hostname = window.location.hostname;
                const partes = hostname.split(".");
                const tenant_name = partes.length >= 2 ? partes[0] : null;

                if (tenant_name === "admin") {
                    this.tenant = { id: 0, nome: "ManagerGlass" };
                    await this.getProprioSistemaDestino()
                    await this.getProprioSistemaOrigem()
                } else {
                    const response = await axios.get(`tenant/client/?nome=${tenant_name}`, {
                        headers: { 'Authorization': `Token ${token}` }
                    });

                    if (response.data.results.length > 0) {
                        this.tenant = response.data.results[0];

                        await this.getProprioSistemaDestino()
                        await this.getProprioSistemaOrigem()
                    }
                }

            } catch (error) {
                console.error("Erro ao buscar tenant:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getProprioSistemaDestino() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                this.$store.dispatch("setLoading", true);


                const response = await axios.get(`tenant/integracao-gestores/?tenant_destino=${this.tenant.id}`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                console.log(response.data.results)
                this.proprio_tenant_destino = response.data.results


            } catch (error) {
                console.error("Erro ao buscar tenant:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getProprioSistemaOrigem() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                this.$store.dispatch("setLoading", true);


                const response = await axios.get(`tenant/integracao-gestores/?tenant_origem=${this.tenant.id}`, {
                    headers: { 'Authorization': `Token ${token}` }
                });

                console.log(response.data.results)
                this.proprio_tenant_origem = response.data.results


            } catch (error) {
                console.error("Erro ao buscar tenant:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },
    }

}
</script>

<style scoped></style>