<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[9998]">
            <div class="bg-white rounded-lg max-w-[90%] shadow-lg p-6 max-h-[80vh] overflow-y-auto ">
                <!-- Cabeçalho do modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-green-600">Alterar Valor de Custo Componentes</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <div class="flex gap-2">
                    <div>
                        <label class="text-corBase">Ação:</label>
                        <v-select :options="lista_opcao_mudanca" v-model="opcao_mudanca" label="nome"
                            :reduce="mudanca => mudanca.value" placeholder="Escolha uma ação" class="mb-2 w-48" />
                    </div>

                    <div v-if="opcao_mudanca">
                        <label class="text-corBase">% Alteração:</label>
                        <input type="number" v-model="porcentagem_alteracao" placeholder="Digite o valor">
                    </div>
                </div>

                <div class="mb-2 flex gap-2">

                    <button @click.prevent="simularValores()" v-if="opcao_mudanca"
                        class="btn-modal-importacao  bg-blue-500 text-white border hover:bg-screenCor hover:text-blue-500 hover:border-blue-500">
                        Simular
                    </button>

                    <button @click.prevent="validarValores()" v-if="valoresSimulados"
                        class="btn-modal-importacao  bg-yellow-500 text-white border hover:bg-screenCor hover:text-yellow-500 hover:border-yellow-500">
                        Validar
                    </button>
                </div>

                <div class="container-modelo-tabela">
                    <table class="container-tabela">
                        <thead class="cabecalho-tabela">
                            <tr class="border-b">
                                <th class="px-6 py-3">
                                    #
                                </th>
                                <th class="px-6 py-3">
                                    Descrição
                                </th>
                                <th class="px-6 py-3">
                                    Fornecedor
                                </th>
                                <th class="px-6 py-3 text-center">
                                    Tipo
                                </th>
                                <th class="px-6 py-3 text-center">
                                    Cor
                                </th>
                                <th class="px-6 py-3 text-center">
                                    Espessura
                                </th>
                                <th class="px-6 py-3 text-center">
                                    Unidade de Medida
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    $ Custo
                                </th>
                                <th scope="col" class="px-16 py-3 text-center">
                                    $ Custo Atualizado
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(produto, index) in lista_componentes_copia" :key="index"
                                class="escopo-tabela list-checkbox">

                                <th scope="row"
                                    class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ index + 1 }}
                                </th>
                                <td class="px-6 py-2 text-xs">
                                    {{ produto.componente?.descricao || '-' }}
                                </td>
                                <td class="px-6 py-2 text-xs">
                                    {{ produto.componente?.fornecedor?.nome_razao || '-' }}
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    {{ produto.componente?.componente_base.produto?.descricao || '-' }}
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    <!-- {{ produto.componente.cor_base?.descricao || '-' }} -->
                                    {{ produto.componente?.componente_base.cor_base?.map(cor => cor.descricao).join(', ') || '-'
                                    }}
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    {{ produto.componente?.componente_base.espessura || '-' }}
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    {{ produto.componente?.componente_base.unidade_medida?.nome || '-' }}
                                </td>
                                <td class="w-96 text-xs text-center">
                                    R$ {{ formatarNumero(produto.custo) }}
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    <span v-if="valoresSimulados">
                                        R$ {{ formatarNumero(produto.custo_atualizado) }}
                                    </span>
                                    <span v-else>-</span>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
        </div>
    </div>

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

</template>

<script>
import axios from "axios"
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import vSelect from "vue-select";

export default {
    name: 'AlterarValorCustoComponente',

    components: {
        ListagemErros,
        SucessoRapido,
        vSelect,
    },

    props: ['lista_componentes'],
    emits: ['fecharModal', 'atualizarLista'],

    data() {
        return {
            checkAll: false,

            lista_componentes_copia: [],

            lista_opcao_mudanca: [
                { value: 'acrescimo', nome: 'Acréscimo' },
                { value: 'desconto', nome: 'Desconto' },
            ],

            opcao_mudanca: '',
            valor_mudanca: '',

            valoresSimulados: false,

            exibirErros: false,
            erros: [],

            sucesso: false,
            sucessoMessage: '',
        }
    },

    computed: {
        checkSome() {
            return this.lista_componentes.some(produto => produto.selected)
        }
    },

    created() {
        console.log(this.lista_componentes)
        this.lista_componentes_copia = JSON.parse(JSON.stringify(this.lista_componentes));
    },

    methods: {

        selectAll() {
            // Atualiza o estado dos demais checkboxes
            const checkboxes = document.querySelectorAll('.list-checkbox input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = this.checkAll;
            });

            this.lista_componentes.forEach((produto_base) => {
                produto_base.selected = this.checkAll;
            });
        },

        formatarNumero(valor) {
            return parseFloat(valor).toFixed(2).replace('.', ',');
        },

        simularValores() {
            
            if (!this.porcentagem_alteracao || this.porcentagem_alteracao == 0) {
                this.erros = { porcentagem_alteracao: 'Digite um valor válido de alteração para simular.' };
                this.toggleErros();
                return;
            }

            const percentual = parseFloat(this.porcentagem_alteracao) / 100;

            this.lista_componentes_copia = this.lista_componentes.map(produto => {
                const custoOriginal = parseFloat(produto.custo) || 0;
                let novoCusto;

                if (this.opcao_mudanca === 'acrescimo') {
                    novoCusto = custoOriginal + (custoOriginal * percentual);
                } else if (this.opcao_mudanca === 'desconto') {
                    novoCusto = custoOriginal - (custoOriginal * percentual);
                }

                return {
                    ...produto,
                    custo_atualizado: novoCusto
                };
            });

            this.valoresSimulados = true;
        },

        async validarValores() {
            if (!this.valoresSimulados) {
                this.erros = { simulacao: 'Realize a simulação antes de validar os valores.' };
                this.toggleErros();
                return;
            }

            if (!this.lista_componentes_copia.length) {
                this.erros = { validacao: 'Não há itens para atualizar.' };
                this.toggleErros();
                return;
            }

            try {
                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const promessas = this.lista_componentes_copia.map(async (produto) => {
                    const payload = {
                        custo: produto.custo_atualizado,  // Enviamos o novo custo calculado
                    };

                    return axios.patch(`/precificacao/itens-tabelas-componente/${produto.id}/`, payload, {
                        headers: {
                            Authorization: `Token ${token}`,
                        }
                    });
                });

                await Promise.all(promessas);

                this.sucesso = true;
                this.sucessoMessage = "Valores atualizados com sucesso!";
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = "";
                    this.$emit("atualizarLista"); // Atualiza a tabela principal
                    this.$emit("fecharModal"); // Fecha o modal
                }, 3000);

            } catch (error) {
                console.error("Erro ao validar valores:", error);
                this.erros = error.response?.data || { validacao: ["Erro ao validar valores."] };
                this.toggleErros();
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

    }
}
</script>

<style scoped>
.btn-modal-importacao {
    @apply px-4 py-1 cursor-pointer
}

input[type=text] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}

input[type=number] {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>