<template>

  <div class="container-formulario">


    <div class="cabecalho-dashboard">
      <h1>Projetos</h1>

      <button @click="$router.push(`/dashboard`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap">

      <button class="escopo-dashboard-desktop" @click="$router.push(`/lista-projetos`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Projetos</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/projeto/controle-gestor`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Controle de Gestor/Linha</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>


    </div>


  </div>


</template>

<script>

export default {
  name: "ProjetosDashboard",


}

</script>

<style lang="scss"></style>