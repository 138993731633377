<template>

    <div class="container-formulario">

        <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
        <div class="cabecalho-formulario">
            <h1 class="text-xl text-corBase">Cadastro Função Colaborador:</h1>

            <router-link to="/funcao-colaborador" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Escopo do formulario de cadastro mobile-->
        <form @submit.prevent="cadastrarFuncoesColaborador">
            <!--        PARTE DE DADOS DO PRODUTO-->
            <div class="div-produtos">

                <div class="campo-chapa">
                    <label class="font-bold text-corBase">Função:</label>
                    <input type="text" v-model="funcao" class="input-form-chapa">
                </div>

                <div class="campo-chapa">
                    <label class="font-bold text-corBase">Ativo:</label>
                    <span class="text-start pt-1 text-xl">
                        <i @click.prevent="toggleAtivo" v-if="ativo === true"
                            class="fa-solid fa-circle-check text-ativo cursor-pointer"></i>
                        <i @click.prevent="toggleAtivo" v-else
                            class="fa-solid fa-circle-xmark text-inativo cursor-pointer"></i>
                    </span>
                </div>
            </div>


            <div class="flex w-full justify-end">
                <button class="btn-enviar" type="submit">Cadastrar</button>
            </div>
        </form>

    </div>
    <MensagemSucesso v-if="sucesso == true" rotas="/funcao-colaborador" mensagem="Função Colaborador cadastrada com sucesso!" />
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />

</template>

<script>
import axios from "axios";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
    name: "TipoPessoa",

    components: {
        ListagemErros,
        MensagemSucesso,
    },

    data() {
        return {
            sucesso: false,
            funcao: '',
            ativo: true,

            exibirErros: false,
            erros: [],
        }
    },

    created() {
    },

    computed: {
        minhaEmpresa() {
            return this.$store.state.minhaEmpresa;
        },
        isadmin() {
            return this.$store.state.isAdminUser
        },
    },

    methods: {

        toggleAtivo() {
            this.ativo = !this.ativo
        },

        async cadastrarFuncoesColaborador() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const data = new FormData();
                data.append('descricao', this.funcao)
                data.append('ativo', this.ativo)
                this.sucesso = false
                const response = await axios.post('rh/funcao-colaborador/', data, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                console.log(response)
                this.sucesso = true
            } catch (error) {
                console.error('Erro ao cadastrar', error);
                this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
                this.toggleErros();
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

    }

}
</script>

<style lang="scss"></style>