<template>

  <div class="container-formulario">
    <galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Produto Base:</h1>

      <router-link to="/produtos/perfil-base" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarProdutoBase" enctype="multipart/form-data">
      <!-- PARTE DE DADOS DO PRODUTO -->
      <div class="div-produtos">
        <div class="flex flex-col w-[40vw]">
          <label class="text-corBase text-xl font-bold">Imagem:</label>
          <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }">
          </div>
          <div class="flex gap-3 mt-4">
            <button class="selecionar-arquivo" @click.prevent="abrir_galeria">
              Pesquisar
            </button>
          </div>
        </div>

        <div class="flex flex-col w-full gap-[14px] ml-4 mr-1">
          <div class="flex items-center gap-3 w-full mt-3">

            <div class="campo-chapa">
              <label class="text-xl text-corBase font-bold">Produto:</label>
              <v-select label="nome" :options="lista_produtos_sistema" v-model="tipo_produto" />
            </div>
          </div>

          <div>

            <div class="div-produtos">
              <div class="w-1/4">
                <label class="text-xl text-corBase font-bold">Código:</label>
                <input type="text" v-model="codigo_base" class="input-form-chapa"
                  v-on:input="codigo_base = codigo_base.toUpperCase()" autofocus>
              </div>

              <div class="w-full">
                <label class="text-xl text-corBase font-bold">Descrição:</label>
                <input type="text" v-model="nome_base" class="input-form-chapa" autofocus>
              </div>

            </div>


            <div class="div-produtos">

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Tipo:</label>
                <v-select :options="lista_tipo_produto_base" v-model="tipo" label="descricao">
                  <template #no-options>
                    <span>
                      {{ tipo_produto ? 'Nenhum tipo encontrado para este produto.' : 'Selecionar Produto' }}
                    </span>
                  </template>
                </v-select>
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Cor:</label>
                <v-select label="descricao" :options="lista_cores" v-model="cor_produto" :multiple="true">
                  <template #no-options>
                    <span>
                      {{ tipo_produto ? 'Nenhuma cor encontrada para este produto.' : 'Selecionar Produto' }}
                    </span>
                  </template>
                </v-select>
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Espessura:</label>
                <v-select label="espessura" :options="lista_espessuras" v-model="espessura" />
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Unidade Medida:</label>
                <v-select label="nome" :options="lista_unidade_medida" v-model="unidade_medida" class="bg-white">
                  <template #no-options>
                    <span>
                      {{ tipo_produto ? 'Nenhuma Unidade de Medida encontrada para este produto.' : 'Selecionar Produto'
                      }}
                    </span>
                  </template>
                </v-select>
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Peso:</label>
                <input type="text" v-model="peso_base" class="input-form-chapa" autofocus>
              </div>

            </div>

            <div class="campo-chapa">
              <label class="text-xl text-corBase">Linha:</label>
              <v-select label="linha" :options="lista_linhas" v-model="linha" :multiple="true" />
            </div>

            <div class="campo-chapa flex flex-col text-center items-center pt-2">
              <label class="text-xl text-corBase">Ativo:</label>
              <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                <i v-if="ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
              </span>
            </div>
          </div>

        </div>
      </div>

      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Cadastrar</button>
      </div>
    </form>

  </div>

  <mensagem-sucesso v-if="sucesso === true" mensagem="Produto cadastrado com sucesso!"
    rotas="/produtos/perfil-base"></mensagem-sucesso>

  <messagem-erro-base v-if="erro === true" mensagem="Ocorreu um erro ao cadastrar Produto."
    rotas="/produtos/perfil-base"></messagem-erro-base>

  <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import MessagemErroBase from "@/components/toasts/toast_erro/MessagemErroBase";
import Galeria from '@/components/midais/ged/Galeria'
import ListagemErros from "../toasts/toast_erro/ListagemErros.vue";

export default {
  name: "CadastroProdutoBase",

  components: {
    vSelect,
    MensagemSucesso,
    MessagemErroBase,
    Galeria,
    ListagemErros,
  },


  data() {
    return {
      sucesso: false,
      erro: false,
      previewImage: {},
      lista_unidade_medida: [],
      lista_produtos_sistema: [],
      lista_cores: [],
      lista_espessuras: [],
      lista_tipo_produto_base: [],
      lista_linhas: [],
      linhasFiltradas: [],
      cor_produto: [],
      tipo_produto: null,
      unidade_medida: null,
      codigo_auxiliar: '',
      codigo_mercado: '',
      espessura: '',
      codigo_base: '',
      tipo: '',
      nome_base: '',
      peso_base: '',
      linha: null,
      produto: null,
      ativo: true,

      exibirErros: false,
      erros: {},

      mostrarModal: false,
      galeria: false
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  watch: {
    tipo_produto: {
      handler(newVal) {
        if (newVal) {
          this.cor_produto = ''
          this.tipo = ''
          this.unidade_medida = ''
          this.peso_base = ''
          this.linha = ''
          this.getListaCores();
          this.getListaTiposProduto()
          this.getListaUnidadeMedida()

          if (newVal.nome == 'Vidro') {
            this.getListaEspessura();
          } else {
            this.espessura = ''
            this.lista_espessuras = []
          }
        }

        console.log(newVal)
      },
      immediate: false,
    },
  },

  created() {
    this.getListaProdutoSistema()
    this.getListaLinhas()
  },


  methods: {

    async getListaTiposProduto() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      axios.get(`produtos/tipo-produto-base/?produto=${this.tipo_produto?.nome}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data);
          this.lista_tipo_produto_base = response.data.results;
        })
        .catch(error => {
          console.error(error);
        });
    },

    // handleSelect(value) {
    //   this.getListaCores(value.id)
    // },

    async getListaProdutoSistema() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get('produtos/produtos-sistema', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data);
          this.lista_produtos_sistema = response.data.results;
          // this.produtoSistemaFiltrado = this.lista_produtos_sistema.map(item => item.nome);
        })
        .catch(error => {
          console.error(error);
        });

    },

    async getListaCores() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);

      axios.get(`produtos/cor-produto-base/?produto=${this.tipo_produto.id}&page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data);
          this.lista_cores = response.data.results;
          // this.coresFiltradas = this.lista_cores.map(item => item.nome);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.$store.dispatch("setLoading", false);
        });
    },

    async getListaEspessura() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);

      axios.get(`produtos/espessura/?page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data.results);
          this.lista_espessuras = response.data.results;
        })
        .catch(error => {
          console.error(error);
        })

    },

    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto;
    },

    abrir_galeria() {
      this.galeria = !this.galeria
    },

    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },

    async getListaUnidadeMedida() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get(`produtos/unidade-medida/?produto=${this.tipo_produto?.nome}&page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data);
          this.lista_unidade_medida = response.data.results;
        })
        .catch(error => {
          console.error(error);
        });

    },

    async getListaLinhas() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      axios.get('projeto/linha-sistema/?ativo=True&page_size=9999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_linhas = response.data.results;
          console.log(this.lista_linhas)
        })
        .catch(error => {
          console.error(error);
        });

    },


    toggleAtivo() {
      this.ativo = !this.ativo
    },


    async cadastrarProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      if (this.tipo_produto?.nome === 'Vidro' && !this.espessura) {
        this.erros = { espessura: ["Este campo é obrigatório."] };
        this.toggleErros();
        return;
      }

      if ((this.tipo_produto?.nome === 'Vidro' || this.tipo_produto?.nome === 'Perfil') && !this.peso_base) {
        this.erros = { peso: ["Este campo é obrigatório."] };
        this.toggleErros();
        return;
      }

      console.log(this.linha);

      let linhaIds = [];
      if (this.linha) {
        linhaIds = await this.processarLinhas(this.linha);
      }

      // Criando um objeto normal
      const data = {
        codigo_base: this.codigo_base,
        nome_base: this.nome_base,
        unidade_medida: this.unidade_medida?.id || '',
        tipo_produto: this.tipo_produto?.id || '',
        peso_base: this.peso_base,
        ativo: this.ativo,
        produto: this.tipo?.id || '',
        espessura: this.espessura?.espessura || '',
        linha: this.linha ? linhaIds : [],
        cor_base: this.cor_produto.length > 0 ? this.cor_produto.map(cor => cor.id) : [],
      };

      // Adiciona a imagem ao objeto apenas se ela existir
      if (this.previewImage) {
        data.imagem = this.previewImage.id;
      }


      console.log(data);

      this.$store.dispatch("setLoading", true);
      this.sucesso = false;

      await axios.post('produtos/produto/', data, {
        headers: {
          'Authorization': `Token ${token}`,
        }
      })
        .then(response => {
          console.log(response.data.results);
          this.sucesso = true;
        })
        .catch(error => {
          console.error(error);
          this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
          this.toggleErros();
        })
        .finally(() => {
          this.$store.dispatch("setLoading", false);
        });
    },


    async processarLinhas(linhas) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      const linhaIds = [];

      for (const linha of linhas) {
        const nome = linha.linha;
        try {

          const response = await axios.get(`produtos/linha-produto-base/?nome=${nome}`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          });

          if (response.data.results.length > 0) {
            linhaIds.push(response.data.results[0].id);
          } else {
            const createdResponse = await axios.post('produtos/linha-produto-base/', { nome }, {
              headers: {
                'Authorization': `Token ${token}`
              }
            });
            linhaIds.push(createdResponse.data.id);
          }
        } catch (error) {
          console.error(`Erro ao processar a linha ${nome}:`, error);
        }
      }

      return linhaIds;
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },


  }
}
</script>

<style lang="scss">
.imagePreviewWrapper {
  @apply w-full h-[300px] mt-2 bg-contain bg-center bg-no-repeat block cursor-pointer border border-corBase/10 bg-white
}

//protudo
.div-produtos {
  @apply flex gap-3 my-3 w-full
}

//chapa
.campo-chapa {
  @apply flex flex-col gap-1 w-full
}

.input-form-chapa {
  @apply w-full bg-white border-2 border-corBase/5 border-b-corBase/20 focus:border-b-corBase/60 px-1 py-1 outline-none focus:transition focus:duration-500
}

.selecionar-arquivo {
  @apply bg-screenCor p-1.5 w-full border-2 border-b-corBase text-corBase text-center shadow-sm shadow-corBase/20
}
</style>