<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Vidro</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-center w-full gap-2 mb-2">

      <div class="ml-2 w-[23%]">
        <label class="text-xl text-corBase">Status:</label>
        <v-select v-model="status" class="w-full z-10" :options="lista_status" :reduce="status => status.value"
          label="nome" :clearable="false" />
      </div>

      <div class="ml-2 w-[23%]">
        <label class="text-xl text-corBase">Disponibilidade:</label>
        <v-select v-model="disponibilidade" class="w-full z-10" :options="lista_disponibilidade"
          :reduce="status => status.value" label="nome" :clearable="false" />
      </div>

      <div class="w-[98%]">
        <label class="text-xl text-corBase">Fornecedor:</label>
        <v-select v-model="fornecedor" :options="lista_fornecedores" label="nome_razao"
          :reduce="fornecedor => fornecedor.id" placeholder="Escolha uma opção" />
      </div>
    </div>

    <div class="mb-4">
      <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
        <div class="px-2 w-1/2">
          <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
            <option value="descricao">Descrição</option>
            <option value="descricao_base">Descrição Base</option>
            <option value="tipo">Tipo</option>
            <option value="cor_base">Cor</option>
            <option value="espessura">Espessura</option>
            <option value="acrescimo_tamanho">Acréscimo Tamanho</option>
          </select>
        </div>

        <div class="w-full">

          <v-select v-if="filtro.campo === 'tipo'" v-model="filtro.valor" :options="lista_tipo_vidro" label="descricao"
            :reduce="tipo => tipo.id" placeholder="Escolha uma opção.">
          </v-select>

          <v-select v-else-if="filtro.campo === 'cor_base'" v-model="filtro.valor" :options="lista_cor_vidro"
            label="descricao" :reduce="cor => cor.id" placeholder="Escolha uma opção." />

          <v-select v-else-if="filtro.campo === 'espessura'" v-model="filtro.valor" :options="lista_espessura"
            label="text" :reduce="espessura => espessura.text" placeholder="Escolha uma opção." />

          <v-select v-else-if="filtro.campo === 'acrescimo_tamanho'" v-model="filtro.valor"
            :options="lista_acrescimo_tamanho" label="label" :reduce="acrescimo => acrescimo.value"
            placeholder="Escolha uma opção." />

          <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Digite o desejado" />
        </div>

        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
      </div>

      <div class="flex w-full justify-center">
        <button class="text-ativo" @click.prevent="adicionarFiltro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>



    <div class="flex flex-wrap items-end gap-2 mb-6">
      <button
        class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
        @click.prevent="mudarPagina(1)">
        Pesquisar
      </button>

      <button @click.prevent="toggleImportarVidros()" class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
  text-white hover:text-yellow-500 py-2 px-4">Importar
      </button>

      <button @click.prevent="alterarSelecionados()"
        class="bg-blue-500 text-white border hover:bg-screenCor hover:text-blue-500 hover:border-blue-500 py-2 px-4">
        Alterar Selecionados
      </button>

    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr class="border-b">
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-2 py-3">
              Ações
            </th>
            <th class="px-6 py-3">
              Imagem
            </th>
            <th class="px-6 py-3 text-center">
              Descrição
            </th>
            <th class="px-6 py-3 whitespace-nowrap text-center">
              Descrição Base
            </th>
            <th class="px-6 py-3 text-center">
              Tipo
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Cor
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Espessura
            </th>
            <th class="px-6 py-3 text-center">
              Unidade Medida
            </th>
            <th class="px-6 py-3 text-center">
              Peso (KG)
            </th>
            <th class="px-6 py-3 text-center">
              Fornecedor
            </th>
            <!-- <th scope="col" class="px-6 py-3 text-center">
              $ Custo
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              $ Custo Médio
            </th> -->
            <th scope="col" class="px-6 py-3 text-center">
              Acréscimo Tamanho
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Medida (M²/M)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              % Acréscimo Tamanho
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Área Mínima Cobrança (M²)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Arredondamento Cobrança (mm)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Disponibilidade
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(vidro, index) in lista_vidro" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="vidro.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-2 py-2 text-xs text-center">
              <a @click.prevent="selecionarVidroEditar(vidro)" href="#"
                class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square"></i>
              </a>
            </td>
            <td class="px-6 py-2 text-xs">
              <button v-if="vidro.imagem && vidro.imagem.url_documento"
                @click.prevent="openPreviewImage(vidro.imagem.url_documento)">
                <img class="w-12" :src="vidro.imagem.url_documento" :alt="vidro.imagem.descricao" />
              </button>
              <img v-else class="w-12"
                src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                alt="Sem foto">
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ vidro.descricao }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ vidro.vidro_base?.nome_base }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ vidro.vidro_base?.produto?.descricao || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              <!-- {{ vidro.vidro_base?.cor_base?.descricao || '-' }} -->
              {{ vidro.vidro_base?.cor_base.map(cor => cor.descricao).join(', ') || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ vidro.vidro_base?.espessura }}mm
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ vidro.vidro_base?.unidade_medida?.nome || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ vidro.vidro_base?.peso_base || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center whitespace-nowrap">
              {{ vidro.fornecedor?.nome_razao || '-' }}
            </td>
            <!-- <td class="px-6 py-2 text-xs text-center">
              {{ vidro.custo || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ vidro.custo_medio || '-' }}
            </td> -->
            <td class="px-6 py-2 text-xs text-center whitespace-nowrap">
              {{ replaceSiglaAcrescimoTamanho(vidro.acrescimo_tamanho) }}
            </td>
            <td class="px-6 py-2 text-xs text-center whitespace-nowrap">
              {{ vidro.medida || '-' }}
              <span v-if="vidro.acrescimo_tamanho == 'MQ'"> M²</span>
              <span v-if="vidro.acrescimo_tamanho == 'ML'"> M</span>
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ vidro.porcentagem_acrescimo || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ vidro.metragem_minima || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ vidro.arredondamento || '-' }}
            </td>
            <td class="px-6 py-2 text-center">
              <div class="flex flex-col items-center">
                <!-- Ícone com condicional -->
                <i v-if="vidro.disponibilidade" class="fa-solid fa-circle-check text-ativo mb-1" title="Ativo"></i>
                <i v-else class="fa-solid fa-circle-xmark text-inativo mb-1"
                  :title="replaceSiglaMotivoIndisponivel(vidro.motivo_indisponivel)"></i>
                <!-- Texto pequeno abaixo do ícone -->
                <span v-if="!vidro.disponibilidade" class="text-xs text-gray-500">
                  {{ replaceSiglaMotivoIndisponivel(vidro.motivo_indisponivel) }}
                </span>
              </div>
            </td>
            <td class="px-6 py-2 text-center">
              <div class="flex flex-col items-center">
                <!-- Ícone com condicional -->
                <i v-if="vidro.ativo" class="fa-solid fa-circle-check text-ativo mb-1" title="Ativo"></i>
                <i v-else class="fa-solid fa-circle-xmark text-inativo mb-1"
                  :title="replaceSiglaMotivoDesativado(vidro.motivo_desativado)"></i>
                <!-- Texto pequeno abaixo do ícone -->
                <span v-if="!vidro.ativo" class="text-xs text-gray-500">
                  {{ replaceSiglaMotivoDesativado(vidro.motivo_desativado) }}
                </span>
              </div>
            </td>

          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_vidro.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />

    </div>
  </div>

  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />

  <AlterarSelecionadosVidro v-if="modalAlterarSelecionados" @fecharModal="toggleAlterarSelecionados()"
    @atualizarLista="mudarPagina(paginaAtual)" :vidros="vidrosSelecionados" />

  <!-- <AlterarFornecedorVidro v-if="modalAlterarFornecedor" @fecharModal="toggleAlterarFornecedores()"
    @atualizarLista="mudarPagina(paginaAtual)" :vidros="vidrosSelecionados" :lista_fornecedores="lista_fornecedores" /> -->

  <!-- <AlterarValorCustoVidro v-if="modalAlterarValorCusto" @fecharModal="toggleAlterarValorCusto"
    @atualizarLista="mudarPagina(paginaAtual)" /> -->
  <!-- :lista_tipo_produto="lista_tipo_vidro" :lista_cores="lista_cor_vidro"
    :lista_espessuras="lista_espessura" -->

  <ModalImportacaoVidro v-if="modalImportarVidros" @fecharModal="toggleImportarVidros"
    @atualizarLista="mudarPagina(paginaAtual)" :lista_tipo_produto="lista_tipo_vidro" :lista_cores="lista_cor_vidro"
    :lista_espessuras="lista_espessura" />

  <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

  <EditarVidro v-if="mostrarModal" :vidro="vidroSelecionado" @atualizarLista="mudarPagina(paginaAtual)"
    @fecharModal="toggleModal" />

</template>

<script>
import axios from "axios";
import EditarVidro from "@/components/midais/Editar/produtos/EditarVidro";
import ModalImportacaoVidro from "./ModalImportacaoVidro.vue";
import PreviewImage from "@/components/midais/ged/PreviewImage.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import AlterarSelecionadosVidro from "@/components/midais/Editar/produtos/AlterarSelecionadosVidro.vue";
// import AlterarFornecedorVidro from "@/components/midais/Editar/produtos/AlterarFornecedorVidro.vue";
// import AlterarValorCustoVidro from "@/components/midais/Editar/produtos/AlterarValorCustoVidro.vue";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaVidro",

  components: {
    EditarVidro,
    ModalImportacaoVidro,
    PreviewImage,
    ListagemErros,
    AlterarSelecionadosVidro,
    // AlterarFornecedorVidro,
    // AlterarValorCustoVidro,
    vSelect,
    Pagination
  },

  data() {
    return {
      checkAll: false,
      filtros: [{ campo: "descricao", valor: "" }],

      lista_cor_vidro: [],
      lista_tipo_vidro: [],
      lista_espessura: [],
      lista_fornecedores: [],
      lista_acrescimo_tamanho: [
        { label: 'Sem Acréscimo', value: 'SA' },
        { label: 'Metro Quadrado', value: 'MQ' },
        { label: 'Metro Linear', value: 'ML' },
      ],
      lista_status: [
        { value: 'True', nome: "Ativo" },
        { value: 'False', nome: "Inativo" },
        { value: 'Todos', nome: "Todos" },
      ],
      lista_disponibilidade: [
        { value: 'True', nome: "Sim" },
        { value: 'False', nome: "Não" },
        { value: 'Todos', nome: "Todos" },
      ],
      lista_custo: [
        { value: 'Todos', nome: "Todos" },
        { value: 'Com Preço', nome: "Com Preço" },
        { value: 'Sem Preço', nome: "Sem Preço" },
      ],

      status: 'True',
      disponibilidade: 'Todos',
      fornecedor: '',

      paginaAtual: 1,
      countItens: '',

      preview_imagem: '',

      vidrosSelecionados: [],
      modalAlterarSelecionados: false,
      // modalAlterarFornecedor: false,
      // modalAlterarValorCusto: false,

      modalImportarVidros: false,

      vidroSelecionado: null,
      mostrarModal: false,

      lista_vidro: [],

      exibirErros: false,
      erros: [],
    }
  },

  created() {
    this.getListaCorVidro()
    this.getListaTipoVidro()
    this.getListaEspessura()
    this.getListaFornecedores()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    checkSome() {
      return this.lista_vidro.some(produto => produto.selected)
    }
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },

    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    replaceSiglaMotivoIndisponivel(status) {
      if (typeof status !== 'string') {
        return '-';
      }

      return status
        .replace('SDE', 'Suspenso por Decisão Empresarial')
        .replace('PFF', 'Produto em Falta no Fornecedor')
    },

    replaceSiglaMotivoDesativado(status) {
      if (typeof status !== 'string') {
        return '-';
      }

      return status
        .replace('DDE', 'Desativado por Decisão Empresarial')
        .replace('DF', 'Descontinuado pelo Fornecedor')
    },

    replaceSiglaAcrescimoTamanho(status) {
      if (typeof status !== 'string') {
        return '-';
      }

      return status
        .replace('SA', 'Sem Acréscimo')
        .replace('MQ', 'Metro Quadrado')
        .replace('ML', 'Metro Linear')
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });

      this.lista_vidro.forEach((vidro) => {
        vidro.selected = this.checkAll;
      });
    },

    async getListaVidro(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        const response = await axios.get(`produtos/vidro/?${params}&ativo=${this.status}&disponibilidade=${this.disponibilidade}&fornecedor=${this.fornecedor || ''}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // Armazenar os dados recebidos
        this.lista_vidro = response.data.results
        this.countItens = response.data.count
        console.log(this.lista_vidro);

      } catch (error) {
        console.error("Erro ao buscar lista de vidros:", error);

      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleImportarVidros() {
      this.modalImportarVidros = !this.modalImportarVidros
    },

    mudarPagina(pagina) {
      this.checkAll = false
      this.paginaAtual = pagina;
      this.getListaVidro(pagina);
    },

    selecionarVidroEditar(vidro) {
      this.vidroSelecionado = { ...vidro }
      this.toggleModal()
    },

    toggleModal() {
      this.mostrarModal = !this.mostrarModal
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    // Requisição para obter a cor do vidro
    async getListaCorVidro() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/cor-produto-base/?tipo_produto=Vidro&ativo=True&page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data.results);
        this.lista_cor_vidro = response.data.results.map(item => ({
          id: item.id,
          descricao: item.descricao
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de cores de vidro:", error);
      }
    },

    // Requisição para obter o tipo de vidro
    async getListaTipoVidro() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/tipo-produto-base/?produto=Vidro&ativo=True&page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data.results);
        this.lista_tipo_vidro = response.data.results.map(item => ({
          id: item.id,
          descricao: item.descricao
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de tipos de vidro:", error);
      }
    },

    // Requisição para obter as espessuras
    async getListaEspessura() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/espessura/?ativo=True&page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data.results);
        this.lista_espessura = response.data.results.map(item => ({
          id: item.id,
          text: item.espessura
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de espessuras:", error);
      }
    },

    // Requisição para obter os fornecedores
    async getListaFornecedores() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`pessoa/pessoas-fisica-juridica/?produto_fornecedor=Vidro&atividade=Fornecedor&page=1`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_fornecedores = response.data.results.map(fornecedor => {
          return {
            id: fornecedor.pessoa.id,
            nome_razao: fornecedor.nome || fornecedor.razao_social,
          }
        })
        this.getSemFornecedor()
      } catch (error) {
        console.error(error)
      }
    },

    async getSemFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      try {
        const nome_razao = encodeURIComponent('Fornecedor Indefinido')
        const response = await axios.get(`pessoas/?nome_razao=${nome_razao}&atividade=Fornecedor`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        const semFornecedor = response.data.results[0];
        if (semFornecedor) {
          // Adicionar "Fornecedor Indefinido" à lista de fornecedores
          this.lista_fornecedores.unshift({
            id: semFornecedor.id,
            nome_razao: semFornecedor.nome_razao
          });
        }
      } catch (error) {
        console.error(error)
      }
    },

    alterarSelecionados() {
      if (this.checkSome || this.checkAll) {
        this.vidrosSelecionados = this.lista_vidro.filter(vidro => vidro.selected);

        // Verificar se há pelo menos um fornecedor definido e um indefinido ao mesmo tempo
        const temFornecedorIndefinido = this.vidrosSelecionados.some(vidro =>
          vidro.fornecedor?.nome_razao === "Fornecedor Indefinido"
        );

        const temFornecedorDefinido = this.vidrosSelecionados.some(vidro =>
          vidro.fornecedor?.nome_razao !== "Fornecedor Indefinido"
        );

        console.log(`Fornecedor Indefinido: ${temFornecedorIndefinido}`);
        console.log(`Fornecedor Definido: ${temFornecedorDefinido}`);

        // Se houver um misto de definidos e indefinidos, exibe erro
        if (temFornecedorIndefinido && temFornecedorDefinido) {
          this.erros = { alterar_selecionados: ["Alteração não permitida quando houver Fornecedor Indefinido combinado com outro Fornecedor."] };
          this.toggleErros();
          return;
        }

        this.toggleAlterarSelecionados();
      } else {
        this.erros = { vidro: ["Selecione pelo menos um Vidro para ser alterado."] };
        this.toggleErros();
      }
    },

    toggleAlterarSelecionados() {
      this.modalAlterarSelecionados = !this.modalAlterarSelecionados;
    },

    // alterarFornecedor() {
    //   if (this.checkSome || this.checkAll) {
    //     this.vidrosSelecionados = this.lista_vidro.filter(vidro => vidro.selected);
    //     this.toggleAlterarFornecedores();
    //   } else {
    //     this.erros = { vidro: ["Selecione o(s) item(ns) para continuar."] };
    //     this.toggleErros();
    //   }
    // },

    // toggleAlterarFornecedores() {
    //   this.modalAlterarFornecedor = !this.modalAlterarFornecedor
    // },

    alterarValorCusto() {
      if (this.checkSome || this.checkAll) {
        this.vidrosSelecionados = this.lista_vidro.filter(vidro => vidro.selected);
        this.toggleAlterarValorCusto();
      } else {
        this.erros = { vidro: ["Selecione o(s) item(ns) para continuar."] };
        this.toggleErros();
      }
    },

    toggleAlterarValorCusto() {
      this.modalAlterarValorCusto = !this.modalAlterarValorCusto
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

  }
}
</script>

<style lang="scss"></style>