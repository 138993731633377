<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[9998]">
            <div class="bg-white rounded-lg min-w-[80%] max-w-[90%] shadow-lg p-6 max-h-[80vh] overflow-y-auto ">
                <!-- Cabeçalho do modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-blue-600">Adicionar Colaboradores</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <!-- Filtros -->
                <!-- <div class="mb-4">
                    <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                        <div class="px-2 w-1/2">
                            <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                                <option value="codigo">Código</option>
                                <option value="descricao">Descrição</option>
                                <option value="tipo">Tipo</option>
                                <option value="cor">Cor</option>
                                <option value="fornecedor">Fornecedor</option>
                            </select>
                        </div>

                        <div class="w-full">
                            <v-select v-if="filtro.campo === 'tipo'" v-model="filtro.valor"
                                :options="lista_tipo_produto" label="descricao" :reduce="tipo => tipo.id"
                                placeholder="Escolha uma opção" />

                            <v-select v-else-if="filtro.campo === 'cor'" v-model="filtro.valor" :options="lista_cores"
                                label="descricao" :reduce="cor => cor.id" placeholder="Escolha uma opção" />

                            <v-select v-else-if="filtro.campo === 'fornecedor'" v-model="filtro.valor"
                                :options="lista_fornecedores" label="nome_razao" :reduce="fornecedor => fornecedor.id"
                                placeholder="Escolha uma opção" />

                            <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                                placeholder="Digite o desejado" />
                        </div>

                        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
                    </div>

                    <div class="flex w-full justify-center">
                        <button class="text-ativo" @click.prevent="adicionarFiltro">
                            <i class="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div> -->

                <!-- Botões de ação -->
                <div class="flex flex-wrap gap-2 mb-6">
                    <button
                        class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
                        @click.prevent="mudarPagina(1)">
                        Pesquisar
                    </button>

                    <button @click.prevent="importarColaboradores()"
                        class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500 text-white hover:text-yellow-500 py-2 px-4">
                        Adicionar
                    </button>
                </div>

                <!-- Tabela de importação -->
                <div class="container-modelo-tabela">
                    <table class="container-tabela overflow-auto">
                        <thead class="cabecalho-tabela">
                            <tr class="border-b">
                                <th class="w-4 p-4">
                                    <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                </th>
                                <th class="px-6 py-3">#</th>
                                <th class="px-2 py-3">Nome</th>
                                <th class="px-20 py-3 text-center">Funções</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(colaborador, index) in lista_colaboradores" :key="colaborador.id"
                                class="border-b hover:bg-gray-50">
                                <td class="w-4 p-4">
                                    <input type="checkbox" class="checkbox" v-model="colaborador.selected">
                                </td>
                                <th
                                    class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ index + 1 }}
                                </th>
                                <td class="px-2 py-3">{{ colaborador.nome_razao || '-' }}</td>

                                <!-- Centralizando o v-select -->
                                <td class="py-3 w-fulltext-center flex justify-center">
                                    <v-select v-model="colaborador.funcao_colaborador" label="descricao"
                                        :options="lista_funcoes_colaborador" :multiple="true" class="w-full"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Pagination v-if="lista_colaboradores.length > 0" :offset="paginaAtual" :total="countItens"
                        :limit="20" @change-page="mudarPagina" />
                </div>

            </div>
            <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
        </div>
    </div>

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
</template>

<script>
import axios from "axios"
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import PreviewImage from "@/components/midais/ged/PreviewImage.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import Pagination from "@/utils/Pagination.vue";
import vSelect from "vue-select";

export default {
    name: 'ImportacaoColaboradores',

    props: ['lista_funcoes_colaborador'],

    emits: ['fecharModal', 'atualizarLista'],

    components: {
        ListagemErros,
        PreviewImage,
        SucessoRapido,
        Pagination,
        vSelect,
    },

    data() {
        return {
            checkAll: false,

            filtros: [{ campo: "codigo", valor: "" }],

            lista_colaboradores: [],
            // lista_funcoes_colaborador: [],

            preview_imagem: '',

            modalAlterarSelecionados: false,
            perfisSelecionados: [],

            exibirErros: false,
            erros: [],

            paginaAtual: 1,
            countItens: '',

            sucesso: false,
            sucessoMessage: '',
        }
    },

    computed: {
        checkSome() {
            return this.lista_colaboradores.some(colaborador => colaborador.selected)
        }
    },

    created() {
        // this.getListaFuncoesColaborador()
    },

    methods: {

        // atualizarFiltro(index) {
        //     this.filtros[index].valor = null;
        // },

        // adicionarFiltro() {
        //     this.filtros.push({ campo: "", valor: "" });
        // },
        // removerFiltro(index) {
        //     this.filtros.splice(index, 1);
        // },

        selectAll() {
            this.lista_colaboradores.forEach((perfil) => {
                perfil.selected = this.checkAll;
            });
        },

        async buscarListaColaboradores(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {

                this.$store.dispatch("setLoading", true);
                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");

                const response = await axios.get(
                    `pessoa/pessoa-fisica/?atividade=Colaborador&importado=false&page=${pagina}&${params}`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                );

                this.lista_colaboradores = response.data.results.map((colaborador) => {
                    return {
                        id: colaborador.pessoa?.id,
                        nome_razao: colaborador.nome
                    }
                })
                console.log(this.lista_colaboradores)
                this.countItens = response.data.count;

            } catch (error) {
                console.error("Erro ao buscar produtos:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async importarColaboradores() {
            if (!this.checkSome) {
                this.erros = { colaborador: ["Selecione pelo menos um Colaborador para importar."] };
                this.toggleErros();
                return;
            }

            try {
                const colaboradoresSelecionados = this.lista_colaboradores
                    .filter(colaborador => colaborador.selected)
                    .map(colaborador => ({
                        pessoa_id: colaborador.id,
                        funcoes: colaborador.funcao_colaborador ? colaborador.funcao_colaborador.map(funcao => funcao.id) : [] // Convertendo para IDs
                    }));

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await axios.post(`rh/colaborador/`, { colaboradores: colaboradoresSelecionados }, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.sucesso = true;
                this.sucessoMessage = 'Colaborador adicionado com sucesso!'
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('atualizarLista')
                    this.$emit('fecharModal')
                }, 3000);
            } catch (error) {
                this.erros = error.response?.data || { perfil: ["Erro ao importar perfis."] };
                this.toggleErros();
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

        mudarPagina(pagina) {
            this.checkAll = false
            this.paginaAtual = pagina;
            this.buscarListaColaboradores(pagina);
        },
    }
}
</script>

<style scoped>
.btn-modal-importacao {
    @apply px-4 py-1 cursor-pointer
}

input[type=text] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}

input[type=number] {
    @apply w-full p-1 border border-[#ccc] rounded-md
}
</style>