<template>
    <div>

        <div class="flex gap-2 w-full">

            <div class="w-full">
                <label class="font-bold text-corBase">Cliente:</label>
                <v-select :options="listaClientes" label="nome_razao" v-model="orcamento.cliente"
                    placeholder="Escolha uma opção" />
            </div>

            <div class="w-full">
                <label class="font-bold text-corBase">Obra:</label>
                <v-select v-model="orcamento.obra" class="w-full" :options="lista_obras" label="descricao"
                    @search="handleSearchObra" :disabled="!orcamento.cliente"
                    :placeholder="orcamento.cliente ? '' : 'Selecione um cliente.'">
                    <template #no-options>
                        <span>
                            {{ searchQuery.length < 3 ? 'Digite pelo menos 3 caracteres para buscar.' : buscando
                                ? 'Carregando...' : 'Nenhuma obra encontrada.' }} </span>
                    </template>
                </v-select>
            </div>

            <div class="w-full">
                <label class="font-bold text-corBase">Referência:</label>
                <input type="text" v-model="orcamento.referencia" maxlength="100" placeholder="Digite aqui..." />
            </div>
        </div>

        <div class="flex gap-2 w-full mt-2">

            <div class="w-full">
                <label class="font-bold text-corBase">Vendedor:</label>
                <v-select v-model="orcamento.vendedor" class="w-full" :options="listaVendedores" label="nome_razao"
                    :reduce="vendedor => vendedor.id" placeholder="Selecione uma opção" />
            </div>

            <div class="w-full">
                <label class="font-bold text-corBase">Status:</label>
                <v-select :options="listaStatus" v-model="orcamento.status" placeholder="Escolha uma opção" />
            </div>

        </div>
        <div class="flex gap-2 w-full mt-2">

            <div class="w-full">
                <label class="font-bold text-corBase">Prazo de Validade:</label>
                <input type="number" v-model="orcamento.prazo_validade" />
            </div>

            <div class="w-full">
                <label class="font-bold text-corBase">Data de Cadastro:</label>
                <input type="date" v-model="orcamento.data_cadastro" />
            </div>

            <div class="w-full">
                <label class="font-bold text-corBase">Data de Validade:</label>
                <input type="date" v-model="orcamento.data_validade" />
            </div>
        </div>

        <div class="flex justify-center mt-5 mb-3">
            <button @click.prevent="buscarEnderecoCliente()" class="btn-puxar-endereco w-50">
                Importar endereço do cadastro do Cliente
            </button>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
            <!-- Primeira Linha -->
            <div class="form-group">
                <label class="font-bold">CEP</label>
                <input v-model="orcamento.cep" type="text" id="cep" @input="buscarEndereco" v-mask="'#####-###'"
                    placeholder="Digite aqui..." />
            </div>
            <div class="form-group">
                <label class="font-bold">Logradouro</label>
                <input v-model="orcamento.logradouro" type="text" placeholder="Digite aqui..." />
            </div>
            <div class="form-group">
                <label class="font-bold">Número</label>
                <input v-model="orcamento.numero" type="text" placeholder="Digite aqui..." />
            </div>

            <div class="form-group">
                <label>Complemento</label>
                <input v-model="orcamento.complemento" type="text" placeholder="Digite aqui..." />
            </div>

            <!-- Terceira Linha -->
            <div class="form-group">
                <label>Bairro</label>
                <input v-model="orcamento.bairro" type="text" placeholder="Digite aqui..." />
            </div>
            <div class="form-group">
                <label class="font-bold">Estado</label>
                <v-select v-model="orcamento.estado" :options="estados" :reduce="estado => estado.sigla" label="nome"
                    placeholder="Selecione um estado" @select="buscarCidades()" />
            </div>
            <div class="form-group">
                <label class="font-bold">Cidade</label>
                <v-select v-model="orcamento.cidade" :options="cidades" :reduce="cidade => cidade.nome" label="nome"
                    placeholder="Selecione uma cidade" />
            </div>
        </div>

        <!-- <div class="flex gap-12 w-full">
  <div class="w-full">
    <label class="font-bold text-corBase">Referência</label>
    <input type="text" class="input-campos" v-model="orcamento.referencia">
  </div>

  <div class="w-full">
    <label class="font-bold text-corBase">Tipo Medida Contramarco</label>
    <Select2 v-model="orcamento.tipoMedidaContramarco" class="w-full" :options="listaTiposMedidas"
      :settings="{ width: '100%' }" />
  </div>
</div> -->

        <!-- <div class="flex w-full justify-around">

  <div class="check-box">
    <label class="font-bold text-corBase">Contramarco</label>
    <input type="checkbox" class="p-1 text-corBase border border-corBase/40 w-5 h-5"
      v-model="orcamento.contramarco">
  </div>

  <div class="check-box">
    <label class="font-bold text-corBase">Arremate</label>
    <input type="checkbox" class="p-1 text-corBase border border-corBase/40 w-5 h-5" v-model="orcamento.arremate">
  </div>

  <div class="check-box">
    <label class="font-bold text-corBase">Arremate Piso</label>
    <input type="checkbox" class="p-1 text-corBase border border-corBase/40 w-5 h-5"
      v-model="orcamento.arrematePiso">
  </div>
</div> -->

        <ModalListagemEnderecos v-if="exibirEnderecos" :lista_enderecos="enderecoCliente"
            @enderecoSelecionado="enderecoSelecionado" @fecharModal="toggleModalEnderecos" />

        <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />

    </div>
</template>

<script>
import ModalListagemEnderecos from '@/views/obras/EtapasCriacaoObra/Modais/ModalListagemEnderecos.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import axios from 'axios'
import vSelect from 'vue-select';

export default {
    name: 'DadosOrcamento',

    components: {
        ModalListagemEnderecos,
        ListagemErros,
        vSelect,
    },

    props: ['orcamento'],

    data() {
        return {
            hoje: this.formatarData(new Date()),

            enderecoCliente: [],
            exibirEnderecos: false,

            searchQuery: "",
            buscando: false,

            lista_obras: [],

            estados: [],
            cidades: [],
            listaStatus: ['Orçamento', 'Agendado', 'Venda', 'Faturado', 'Cancelado'],
            listaClientes: [],
            listaVendedores: [],

            exibirErros: false,
            erros: [],
        }
    },

    watch: {
        'orcamento.estado': {
            handler(novoEstado) {
                if (novoEstado) {
                    this.buscarCidades(novoEstado);
                }
            },
            immediate: true
        }
    },

    created() {
        if (!this.orcamento.criado_em) {
            this.orcamento.data_cadastro = this.hoje;
        } else {
            this.orcamento.data_cadastro = this.orcamento.criado_em.split('T')[0];
        }

        this.getListaClientes()
        this.getListaVendedores()
        this.carregarEstados()
    },

    methods: {

        async carregarEstados() {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/estados/`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });

                this.estados = response.data.map((estado) => ({
                    sigla: estado.sigla,
                    nome: `${estado.nome} (${estado.sigla})`,
                }))

            } catch (error) {
                console.error("Erro ao carregar estados:", error);
            }
        },

        async buscarCidades(estado) {
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");
                const response = await axios.get(`obra/municipios/?estado=${estado}`, {
                    headers: {
                        "Authorization": `Token ${token}`
                    }
                });
                this.cidades = response.data.map((cidade) => ({
                    id: cidade.id,
                    nome: cidade.nome,
                }))

            } catch (error) {
                console.error("Erro ao carregar cidades:", error);
            }
        },

        async buscarEndereco() {
            const cep = this.orcamento.cep.replace(/\D/g, '');
            if (cep.length === 8) {
                try {
                    const token = this.$store.state.token;
                    await this.$store.dispatch('fetchURLrequest');
                    this.$store.dispatch("setLoading", true);
                    const response = await axios.get(`cep/${cep}`, {
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });
                    if (!response.data.erro) {
                        this.orcamento.logradouro = response.data.logradouro;
                        this.orcamento.bairro = response.data.bairro;
                        this.orcamento.cidade = response.data.localidade;
                        this.orcamento.estado = response.data.uf;
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    this.$store.dispatch("setLoading", false);
                }
            }
        },

        async handleSearchObra(searchTerm) {
            this.searchQuery = searchTerm; // Atualiza o termo de busca

            if (!searchTerm || searchTerm.length < 3) {
                this.lista_obras = []; // Limpa a lista se não atender o critério
                return;
            }

            try {

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                this.buscando = true
                // this.$store.dispatch("setLoading", true);

                const response = await axios.get(`obra/?cliente=${this.orcamento?.cliente?.id}&descricao=${searchTerm}&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.lista_obras = response.data.results;
            } catch (error) {
                console.error("Erro ao buscar obras:", error);
            } finally {
                this.buscando = false
                // this.$store.dispatch("setLoading", false);
            }
        },

        async getListaVendedores() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('rh/colaborador/?funcao=Vendedor&ativo=True&page_size=9999', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                this.listaVendedores = response.data.results.map(item => ({
                    id: item.pessoa.id,
                    nome_razao: item.pessoa.nome_razao
                }))

            } catch (error) {
                console.error(error)
            }
        },

        async getListaClientes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?atividade=Cliente&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaClientes = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    nome_razao: person.nome || person.razao_social,
                }))
                console.log(this.listaClientes)
            } catch (error) {
                console.error(error)
            }
        },

        toggleModalEnderecos() {
            this.exibirEnderecos = !this.exibirEnderecos;
        },

        async buscarEnderecoCliente() {
            if (!this.orcamento.cliente) {
                this.erros = { cliente: 'Selecione um cliente para buscar o endereço.' }
                this.toggleErros()
                return
            }
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`pessoas/enderecos/?pessoa=${this.orcamento.cliente.id}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                console.log(response.data.count)

                if (response.data.count == 1) {
                    this.enderecoCliente = response.data.results[0]
                    this.orcamento.cep = this.enderecoCliente.cep
                    this.orcamento.logradouro = this.enderecoCliente.logadouro
                    this.orcamento.numero = this.enderecoCliente.numero
                    this.orcamento.complemento = this.enderecoCliente.complemento
                    this.orcamento.bairro = this.enderecoCliente.bairro
                    this.orcamento.estado = this.enderecoCliente.uf
                    this.orcamento.cidade = this.enderecoCliente.cidade
                } else if (response.data.count > 1) {
                    this.enderecoCliente = response.data.results
                    this.toggleModalEnderecos()
                } else {
                    this.erros = { endereço: ["O Cliente selecionado não possui um endereço cadastrado."] };
                    this.toggleErros()
                    return
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch('setLoading', false)
            }
        },

        enderecoSelecionado(endereco) {
            this.orcamento.cep = endereco.cep
            this.orcamento.logradouro = endereco.logadouro
            this.orcamento.numero = endereco.numero
            this.orcamento.complemento = endereco.complemento
            this.orcamento.bairro = endereco.bairro
            this.orcamento.estado = endereco.uf
            this.orcamento.cidade = endereco.cidade
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

        formatarData(data) {
            return data.toISOString().split('T')[0];
        }

    }
}
</script>

<style scoped>
.btn-puxar-endereco {
    @apply px-4 py-1 cursor-pointer text-white bg-ativo border hover:bg-screenCor hover:text-ativo hover:border-ativo
}

input,
select {
    @apply w-full p-1 border border-[#ccc] rounded-md
}
</style>