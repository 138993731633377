<template>
  <div class="container-modal-editar">
    <div class="sombra-modal">
      <div class="conteudo-modal-editar">
        <form class="form-editar text-white" @submit.prevent="putDadosEditado">
          <div class="flex justify-end">
            <button @click.prevent="$emit('fecharModal')">
              <i class="fas fa-close text-2xl text-corBase"></i>
            </button>
          </div>
          <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Cor Vidro</h2>
          <!--Campos da Pessoa-->
          <div>
            <div class="md:flex md:gap-12">

              <div class="flex flex-col mt-2 w-full justify-start">
                <label class="text-base text-corBase font-bold mt-2">Código:</label>
                <input type="text" required class="input-campos text-base" v-model="cor.codigo"
                v-on:input="cor.codigo = cor.codigo.toUpperCase()">
              </div>

              <div class="flex flex-col mt-2 w-full justify-start">
                <label class="text-base text-corBase font-bold mt-2">Descrição:</label>
                <input type="text" required class="input-campos text-base" v-model="cor.descricao">
              </div>

              <div class="flex flex-col mt-2 w-full justify-start ">
                <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                  <i v-if="cor.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                  <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                </span>
              </div>


            </div>

          </div>

          <!--Botão-->
          <div class="btn-formulario-modal flex justify-end">
            <button class="btn-formulario-salvar" type="submit">
              Salvar
            </button>
          </div>
        </form>
      </div>
      <SucessoRapido v-if="sucesso" mensagem="Cor de Vidro atualizada com sucesso!" />
      <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
name: "EditarCorVidro",
components: {
  ListagemErros,
  SucessoRapido,
},
data() {
  return {
    sucesso: false,

    erros: [],
    exibirErros: false,

  }
},

props: ['cor'],
emits: ['fecharModal', 'atualizarLista'],

methods: {
  toggleAtivo() {
    this.cor.ativo = !this.cor.ativo
  },

  async putDadosEditado() {
    const dadosAtualizados = new FormData();
    dadosAtualizados.append('ativo', this.cor.ativo)
    dadosAtualizados.append('codigo', this.cor.codigo)
    dadosAtualizados.append('descricao', this.cor.descricao)

    try {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      const response = await axios.patch(`produtos/cor-vidro/${this.cor.id}/`, dadosAtualizados, {
        headers: {
          'Authorization': `Token ${token}`,
        }
      });
      console.log(response);

      this.sucesso = true
      setTimeout(() => {
        this.sucesso = false;
        this.$emit('fecharModal')
        this.$emit('atualizarLista')
      }, 3000);

    } catch (error) {
      console.error(error)
      this.erros = error.response?.data || {geral: 'Ocorreu um erro desconhecido.'}
      this.toggleErros()
    }
  },

  toggleErros(){
    this.exibirErros = !this.exibirErros
  }

}
}
</script>

<style lang="scss">

</style>