<template>

    <div class="block mt-8">

        <div class="mb-4">
            <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                <div class="px-2 w-1/2">
                    <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                        <option value="descricao">Descrição</option>
                        <option value="ativo">Status</option>
                    </select>
                </div>

                <div class="w-full">

                    <v-select v-if="filtro.campo === 'ativo'" v-model="filtro.valor" :options="lista_status"
                        label="nome" :reduce="status => status.value" placeholder="Escolha uma opção" />

                    <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                        placeholder="Digite o desejado" />
                </div>

                <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
            </div>

            <div class="flex w-full justify-center">
                <button class="text-ativo" @click.prevent="adicionarFiltro">
                    <i class="fas fa-plus-circle"></i>
                </button>
            </div>
        </div>

    </div>
    <div class="flex">

        <div class="flex flex-wrap gap-2 mb-2">

            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
text-white hover:text-corBase py-2 px-4" @click.prevent="getItensOrcamento(1)">Pesquisar
            </button>

            <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4" @click.prevent="$router.push(`/orcamento/selecionar-projeto/`)">Adicionar
                Projeto
            </button>

            <button class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
  text-white hover:text-yellow-500 py-2 px-4" @click.prevent="calcularOrcamento()">Calcular
            </button>

        </div>
    </div>

    <div>
        <h2 class=" text-corBase font-bold">Projetos do Orçamento</h2>
    </div>

    <!-- ARRUMAR CADASTRO DE PROJETO -->

    <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
            <thead class="cabecalho-tabela">
                <tr>
                    <th scope="col" class="px-3 py-3">
                        #
                    </th>
                    <th scope="col" class="px-3 py-3">
                        Editar
                    </th>
                    <th scope="col" class="px-3 py-3">
                        Projeto
                    </th>
                    <th scope="col" class="px-3 py-3">
                        Largura
                    </th>
                    <th scope="col" class="px-3 py-3">
                        Altura
                    </th>
                    <th scope="col" class="px-3 py-3">
                        Quantidade
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(itemOrcamento, index) in listaItensOrcamento" :key="index" class="escopo-tabela">
                    <th scope="row"
                        class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                        {{ index + 1 }}
                    </th>
                    <td class="px-3 py-2 text-xs">
                        <router-link :to="'/item-orcamento/' + itemOrcamento.id">
                            <i class="fa-solid fa-paperclip"></i>
                        </router-link>
                    </td>
                    <td class="px-3 py-2 text-xs">
                        {{ itemOrcamento.descricao }}
                    </td>
                    <td class="px-3 py-2 text-xs">
                        {{ itemOrcamento.largura }}
                    </td>
                    <td class="px-3 py-2 text-xs">
                        {{ itemOrcamento.altura }}
                    </td>
                    <td class="px-3 py-2 text-xs">
                        {{ itemOrcamento.quantidade }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>


</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';

export default {
    name: 'VaosOrcamento',

    props: ['orcamento'],

    components: {
        vSelect,
    },

    data() {
        return {
            filtros: [{ campo: "descricao", valor: "" }],

            listaItensOrcamento: [],
        }
    },

    created() {
        this.$store.state.orcamentoIdAtual = this.orcamento.id
    },

    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },
        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },


        async getItensOrcamento() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const response = await axios.get(`orcamentos/item-orcamento/?orcamento_id=${this.orcamento.id}&page_size=999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaItensOrcamento = response.data.results
            } catch (err) {
                console.error(err.message)
            }
        },
    }
}
</script>