<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[9998]">
            <div class="bg-white rounded-lg max-w-[90%] shadow-lg p-6 max-h-[80vh] overflow-y-auto ">
                <!-- Cabeçalho do modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-green-600">Importar Componentes</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <!-- Filtros -->
                <div class="mb-4">
                    <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                        <div class="px-2 w-1/2">
                            <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                                <option value="codigo">Código</option>
                                <option value="descricao">Descrição</option>
                                <option value="tipo">Tipo</option>
                                <option value="cor">Cor</option>
                                <option value="fornecedor">Fornecedor</option>
                            </select>
                        </div>

                        <div class="w-full">
                            <v-select v-if="filtro.campo === 'tipo'" v-model="filtro.valor"
                                :options="lista_tipo_produto" label="descricao" :reduce="tipo => tipo.id"
                                placeholder="Escolha uma opção" />

                            <v-select v-else-if="filtro.campo === 'cor'" v-model="filtro.valor" :options="lista_cores"
                                label="descricao" :reduce="cor => cor.id" placeholder="Escolha uma opção" />

                            <v-select v-else-if="filtro.campo === 'fornecedor'" v-model="filtro.valor"
                                :options="lista_fornecedores" label="nome_razao" :reduce="fornecedor => fornecedor.id"
                                placeholder="Escolha uma opção" />

                            <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                                placeholder="Digite o desejado" />
                        </div>

                        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
                    </div>

                    <div class="flex w-full justify-center">
                        <button class="text-ativo" @click.prevent="adicionarFiltro">
                            <i class="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>

                <!-- Botões de ação -->
                <div class="flex flex-wrap gap-2 mb-6">
                    <button
                        class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
                        @click.prevent="mudarPagina(1)">
                        Pesquisar
                    </button>

                    <button @click.prevent="importarComponentes()"
                        class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500 text-white hover:text-yellow-500 py-2 px-4">
                        Importar
                    </button>
                </div>

                <!-- Tabela de importação -->
                <div class="container-modelo-tabela">
                    <table class="container-tabela overflow-auto">
                        <thead class="cabecalho-tabela">
                            <tr class="border-b">
                                <th class="w-4 p-4">
                                    <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                </th>
                                <th class="px-6 py-3">#</th>
                                <th class="px-10 py-3">Código</th>
                                <th class="px-10 py-3">Descrição</th>
                                <th class="px-16 py-3">Fornecedor</th>
                                <th class="px-10 py-3 text-center">$ Custo</th>
                                <th class="px-10 py-3 text-center">% Adicional Custo</th>
                                <th class="px-6 py-3 text-center">$ Custo Final</th>

                                <th class="px-6 py-3 text-center bg-gray-300">% Tabela Venda Projeto</th>
                                <th class="px-6 py-3 text-center">% Tabela Venda do Item</th>

                                <th class="px-6 py-3 text-center bg-gray-300">% Adicional Venda Projeto</th>
                                <th class="px-6 py-3 text-center">% Adicional Venda Item</th>
                                <th class="px-6 py-3 text-center bg-gray-300">$ Praticado Venda Projeto</th>
                                <th class="px-6 py-3 text-center">$ Praticado Venda do Item</th>

                                <th class="px-6 py-3 text-center bg-gray-300">$ Venda Projeto</th>
                                <th class="px-6 py-3 text-center">$ Venda do Item</th>

                                <th class="px-16 py-3 text-center whitespace-nowrap">
                                    Lucro Venda <br> Projeto
                                </th>
                                <th class="px-16 py-3 text-center whitespace-nowrap">
                                    Lucro Venda <br> do Item
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(produto, index) in lista_componentes" :key="produto.id"
                                class="border-b hover:bg-gray-50">
                                <td class="w-4 p-4">
                                    <input type="checkbox" class="checkbox" v-model="produto.selected">
                                </td>
                                <th
                                    class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ index + 1 }}
                                </th>
                                <td class="px-10 py-3 whitespace-nowrap">{{ produto.codigo || '-' }}</td>
                                <td class="px-10 py-3 whitespace-nowrap">{{ produto.descricao || '-' }}</td>
                                <td class="px-16 py-3 whitespace-nowrap">{{ produto.fornecedor?.nome_razao || '-' }}
                                </td>
                                <td class="text-center whitespace-nowrap">
                                    <input v-model="produto.custo" type="number" min="0" class="w-full">
                                </td>
                                <td class="text-center whitespace-nowrap">
                                    <input v-model="produto.adicional_custo" type="number" min="0" class="w-full">
                                </td>
                                <td class="px-6 py-3 text-center whitespace-nowrap">
                                    R$ {{ calcularCustoFinal(produto) }}
                                </td>
                                <td class="px-6 py-3 text-center whitespace-nowrap bg-gray-300">
                                    <span v-if="produto.lucro_projeto" class="px-6 py-3">{{ produto.lucro_projeto
                                        }}%</span>
                                    <span v-else>-</span>
                                </td>

                                <td class="px-6 py-3 text-center whitespace-nowrap">
                                    <span v-if="produto.lucro_item" class="px-6 py-3">{{ produto.lucro_item }}%</span>
                                    <span v-else>-</span>
                                </td>
                                <td class="text-center whitespace-nowrap">
                                    <input v-model="produto.adicional_venda_projeto" type="number" min="0"
                                        class="w-full">
                                </td>
                                <td class="text-center whitespace-nowrap">
                                    <input v-model="produto.adicional_venda_item" type="number" min="0" class="w-full">
                                </td>
                                <td class="text-center whitespace-nowrap">
                                    <input v-model="produto.preco_praticado_venda_projeto" type="number" min="0"
                                        class="w-full">
                                </td>
                                <td class="text-center whitespace-nowrap">
                                    <input v-model="produto.preco_praticado_venda_item" type="number" min="0"
                                        class="w-full">
                                </td>
                                <td class="px-6 py-3 text-center whitespace-nowrap">
                                    R$ {{ calcularPrecoFinalProjeto(produto) }}
                                </td>
                                <td class="px-6 py-3 text-center whitespace-nowrap">
                                    R$ {{ calcularPrecoFinalItem(produto) }}
                                </td>
                                <td class="text-center whitespace-nowrap">
                                    {{ calcularLucroProjeto(produto) }}
                                </td>

                                <td class="text-center whitespace-nowrap">
                                    {{ calcularLucroItem(produto) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Pagination v-if="lista_componentes.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                        @change-page="mudarPagina" />
                </div>

            </div>
            <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
        </div>
    </div>
</template>

<script>
import axios from "axios"
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import PreviewImage from "@/components/midais/ged/PreviewImage.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import Pagination from "@/utils/Pagination.vue";
// import ModalSelecionarFornecedor from "./ModalSelecionarFornecedor.vue";
// import AlterarSelecionadosModalImportacaoVidro from "@/components/midais/Editar/produtos/AlterarSelecionadosModalImportacaoVidro.vue";
import vSelect from "vue-select";

export default {
    name: 'ModalImportacaoComponente',

    props: ['lista_cores', 'lista_tipo_produto', 'lista_fornecedores'],

    emits: ['fecharModal', 'atualizarLista'],

    components: {
        ListagemErros,
        PreviewImage,
        SucessoRapido,
        Pagination,
        // ModalSelecionarFornecedor,
        // AlterarSelecionadosModalImportacaoVidro,
        vSelect,
    },

    data() {
        return {
            checkAll: false,

            filtros: [{ campo: "codigo", valor: "" }],
            lista_acrescimo_tamanho: [
                { label: 'Sem Acréscimo', value: 'SA' },
                { label: 'Metro Quadrado', value: 'MQ' },
                { label: 'Metro Linear', value: 'ML' },
            ],

            modalFornecedor: false,
            fornecedor: null,

            lista_componentes: [],

            preview_imagem: '',

            modalAlterarSelecionados: false,
            componentesSelecionados: [],

            exibirErros: false,
            erros: [],

            paginaAtual: 1,
            countItens: '',

            sucesso: false,
            sucessoMessage: '',
        }
    },

    computed: {
        checkSome() {
            return this.lista_componentes.some(produto => produto.selected)
        }
    },

    created() {

    },

    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },

        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        selectAll() {
            // Atualiza o estado dos demais checkboxes
            const checkboxes = document.querySelectorAll('.list-checkbox input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = this.checkAll;
            });

            this.lista_componentes.forEach((componente) => {
                componente.selected = this.checkAll;
            });
        },

        toggleModalFornecedor() {
            this.modalFornecedor = !this.modalFornecedor
        },

        fornecedorSelecionado(fornecedor) {
            this.lista_componentes = []
            console.log(fornecedor)
            this.fornecedor = fornecedor
            console.log('this.fornecedor: ', this.fornecedor)
        },

        async buscarListaComponentes(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {

                this.$store.dispatch("setLoading", true);
                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");

                const response = await axios.get(
                    `produtos/componente-acessorio/?page=${pagina}&${params}&precificacao=true`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                );

                this.lista_componentes = response.data.results
                console.log(this.lista_componentes)
                this.countItens = response.data.count;

            } catch (error) {
                console.error("Erro ao buscar produtos:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        alterarSelecionados() {
            if (this.checkSome || this.checkAll) {
                this.componentesSelecionados = this.lista_componentes.filter(componente => componente.selected);
                this.toggleAlterarSelecionados();
            } else {
                this.erros = { componente: ["Selecione pelo menos um Componente para ser alterado."] };
                this.toggleErros();
            }
        },

        toggleAlterarSelecionados() {
            this.modalAlterarSelecionados = !this.modalAlterarSelecionados;
        },

        atualizarListaComponentes(componentesAtualizados) {

            console.log(componentesAtualizados)
            // Atualiza os itens selecionados na lista principal
            componentesAtualizados.forEach(atualizado => {
                const index = this.lista_componentes.findIndex(v => v.id === atualizado.id);
                if (index !== -1) {
                    this.lista_componentes[index] = { ...this.lista_componentes[index], ...atualizado };
                }
            });
        },

        calcularValorFinal(custo, margem) {
            custo = parseFloat(custo || 0);
            margem = parseFloat(margem || 0);
            return (custo + (custo * margem / 100)).toFixed(2);
        },

        /** Formata valores monetários **/
        formatarNumero(valor) {
            return parseFloat(valor).toFixed(2).replace('.', ',');
        },

        /** Calcula o custo final de cada componente **/
        calcularCustoFinal(produto) {
            const custo = parseFloat(produto.custo) || 0;
            const adicional_custo = parseFloat(produto.adicional_custo) || 0;
            return this.formatarNumero(custo + (custo * adicional_custo / 100));
        },

        /** Calcula o preço final para venda de projeto **/
        calcularPrecoFinalProjeto(produto) {
            if (produto.preco_praticado_venda_projeto) {
                return this.formatarNumero(produto.preco_praticado_venda_projeto);
            }
            const custo_final = parseFloat(this.calcularCustoFinal(produto).replace(',', '.')) || 0;
            const adicional_venda_projeto = parseFloat(produto.adicional_venda_projeto) || 0;
            const lucro_projeto = parseFloat(produto.lucro_projeto) || 0;

            return this.formatarNumero(custo_final + (custo_final * (adicional_venda_projeto + lucro_projeto) / 100));
        },

        /** Calcula o preço final para venda de item **/
        calcularPrecoFinalItem(produto) {
            if (produto.preco_praticado_venda_item) {
                return this.formatarNumero(produto.preco_praticado_venda_item);
            }
            const custo_final = parseFloat(this.calcularCustoFinal(produto).replace(',', '.')) || 0;
            const adicional_venda_item = parseFloat(produto.adicional_venda_item) || 0;
            const lucro_item = parseFloat(produto.lucro_item) || 0;

            return this.formatarNumero(custo_final + (custo_final * (adicional_venda_item + lucro_item) / 100));
        },

        calcularLucroProjeto(produto) {
            const custo_final = parseFloat(this.calcularCustoFinal(produto).replace('R$ ', '').replace(',', '.')) || null;
            const venda_projeto = parseFloat(this.calcularPrecoFinalProjeto(produto).replace('R$ ', '').replace(',', '.')) || null;

            if (custo_final === null || venda_projeto === null) return "-";

            const lucro = venda_projeto - custo_final;
            const percentual = custo_final > 0 ? (lucro / custo_final) * 100 : 0;

            return lucro ? `R$ ${lucro.toFixed(2)} (${percentual.toFixed(2)}%)` : "-";
        },

        calcularLucroItem(produto) {
            const custo_final = parseFloat(this.calcularCustoFinal(produto).replace('R$ ', '').replace(',', '.')) || null;
            const venda_item = parseFloat(this.calcularPrecoFinalItem(produto).replace('R$ ', '').replace(',', '.')) || null;

            if (custo_final === null || venda_item === null) return "-";

            const lucro = venda_item - custo_final;
            const percentual = custo_final > 0 ? (lucro / custo_final) * 100 : 0;

            return lucro ? `R$ ${lucro.toFixed(2)} (${percentual.toFixed(2)}%)` : "-";
        },

        /** Importa os componentes selecionados **/
        async importarComponentes() {
            if (!this.checkSome) {
                this.erros = { componente: ["Selecione pelo menos um Componente para importar."] };
                this.toggleErros();
                return;
            }

            try {
                const componentesSelecionados = this.lista_componentes.filter(produto => produto.selected).map(produto => {
                    const custo_final = parseFloat(this.calcularCustoFinal(produto).replace(',', '.')) || 0;
                    const preco_final_projeto = parseFloat(this.calcularPrecoFinalProjeto(produto).replace(',', '.')) || 0;
                    const preco_final_item = parseFloat(this.calcularPrecoFinalItem(produto).replace(',', '.')) || 0;

                    return {
                        componente: produto.id,
                        custo: parseFloat(produto.custo) || null,
                        adicional_custo: parseFloat(produto.adicional_custo) || null,
                        adicional_venda_projeto: parseFloat(produto.adicional_venda_projeto) || null,
                        adicional_venda_item: parseFloat(produto.adicional_venda_item) || null,
                        preco_praticado_venda_projeto: parseFloat(produto.preco_praticado_venda_projeto) || null,
                        preco_praticado_venda_item: parseFloat(produto.preco_praticado_venda_item) || null,
                        custo_final: custo_final,
                        preco_final_projeto: preco_final_projeto,
                        preco_final_item: preco_final_item
                    };
                });

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await axios.post(`precificacao/itens-tabelas-componente/`, { componentes: componentesSelecionados }, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.sucesso = true;
                this.sucessoMessage = 'Componentes importados com sucesso!'
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('atualizarLista')
                    this.$emit('fecharModal')
                }, 3000);
            } catch (error) {
                this.erros = error.response?.data || { componente: ["Erro ao importar componentes."] };
                this.toggleErros();
            }
        },


        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

        mudarPagina(pagina) {
            this.checkAll = false
            this.paginaAtual = pagina;
            this.buscarListaComponentes(pagina);
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },
    }
}
</script>

<style scoped>
.btn-modal-importacao {
    @apply px-4 py-1 cursor-pointer
}

input[type=text] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}

input[type=number] {
    @apply w-full p-1 border border-[#ccc] rounded-md
}
</style>