<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
            <div
                class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                <!-- Cabeçalho do Modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-red-600">Lista de Endereços</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <!-- Lista de Endereços -->

                <!-- Endereço do Orçamento -->
                <h2>Endereço Orçamento</h2>
                <div v-if="orcamentoSelecionado" class="mb-2">
                        <p class="text-base text-gray-700">
                            {{ orcamentoSelecionado.logradouro }}, nº {{ orcamentoSelecionado.numero }}
                            <span v-if="orcamentoSelecionado.complemento">, {{ orcamentoSelecionado.complemento }}</span>,
                            bairro {{ orcamentoSelecionado.bairro }}, {{ orcamentoSelecionado.cidade }} - {{ orcamentoSelecionado.estado }}
                        </p>
                </div>
                <div v-else>
                    <p>Sem endereço cadastrado.</p>
                </div>

                <hr>

                <!-- Endereço da Obra -->
                <h2 class="mt-2">Endereço Obra</h2>
                <div v-if="orcamentoSelecionado.obra">
                        <p class="text-base text-gray-700">
                            {{ orcamentoSelecionado.obra.endereco }}, nº {{ orcamentoSelecionado.obra.numero }}
                            <span v-if="orcamentoSelecionado.obra.complemento">, {{ orcamentoSelecionado.obra.complemento }}</span>,
                            bairro {{ orcamentoSelecionado.obra.bairro }}, {{ orcamentoSelecionado.obra.cidade }} - {{ orcamentoSelecionado.obra.estado }}
                        </p>
                </div>
                <div v-else>
                    <p>Sem endereço cadastrado.</p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "ListarEnderecos",
    props: ['orcamentoSelecionado'],
    emits: ['fecharModal'],

    data() {
        return {
            
        }
    },


    created() {
        console.log(this.orcamentoSelecionado)
    },

    methods: {

    }
};
</script>

<style scoped></style>