<template>

    <div class="container-formulario">
  
      <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Dados Medidas</h1>
  
        <router-link to="/produtos/medidas" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>
  
      <!-- Escopo do formulario de cadastro mobile-->
      <form @submit.prevent="cadastrarMedidas">
        <!--        PARTE DE DADOS DO PRODUTO-->
        <div class="flex flex-col md:flex-row gap-3 my-6 w-full ">
  
          <div class="campo-chapa">
            <label class="font-bold text-corBase">Descrição</label>
            <input type="text" v-model="descricao" class="input-form-chapa" required >
          </div>
  
        </div>
  
  
        <div class="flex w-full justify-end">
          <button class="btn-enviar" type="submit">Salvar</button>
        </div>
  
      </form>
    </div>
    <MensagemSucesso v-if="sucesso==true" rotas="/produtos/medidas"
                     mensagem="Medidas Cadastrada com Sucesso!"/>
  
  </template>
  
  <script>
  import axios from "axios";
  import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
  
  export default {
    name: "CadastroEspessua",
  
    components: {
      MensagemSucesso
    },
  
  
    data() {
      return {
        sucesso: false,
        espessura: '',
      }
    },
  
    created() {
    },
  
    computed: {
      minhaEmpresa() {
        return this.$store.state.minhaEmpresa;
      },
  
      isadmin() {
        return this.$store.state.isAdminUser
      },
    },
  
    methods: {
  
  
      async cadastrarMedidas() {
        const token = await this.$store.dispatch('getAuthTokenCookie')
  
        await this.$store.dispatch('fetchURLrequest')
  
        const data = new FormData();
        data.append('ativo', 'True')
        data.append('descricao', this.descricao)
        this.sucesso = false
        this.$store.dispatch("setLoading", true);
        await axios.post('produtos/medidas/', data, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              console.log(response.data);
              this.sucesso = true
              this.$store.dispatch("setLoading", false);
            })
            .catch(error => {
              console.log(error);
              this.$store.dispatch("setLoading", false);
            })
  
      },
  
  
    }
  
  }
  </script>
  
  <style lang="scss"></style>