<template>
  <div class="container-formulario">

    <!-- Cabeçalho -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">
        Alterar Orçamento
      </h1>

      <button @click.prevent="$router.push('/lista-orcamentos')" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </button>
    </div>

    <div class="w-full block justify-center">
      <div class="flex flex-wrap gap-2 justify-center">

        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          dashboardAtivo == 'DadosDashboard'
            ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirDashboard('DadosDashboard')">
          Dados
        </button>

        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          dashboardAtivo == 'VaosDashboard'
            ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirDashboard('VaosDashboard')">
          Vãos
        </button>

        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          dashboardAtivo == 'ItensAvulsosDashboard'
            ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirDashboard('ItensAvulsosDashboard')">
          Itens Avulsos
        </button>

        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          dashboardAtivo == 'CustosMargensDashboard'
            ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirDashboard('CustosMargensDashboard')">
          Custos e Margens
        </button>
      </div>
    </div>

    <DadosOrcamento v-if="dashboardAtivo == 'DadosDashboard'" :orcamento="orcamento" />
    <VaosOrcamento v-if="dashboardAtivo == 'VaosDashboard'" :orcamento="orcamento" />
    <ItensAvulsosOrcamento v-if="dashboardAtivo == 'ItensAvulsosDashboard'" :orcamento="orcamento" />
  </div>


  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
  <SucessoRapido v-if="sucesso" mensagem="Orçamento calculado com sucesso!" />
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import DadosOrcamento from './itens_alterar_orcamento/DadosOrcamento.vue';
import VaosOrcamento from './itens_alterar_orcamento/VaosOrcamento.vue';
import ItensAvulsosOrcamento from './itens_alterar_orcamento/ItensAvulsosOrcamento.vue';

export default {
  name: 'AlterarOrcamento',

  components: {
    vSelect,
    ListagemErros,
    SucessoRapido,
    DadosOrcamento,
    VaosOrcamento,
    ItensAvulsosOrcamento,
  },

  data() {
    return {
      idOrcamento: '',
      orcamento: [],

      lista_status: [
        { value: 'True', nome: "Ativo" },
        { value: 'False', nome: "Inativo" },
      ],

      listaItensOrcamento: [],

      dashboardAtivo: null, // Armazena o dashboard ativo
      nomesDashboards: {
        DadosDashboard: "Dados",
        VaosDashboard: "Vãos",
        ItensAvulsosDashboard: "Itens Avulsos",
        CustosMargensDashboard: "Custos e Margens",
      },

      sucesso: false,
      erros: [],
      exibirErros: false,
    }
  },


  created() {
    this.idOrcamento = this.$route.params.id
    this.getDadosOrcamento(this.idOrcamento)
  },

  methods: {

    abrirDashboard(nome) {
      this.dashboardAtivo = nome;
    },

    async getDadosOrcamento(idOrcamento) {
      try {

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`orcamentos/orcamento/${idOrcamento}/`, {
          headers: { Authorization: `Token ${token}` }
        })

        console.log(response.data)
        this.orcamento = response.data
      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro ao buscar dados do orçamento.' }
        this.toggleErros()
      }
    },

    async getItensOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get(`orcamentos/item-orcamento/?orcamento_id=${this.orcamentoId}&page_size=999`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaItensOrcamento = response.data.results
      } catch (err) {
        console.error(err.message)
      }
    },

    async calcularOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get(`projeto/engine/${this.orcamentoId}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaItensOrcamento = response.data.results
        this.sucesso = true
        setTimeout(() => {
          this.sucesso = false;
        }, 3000);
      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    }
  }
}
</script>

<style scoped lang="scss"></style>