<template>


  <div class="container-formulario">

    <div class="cabecalho-dashboard">
      <h1>Gerenciamento de Documentos Eletronicos</h1>

      <button @click="$router.push(`/configuracoes`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap">

      <button class="escopo-dashboard-desktop" @click="$router.push(`/ged/lista-imagens`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Imagens</h2>
            <i class="fa-solid fa-image"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Imagens presentes em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/ged/categoria`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Categorias</h2>
            <i class="fa-solid fa-folder"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Categorias presentes em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/ged/subcategoria`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Subcategorias</h2>
            <i class="fa-solid fa-folder-tree"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Subcategorias presentes em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/fornecedor`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Revistas</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Revistas presentes em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipos`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Manuais</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Manuais presentes em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/outros`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Outros</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

    </div>


  </div>


</template>

<script>

export default {

  name: 'DashboardGed',

  data() {
    return {}
  },


}

</script>

<style lang="scss">

</style>