<template>
    <div class="mt-8">

    </div>
</template>

<script>
export default {
    name: 'ItensAvulsosOrcamento'
}
</script>

<style scoped>

</style>