<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Cor:</h1>

      <router-link to="/lista-cores-base" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarCorBase">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="flex flex-col md:flex-row gap-3 my-3 w-full ">

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Código:</label>
          <input type="text" v-model="codigo" v-on:input="codigo = codigo.toUpperCase()" class="input-form-chapa"
            autofocus>
        </div>

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Descrição:</label>
          <input type="text" v-model="descricao" class="input-form-chapa" autofocus>
        </div>

        <div class="campo-chapa">
          <label class="text-xl text-corBase">Produto:</label>
          <v-select v-model="tipo_produto" label="text" class="w-full z-10" :options="lista_produtos_sistema"
            :multiple="true" />
        </div>

        <div class="campo-chapa">
          <label class="text-xl text-corBase block">Ativo:</label>
          <div class="cursor-pointer flex items-center justify-center w-6 h-6" @click.prevent="toggleAtivo">
            <i :class="ativo ? 'fa-solid fa-circle-check text-ativo' : 'fa-solid fa-circle-xmark text-inativo'"
              class="text-2xl"></i>
          </div>
        </div>

      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Salvar</button>
      </div>

    </form>
  </div>
  <MensagemSucesso v-if="sucesso == true" rotas="/lista-cores-base" mensagem="Cor cadastrada com sucesso!" />
  <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";

export default {
  name: "CadastroCoresProdutoBase",

  components: {
    MensagemSucesso,
    ListagemErros,
    vSelect,
  },


  data() {
    return {
      sucesso: false,
      codigo: '',
      descricao: '',
      tipo_produto: '',
      ativo: true,
      lista_produtos_sistema: [],

      erros: {},
      exibirErros: false,
    }
  },

  created() {
    this.getProdutosSistema()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    async getProdutosSistema() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produtos-sistema', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data)
          this.lista_produtos_sistema = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }))
        })
        .catch(err => {
          console.error(err)
        })
    },

    toggleAtivo() {
      this.ativo = !this.ativo
    },

    // async cadastrarCorBase() {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')

    //   await this.$store.dispatch('fetchURLrequest')

    //   console.log(this.tipo_produto)

    //   const produtoIds = Array.isArray(this.tipo_produto)
    //     ? this.tipo_produto.map(produto => produto.id)
    //     : [];

    //   const data = {
    //     ativo: 'True',
    //     descricao: this.descricao,
    //     produto_aplicado: produtoIds
    //   };

    //   this.sucesso = false
    //   this.$store.dispatch("setLoading", true);
    //   await axios.post('produtos/cor-produto-base/', data, {
    //     headers: {
    //       'Authorization': `Token ${token}`
    //     }
    //   })
    //     .then(response => {
    //       console.log(response.data);
    //       this.descricao = ''
    //       this.tipo_produto = ''
    //       this.sucesso = true
    //       this.$store.dispatch("setLoading", false);
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       this.$store.dispatch("setLoading", false);
    //     })
    // },


    async cadastrarCorBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      const produtoIds = Array.isArray(this.tipo_produto)
        ? this.tipo_produto.map(produto => produto.id)
        : [];

      const data = {
        ativo: this.ativo,
        codigo: this.codigo,
        descricao: this.descricao,
        produto_aplicado: produtoIds
      };

      this.sucesso = false;
      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.post('produtos/cor-produto-base/', data, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        console.log('Cor cadastrada:', response.data);
        this.sucesso = true
        this.codigo = ''
        this.descricao = ''
        this.tipo_produto = ''
      } catch (error) {
        console.error('Erro ao cadastrar a cor base:', error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },

  }
}
</script>

<style lang="scss"></style>