<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Criar Vão para {{ obra.descricao }}</h1>
            </div>

            <router-link :to="`/obra/${$route.params.obra}`" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Formulário de criação de vão -->
        <div>

            <div class="flex gap-2 justify-center items-center w-full">

                <div class="w-1/4">
                    <label class="text-corBase pt-1">Ambiente:</label>
                    <div class="flex justify-center">
                        <v-select v-model="vao.local_vao" :options="listaLocaisVao" label="nome"
                            class="bg-white pb-2 w-full" placeholder="Escolha uma opção" :multiple="true" />
                    </div>
                </div>

                <div class="w-1/4">
                    <label class="font-bold text-corBase pb-0">Código Arquitetônico:</label>
                    <input v-model.number="vao.codigo_arquitetonico" type="text" maxlength="20"
                        id="codigo_arquitetonico" placeholder="Digite aqui..."
                        :reduce="codigo_arquitetonico => codigo_arquitetonico.id" required />
                </div>

                <div class="w-full">
                    <label class="mr-2 text-corBase">Descrição:</label>
                    <input v-model="vao.descricao" type="text" id="descricao" placeholder="Digite aqui..." required />
                </div>

            </div>

            <div class="flex gap-2 justify-center items-center w-full mt-4">
                <div class="w-full">
                    <label class="mr-2 text-corBase">Situação do Vão:</label>
                    <v-select :options="lista_estado_vao" label="label" :reduce="estado_vao => estado_vao.value"
                        v-model="vao.situacao_vao" placeholder="Escolha uma opção" />
                </div>

                <div class="w-full">
                    <label class="text-corBase pt-1">Medidas:</label>
                    <v-select :options="[{ label: 'Sim', value: true }, { label: 'Não', value: false }]" label="label"
                        :reduce="medidas => medidas.value" v-model="vao.medidas" placeholder="Escolha uma opção" />
                </div>

                <div class="w-full" v-if="vao.medidas == true">
                    <label class="text-corBase pt-1">Responsável:</label>
                    <v-select :options="[{ label: 'Contratante', value: 'CT' }, { label: 'Contratada', value: 'CD' }]"
                        label="label" :reduce="medidas => medidas.value" v-model="vao.quem_mediu"
                        placeholder="Escolha uma opção" />
                </div>

                <div class="w-full" v-if="vao.medidas == true">
                    <label class="text-corBase pt-1">Medidor:</label>
                    <v-select :options="vao.quem_mediu === 'CT' ? lista_stakeholders :
                        vao.quem_mediu === 'CD' ? lista_responsavel_medidor : []" label="nome_razao"
                        v-model="vao.medidor" placeholder="Escolha uma opção">
                        <template #no-options>
                            <span>
                                {{ vao.quem_mediu === 'CT' || vao.quem_mediu === 'CD' ? 'Nenhuma pessoa encontrada.' :
                                    'Selecione quem mediu.' }}
                            </span>
                        </template>
                    </v-select>
                </div>

            </div>

            <div class="form-group flex items-center">
                <label class="mr-5 text-corBase">Modelo</label>
                <v-select v-model="modeloSelecionado" :options="lista_modelos" label="descricao"
                    placeholder="Selecione um modelo de tipologia" class="w-full pt-2" :clearable="false"
                    @update:modelValue="getTipologias(modeloSelecionado.id)" />

            </div>

            <div class="form-group flex items-center">
                <label class="mr-2 text-corBase">Tipologia</label>
                <!-- <select class="cursor-pointer" v-model="tipologiaSelecionada" @change="onTipologiaChange">
                    <option value="" disabled>Selecione uma tipologia</option>
                    <option v-for="tipologia in tipologias" :key="tipologia.id" :value="tipologia.id">
                        {{ tipologia.nome }}
                    </option>
                </select> -->
                <v-select v-model="tipologiaSelecionada" :options="tipologias" label="nome"
                    placeholder="Selecione uma tipologia" class="w-full pt-2" :clearable="false"
                    @update:modelValue="onTipologiaChange" :reduce="tipologiaSelecionada => tipologiaSelecionada.id" />

            </div>

            <!-- Lista de linhas baseada na tipologia selecionada -->
            <div class="flex" v-if="linhas.length > 0">
                <div class="w-72">
                    <p class="text-sm text-gray-500 mt-2">Mudar pra modal</p>

                    <p>Linhas disponíveis:</p>
                    <div v-for="linha in linhas" :key="linha.id" class="flex">
                        <label class="mr-2">{{ linha.linha }}</label>
                        <input type="checkbox" :value="linha" v-model="linhasSelecionadas" />
                    </div>
                    <button type="button" class="btn-salvar" @click="adicionarTipologia">Adicionar</button>
                </div>
                <div class="">
                    <img v-if="tipologiaSelecionada && tipologias.find(t => t.id === tipologiaSelecionada)?.imagem?.url_documento"
                        :src="tipologias.find(t => t.id === tipologiaSelecionada)?.imagem.url_documento"
                        alt="Imagem da Tipologia" class="w-32 h-32 object-cover rounded-md border" />
                </div>
            </div>

            <!-- Seção de abas -->
            <div class="bg-gray-300 p-5 pt-0 rounded-lg">
                <div class="abas-container">
                    <template v-for="(aba, index) in abas" :key="index">
                        <div :class="['aba', { 'aba-selecionada': abaSelecionada === index }]">
                            {{ aba.nome }}
                        </div>
                    </template>
                </div>

                <!-- Conteúdo da aba selecionada -->
                <div v-if="abas.length > 0 && obra.descricao">
                    <div v-for="(aba, index) in abas" :key="index" v-show="abaSelecionada === index">

                        <!-- Aba Geral -->
                        <GeralComponent v-if="aba.nome === 'Geral'" :vao="vao" @update-file="onFileChange" :obra="obra"
                            :combinacoes_cores="combinacoes_cores" @atualizarCombinacoes="receberNovaCombinacao" />

                        <!-- Aba correspondente a tipologia -->
                        <div v-else class="flex items-start">
                            <!-- Coluna das imagens -->
                            <div class="w-1/2 flex flex-col items-center text-center">
                                <h4 class="text-corBase">Imagem da tipologia:</h4>
                                <img :src="aba.imagem.url_documento" class="foto-tipologia w-32 h-auto cursor-pointer"
                                    alt="Imagem tipologia" @click.prevent="openPreviewImage(aba.imagem.url_documento)">
                                <!-- @click="abrirModal(aba.imagem.url_documento)" -->

                                <div v-if="vao.imagem">
                                    <h4 class="text-corBase mt-10">Imagem do vão:</h4>
                                    <img :src="vao.imagem"
                                        class="foto-tipologia w-32 h-auto cursor-pointer object-cover"
                                        alt="Pré-visualização" @click.prevent="openPreviewImage(vao.imagem)" />
                                    <!-- @click="abrirModal(vao.imagem)" -->
                                </div>
                            </div>

                            <!-- Linha de separação vertical -->
                            <div class="w-px h-full bg-gray-600 mx-4"></div>

                            <!-- Coluna das informações da linha -->
                            <div class="w-1/2 flex flex-col space-y-4">
                                <!-- Abas internas para linhas -->
                                <div class="linhas-abas-container">
                                    <template v-for="(linha, linhaIndex) in aba.linhas" :key="linhaIndex">
                                        <div :class="['linha-aba', { 'linha-aba-selecionada': linhaSelecionada === linhaIndex }]"
                                            @click="selecionarLinha(linhaIndex, aba)">
                                            {{ linha.linha }}
                                        </div>
                                    </template>
                                </div>

                                <!-- Conteúdo da linha selecionada -->
                                <div v-if="aba.linhas.length > 0">
                                    <div v-for="(linha, linhaIndex) in aba.linhas" :key="linhaIndex"
                                        v-show="linhaSelecionada === linhaIndex">

                                        <!-- Verifica se um projeto foi selecionado -->
                                        <div v-if="projetosSelecionados[`${aba.id}_${linha.linha}`]">
                                            <p class="text-corBase font-semibold text-center pb-5">Projeto Selecionado
                                                para linha {{
                                                    linha.linha }}:</p>
                                            <div class="flex flex-wrap gap-6 justify-center">
                                                <div
                                                    class="flex flex-col items-center w-60 p-4 bg-white border border-corBase/40 shadow-md rounded-md">
                                                    <img src="@/assets/imagens/fotos/janela2folhas.png"
                                                        alt="Imagem do Projeto" class="w-40 h-40 object-cover mb-4">
                                                    <p class="text-sm font-medium text-center">
                                                        {{
                                                            projetosSelecionados[`${aba.id}_${linha.linha}`].projeto.descricao_fixa
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Exibição dos projetos -->
                                        <div v-else>
                                            <p class="text-corBase font-semibold text-center pb-5">Lista de Projetos</p>
                                            <!-- Lista de Projetos -->
                                            <!-- <p>{{ aba }}</p> -->
                                            <!-- <p>Chave: {{ `${linha.linha}_${aba.id_tipologia}` }}</p> -->

                                            <ListaProjetos
                                                v-if="listaProjetos[`${linha.linha}_${aba.id_tipologia}`]?.length > 0"
                                                :projetos="listaProjetos[`${linha.linha}_${aba.id_tipologia}`]"
                                                :abaId="aba.id" :nomeDaLinha="linha"
                                                @projeto-selecionado="salvarProjetos" />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Botões para navegar entre as abas -->
                <div class="flex w-full justify-end itens-center mt-6">
                    <button v-if="abaSelecionada > 0" @click="voltarAba" class="btn-cancelar">
                        Voltar
                    </button>
                    <button v-if="abaSelecionada < abas.length - 1" @click="onAvancarAba" class="btn-salvar">
                        Avançar
                    </button>
                    <button v-else type="button" @click="onAvancarAba" class="btn-salvar">
                        Salvar
                    </button>
                </div>

            </div>
        </div>

        <!-- Modal de Foto Ampliada -->
        <!-- <div v-if="fotoAmpliada" class="modal-mask z-9999" @click="fecharModal">
            <div class="modal-wrapper z-9999">
                <img :src="fotoAmpliada" class="modal-foto z-9999" />
            </div>
        </div> -->

    </div>
    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
    <SucessoRapido v-if="sucesso" mensagem="Vão criado com sucesso." />
    <ErroRapido v-if="erro" :mensagem="erroMessage" />
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
</template>

<script>
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import GeralComponent from './ComponentesCriacaoVao/GeralComponent.vue';
import ListaProjetos from './ComponentesCriacaoVao/ListaProjetos.vue'
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import axios from 'axios';

export default {
    name: 'CriarVao',

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
        GeralComponent,
        ListaProjetos,
        PreviewImage,
        ListagemErros,
    },

    data() {
        return {

            lista_estado_vao: [
                { label: 'A Construir', value: 'AC' },
                { label: 'Em Construção', value: 'EC' },
                { label: 'Construído e Sem Acabamento', value: 'CSA' },
                { label: 'Construído e Acabado', value: 'CA' },
            ],
            lista_stakeholders: [],
            lista_responsavel_medidor: [],

            vao: {
                local_vao: [],
                codigo_arquitetonico: '',
                descricao: '',

                situacao_vao: '',
                medidas: true,
                quem_mediu: 'CD', //campo responsável
                medidor: [],

                quantidade: 1,
                largura: '',
                altura: '',

                // cor_perfil: '',
                // cor_componente: '',
                // cor_vidro: '',
                // cor_chapa: '',

                observacao: '',

                contramarco: false,
                medida_contramarco: '',

                arremate: false,

                imagem: null,
                imagemFile: null,
            },

            combinacoes_cores: [],

            obra: [],

            listaLocaisVao: [],


            cliente: '',

            mostrarVariaveis: false,
            mostrarVariaveisPorLinha: {},

            tipologiaSelecionada: null,
            tipologias: [],

            lista_modelos: [],
            modeloSelecionado: null,

            linhas: [],
            linhasSelecionadas: [],

            abas: [{ nome: 'Geral', ativo: true }],
            abaSelecionada: 0,
            linhaSelecionada: 0,

            sucesso: false,
            erro: false,
            erroMessage: '',

            erros: [],
            exibirErros: false,

            preview_imagem: null,

            id_vao_criado: '',

            projetosPorLinha: {},
            projetosSelecionados: {},

            listaProjetosSelecionados: [],

            listaProjetos: {},
            listaProjetosPublic: {},
            projetoSelecionadoPorLinha: {}
        };
    },

    watch: {
        'vao.quem_mediu': {
            handler() {
                this.vao.medidor = ''
            },
            immediate: true,
        },

        'vao.medidas': {
            handler() {
                this.vao.quem_mediu = ''
                this.vao.medidor = ''
            },
            immediate: false,
        },
    },

    created() {
        this.getListaLocaisVao()
        this.getModelosTipologia()
        this.getDadosObra()
    },

    methods: {

        receberNovaCombinacao(novaCombinacao) {
            this.combinacoes_cores.push(novaCombinacao);
        },

        async getListaLocaisVao() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`obra/local-vao/?ativo=True&page_size=999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaLocaisVao = response.data.results
            } catch (error) {
                console.error(error)
            }
        },

        async getDadosObra() {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`obra/obra/${this.$route.params.obra}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                this.obra = response.data
                this.getListaPessoasAlocadas(this.obra.id)
                console.log('Obra:')
                console.log(this.obra)

                // Adicionar medidores
                if (this.obra.medidores) {
                    this.obra.medidores.forEach(medidorItem => {
                        if (medidorItem.medidor) {
                            this.lista_responsavel_medidor.push({
                                id: medidorItem.medidor.id,
                                nome_razao: `${medidorItem.medidor.nome_razao} (Medidor)`
                            });
                        }
                    });
                }

                // Adicionar responsável técnico
                if (this.obra.responsavel_tecnico) {
                    this.lista_responsavel_medidor.push({
                        id: this.obra.responsavel_tecnico.id,
                        nome_razao: `${this.obra.responsavel_tecnico.nome_razao} (Responsável Técnico)`
                    });
                }

                console.log("Lista Responsável Medidor:", this.lista_responsavel_medidor);


            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getListaPessoasAlocadas(obra_id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`obra/pessoa-alocada/?ativo=True&page_size=9999&obra=${obra_id}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })
                console.log(response.data.results)
                this.lista_stakeholders = response.data.results.map(stakeholder => {
                    return {
                        id: stakeholder.pessoa.id,
                        nome_razao: `${stakeholder.pessoa.nome_razao} (${stakeholder.responsabilidade.descricao})`
                    }
                })
                console.log(this.lista_stakeholders)
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getModelosTipologia() {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`projeto/modelo-variacao/?page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                this.lista_modelos = response.data.results
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getTipologias(modeloId) {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`/projeto/tipologia/?ativo=True&modelo=${modeloId}&page_size=9999`, {
                    headers: { Authorization: `Token ${token}` }
                });
                // console.log(response.data.results)
                this.tipologias = response.data.results
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getLinhas() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`projeto/linha-sistema/?page_size=9999`, {
                    headers: { Authorization: `Token ${token}` }
                });
                this.linhas = response.data.results;
            } catch (error) {
                console.error('Erro ao carregar linhas:', error);
            }
        },

        async onAvancarAba() {
            if (this.abas[this.abaSelecionada].nome === 'Geral') {
                // Verifica se todos os campos obrigatórios estão preenchidos
                // if (!this.vao.descricao || !this.vao.largura || !this.vao.altura || !this.vao.cor_componente || !this.vao.cor_perfil || !this.vao.cor_vidro) {
                //     this.erro = true
                //     this.erroMessage = "Preencha todos os campos obrigatórios."
                //     setTimeout(() => {
                //         this.erro = false;
                //         this.erroMessage = ''
                //     }, 3000);
                //     return;
                // }

                // Verifica se há tipologia adicionada
                const hasTipologiaAdicionada = this.abas.some(aba => aba.nome !== 'Geral');
                if (!hasTipologiaAdicionada) {
                    this.salvarVao()
                    return;
                }
            }

            if (this.abaSelecionada === this.abas.length - 1) {
                await this.salvarVao();
            } else {
                this.proximaAba();
            }
        },

        proximaAba() {
            if (this.abaSelecionada < this.abas.length - 1) {
                this.abaSelecionada++;
            }
        },

        voltarAba() {
            if (this.abaSelecionada > 0) {
                this.abaSelecionada--;
            }
        },

        onFileChange(file) {
            this.vao.imagem = file;
            console.log('filechange: ', this.vao.imagem)
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        async onTipologiaChange() {
            await this.getLinhas();
            this.linhasSelecionadas = [];
        },

        // Adicionando a aba da tipologia
        async adicionarTipologia() {
            // console.log('click')
            const tipologia = this.tipologias.find(t => t.id === this.tipologiaSelecionada);
            if (tipologia && this.linhasSelecionadas.length > 0) {
                const uniqueAbaId = `${tipologia.linha}_${this.abas.length}`;

                this.abas.push({
                    nome: `${tipologia.nome}`,
                    ativo: true,
                    id: uniqueAbaId,
                    id_tipologia: tipologia.id,
                    linhas: [...this.linhasSelecionadas],
                    imagem: tipologia.imagem
                });

                for (const linha of this.linhasSelecionadas) {
                    const projetos = await this.buscarProjetos(tipologia.id, linha.linha);
                    this.projetosPorLinha[`${uniqueAbaId}_${linha.linha}`] = projetos;
                }

                this.tipologiaSelecionada = null;
                this.modeloSelecionado = ''
                this.linhasSelecionadas = [];
                this.linhas = [];
            }
        },

        // Busco projetos com aquela tipologia e aquela linha
        async buscarProjetos(tipologiaId, linhaNome) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(
                    `/projeto/projeto/?tipologia=${tipologiaId}&linha_nome=${linhaNome}`, {
                    headers: { Authorization: `Token ${token}` }
                });

                const linhaNome_tipologiaId = `${linhaNome}_${tipologiaId}`;

                this.listaProjetos[linhaNome_tipologiaId] = response.data.results;
                console.log('Chave composta:', linhaNome_tipologiaId);
                console.log('Lista de Projetos:', this.listaProjetos[linhaNome_tipologiaId]);

            } catch (error) {
                console.error('Erro ao buscar projetos:', error);
                return [];
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // Criar o Vão
        async salvarVao() {
            console.log(this.vao);

            this.$store.dispatch("setLoading", true);

            if (this.vao.quantidade < 1) {
                this.erros = { quantidade: 'A quantidade não pode ser menor que 1.' };
                this.toggleErros();
                return;
            }

            console.log('localvao', this.vao.local_vao);

            const data = {
                obra: this.$route.params.obra,
                ativo: true,
                local_vao: Array.isArray(this.vao.local_vao) ? this.vao.local_vao.map(item => item.id) : [],
                codigo_arquitetonico: this.vao.codigo_arquitetonico,
                descricao: this.vao.descricao,
                situacao_vao: this.vao.situacao_vao,
                medidas: this.vao.medidas,
                quem_mediu: this.vao.quem_mediu,
                medidor: this.vao.medidor?.id,
                quantidade: this.vao.quantidade,
                largura: this.vao.largura,
                altura: this.vao.altura,
                observacao: this.vao.observacao,
                combinacoes_cores: this.combinacoes_cores,
            };

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.post('obra/vao-obra/', data, {
                    headers: {
                        'Authorization': `Token ${token}`,
                        // Não definir 'Content-Type' para permitir JSON puro
                    }
                });

                const vaoCriadoId = response.data.id;  // Pegando o ID do vão criado

                console.log("Vão criado com sucesso. ID:", vaoCriadoId);

                if (this.vao.imagemFile) {
                    console.log("Enviando imagem para o vão...");

                    const formData = new FormData();
                    formData.append("imagem", this.vao.imagemFile);

                    await axios.patch(`obra/vao-obra/${vaoCriadoId}/`, formData, {
                        headers: {
                            'Authorization': `Token ${token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    });

                    console.log("Imagem adicionada ao vão.");
                }

                // **Mostrar sucesso**
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$router.push(`/obra/${this.$route.params.obra}`);
                }, 3000);
            } catch (error) {
                console.error('Erro ao criar vão:', error);
                this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
                this.toggleErros();
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

        salvarProjetos(projeto) {
            // Salva o projeto selecionado no objeto `projetosSelecionados`
            this.projetosSelecionados[`${projeto.abaId}_${projeto.nomeDaLinha.linha}`] = projeto;
            console.log(this.projetosSelecionados);
        },

        // Função para salvar os projetos associados ao vão

        async salvarListaProjetos(id_vao) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            const promises = [];

            for (const aba of this.abas) {
                // Garante que a aba possui linhas e uma tipologia válida
                if (aba.nome !== 'Geral' && Array.isArray(aba.linhas) && aba.linhas.length > 0 && aba.id_tipologia) {
                    for (const linha of aba.linhas) {
                        if (linha.id) {
                            const formData = new FormData();

                            formData.append('vao_obra', id_vao);
                            formData.append('ativo', 'true');
                            formData.append('tipologias', aba.id_tipologia);
                            formData.append('linhas', linha.id);

                            // Verifica se há projeto associado à linha atual
                            const projetoAssociado = Object.values(this.projetosSelecionados).find(
                                projeto => projeto.nomeDaLinha && projeto.nomeDaLinha.id === linha.id
                            );

                            if (projetoAssociado) {
                                formData.append('projeto', projetoAssociado.projeto.id);
                            }

                            // Adiciona a promise para salvar o registro
                            promises.push(
                                axios.post(`obra/linhas-projeto-vao-obra/`, formData, {
                                    headers: { Authorization: `Token ${token}` }
                                })
                                    .then(() => {
                                        console.log(
                                            `Linha ${linha.id} com tipologia ${aba.id_tipologia}` +
                                            (projetoAssociado ? ` e projeto ${projetoAssociado.projeto.id}` : '') +
                                            ' salva com sucesso.'
                                        );
                                    })
                                    .catch(error => {
                                        console.error(
                                            `Erro ao salvar linha ${linha.id} com tipologia ${aba.id_tipologia}` +
                                            (projetoAssociado ? ` e projeto ${projetoAssociado.projeto.id}` : '') +
                                            ':', error
                                        );
                                    })
                            );
                        }
                    }
                }
            }

            // Aguarda todas as promises serem concluídas
            this.$store.dispatch("setLoading", true);
            try {
                await Promise.all(promises);
                console.log('Todas as operações foram concluídas com sucesso.');
            } catch (error) {
                console.error('Erro em uma ou mais operações:', error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },


        // async salvarListaProjetos(id_vao) {
        //     const token = await this.$store.dispatch('getAuthTokenCookie');
        //     await this.$store.dispatch('fetchURLrequest');

        //     // Verifica se há projetos selecionados
        //     const hasProjetos = Object.keys(this.projetosSelecionados).length > 0;

        //     if (hasProjetos) {
        //         // Envia os projetos selecionados com suas linhas e tipologias
        //         for (const chave in this.projetosSelecionados) {
        //             const projetoIndex = this.projetosSelecionados[chave];

        //             if (projetoIndex && projetoIndex.projeto && projetoIndex.nomeDaLinha && projetoIndex.projeto.id) {
        //                 const { projeto, nomeDaLinha } = projetoIndex;

        //                 // Encontra a tipologia associada à chave do projeto e linha específicos
        //                 const [tipologiaNome, linha] = chave.split('_');
        //                 const tipologiaAssociada = this.abas.find(aba => aba.nome === tipologiaNome);

        //                 if (tipologiaAssociada && tipologiaAssociada.id_tipologia) {
        //                     const formDataProjeto = new FormData();

        //                     formDataProjeto.append('vao_obra', id_vao);
        //                     formDataProjeto.append('ativo', 'true');
        //                     formDataProjeto.append('projeto', projeto.id);
        //                     formDataProjeto.append('linhas', nomeDaLinha.id);
        //                     formDataProjeto.append('tipologias', tipologiaAssociada.id_tipologia);

        //                     this.$store.dispatch("setLoading", true);
        //                     try {
        //                         await axios.post(`obra/linhas-projeto-vao-obra/`, formDataProjeto, {
        //                             headers: {
        //                                 Authorization: `Token ${token}`
        //                             }
        //                         });
        //                         console.log(`Projeto ${projeto.id} com tipologia ${tipologiaAssociada.nome} e linha ${nomeDaLinha.linha} salvo com sucesso.`);
        //                     } catch (error) {
        //                         console.error(`Erro ao enviar o projeto ${projeto.id} com linha ${nomeDaLinha.linha}:`, error);
        //                     } finally {
        //                         this.$store.dispatch("setLoading", false);
        //                     }
        //                 } else {
        //                     console.warn(`Tipologia associada não encontrada ou inválida para o projeto ${projeto.id} e linha ${nomeDaLinha.linha}`);
        //                 }
        //             } else {
        //                 console.warn(`Projeto ou linha inválido na chave ${chave}.`);
        //             }
        //         }
        //     }

        //     // Salva as tipologias que não possuem projetos associados
        //     for (const aba of this.abas) {
        //         if (aba.nome !== 'Geral' && Array.isArray(aba.linhas) && aba.linhas.length > 0 && aba.id_tipologia) {
        //             const formDataSemProjetos = new FormData();

        //             formDataSemProjetos.append('vao_obra', id_vao);
        //             formDataSemProjetos.append('ativo', 'true');
        //             formDataSemProjetos.append('tipologias', aba.id_tipologia);

        //             // Verifica se a tipologia já foi associada a um projeto selecionado, caso contrário, salva separadamente
        //             const isAssociatedWithProject = Object.values(this.projetosSelecionados).some(projeto =>
        //                 projeto.nomeDaLinha && aba.linhas.some(linha => linha.id === projeto.nomeDaLinha.id)
        //             );

        //             if (!isAssociatedWithProject) {
        //                 // Adiciona as linhas específicas da tipologia atual
        //                 for (const linha of aba.linhas) {
        //                     if (linha.id) {
        //                         formDataSemProjetos.append('linhas', linha.id);
        //                     }
        //                 }

        //                 this.$store.dispatch("setLoading", true);
        //                 try {
        //                     await axios.post(`obra/linhas-projeto-vao-obra/`, formDataSemProjetos, {
        //                         headers: {
        //                             Authorization: `Token ${token}`
        //                         }
        //                     });
        //                     console.log(`Tipologia ${aba.id_tipologia} com suas linhas salvas com sucesso (sem projetos).`);
        //                 } catch (error) {
        //                     console.error(`Erro ao salvar tipologia ${aba.id_tipologia} com suas linhas:`, error);
        //                 } finally {
        //                     this.$store.dispatch("setLoading", false);
        //                 }
        //             }
        //         }
        //     }
        // },

        async buscarCliente() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`/obra/?obra=${this.$route.params.obra}`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                )
                this.cliente = response.data.results[0].cliente.id
            } catch (error) {
                console.error(error)
            }
        },

        selecionarLinha(linhaIndex, aba) {
            this.linhaSelecionada = linhaIndex;
            const chave = `${aba.id}_${aba.linhas[linhaIndex].linha}`;
            this.mostrarVariaveis = !!this.mostrarVariaveisPorLinha[chave];
        },

    }
};
</script>

<style scoped>
label {
    display: block;
    font-weight: bold;
}

input[type="file"],
input[type="number"],
input[type="text"],
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.abas-container {
    display: flex;
    margin-top: 15px;
    @apply mt-4 p-2 border-b rounded-lg
}

.aba {
    padding: 10px;
    cursor: pointer;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    @apply rounded-lg bg-white text-corBase border border-white
}

.aba-selecionada {
    @apply bg-corBase text-white border border-white
}

.foto-tipologia {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    z-index: 10;
}

.linhas-abas-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 5px;
}

.linha-aba {
    padding: 10px;
    cursor: pointer;
    margin-top: 2px;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    @apply rounded-lg bg-white text-corBase border border-white
}

.linha-aba-selecionada {
    @apply bg-corBase text-white border border-white
}

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-foto {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
</style>
