<template>


    <div class="container-formulario">

        <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Medidas</h1>
            </div>

            <router-link to="/configuracoes-produto" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>

        </div>

        <div class="flex items-center gap-3 mb-10">

            <div class="flex gap-8">
                <div>
                    <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
    text-white hover:text-corBase py-2 px-4" @click="getListaMedidas(1)">Pesquisar
                    </button>
                </div>
            </div>
            <div v-if="isadmin === true">
                <button @click="$router.push(`/medidas-cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4 ">Adicionar
                </button>
            </div>
        </div>

        <!-- Container da exibiçao da lista de pessoas -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th scope="col" class="p-4">
                            <div class="flex items-center">
                                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                                <label class="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" class="px-6 py-3">
                            #
                        </th>
                        <th class="px-6 py-3">
                            Codigo
                        </th>
                        <th class="px-6 py-3">
                            Descrição
                        </th>

                        <th scope="col" class="px-6 py-3">
                            Editar
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(medida, index) in lista_medida" :key="index" class="escopo-tabela">

                        <td class="w-4 p-4">
                            <div class="flex items-center">
                                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                    v-model="medida.selected">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </td>
                        <th scope="row"
                            class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                            {{ index + 1 }}
                        </th>
                        <td class="px-6 py-2 text-xs">
                            {{ medida.descricao }}
                        </td>

                        <template v-if="medida.ativo === true">
                            <td class="px-1 sm:px-6  py-1 sm:py-4">
                                <i class="fa-solid fa-circle-check  text-ativo"></i>
                            </td>
                        </template>
                        <template v-if="medida.ativo === false">
                            <td class="px-1 sm:px-6  py-1 sm:py-4">
                                <i class="fa-solid fa-circle-xmark text-inativo"></i>
                            </td>
                        </template>

                        <td class="px-6 py-2 text-xs">
                            <a @click.prevent="selecionarMedidaEditar(medida)" href="#"
                                class="font-medium text-corBase hover:underline">
                                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
                            </a>
                        </td>

                    </tr>
                </tbody>
            </table>

            <Pagination v-if="lista_medida.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
            <editar-medidas v-if="mostrarModal" :medida="medidaSelecionada" @fecharModal="toggleModal"
                @atualizarLista="getListaMedidas(1)" />
        </div>
    </div>

</template>

<script>
import axios from "axios";
import EditarMedidas from "@/components/midais/Editar/produtos/EditarMedidas";
import Pagination from "@/utils/Pagination.vue";

export default {
    name: "Listamedida",
    components: {
        EditarMedidas,
        Pagination
    },

    data() {
        return {
            lista_medida: [],
            checkAll: false,
            // varival que inicializa o numero de paginas
            paginaAtual: 1,
            countItens: '',

            mostrarModal: false,
            medidaSelecionada: null,

            error: [],
        }
    },

    computed: {
        minhaEmpresa() {
            return this.$store.state.minhaEmpresa;
        },

        isadmin() {
            return this.$store.state.isAdminUser
        },
    },

    created() {
    },

    methods: {
        selectAll() {
            // Atualiza o estado dos demais checkboxes
            const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = this.checkAll;
            });
        },

        selecionarMedidaEditar(medida) {
            this.medidaSelecionada = {...medida}
            this.toggleModal()
        },

        toggleModal() {
            this.mostrarModal = !this.mostrarModal
        },


        async getListaMedidas(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            this.$store.dispatch("setLoading", true);

            try {
                const response = await axios.get(`/produtos/medidas/?page=${pagina}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                this.lista_medida = response.data.results;
                this.countItens = response.data.count
                console.log(this.lista_medida);

            } catch (error) {
                console.error("Erro ao buscar medidas:", error);

            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getListaMedidas(pagina);
        },


    }
}
</script>

<style lang="scss" scoped></style>