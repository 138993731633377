<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>

            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Perfil</h2>

            <div>
              <div class="w-full flex gap-5">

                <div class="flex flex-col w-full md:w-[35%] ">
                  <label class="text-corBase text-xl">Imagem:</label>
                  <div class="imagePreviewWrapper"
                    :style="{ 'background-image': `url(${dadosPerfil.imagem?.url_documento})` }"
                    @click.prevent="toggleGaleria">
                  </div>
                  <div class="flex gap-3 mt-4">
                    <button class="selecionar-arquivo" @click.prevent="toggleGaleria">Pesquisar</button>
                  </div>
                </div>

                <div class="md:w-[65%] flex flex-col">

                  <div class="flex items-center gap-3 w-full mt-3">

                    <div class="flex">
                      <label class="text-xl text-corBase font-bold flex mr-3">Patente:</label>
                      <input type="checkbox" v-model="dadosPerfil.perfil_base.produto_especializado" @click.prevent>
                    </div>

                    <div class="campo-chapa" v-if="dadosPerfil.perfil_base.produto_especializado">
                      {{ dadosPerfil.perfil_base?.patente?.descricao }}
                    </div>
                  </div>

                  <div class="w-full flex">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Código Base:</label>
                      {{ dadosPerfil.perfil_base?.codigo_base || '-' }}
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Descrição Base:</label>
                      {{ dadosPerfil.perfil_base?.nome_base || '-' }}
                    </div>
                  </div>

                  <div class="w-full flex">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Tipo:</label>
                      {{ dadosPerfil.perfil_base?.produto?.descricao || '-' }}
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Unidade Medida:</label>
                      {{ dadosPerfil.perfil_base?.unidade_medida?.nome || '-' }}
                    </div>
                  </div>

                  <div class="container-campos">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Medida:</label>
                      {{ dadosPerfil.tamanho || '-' }}
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Cor:</label>
                      {{ dadosPerfil.cor_perfil?.descricao || '-' }}
                    </div>
                  </div>

                  <div class="container-campos">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Fornecedor:</label>
                      {{ dadosPerfil.fornecedor.nome_razao || '-' }}
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Patente:</label>
                      {{ dadosPerfil.perfil_base?.patente?.descricao || '-' }}
                    </div>
                  </div>

                  <div class="container-campos">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Peso:</label>
                      <label v-if="dadosPerfil.perfil_base.produto_especializado && dadosPerfil.perfil_base?.patente">
                        {{ dadosPerfil.peso || '-' }}
                      </label>
                      <input v-else type="number" v-model="dadosPerfil.peso"
                        :disabled="dadosPerfil.perfil_base.produto_especializado">
                    </div>

                  </div>

                  <div class="container-campos">
                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Base:</label>
                      <input type="text" v-model="codigoBase" class="border-gray-300 rounded p-1" />
                    </div>
                  
                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Cor-Medida:</label>
                      <input type="text" v-model="codigoSufixo" class="border-gray-300 rounded p-1" disabled />
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Final:</label>
                      <input type="text" v-model="dadosPerfil.codigo" disabled>
                        <!-- dadosPerfil.perfil_base.produto_especializado -->
                    </div>
                  </div>

                  <div class="container-campos">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Descrição:</label>
                      <input type="text" v-model="dadosPerfil.descricao"
                        :disabled="dadosPerfil.perfil_base.produto_especializado">
                    </div>

                  </div>

                  <div class="container-campos">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Tamanho Mínimo Corte:</label>
                      <input type="number" min="1" v-model="dadosPerfil.tamanho_minimo_corte">
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Perda Tratamento:</label>
                      <input type="text" v-model="dadosPerfil.perda_tratamento">
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Tamanho Máximo Sucata (mm):</label>
                      <input type="number" min="1" v-model="dadosPerfil.sucata_maxima">
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div class="flex justify-end mt-2">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>

          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso" mensagem="Componente atualizado com sucesso." />
    </div>
  </div>

  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

  <Galeria v-if="showGaleria === true" @fechar_galeria="toggleGaleria" @objetoClicado="atualizarFileInput" />
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import Galeria from "../../ged/Galeria.vue";
import vSelect from "vue-select";

export default {
  name: "EditarPerfil",
  components: {
    SucessoRapido
  },
  data() {
    return {
      sucesso: false,
      showGaleria: false,

      erros: [],
      exibirErros: false,

      codigoBase: "",
      codigoSufixo: "",
    }
  },

  components: {
    SucessoRapido,
    ListagemErros,
    Galeria,
    vSelect,
  },

  computed: {
    codigoCompleto() {
      return `${this.codigoBase}${this.codigoSufixo}`;
    }
  },

  watch: {
    // Sempre que `codigoBase` mudar, atualizar `dadosPerfil.codigo`
    codigoBase(newValue) {
      this.dadosPerfil.codigo = `${newValue}${this.codigoSufixo}`;
    }
  },

  props: ["dadosPerfil"],
  emits: ["fecharModal", "atualizarLista"],

  created() {
    this.inicializarCodigo();
  },
  
  methods: {

    inicializarCodigo() {
      if (this.dadosPerfil.codigo) {
        const partes = this.dadosPerfil.codigo.split("-");
        
        const cor = this.dadosPerfil.cor_perfil?.codigo || "";
        const medida = this.dadosPerfil.tamanho ? this.dadosPerfil.tamanho.toString() : "";

        const corIndex = partes.indexOf(cor);

        if (corIndex > 0) {
          // Caso a cor seja encontrada, o código base é tudo antes dela
          this.codigoBase = partes.slice(0, corIndex).join("-");
          this.codigoSufixo = `-${partes.slice(corIndex).join("-")}`;
        } else {
          // Se a cor não for encontrada, assume que o código base é antes da medida
          const medidaIndex = partes.indexOf(medida);
          if (medidaIndex > 0) {
            this.codigoBase = partes.slice(0, medidaIndex).join("-");
            this.codigoSufixo = `-${partes.slice(medidaIndex).join("-")}`;
          } else {
            // Se nada for encontrado, assume que todo o código é editável
            this.codigoBase = this.dadosPerfil.codigo;
            this.codigoSufixo = "";
          }
        }
      }
    },

    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append("codigo", this.codigoCompleto);
      dadosAtualizados.append('descricao', this.dadosPerfil.descricao);
      dadosAtualizados.append('peso', this.dadosPerfil.peso);
      dadosAtualizados.append('tamanho', this.dadosPerfil.tamanho || '');
      dadosAtualizados.append('tamanho_minimo_corte', this.dadosPerfil.tamanho_minimo_corte || '');
      dadosAtualizados.append('perda_tratamento', this.dadosPerfil.perda_tratamento || '');
      dadosAtualizados.append('sucata_maxima', this.dadosPerfil.sucata_maxima || '');
      dadosAtualizados.append('imagem', this.dadosPerfil.imagem?.id || '');

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.patch(`produtos/perfil/${this.dadosPerfil.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response);
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
          this.$emit('atualizarLista')
        }, 3000);

      } catch (error) {
        console.error(error)
      }
    },

  }
}
</script>

<style scoped>
input[type=text],
input[type=number] {
  @apply w-full p-1 border border-[#ccc] rounded-md
}
</style>