<template>

    <div class="container-formulario">
        <div class="container-cabecalho flex justify-between mb-4 ">
            <div>
                <h1 class="text-xl text-corBase font-bold">Perfis Base</h1>
            </div>

            <router-link to="/produtos" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>

        </div>

        <div class="block">

            <div class="mb-4">
                <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                    <div class="px-2 w-1/2">
                        <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                            <option value="codigo_base">Código</option>
                            <option value="nome_base">Descrição</option>
                            <option value="tipo">Tipo</option>
                            <option value="cor_base_id">Cor</option>
                            <option value="peso">Peso</option>
                            <option value="unidade_medida">Unidade Medida</option>
                            <option value="medida">Medida</option>
                            <option value="patente">Patente</option>
                            <option value="linha">Linha</option>
                            <option value="ativo">Status</option>
                        </select>
                    </div>

                    <div class="w-full">
                        <!-- Filtros por input simples -->

                        <v-select v-if="filtro.campo === 'tipo'" v-model="filtro.valor" :options="lista_tipo_produto"
                            label="descricao" :reduce="tipo => tipo.id" placeholder="Escolha uma opção ">
                            <template #no-options>
                                <span>
                                    {{ lista_tipo_produto.length ? 'Nenhum tipo encontrado.' : 'Selecionar Produto' }}
                                </span>
                            </template>
                        </v-select>

                        <v-select v-else-if="filtro.campo === 'cor_base_id'" v-model="filtro.valor"
                            :options="lista_cores" label="descricao" :reduce="cor => cor.id"
                            placeholder="Escolha uma opção ">
                            <template #no-options>
                                <span>
                                    {{ lista_cores.length ? 'Nenhuma cor encontrada.' : 'Selecionar Produto' }}
                                </span>
                            </template>
                        </v-select>

                        <v-select v-else-if="filtro.campo === 'medida'" v-model="filtro.valor" :options="lista_medidas"
                            label="medida" :reduce="medida => medida.medida" placeholder="Escolha uma opção" />

                        <v-select v-else-if="filtro.campo === 'unidade_medida'" v-model="filtro.valor"
                            :options="lista_unidade_medida" label="nome" :reduce="unidade_medida => unidade_medida.id"
                            placeholder="Escolha uma opção">
                            <template #no-options>
                                <span>
                                    {{ lista_unidade_medida.length ? 'Nenhuma unidade encontrada.' :
                                        'Selecionar Unidade' }}
                                </span>
                            </template>
                        </v-select>

                        <v-select v-else-if="filtro.campo === 'patente'" v-model="filtro.valor"
                            :options="lista_patente" label="descricao" :reduce="patente => patente.id"
                            placeholder="Escolha uma opção" />

                        <v-select v-else-if="filtro.campo === 'linha'" v-model="filtro.valor"
                            :options="lista_linha_aplicacao" label="linha" :reduce="linha => linha.linha"
                            placeholder="Escolha uma opção" />

                        <v-select v-else-if="filtro.campo === 'ativo'" v-model="filtro.valor" :options="lista_status"
                            label="nome" :reduce="status => status.value" placeholder="Escolha uma opção" />

                        <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                            placeholder="Digite o desejado" />
                    </div>

                    <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
                </div>

                <div class="flex w-full justify-center">
                    <button class="text-ativo" @click.prevent="adicionarFiltro">
                        <i class="fas fa-plus-circle"></i>
                    </button>
                </div>
            </div>


        </div>

        <div class="flex">

            <div class="flex flex-wrap gap-2">

                <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
    text-white hover:text-corBase py-2 px-4" @click.prevent="mudarPagina(1)">Pesquisar
                </button>

                <button @click="$router.push('/produto-base/importacao-base')"
                    class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500 text-white hover:text-yellow-500 py-2 px-4">Importar
                    Perfis</button>

                <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
          text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionados()">
                    Alterar Selecionados
                </button>

                <button @click="$router.push(`/perfil-base/cadastro`)"
                    class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 ">Adicionar
                </button>

            </div>
        </div>


        <!-- Container da exibiçao da lista de pessoas -->
        <div class="container-modelo-tabela">
            <table class="container-tabela">
                <thead class="cabecalho-tabela">
                    <tr class="border-b">
                        <th scope="col" class="p-4">
                            <div class="flex items-center">
                                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" class="px-6 py-3">
                            #
                        </th>
                        <th v-if="isadmin === true" scope="col" class="px-6 py-3">
                            Ações
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Imagem
                        </th>
                        <th class="px-6 py-3">
                            Código
                        </th>
                        <th class="px-6 py-3">
                            Descrição
                        </th>
                        <th class="px-6 py-3 text-center">
                            Tipo
                        </th>
                        <th class="px-6 py-3 text-center">
                            Cor
                        </th>
                        <th class="px-6 py-3 text-center">
                            Bloquear Cor
                        </th>
                        <th scope="col" class="px-6 py-3 text-center">
                            Peso
                        </th>
                        <th scope="col" class="px-6 py-3 text-center">
                            Unidade Medida
                        </th>
                        <th class="px-6 py-3 text-center">
                            Medida
                        </th>
                        <th class="px-16 py-3 text-center">
                            Patente
                        </th>
                        <th scope="col" class="px-6 py-3 text-center">
                            Linha
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(produto, index) in lista_produto_base" :key="index" class="escopo-tabela">

                        <td class="w-4 p-4">
                            <div class="flex items-center">
                                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                    v-model="produto.selected">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </td>
                        <th scope="row"
                            class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                            {{ index + 1 }}
                        </th>
                        <td class="px-6 py-2 text-xs text-center" v-if="isadmin === true">
                            <a @click.prevent="selecionarProdutoBaseEditar(produto)" href="#"
                                class="font-medium text-corBase hover:underline">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </a>
                        </td>
                        <td class="px-6 py-2 text-xs">
                            <button v-if="produto.imagem && produto.imagem.url_documento"
                                @click.prevent="openPreviewImage(produto.imagem.url_documento)">
                                <img class="w-12" :src="produto.imagem.url_documento" :alt="produto.imagem.descricao" />
                            </button>
                            <img v-else class="w-12"
                                src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                alt="Sem foto">
                        </td>
                        <td class="px-6 py-2 text-xs">
                            {{ produto.codigo_base || '-' }}
                        </td>
                        <td class="px-6 py-2 text-xs">
                            {{ produto.nome_base || '-' }}
                        </td>
                        <td class="px-6 py-2 text-xs text-center">
                            {{ produto.produto?.descricao || '-' }}
                        </td>
                        <td class="px-6 py-2 text-xs text-center">
                            <!-- {{ produto.cor_base?.descricao || '-' }} -->
                            {{ produto.cor_base.map(cor => cor.descricao).join(', ') || '-' }}
                        </td>
                        <td class="px-6 py-2 text-xs text-center">
                            <input type="checkbox" v-model="produto.bloquear_cor" @click.prevent>
                        </td>
                        <td class="px-6 py-2 text-xs text-center">
                            {{ produto.peso_base || '-' }}
                        </td>
                        <td class="px-6 py-2 text-xs text-center">
                            {{ produto.unidade_medida?.nome || '-' }}
                        </td>
                        <td class="px-6 py-2 text-xs text-center">
                            {{ produto.medida.map(medida => medida.descricao).join(', ') || '-' }}
                        </td>
                        <td class="px-6 py-2 text-xs text-center">
                            {{ produto.patente?.descricao || '-' }}
                        </td>
                        <td class="px-6 py-2 text-xs text-center whitespace-nowrap">
                            {{ produto.linha.map(linha => linha.nome).join(', ') || '-' }}
                        </td>
                        <template v-if="produto.ativo === true">
                            <td class="px-6 py-2 text-xs">
                                <i class="fa-solid fa-circle-check text-lg text-ativo ml-3"></i>
                            </td>
                        </template>
                        <template v-else>
                            <td class="px-6 py-2">
                                <i class="fa-solid fa-circle-xmark text-lg text-inativo ml-3"></i>
                            </td>
                        </template>

                    </tr>
                </tbody>
            </table>

            <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

            <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

            <Pagination v-if="lista_produto_base.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />

            <EditarPerfilBase v-if="mostrarModal === true" :produto="produtoSelecionado" @fecharModal="toggleModal()"
                @atualizarLista="mudarPagina(paginaAtual)" :lista_tipo_produto="lista_tipo_produto"
                :lista_cores="lista_cores" :lista_unidade_medida="lista_unidade_medida"
                :lista_linha_aplicacao="lista_linha_aplicacao" :lista_patente="lista_patente" />

            <AlterarSelecionadosPerfilBase v-if="modalAlterarSelecionados === true"
                @fecharModal="toggleAlterarSelecionados()" @atualizarLista="mudarPagina(paginaAtual)"
                :produtos="produtosSelecionados" :tipo_produto="tipo_produto" :lista_tipo_produto="lista_tipo_produto"
                :lista_cores="lista_cores" :lista_unidade_medida="lista_unidade_medida"
                :lista_linha_aplicacao="lista_linha_aplicacao" :lista_patente="lista_patente" />
        </div>
    </div>

</template>

<script>
import axios from "axios";
import vSelect from 'vue-select'
import EditarPerfilBase from "@/components/midais/Editar/produtos/EditarProdutosBase/EditarPerfilBase.vue";
import AlterarSelecionadosPerfilBase from "@/components/midais/Editar/produtos/AlterarSelecionadosProdutoBase/AlterarSelecionadosPerfilBase.vue";
import PreviewImage from "@/components/midais/ged/PreviewImage";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import Pagination from "@/utils/Pagination.vue";

export default {
    name: "ListaProdutoBase",

    components: {
        EditarPerfilBase,
        AlterarSelecionadosPerfilBase,
        vSelect,
        PreviewImage,
        ListagemErros,
        Pagination,
    },

    data() {
        return {
            checkAll: false,
            filtros: [{ campo: "codigo_base", valor: "" }],

            // listas
            lista_produto_base: [],
            lista_tipo_produto: [],
            lista_cores: [],
            lista_medidas: [],
            lista_unidade_medida: [],
            lista_linha_aplicacao: [],
            lista_patente: [],
            lista_status: [
                { value: 'True', nome: "Ativo" },
                { value: 'False', nome: "Inativo" },
                { value: 'Todos', nome: "Todos" },
            ],

            preview_imagem: '',

            produtoSelecionado: null,
            mostrarModal: false,

            produtosSelecionados: [],
            modalAlterarSelecionados: false,

            exibirErros: false,
            erros: [],

            countItens: 0,
            paginaAtual: 1,

            error: []
        }
    },

    created() {
        //   this.getListaEspessura()
        this.getListaLinhaAplicacao()
        this.getListaCores()
        this.getListaTipoProdutoBase()
        this.getListaUnidadeMedida()
        this.getListaPatente()
    },


    computed: {
        isadmin() {
            return this.$store.state.isAdminUser
        },

        minhaEmpresa() {
            return this.$store.state.minhaEmpresa;
        },

        checkSome() {
            return this.lista_produto_base.some(produto => produto.selected)
        }
    },
    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },
        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        mudarPagina(pagina) {
            // this.checkAll = false
            this.paginaAtual = pagina;
            this.getListaProdutos(pagina);
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        async getListaCores() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            axios.get(`produtos/cor-produto-base/?produto_nome=Perfil&ativo=True&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.lista_cores = response.data.results;
                })
                .catch(error => {
                    console.error(error);
                }) 
        },

        async getListaEspessura() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            axios.get(`produtos/espessura/?ativo=True&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.lista_espessuras = response.data.results;
                })
                .catch(error => {
                    console.error(error);
                })
        },

        async getListaLinhaAplicacao() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            axios.get(`projeto/linha-sistema/?ativo=True&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.lista_linha_aplicacao = response.data.results;
                })
                .catch(error => {
                    console.error(error);
                })
        },

        async getListaUnidadeMedida() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            axios.get(`produtos/unidade-medida/?produto=Perfil&ativo=True&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.lista_unidade_medida = response.data.results;
                })
                .catch(error => {
                    console.error(error);
                });

        },

        async getListaTipoProdutoBase() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            // this.$store.dispatch("setLoading", true);

            try {
                const response = await axios.get(`/produtos/tipo-produto-base/?produto=Perfil&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                this.lista_tipo_produto = response.data.results;
                console.log(this.lista_tipo_produto);

            } catch (error) {
                console.error("Erro ao buscar tipos de produto base:", error);
            }
        },

        async getListaPatente() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            axios.get(`produtos/patente/?produto=Perfil&ativo=True&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data);
                    this.lista_patente = response.data.results;
                })
                .catch(error => {
                    console.error(error);
                });
        },


        alterarSelecionados() {
            if (this.checkSome || this.checkAll) {
                this.produtosSelecionados = this.lista_produto_base.filter(produto => produto.selected);
                console.log(this.produtosSelecionados);
                this.toggleAlterarSelecionados();

                // const primeiroId = this.produtosSelecionados[0].tipo_produto.id;
                // const todosIguais = this.produtosSelecionados.every(produto => produto.tipo_produto.id === primeiroId);
                // if (todosIguais) {
                //     console.log(todosIguais);
                //     this.tipo_produto = this.produtosSelecionados[0].tipo_produto.nome
                // } else {
                //     this.toggleErros()
                //     this.erros = { alterar_selecionados: 'Os itens selecionados precisam pertencer ao mesmo produto.' }
                // }
            } else {
                this.toggleErros()
                this.erros = { alterar_selecionados: 'Selecione pelo menos um Produto para ser alterado.' }
            }
        },


        toggleAlterarSelecionados() {
            this.modalAlterarSelecionados = !this.modalAlterarSelecionados;
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },


        selectAll() {
            // Atualiza o estado dos demais checkboxes
            // const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
            // checkboxes.forEach((checkbox) => {
            //     checkbox.checked = this.checkAll;
            // });

            this.lista_produto_base.forEach((produto_base) => {
                produto_base.selected = this.checkAll;
            });
        },

        async getListaProdutos(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {

                this.$store.dispatch("setLoading", true);

                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");

                console.log('Params')
                console.log(params)

                const response = await axios.get(
                    `produtos/produto/?tipo_produto=Perfil&${params}&page=${pagina}`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                );

                this.lista_produto_base = response.data.results;
                this.checkAll = false
                console.log(this.lista_produto_base)
                this.countItens = response.data.count;

            } catch (error) {
                console.error("Erro ao buscar produtos:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        selecionarProdutoBaseEditar(produto) {
            this.produtoSelecionado = { ...produto }
            this.toggleModal()
        },

        toggleModal() {
            this.mostrarModal = !this.mostrarModal
        },
    }
}
</script>

<style lang="scss"></style>