<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999] p-4">
        <div class="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full sm:w-full md:w-2/3 lg:w-1/3 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Vidros</h2>
            </div>

            <div class="space-y-4">
                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label for="corte" class="block text-sm font-medium text-corBase mb-1">Acréscimo
                            Tamanho:</label>
                        <v-select :options="lista_acrescimo_tamanho" label="label" placeholder="Escolha uma opção"
                            v-model="acrescimo_tamanho" :reduce="acrescimo => acrescimo.value" />
                    </div>

                    <div class="text-left w-full">
                        <label for="posicao" class="block text-sm font-medium text-corBase mb-1">Medida (M²/M):</label>
                        <input v-model="medida" type="text" maxlength="100" class="w-full"
                            :placeholder="acrescimo_tamanho == 'SA' || !acrescimo_tamanho ? '' : 'Digite aqui...'"
                            :disabled="acrescimo_tamanho == 'SA' || !acrescimo_tamanho">
                    </div>
                </div>

                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">% Acréscimo:</label>
                        <input v-model="porcentagem_acrescimo" type="text" maxlength="100"
                            :placeholder="acrescimo_tamanho == 'SA' || !acrescimo_tamanho ? '' : 'Digite aqui...'"
                            :disabled="acrescimo_tamanho == 'SA' || !acrescimo_tamanho" class="w-full">
                    </div>

                    <div class="text-left w-full">
                        <label for="aplicacao" class="block text-sm font-medium text-corBase mb-1">Área Mínima
                            Cobrança (M²):</label>
                        <input v-model="metragem_minima" type="text" maxlength="100" class="w-full"
                            placeholder="Digite aqui...">
                    </div>
                </div>

                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label for="aplicacao" class="block text-sm font-medium text-corBase mb-1">Arredondamento
                            Cobrança (mm):</label>
                        <input v-model="arredondamento" type="text" maxlength="100" class="w-full"
                            placeholder="Digite aqui...">
                    </div>


                    <div class="flex flex-col text-center items-center w-full">
                        <label for="fase_esquadria" class="block text-sm font-medium text-corBase mb-1">Status:</label>
                        <div class="cursor-pointer flex items-center justify-center w-6 h-6"
                            @click.prevent="toggleAtivo">
                            <i :class="ativo ? 'fa-solid fa-circle-check text-ativo' : 'fa-solid fa-circle-xmark text-inativo'"
                                class="text-2xl"></i>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>

            <SucessoRapido v-if="sucesso" mensagem="Vidros atualizados com sucesso!" />
            <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'AlterarSelecionadosModalImportacaoVidro',
    data() {
        return {
            acrescimo_tamanho: '',
            medida: '',
            porcentagem_acrescimo: '',
            metragem_minima: '',
            arredondamento: '',
            ativo: true,

            lista_acrescimo_tamanho: [
                { label: 'Sem Acréscimo', value: 'SA' },
                { label: 'Metro Quadrado', value: 'MQ' },
                { label: 'Metro Linear', value: 'ML' },
            ],

            sucesso: false,
            exibirErros: false,
            erros: [],
        };
    },

    components: {
        vSelect,
        SucessoRapido,
        ListagemErros,
    },

    props: ['vidros'],

    emits: ['fecharModal', 'atualizarLista'],

    created() {
    },

    methods: {

        toggleAtivo() {
            this.ativo = !this.ativo
        },

        async salvarAlteracoes() {
            try {

                if (this.acrescimo_tamanho && this.acrescimo_tamanho !== 'SA') {
                    // Se acréscimo tamanho não for 'SA', verificar medida e porcentagem_acrescimo
                    if ((!this.medida || this.medida <= 0) || (!this.porcentagem_acrescimo || this.porcentagem_acrescimo <= 0)) {
                        this.erros = {
                            acrescimo_tamanho: `O tipo de acréscimo selecionado exige que medida e porcentagem de acréscimo sejam válidos e maiores que zero.`,
                        };
                        this.toggleErros();
                        return;
                    }
                }

                const vidrosAtualizados = this.vidros.map(vidro => {
                    const atualizado = { ...vidro };

                    // Atualiza os campos selecionados
                    if (this.acrescimo_tamanho) {
                        atualizado.acrescimo_tamanho = this.acrescimo_tamanho;
                    }
                    if (this.medida) {
                        atualizado.medida = this.medida;
                    }
                    if (this.porcentagem_acrescimo) {
                        atualizado.porcentagem_acrescimo = this.porcentagem_acrescimo;
                    }
                    if (this.metragem_minima) {
                        atualizado.metragem_minima = this.metragem_minima;
                    }
                    if (this.arredondamento) {
                        atualizado.arredondamento = this.arredondamento;
                    }
                    if (this.ativo !== undefined) {
                        atualizado.ativo = this.ativo;
                    }

                    return atualizado;
                });

                // Emitir a lista de vidros atualizada para o modal principal
                this.$emit('atualizarLista', vidrosAtualizados);

                // Exibe mensagem de sucesso e fecha o modal
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal');
                }, 3000);
            } catch (error) {
                console.error('Erro ao salvar alterações:', error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' };
                this.toggleErros();
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

    },

};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}

input[type=text] {
    @apply w-full p-1 border border-[#ccc] rounded-md
}
</style>