<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Linhas</h1>
      </div>

      <router-link to="/configuracoes-projeto" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="block">

      <div class="mb-4">
        <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
          <div class="px-2 w-1/2">
            <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
              <option value="codigo">Código</option>
              <option value="linha">Descrição</option>
              <option value="bitola">Bitola</option>
              <option value="projetista_sistemista_id">Sistemista</option>
              <option value="ativo">Status</option>
            </select>
          </div>

          <div class="w-full">

            <v-select v-if="filtro.campo === 'projetista_sistemista_id'" v-model="filtro.valor"
              :options="projetistas_sistemistas" label="text" :reduce="sistemista => sistemista.id"
              placeholder="Escolha uma opção" />

            <v-select v-else-if="filtro.campo === 'ativo'" v-model="filtro.valor" :options="lista_status" label="nome"
              :reduce="status => status.value" placeholder="Escolha uma opção" />

            <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Digite o desejado" />
          </div>

          <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
        </div>

        <div class="flex w-full justify-center">
          <button class="text-ativo" @click.prevent="adicionarFiltro">
            <i class="fas fa-plus-circle"></i>
          </button>
        </div>
      </div>

    </div>

    <div class="flex">

      <div class="flex flex-wrap gap-2 mb-2">

        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
text-white hover:text-corBase py-2 px-4" @click.prevent="mudarPagina(1)">Pesquisar
        </button>

        <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
text-white hover:text-ativo py-2 px-4" @click.prevent="$router.push(`linha-sistema/cadastro`)">Adicionar
        </button>

      </div>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">#</th>
            <th v-if="isadmin === true" scope="col" class="px-6 text-center">Ações</th>
            <th class="px-6 py-2">Código</th>
            <th class="px-20 py-2">Descrição</th>
            <th class="px-6 py-2 text-center">Bitola</th>
            <th class="px-12 py-2 text-center">Sistemista</th>
            <th class="px-6 py-2 text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(linha_sistema, index) in lista_linha_sistema" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="linha_sistema.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-center" v-if="isadmin === true">
              <a @click="selecionarLinhaEditar(linha_sistema)" href="#"
                class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square"></i>
              </a>
            </td>
            <td class="px-6 py-2">{{ linha_sistema.codigo }}</td>
            <td class="px-20 py-2 whitespace-nowrap">{{ linha_sistema.linha }}</td>
            <td class="px-6 py-2 text-center">{{ linha_sistema.bitola || '-' }}</td>
            <td class="px-12 py-2 text-center">
              {{ linha_sistema.projetistasistemistas?.nome || '-' }}
              ({{ linha_sistema.projetistasistemistas?.codigo || '-' }})
            </td>
            <td class="px-6 py-2 text-center">
              <i v-if="linha_sistema.ativo" class="fa-solid fa-circle-check text-ativo"></i>
              <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination v-if="lista_linha_sistema.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
      <editar-linha-sistema v-if="mostrarModal === true" :linhaSistema="linhaSelecionada" @fecharModal="toggleModal()"
        @atualizarLista="getListaLinhasSistemistas(paginaAtual)"></editar-linha-sistema>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import EditarLinhaSistema from "@/components/midais/Editar/projeto/EditarLinhaSistema";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaLinhasSistemistas",

  components: {
    vSelect,
    EditarLinhaSistema,
    Pagination
  },

  data() {
    return {
      lista_linha_sistema: [],
      projetistas_sistemistas: [],

      filtros: [{ campo: "codigo", valor: "" }],
      lista_status: [
        { value: 'True', nome: "Ativo" },
        { value: 'False', nome: "Inativo" },
        // { value: 'Todos', nome: "Todos" },
      ],

      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: 0,

      // modal
      linhaSelecionada: null,
      mostrarModal: false,

      // filtros
      // nome_projetista: '',
      projetista: '',
      linha: '',
      bitola: '',

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    checkSome() {
      return this.lista_linha_sistema.some(linha => linha.selected)
    }
  },

  created() {
    this.getListaProjetistaSistemista()
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },
    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      this.lista_linha_sistema.forEach((linha) => {
        linha.selected = this.checkAll;
      });
    },

    async getListaProjetistaSistemista() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/projetista-sistemista/?ativo=True&page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.projetistas_sistemistas = response.data.results.map(item => ({
            id: item.id,
            text: `${item.nome} (${item.codigo})`
          }));
          console.log(response.data.results)
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getListaLinhasSistemistas(pagina) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        const response = await axios.get(`/projeto/linha-sistema/?${params}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_linha_sistema = response.data.results;
        this.countItens = response.data.count;
      } catch (error) {
        console.error("Erro ao buscar linhas sistemistas:", error);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina
      this.getListaLinhasSistemistas(pagina)
    },

    selecionarLinhaEditar(linha) {
      this.linhaSelecionada = { ...linha }
      this.toggleModal()
    },

    toggleModal() {
      this.mostrarModal = !this.mostrarModal
    }

  }
}
</script>

<style lang="scss"></style>