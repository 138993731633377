<template>


    <div class="container-formulario">

        <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Colaboradores</h1>
            </div>

            <router-link to="/rh" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>

        </div>


        <div class="flex items-end gap-8 mb-6">

            <div class="w-1/3">
                <label class="text-xl text-corBase font-bold">Função:</label>
                <v-select v-model="funcao" label="descricao" :options="lista_funcoes_colaborador" class="w-full" />
            </div>


            <div class="w-1/3">
                <label class="text-xl text-corBase font-bold">Status:</label>
                <v-select v-model="status" :options="[
                    { value: 'True', text: 'Ativo' },
                    { value: 'False', text: 'Inativo' },
                ]" label="text" :reduce="option => option.value" class="w-full" />
            </div>

        </div>

        <div class="flex items-center gap-3 mb-10">

            <div class="flex gap-2">
                <div>
                    <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
    text-white hover:text-corBase py-2 px-4" @click="mudarPagina(1)">Pesquisar
                    </button>
                </div>
            </div>
            <div>
                <button @click.prevent="toggleAdicionarColaborador()" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4 ">Adicionar
                </button>
            </div>
        </div>

        <!-- Container da exibiçao da lista de pessoas -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th scope="col" class="p-4">
                            <div class="flex items-center">
                                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                                <label class="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" class="px-6 py-3">
                            #
                        </th>
                        <th class="px-6 py-3 text-center">
                            Ações
                        </th>
                        <th class="px-6 py-3">
                            Nome
                        </th>
                        <th class="px-6 py-3">
                            Função(ções)
                        </th>
                        <th scope="col" class="px-6 py-3 text-center">
                            Status
                        </th>
                        <th scope="col" class="px-10 py-3" v-if="isadmin === true">
                            Ações
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(colaborador, index) in lista_colaboradores" :key="index" class="escopo-tabela">
                        <td class="w-4 p-4">
                            <div class="flex items-center">
                                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                    v-model="colaborador.selected">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </td>
                        <th scope="row"
                            class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                            {{ index + 1 }}
                        </th>
                        <td class="px-6 py-2 text-xs text-center">
                            <a @click="selecionarTipoEditar(funcao)" href="#"
                                class="font-medium text-corBase pl-2 hover:underline">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </a>
                        </td>
                        <td class="px-6 py-2 text-xs">
                            {{ colaborador.pessoa?.nome_razao || '-' }}
                        </td>
                        <td class="px-6 py-2 text-xs">
                            {{ colaborador.funcao_colaborador.map(funcao => funcao.descricao).join(', ') || '-' }}
                        </td>
                        <td class="px-6 py-3 text-center">
                            <i v-if="colaborador.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                            <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                        </td>
                        <td class="px-6 py-2 text-xs" v-if="isadmin">
                            <a @click="selecionarTipoEditar(colaborador)" href="#"
                                class="font-medium text-corBase pl-2 hover:underline">
                                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <Pagination v-if="lista_colaboradores.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />

        </div>
    </div>

    <ImportacaoColaboradores v-if="modalAdicionarColaboradores" :lista_funcoes_colaborador="lista_funcoes_colaborador"
        @fecharModal="toggleAdicionarColaborador()" @atualizarLista="mudarPagina(paginaAtual)" />

</template>

<script>
import axios from "axios";
import ImportacaoColaboradores from "../importacao/ImportacaoColaboradores.vue";
import Pagination from "@/utils/Pagination.vue";
import vSelect from "vue-select";

export default {
    name: "ListaFuncoesColaborador",

    components: {
        ImportacaoColaboradores,
        Pagination,
        vSelect,
    },

    data() {
        return {
            lista_colaboradores: [],
            lista_funcoes_colaborador: [],

            descricao: '',
            status: '',

            checkAll: false,
            // varival que inicializa o numero de paginas
            paginaAtual: 1,
            countItens: '',

            modalAdicionarColaboradores: false,

            colaboradorSelecionada: [],
            exibirModal: false,

            error: [],
        }
    },

    computed: {

        minhaEmpresa() {
            return this.$store.state.minhaEmpresa;
        },

        isadmin() {
            return this.$store.state.isAdminUser
        },
    },

    created() {
        this.getListaFuncoesColaborador()
    },


    methods: {

        selectAll() {
            // Atualiza o estado dos demais checkboxes
            const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = this.checkAll;
            });
        },

        async getListaColaboradores(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            this.$store.dispatch("setLoading", true);

            try {
                const response = await axios.get(`rh/colaborador/?descricao=${this.descricao}&ativo=${this.status}&page=${pagina}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                this.lista_colaboradores = response.data.results;
                this.countItens = response.data.count;
                console.log(this.lista_colaboradores);

            } catch (error) {
                console.error("Erro ao buscar funções de colaborador:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getListaFuncoesColaborador() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`rh/funcao-colaborador/?ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_funcoes_colaborador = response.data.results
            } catch (error) {
                console.error(error)
            }
        },

        toggleAdicionarColaborador() {
            this.modalAdicionarColaboradores = !this.modalAdicionarColaboradores
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getListaColaboradores(pagina);
        },

        selecionarTipoEditar(tipo) {
            this.colaboradorSelecionada = { ...tipo }
            this.toggleModal()
        },

        toggleModal() {
            this.exibirModal = !this.exibirModal
        },

    }
}
</script>

<style lang="scss"></style>