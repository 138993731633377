<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Relacionamento Empresarial</h1>
      </div>

      <router-link to="/dashboard" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>


    <!-- Filtros -->
    <div class="flex items-end gap-8 mb-6">
      <div class="w-1/2">
        <label class="text-xl text-corBase font-bold">Pessoa:</label>
        <v-select v-model="tipo" :options="[
          { id: 'fisica', text: 'Física' },
          { id: 'juridica', text: 'Jurídica' },
          { id: 'todos', text: 'Todos' }
        ]" label="text" :reduce="option => option.id" class="w-full" :clearable="false" />
      </div>

      <div class="w-1/2">
        <label class="text-xl text-corBase font-bold">Relacionamento:</label>
        <v-select v-model="relacionamento" :options="lista_relacionamentos" label="nome"
          :reduce="relacionamento => relacionamento.nome" class="w-full" />
      </div>

      <!-- <div class="w-1/3">
        <label class="text-xl text-corBase font-bold">Logradouro:</label>
        <input type="text" v-model="nome_razao" class="input-campos">
      </div> -->

      <!-- <div class="w-1/3">
        <label class="text-xl text-corBase font-bold">Nome ou Razão:</label>
        <input type="text" v-model="nome_razao" class="input-campos">
      </div> -->

      <!-- <div class="flex gap-2">
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click.prevent="mudarPagina(1)">Pesquisar</button> -->
      <!-- v-if="isadmin === true" -->
      <!-- <div>
          <button @click.prevent="$router.push(`/pessoa/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
          </button>
        </div>
      </div> -->
    </div>

    <div class="mb-4">
      <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
        <div class="px-2 w-1/2">
          <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
            <!-- <option value="pessoa">Pessoa</option> -->
            <option value="nome">Nome/Razão Social</option>
            <option value="nome_2">Nome Social/Nome Fantasia</option>
            <option value="identificacao">CPF/CNPJ</option>
            <option v-if="relacionamento == 'Colaborador' || relacionamento == 'Stakeholders'" value="funcao">Função
            </option>
            <option v-if="relacionamento == 'Fornecedor'" value="produto_fornecedor">Produto</option>
            <!-- <option value="atividade">Relacionamento</option> -->
            <option value="logadouro">Logradouro</option>
            <option value="bairro">Bairro</option>
            <option value="uf">Estado</option>
            <option value="cidade">Cidade</option>
            <option value="ativo">Status</option>
          </select>
        </div>

        <div class="w-full">

          <v-select v-if="filtro.campo === 'ativo'" v-model="filtro.valor" :options="lista_status" label="nome"
            :reduce="status => status.value"></v-select>

          <v-select v-else-if="filtro.campo === 'funcao'" v-model="filtro.valor" :options="lista_funcao"
            :reduce="funcao => funcao.descricao" label="descricao" />

          <v-select v-else-if="filtro.campo === 'produto_fornecedor'" v-model="filtro.valor"
            :options="lista_produtos_fornecedor" :reduce="produto => produto.nome" label="nome" />

          <v-select v-else-if="filtro.campo === 'uf'" v-model="filtro.valor" :options="estados"
            :reduce="estado => estado.sigla" label="nome" />

          <v-select v-else-if="filtro.campo === 'cidade'" v-model="filtro.valor" :options="cidades"
            :reduce="cidade => cidade.nome" label="nome">
            <template #no-options>
              <span v-if="filtros.some(f => f.campo === 'uf' && f.valor)">
                Nenhuma cidade encontrada.
              </span>
              <span v-else>
                Selecione um estado primeiro.
              </span>
            </template>
          </v-select>


          <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Insira o valor" />
        </div>

        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index, filtro.campo)"></i>
      </div>

      <div class="flex w-full justify-center">
        <button class="text-ativo" @click.prevent="adicionarFiltro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>

    <div class="flex items-end gap-8 mb-6">
      <div class="flex gap-2">
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click.prevent="mudarPagina(1)">Pesquisar</button>
        <!-- v-if="isadmin === true" -->
        <div>
          <button @click.prevent="$router.push(`/pessoa/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
          </button>
        </div>
      </div>
    </div>

    <!-- v-if="tipo == 'fisica'" -->
    <!-- TABELA PESSOA FÍSICA -->
    <div class="container-modelo-tabela">
      <table class="container-tabela">
        <thead class="cabecalho-tabela">
          <tr class="border-b">
            <th scope="col" class="px-4 py-3">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox">
                <label for="checkbox-all" class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3 text-start">
              #
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Pessoa
            </th>
            <th scope="col" class="px-6 py-3">
              Relacionamento(s)
            </th>
            <th scope="col" class="px-6 py-3">
              CPF/CNPJ
            </th>
            <th scope="col" class="px-6 py-3">
              Nome/Razão Social
            </th>
            <th scope="col" class="px-6 py-3">
              Nome Social/Nome Fantasia
            </th>
            <th scope="col" class="px-6 py-3"
              v-if="relacionamento == 'Colaborador' || relacionamento == 'Stakeholders'">
              <!-- v-if="tipo == 'juridica' || tipo == 'todos'" -->
              Função
            </th>
            <th scope="col" class="px-6 py-3"
              v-if="relacionamento == 'Fornecedor'">
              <!-- v-if="tipo == 'juridica' || tipo == 'todos'" -->
              Produto
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Endereço(s)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Telefone(s)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Email(s)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Interação(ções)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              <!-- v-if="isadmin == true" -->
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pessoa, index) in listaPessoas" :key="index" class="escopo-tabela">
            <td class="px-4 py-3">
              <div class="flex items-center">
                <input id="checkbox-table-1" type="checkbox" class="checkbox">
                <label for="checkbox-table-1" class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-3 text-start font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-3 text-center">
              <div class="flex justify-center items-center">
                <i v-if="pessoa.fisica || tipo == 'fisica'" class="fa-solid fa-person"></i>
                <i v-else class="fa-solid fa-building"></i>
              </div>
            </td>
            <td class="px-6 py-3">
              <ul class="list-disc">
                <li v-for="atividade in pessoa.pessoa.atividade" :key="atividade.id">
                  {{ atividade.nome }}
                </li>
              </ul>
            </td>
            <td class="px-6 py-3">
              {{ formatarCpfCnpj(pessoa.cpf || pessoa.cnpj) || '-' }}
            </td>
            <td class="px-6 py-3">
              {{ pessoa.nome || pessoa.razao_social || '-' }}
            </td>
            <td class="px-6 py-3">
              {{ pessoa.nome_social || pessoa.nome_fantasia || '-' }}
            </td>
            <td class="px-6 py-3" v-if="relacionamento == 'Colaborador' || relacionamento == 'Stakeholders'">
              <ul v-if="pessoa.funcao.length > 0" class="list-disc">
                <li v-for="funcao in pessoa.funcao" :key="funcao.id">
                  {{ funcao.descricao }}
                </li>
              </ul>
              <p v-else>-</p>
            </td>
            <td class="px-6 py-3" v-if="relacionamento == 'Fornecedor'">
              <ul v-if="pessoa.produtos_fornecedor.length > 0" class="list-disc">
                <li v-for="produto in pessoa.produtos_fornecedor" :key="produto.id">
                  {{ produto.nome }}
                </li>
              </ul>
              <p v-else>-</p>
            </td>
            <td class="px-6 py-3 text-center">
              <p @click.prevent="listarEnderecos(pessoa.pessoa.id)" class="cursor-pointer text-blue-500">Ver lista</p>
            </td>
            <td class="px-6 py-3 text-center">
              <p @click.prevent="listarTelefones(pessoa.pessoa.id)" class="cursor-pointer text-blue-500">Ver lista</p>
            </td>
            <td class="px-6 py-3 text-center">
              <p @click.prevent="listarEmails(pessoa.pessoa.id)" class="cursor-pointer text-blue-500">Ver lista</p>
            </td>
            <td class="px-6 py-3 text-center">
              <p @click.prevent="listarInteracoes(pessoa.pessoa.id)" class="cursor-pointer text-blue-500">Ver lista</p>
            </td>
            <td class="px-6 py-3 text-center">
              <div class="flex justify-center items-center">
                <i v-if="pessoa.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
              </div>
            </td>
            <td class="px-6 py-3">

              <!-- EDITAR PESSOA FÍSICA -->
              <div v-if="tipo == 'fisica' || (tipo == 'todos' && pessoa.fisica == true)">
                <a @click="selecionarPessoaEditarFisico(pessoa)" href="#"
                  class="font-medium text-corBase hover:underline">
                  <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
                </a>
              </div>

              <!-- EDITAR PESSOA JURÍDICA -->
              <div v-if="tipo == 'juridica' || (tipo == 'todos' && pessoa.fisica == false)">
                <a @click="selecionarPessoaEditarJuridico(pessoa)" href="#"
                  class="font-medium text-corBase hover:underline">
                  <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination v-if="listaPessoas.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

  <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

  <ListarEnderecos v-if="mostrarEnderecos" :pessoa="pessoaSelecionada" @fecharModal="toggleEnderecos()" />
  <ListarTelefones v-if="mostrarTelefones" :pessoa="pessoaSelecionada" @fecharModal="toggleTelefones()" />
  <ListarEmails v-if="mostrarEmails" :pessoa="pessoaSelecionada" @fecharModal="toggleEmails()" />
  <ListarInteracoes v-if="mostrarInteracoes" :pessoa="pessoaSelecionada" @fecharModal="toggleInteracoes()" />

  <EditarDados v-if="modalEditarFisico" :dadosPessoa="pessoaSelecionada" @fecharModal="toggleModalEditarFisico()"
    @atualizarLista="buscarPorTipoDePessoa(paginaAtual)" />

  <EditarDadosPessoaJuridica v-if="modalEditarJuridico" :dadosPessoa="pessoaSelecionada"
    @fecharModal="toggleModalEditarJuridico()" @atualizarLista="buscarPorTipoDePessoa(paginaAtual)" />


</template>

<script>
import axios from "axios";

import ListarEnderecos from "./ListarEnderecos.vue";
import ListarTelefones from "./ListarTelefones.vue";
import ListarEmails from "./ListarEmails.vue";
import ListarInteracoes from "./ListarInteracoes.vue";

import EditarDados from "@/components/midais/Editar/EditarDados";
import EditarDadosPessoaJuridica from "@/components/midais/Editar/EditarDadosPessoaJuridica";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import Pagination from "@/utils/Pagination.vue";
import vSelect from "vue-select";

export default {
  name: 'ListaDePessoas',

  components: {
    ListarEnderecos,
    ListarTelefones,
    ListarEmails,
    ListarInteracoes,
    EditarDados,
    EditarDadosPessoaJuridica,
    ListagemErros,
    Pagination,
    vSelect
  },

  data() {
    return {
      filtros: [{ campo: "nome", valor: "" }],
      lista_status: [
        { value: 'True', nome: "Ativo" },
        { value: 'False', nome: "Inativo" },
      ],
      lista_tipos_pessoa: [
        { id: 'fisica', text: 'Física' },
        { id: 'juridica', text: 'Jurídica' },
        { id: 'todos', text: 'Todos' }
      ],
      estados: [],
      cidades: [],
      lista_funcao: [],
      lista_produtos_fornecedor: [],

      verRelacionamento: false,

      listaPessoas: [],

      paginaAtual: 1,
      countItens: '',

      tipo: 'todos',
      nome_razao: '',
      relacionamento: '',

      lista_relacionamentos: [],

      modalEditarFisico: false,
      modalEditarJuridico: false,
      pessoaSelecionada: [],

      mostrarEnderecos: false,
      mostrarTelefones: false,
      mostrarEmails: false,
      mostrarInteracoes: false,

      erros: [],
      exibirErros: false,
    }
  },

  watch: {
    tipo: {
      handler(newValue) {
        if (newValue) {
          this.listaPessoas = []
        }
      }
    },

    filtros: {
      handler(novosFiltros) {
        const filtroEstado = novosFiltros.find(filtro => filtro.campo === 'uf' && filtro.valor);
        if (filtroEstado) {
          this.buscarCidades(filtroEstado.valor);
        }
      },
      deep: true,
    }
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getListaRelacionamentos()
    this.getListaFuncaoStakeholders()
    this.getListaProdutos()
    this.carregarEstados()
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },

    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index, campo) {

      if (campo === 'pessoa') {
        const pessoaFiltrosRestantes = this.filtros.filter(filtro => filtro.campo === 'pessoa').length;

        if (pessoaFiltrosRestantes <= 1) {
          this.erros = { filtro: ["Você precisa manter um filtro de Pessoa."] };
          this.toggleErros();
          return;
        }
      }

      // Remove o filtro normalmente
      this.filtros.splice(index, 1);
    },


    async carregarEstados() {
      try {
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");
        const response = await axios.get(`obra/estados/`, {
          headers: {
            "Authorization": `Token ${token}`
          }
        });

        this.estados = response.data.map((estado) => ({
          sigla: estado.sigla,
          nome: `${estado.nome} (${estado.sigla})`,
        }))

      } catch (error) {
        console.error("Erro ao carregar estados:", error);
      }
    },

    async buscarCidades(estado) {
      try {
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");
        const response = await axios.get(`obra/municipios/?estado=${estado}`, {
          headers: {
            "Authorization": `Token ${token}`
          }
        });
        this.cidades = response.data.map((cidade) => ({
          id: cidade.id,
          nome: cidade.nome,
        }))

      } catch (error) {
        console.error("Erro ao carregar cidades:", error);
      }
    },

    async getListaRelacionamentos() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`pessoas/atividade/?page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_relacionamentos = response.data.results
        console.log(this.lista_relacionamentos)
      } catch (error) {
        console.error(error)
      }
    },

    async getListaFuncaoStakeholders() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`pessoas/tipo-pessoas/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_funcao = response.data.results
        console.log(this.lista_funcao)
      } catch (error) {
        console.error(error)
      }
    },

    async getListaProdutos() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`produtos/produtos-sistema/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_produtos_fornecedor = response.data.results
        // console.log(this.lista_produtos)
      } catch (error) {
        console.error(error)
      }
    },

    buscarPorTipoDePessoa(pagina) {
      if (this.tipo == 'fisica') {
        this.getListaPessoasFisica(pagina)
      } else if (this.tipo == 'juridica') {
        this.getListaPessoasJuridica(pagina)
      } else {
        this.getListaPessoasFisicaJuridica(pagina)
      }
    },

    async getListaPessoasFisica(pagina) {

      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        this.$store.dispatch("setLoading", true);

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        console.log('Params')
        console.log(params)
        const response = await axios.get(`pessoa/pessoa-fisica/?${params}&atividade=${this.relacionamento || ''}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.listaPessoas = response.data.results;
        this.countItens = response.data.count;
        console.log(this.listaPessoas);

      } catch (error) {
        console.error("Erro ao buscar lista de pessoas:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getListaPessoasJuridica(pagina) {

      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        this.$store.dispatch("setLoading", true);

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        console.log('Params')
        console.log(params)
        const response = await axios.get(`pessoa/pessoa-juridica/?${params}&atividade=${this.relacionamento || ''}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.listaPessoas = response.data.results;
        this.countItens = response.data.count;
        console.log(this.listaPessoas);

      } catch (error) {
        console.error("Erro ao buscar lista de pessoas:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getListaPessoasFisicaJuridica(pagina) {

      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        this.$store.dispatch("setLoading", true);

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        console.log('Params')
        console.log(params)
        const response = await axios.get(`pessoa/pessoas-fisica-juridica/?${params}&atividade=${this.relacionamento || ''}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.listaPessoas = response.data.results;
        this.countItens = response.data.count;
        console.log(this.listaPessoas);

      } catch (error) {
        console.error("Erro ao buscar lista de pessoas:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    listarEnderecos(pessoaId) {
      this.pessoaSelecionada = pessoaId
      this.toggleEnderecos()
    },

    toggleEnderecos() {
      this.mostrarEnderecos = !this.mostrarEnderecos
    },

    listarTelefones(pessoaId) {
      this.pessoaSelecionada = pessoaId
      this.toggleTelefones()
    },

    toggleTelefones() {
      this.mostrarTelefones = !this.mostrarTelefones
    },

    listarEmails(pessoaId) {
      this.pessoaSelecionada = pessoaId
      this.toggleEmails()
    },

    toggleEmails() {
      this.mostrarEmails = !this.mostrarEmails
    },

    listarInteracoes(pessoaId) {
      this.pessoaSelecionada = pessoaId
      this.toggleInteracoes()
    },

    toggleInteracoes() {
      this.mostrarInteracoes = !this.mostrarInteracoes
    },



    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },

    selecionarPessoaEditarFisico(pessoa) {
      this.pessoaSelecionada = pessoa
      this.toggleModalEditarFisico()
    },

    toggleModalEditarFisico() {
      this.modalEditarFisico = !this.modalEditarFisico
    },

    selecionarPessoaEditarJuridico(pessoa) {
      this.pessoaSelecionada = pessoa
      this.toggleModalEditarJuridico()
    },

    toggleModalEditarJuridico() {
      this.modalEditarJuridico = !this.modalEditarJuridico
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.buscarPorTipoDePessoa(pagina);
    },

    formatarCpfCnpj(valor) {
      if (!valor) return '';

      // Remover quaisquer caracteres não numéricos
      const apenasNumeros = valor.replace(/\D/g, '');

      // Verificar o comprimento para decidir se é CPF ou CNPJ
      if (apenasNumeros.length === 11) {
        // Formatar como CPF (###.###.###-##)
        return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (apenasNumeros.length === 14) {
        // Formatar como CNPJ (##.###.###/####-##)
        return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }

      // Retornar o valor original caso não seja nem CPF nem CNPJ válido
      return valor;
    },


  }
}
</script>

<style lang="scss">
// estilo da lista até 768 pixels
.tela-lista-pessoas {
  @apply w-[95%] mt-6 bg-screenCor rounded-3xl p-4
}

.botoes-cabecalho {
  @apply flex flex-col gap-3 mt-6
}

.btn-add-juridica {
  @apply py-1 px-3 bg-corBase rounded-sm text-screenCor border border-screenCor hover:bg-screenCor hover:border hover:border-corBase hover:text-corBase
}

.btn-add-fisica {
  @apply bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4
}


// estilo lista de pessoas acima de 768 pixels
.container-lista-pessoas768 {
  @apply ml-24 mt-24 absolute w-[90%] p-4 bg-white rounded-3xl xl:w-[88%]
}


// modelo tabela estilo
.container-modelo-tabela {
  @apply relative rounded-lg w-full shadow-sm sm:rounded-lg sm:overflow-visible overflow-x-auto;
}

.container-tabela {
  @apply w-full text-sm text-left text-gray-500 dark:text-gray-400;
}

.cabecalho-tabela {
  @apply text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400;
}

.checkbox {
  @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600;
}

.escopo-tabela {
  @apply bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600;
}
</style>
