<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[9998]">
            <div class="bg-white rounded-lg max-w-[90%] shadow-lg p-6 max-h-[80vh] overflow-y-auto ">
                <!-- Cabeçalho do modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-green-600">Importar Componentes</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <div class="mb-4">
                    <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                        <div class="px-2 w-1/2">
                            <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                                <option value="codigo_base">Código Base</option>
                                <option value="nome_base">Descrição Base</option>
                                <option value="tipo">Tipo</option>
                                <option value="cor_base_id">Cor</option>
                                <!-- <option value="fornecedor">Fornecedor</option> -->
                            </select>
                        </div>

                        <div class="w-full">

                            <v-select v-if="filtro.campo === 'tipo'" v-model="filtro.valor"
                                :options="lista_tipo_produto" label="descricao" :reduce="tipo => tipo.id" placeholder="Escolha uma opção" />

                            <v-select v-else-if="filtro.campo === 'cor_base_id'" v-model="filtro.valor"
                                :options="lista_cores" label="descricao" :reduce="cor => cor.id" placeholder="Escolha uma opção" />

                            <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                            placeholder="Digite o desejado" />
                        </div>

                        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
                    </div>

                    <div class="flex w-full justify-center">
                        <button class="text-ativo" @click.prevent="adicionarFiltro">
                            <i class="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>

                <div class="mb-2 flex gap-2">

                    <button @click.prevent="toggleModalFornecedor()"
                        class="btn-modal-importacao bg-ativo text-white border hover:bg-screenCor hover:text-ativo hover:border-ativo">
                        Selecionar Fornecedor
                    </button>

                    <button @click.prevent="mudarPagina(1)"
                        class="btn-modal-importacao bg-corBase text-white border hover:bg-screenCor hover:text-corBase hover:border-corBase">
                        Pesquisar
                    </button>

                    <!-- <button @click.prevent="alterarSelecionados()"
                        class="btn-modal-importacao  bg-blue-500 text-white border hover:bg-screenCor hover:text-blue-500 hover:border-blue-500">
                        Alterar Selecionados
                    </button> -->

                    <button @click.prevent="importarComponentes()"
                        class="btn-modal-importacao  bg-yellow-500 text-white border hover:bg-screenCor hover:text-yellow-500 hover:border-yellow-500">
                        Importar
                    </button>
                </div>

                <p v-if="fornecedor" class="text-corBase font-bold underline">
                    Fornecedor: {{ fornecedor?.razao_social || fornecedor?.nome || fornecedor?.nome_razao }}
                </p>

                <div class="relative rounded-md shadow-md pt-2">
                    <table class="container-tabela">
                        <thead class="cabecalho-tabela">
                            <tr class="border-b">
                                <th class="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" class="p-4">
                                    <div class="flex items-center">
                                        <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                        <label class="sr-only">checkbox</label>
                                    </div>
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Imagem
                                </th>
                                <th class="px-28 py-3">
                                    Descrição
                                </th>
                                <th class="px-28 py-3">
                                    Código Base
                                </th>
                                <th class="px-6 py-3 text-center whitespace-nowrap">
                                    Descrição Base
                                </th>
                                <th class="px-6 py-3 text-center">
                                    Tipo
                                </th>
                                <th class="px-6 py-3 text-center">
                                    Cor
                                </th>
                                <th class="px-6 py-3 text-center">
                                    Unidade Medida
                                </th>
                                <th class="px-6 py-3 text-center">
                                    Peso (KG)
                                </th>
                                <th scope="col" class="px-16 py-3 text-center">
                                    Unidade Medida Embalagem
                                </th>
                                <th scope="col" class="px-3 py-3 text-center">
                                    Itens na Embalagem
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    $ Custo
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    % Custo Adicional
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(produto, index) in lista_componentes" :key="index"
                                class="escopo-tabela list-checkbox">

                                <th scope="row"
                                    class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ index + 1 }}
                                </th>

                                <td class="w-4 p-4">
                                    <div class="flex items-center">
                                        <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                            v-model="produto.selected">
                                        <label class="sr-only">checkbox</label>
                                    </div>
                                </td>
                                <!-- <td class="px-6 py-2 text-xs">
                                    {{ produto.codigo_base || '-' }}
                                </td> -->
                                <td class="px-6 py-2 text-xs">
                                    <button v-if="produto.imagem && produto.imagem.url_documento"
                                        @click.prevent="openPreviewImage(produto.imagem.url_documento)">
                                        <img class="w-12" :src="produto.imagem.url_documento"
                                            :alt="produto.imagem.descricao" />
                                    </button>
                                    <img v-else class="w-12"
                                        src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                                        alt="Sem foto">
                                </td>
                                <td class="py-2 text-xs">
                                    <input v-model="produto.descricao" type="text" maxlength="100" class="w-full">
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    {{ produto.codigo_base || '-' }}
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    {{ produto.nome_base || '-' }}
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    {{ produto.produto?.descricao || '-' }}
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    <!-- {{ produto.cor_base?.descricao || '-' }} -->
                                    {{ produto.cor_base.map(cor => cor.descricao).join(', ') || '-' }}
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    {{ produto.unidade_medida?.nome || '-' }}
                                </td>
                                <td class="px-6 py-2 text-xs text-center">
                                    {{ produto.peso_base || '-' }}
                                </td>
                                <td class="w-96 text-xs text-center">
                                    <v-select :options="lista_unidade_medida_embalagem" label="nome"
                                        v-model="produto.unidade_medida_embalagem" />
                                </td>
                                <td class="w-96 text-xs text-center">
                                    <input v-model="produto.quantidade_embalagem" type="text" maxlength="100"
                                        class="w-full">
                                </td>
                                <td class="w-96 text-xs text-center">
                                    <input v-model="produto.custo" type="text" maxlength="100" class="w-full">
                                </td>
                                <td class="w-96 text-xs text-center">
                                    <input v-model="produto.custo_adicional" type="text" maxlength="100" class="w-full">
                                </td>


                            </tr>
                        </tbody>
                    </table>

                    <Pagination v-if="lista_componentes.length > 0" :offset="paginaAtual" :total="countItens"
                        :limit="20" @change-page="mudarPagina" />
                </div>

            </div>
            <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
        </div>
    </div>

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

    <ModalSelecionarFornecedor v-if="modalFornecedor" @selecionarFornecedor="fornecedorSelecionado"
        @fecharModal="toggleModalFornecedor" produto="Componente" />

    <!-- <AlterarSelecionadosModalImportacaoComponente v-if="modalAlterarSelecionados" :componentes="componentesSelecionados"
        @atualizarLista="atualizarListaComponentes" @fecharModal="toggleAlterarSelecionados" /> -->
</template>

<script>
import axios from "axios"
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import PreviewImage from "@/components/midais/ged/PreviewImage.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import Pagination from "@/utils/Pagination.vue";
import ModalSelecionarFornecedor from "./ModalSelecionarFornecedor.vue";
// import AlterarSelecionadosModalImportacaoComponente from "@/components/midais/Editar/produtos/AlterarSelecionadosModalImportacaoComponente.vue";
import vSelect from "vue-select";

export default {
    name: 'ModalImportacaoComponente',

    props: ['lista_tipo_produto', 'lista_cores', 'lista_unidade_medida_embalagem'],
    emits: ['fecharModal', 'atualizarLista'],

    components: {
        ListagemErros,
        PreviewImage,
        SucessoRapido,
        Pagination,
        ModalSelecionarFornecedor,
        // AlterarSelecionadosModalImportacaoComponente,
        vSelect,
    },

    data() {
        return {
            checkAll: false,

            filtros: [{ campo: "codigo_base", valor: "" }],
            modalFornecedor: false,
            fornecedor: null,
            // fornecedor: {
            //     id: 'a18a72c4-83b8-40ef-aa74-e684a3d60acf',
            //     nome_razao: 'TVSBT CANAL 4 DE SAO PAULO S/A',
            // },

            lista_componentes: [],

            preview_imagem: '',

            // modalAlterarSelecionados: false,
            componentesSelecionados: [],

            exibirErros: false,
            erros: [],

            paginaAtual: 1,
            countItens: '',

            sucesso: false,
            sucessoMessage: '',
        }
    },

    computed: {
        checkSome() {
            return this.lista_componentes.some(produto => produto.selected)
        }
    },

    created() {
        // this.buscarListaComponentes(1)
        // this.getListaUnidadeMedida()
        // this.getListaCores()
        // this.getListaEspessura()

    },

    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },

        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        selectAll() {
            // Atualiza o estado dos demais checkboxes
            const checkboxes = document.querySelectorAll('.list-checkbox input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = this.checkAll;
            });

            this.lista_componentes.forEach((produto_base) => {
                produto_base.selected = this.checkAll;
            });
        },

        toggleModalFornecedor() {
            this.modalFornecedor = !this.modalFornecedor
        },

        fornecedorSelecionado(fornecedor) {
            this.lista_componentes = []
            console.log(fornecedor)
            this.fornecedor = fornecedor
            console.log('this.fornecedor: ', this.fornecedor)
        },

        async buscarListaComponentes(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            console.log(this.fornecedor)

            if (!this.fornecedor) {
                this.erros = { fornecedor: 'Selecione um fornecedor para buscar os produtos.' }
                this.toggleErros()
                return
            }

            try {

                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");

                const response = await axios.get(
                    `produtos/produto-importados-fornecedor/?tipo_produto=Componente&fornecedor_id=${this.fornecedor.pessoa?.id || this.fornecedor.id}&${params}&ativo=True&importado=False&page=${pagina}`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                );

                this.lista_componentes = response.data.results.map(produto => ({
                    ...produto,
                    descricao: produto.nome_base,
                }));
                console.log(this.lista_componentes)
                this.countItens = response.data.count;

            } catch (error) {
                console.error("Erro ao buscar produtos:", error);
            }
        },

        // alterarSelecionados() {
        //     if (this.checkSome || this.checkAll) {
        //         this.componentesSelecionados = this.lista_componentes.filter(componente => componente.selected);
        //         this.toggleAlterarSelecionados();
        //     } else {
        //         this.erros = { componente: ["Selecione pelo menos um Componente para ser alterado."] };
        //         this.toggleErros();
        //     }
        // },

        // toggleAlterarSelecionados() {
        //     this.modalAlterarSelecionados = !this.modalAlterarSelecionados;
        // },

        atualizarListaComponentes(componentesAtualizados) {

            console.log(componentesAtualizados)
            // Atualiza os itens selecionados na lista principal
            componentesAtualizados.forEach(atualizado => {
                const index = this.lista_componentes.findIndex(v => v.id === atualizado.id);
                if (index !== -1) {
                    this.lista_componentes[index] = { ...this.lista_componentes[index], ...atualizado };
                }
            });
        },

        async importarComponentes() {
            if (this.checkSome || this.checkAll) {

                const invalidos = this.lista_componentes.filter(produto =>
                    produto.selected &&
                    produto.unidade_medida_embalagem &&
                    (!produto.quantidade_embalagem || produto.quantidade_embalagem <= 0)
                );

                if (invalidos.length > 0) {
                    const descricoesErro = invalidos.map(produto => produto.descricao || 'Sem descrição').join(', ');
                    this.erros = { importar: `O(s) produto(s) "${descricoesErro}" possui(em) uma Unidade de Medida da Embalagem, mas não possui quantidade de itens na embalagem.` }
                    this.toggleErros()
                    return;
                }

                try {
                    const componentesSelecionados = this.lista_componentes
                        .filter(produto => produto.selected)
                        .map(produto => ({
                            componente_base: produto.id,
                            descricao: produto.descricao,
                            custo: produto.custo,
                            custo_adicional: produto.custo_adicional,
                            unidade_medida_embalagem: produto.unidade_medida_embalagem?.id,
                            quantidade_embalagem: produto.quantidade_embalagem
                        }));

                    const token = await this.$store.dispatch('getAuthTokenCookie');
                    await this.$store.dispatch('fetchURLrequest');

                    // Envia todos os dados em uma única requisição
                    console.log('Payload enviado: ', componentesSelecionados);

                    await axios.post(`produtos/componente-acessorio/?fornecedor_id=${this.fornecedor.pessoa?.id || this.fornecedor.id}`, { componentes: componentesSelecionados }, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });

                    // Exibe mensagem de sucesso e atualiza a lista
                    this.sucesso = true;
                    this.sucessoMessage = 'Componentes importados com sucesso.';
                    this.checkAll = false
                    setTimeout(() => {
                        this.sucesso = false;
                        this.sucessoMessage = '';
                        this.$emit('fecharModal');
                        this.$emit('atualizarLista');
                    }, 3000);

                } catch (error) {
                    console.error('Erro ao importar componentes:', error);
                    this.erros = error.response?.data || { componente: ["Ocorreu um erro ao importar os componentes."] };
                    this.toggleErros();
                }
            } else {
                this.erros = { componente: ["Selecione pelo menos um Componente para importar."] };
                this.toggleErros();
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

        mudarPagina(pagina) {
            this.checkAll = false
            this.paginaAtual = pagina;
            this.buscarListaComponentes(pagina);
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },
    }
}
</script>

<style scoped>
.btn-modal-importacao {
    @apply px-4 py-1 cursor-pointer
}

input[type=text] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}
</style>