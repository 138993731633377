<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="px-3 py-3">
            #
          </th>
          <th scope="col" class="px-3 py-3">
            Editar
          </th>
          <th class="px-3 py-3">
            Localização
          </th>
          <th scope="col" class="px-3 py-3">
            Condição (fórmula)
          </th>
          <th scope="col" class="px-3 py-3">
            Largura (fórmula)
          </th>
          <th scope="col" class="px-3 py-3">
            Altura (fórmula)
          </th>
          <th scope="col" class="px-3 py-3">
            Quantidade
          </th>
          <th scope="col" class="px-12 py-3">
            Observação
          </th>
          <th scope="col" class="px-3 py-3">
            Lado
          </th>
          <th scope="col" class="px-3 py-3">
            Furação
          </th>
          <th scope="col" class="px-3 py-3">
            Fase
          </th>
          <th scope="col" class="px-3 py-3">
            Aplicação
          </th>
          <th scope="col" class="px-3 py-3">
            Acréscimo (%)
          </th>
          <th scope="col" class="px-3 py-3">
            Acréscimo (R$)
          </th>

        </tr>
      </thead>
      <tbody>
      <tr v-for="(vidro, index) in vidros" :key="index" class="escopo-tabela text-left">
        <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
          {{ index + 1 }}
        </th>
        <td class="px-3 py-2 text-xs">
          <button>
            <i class="fa-solid fa-paperclip"></i>
          </button>
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.area_vidro.descricao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.condicao_formula }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.largura_formula }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.altura_formula }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.qtd_formula }}
        </td>
        <td class="px-12 py-2 text-xs whitespace-nowrap">
          {{ vidro.observacao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.lado }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.furacao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.fase_esquadria?.descricao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.aplicacao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.acrescimoReais }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ vidro.acrescimoPorcentagem }}
        </td>

      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'AlterarVidroOrcamento',

  data() {
    return {

    }
  },

  props: ['vidros']
}
</script>

<style scoped lang="scss">

</style>
