import {createRouter, createWebHistory} from 'vue-router'
import store from '../store'

import ListaDePessoas from "../views/cadastros_pessoa/ListaDePessoas.vue";
import Login from "../views/Login.vue";
import PessoaFisica from "@/views/cadastros_pessoa/PessoaFisica";
import PessoaJuridica from "@/views/cadastros_pessoa/PessoaJuridica";
import Dashboard from "@/views/Dashboard";
import Configuracoes from '@/views/configuracoes/Configuracoes.vue';
import ConfiguracoesSistemaAdmin from '@/views/dashboard_views/ConfiguracoesSistemaAdmin.vue';
import Relatorios from '@/views/relatorios/Relatorios';
import ListaPessoaFisica from "@/views/cadastros_pessoa/ListaPessoaFisica";
import ListaPessoaJuridica from "@/views/cadastros_pessoa/ListaPessoaJuridica";
import LandingPage from "@/views/LandingPage";
import AgendaView from "@/views/agenda/AgendaView";
import ListaTipoPessoa from "@/views/tipos/ListaTipoPessoa";
import PessoasDashboard from "@/components/dashboards_components/PessoasDashboard";
import ProdutosDashboard from "@/components/dashboards_components/ProdutosDashboard";
import ListaChapas from "@/views/listas_produtos/ListaChapas";
import CadastroChapa from "@/views/cadastros_produtos/CadastroChapa";
import ListaComponentes from "@/views/listas_produtos/ListaComponentes";
import CadastroComponenteAcessorio from "@/components/cadastros_produtos_componentes/CadastroComponenteAcessorio";
import ListaServico from "@/views/listas_produtos/ListaServico";
import CadastroServico from "@/components/cadastros_produtos_componentes/CadastroServico";
import ListaUsoComsumo from "@/views/listas_produtos/ListaUsoComsumo";
import CadastroUsoConsumo from "@/components/cadastros_produtos_componentes/CadastroUsoConsumo";
import ListaVidro from "@/views/listas_produtos/ListaVidro";
import CadastroVidro from "@/components/cadastros_produtos_componentes/CadastroVidro";
import ListaPerfil from "@/views/listas_produtos/ListaPerfil";
import CadastroPerfil from "@/components/cadastros_produtos_componentes/CadastroPerfil";
import TipoPessoa from "@/components/tipos_de_cadastros/cadastro_tipos/TipoPessoa";
import ListaTipoChapas from "@/views/tipos/ListaTipoChapas";
import TipoChapa from "@/components/tipos_de_cadastros/cadastro_tipos/TipoChapa";
import ListaTipoEmail from "@/views/tipos/ListaTipoEmail";
import TipoEmail from "@/components/tipos_de_cadastros/cadastro_tipos/TipoEmail";
import ListaTipoInteracao from "@/views/tipos/ListaTipoInteracao";
import TipoInteracao from "@/components/tipos_de_cadastros/cadastro_tipos/TipoInteracao";
import ListaTipoEndereco from "@/views/tipos/ListaTipoEndereco";
import TipoEndereco from "@/components/tipos_de_cadastros/cadastro_tipos/TipoEndereco";
import ListaTipoTelefone from "@/views/tipos/ListaTipoTelefone";
import TipoTelefone from "@/components/tipos_de_cadastros/cadastro_tipos/TipoTelefone";
import ListaTipoComponentes from "@/views/tipos/ListaTipoComponentes";
import TipoComponente from "@/components/tipos_de_cadastros/cadastro_tipos/TipoComponente";
import ListaTipoServicos from "@/views/tipos/ListaTipoServicos";
import TipoServico from "@/components/tipos_de_cadastros/cadastro_tipos/TipoServico";
import ListaTipoConsumo from "@/views/tipos/ListaTipoConsumo";
import TipoConsumo from "@/components/tipos_de_cadastros/cadastro_tipos/TipoConsumo";
import ListaTipoVidro from "@/views/tipos/ListaTipoVidro";
import TipoVidro from "@/components/tipos_de_cadastros/cadastro_tipos/TipoVidro";
import ListaTipoPerfis from "@/views/tipos/ListaTipoPerfis";
import TipoPerfis from "@/components/tipos_de_cadastros/cadastro_tipos/TipoPerfis";
import DashboardGed from "@/components/ged/DashboardGed";
import ListaImagem from "@/components/cadastros_ged/ListaImagem";
import ProjetosDashboard from "@/components/dashboards_components/ProjetosDashboard"
import ListaTipologia from "@/views/listas_projetos/ListaTipologia"
import CadastroTipologia from "@/components/cadastros_projetos_componentes/CadastroTipologia"
import DashboardGedView from "@/views/ged/DashboardGedView";
import CadastroImagem from "@/views/ged/CadastroImagem";
import ListaProjetos from "@/views/listas_projetos/ListaProjetos"
import CadastrosProjetos from "@/components/cadastros_projetos_componentes/CadastrosProjetos"
import ListaModelos from "@/views/listas_projetos/ListaModelos"
import CasdastroModelosVariacoes from "@/components/cadastros_projetos_componentes/CadastroModelosVariacoes"
import ListaProjetistaSistemista from "@/views/listas_projetos/ListaProjetistaSistemista";
import CadastroProjetistaSistemista from "@/components/cadastros_projetos_componentes/CadastroProjetistaSistemista"
import ListaLinhasSistemas from "@/views/listas_projetos/ListaLinhasSistemas";
import CadastroLinhasSistemas from "@/components/cadastros_projetos_componentes/CadastroLinhasSistemas";
import AlterarProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/AlterarProjeto";
import ListaCategorias from "@/components/ged/ListaCategorias";
import CategoriasCadastro from "@/components/ged/CategoriasCadastro";
import ListaSubcategorias from "@/components/ged/ListaSubcategorias";
import SubcategoriasCadastro from "@/components/ged/SubcategoriasCadastro";
import DashboardCadastroCores from "@/components/dashboards_components/DashboardCadastroCores";
import ListaCadastroCoresChapa from "@/components/lista_cadastro_cores/ListaCadastroCoresChapa";
import CadastroCoresChapa from "@/components/cadastro_cores/CadastroCoresChapa";
import ListaCadastroCoresComponentes from "@/components/lista_cadastro_cores/ListaCadastroCoresComponentes";
import CadastroCoresComponentes from "@/components/cadastro_cores/CadastroCoresComponentes";
import ListaCadastroUsoConsumo from "@/components/lista_cadastro_cores/ListaCadastroUsoConsumo";
import ListaCadastroPerfil from "@/components/lista_cadastro_cores/ListaCadastroPerfil";
import CadastroCoresPerfil from "@/components/cadastro_cores/CadastroCoresPerfil";
import CadastroCoresUsoConsumo from "@/components/cadastro_cores/CadastroCoresUsoConsumo";
import ListaCadastroVidro from "@/components/lista_cadastro_cores/ListaCadastroVidro";
import CadastroCoresVidro from "@/components/cadastro_cores/CadastroCoresVidro";
import DashboardCadastroGrupoAplicacao from "@/components/dashboards_components/DashboardCadastroGrupoAplicacao";
import ListaGrupoAplicacaoChapa from "@/components/lista_cadastro_grupo_aplicacao/ListaGrupoAplicacaoChapa";
import CadastroGrupoAplicacaoChapa from "@/components/cadastro_grupo_aplicacao/CadastroGrupoAplicacaoChapa";
import ListaGrupoAplicacaoComponente from "@/components/lista_cadastro_grupo_aplicacao/ListaGrupoAplicacaoComponente";
import CadastroGrupoAplicacaoComponente from "@/components/cadastro_grupo_aplicacao/CadastroGrupoAplicacaoComponente";
import ListaGrupoAplicacaoServico from "@/components/lista_cadastro_grupo_aplicacao/ListaGrupoAplicacaoServico";
import CadastroGrupoAplicacaoServico from "@/components/cadastro_grupo_aplicacao/CadastroGrupoAplicacaoServico";
import ListaGrupoAplicacaoUsoConsumo from "@/components/lista_cadastro_grupo_aplicacao/ListaGrupoAplicacaoUsoConsumo";
import CadastroGrupoAplicacaoUsoConsumo from "@/components/cadastro_grupo_aplicacao/CadastroGrupoAplicacaoUsoConsumo";
import ListaFornecedor from "@/views/cadastros_pessoa/ListaFornecedor";
import Fornecedor from "@/views/cadastros_pessoa/Fornecedor";
import ListaTipoFornecedor from "@/views/tipos/ListaTipoFornecedor";
import TipoFornecedor from "@/components/tipos_de_cadastros/cadastro_tipos/TipoFornecedor";
import ListaProdutoBase from "@/views/listas_produtos/ListaProdutoBase";
import CadastroProdutoBase from "@/components/cadastros_produtos_componentes/CadastroProdutoBase";
import ListaUnidadeMedida from "@/views/listas_produtos/ListaUnidadeMedida";
import UnidadeMedida from "@/components/cadastros_produtos_componentes/UnidadeMedida";
import ListaEspessura from "@/views/listas_produtos/ListaEspessura";
import CadastroEspessura from "@/components/cadastros_produtos_componentes/CadastroEspessura";
import ListaLinhaAplicacao from "@/views/listas_produtos/ListaLinhaAplicacao";
import CadastroLinhaAplicacao from "@/components/cadastros_produtos_componentes/CadastroLinhaAplicacao";
import ImportacaoPerfilPage from "@/components/importacoes/ImportacaoPerfilPage";
import EstoqueDashboard from "@/views/estoque/EstoqueDashboard";
import EntradaNotaFiscal from "@/views/estoque/ListaEntradaNotaFiscal";
import ListaEntradaNotaFiscal from "@/views/estoque/ListaEntradaNotaFiscal";
import EntradaForm from "@/views/estoque/EntradaForm";
import ImportacaoXmlEntrada from "@/components/importacao/xml_produtos/ImportacaoXmlEntrada";
import ListaEntradaSaidaEstoque from "@/components/entrada_saida/ListaEntradaSaidaEstoque";
import EntradaSaidaEstoque from "@/components/entrada_saida/EntradaSaidaEstoque";
import Inventario from "@/components/estoque/inventario/Inventario";
import ListaProdutoPorFornecedor from "@/views/listas_produtos/ListaProdutoPorFornecedor";
import CadastroProdutoPorFornecedor from "@/components/cadastros_produtos_componentes/CadastroProdutoPorFornecedor";
import ListaCoresPadraoSistema from "@/components/lista_cadastro_cores/ListaCoresPadraoSistema";
import CadastroCoresProdutoBase from "@/components/cadastro_cores/CadastroCoresProdutoBase";
import ListaTipoProdutoBase from "@/views/tipos/ListaTipoProdutoBase";
import ListaLinhaProdutoBase from "@/views/listas_produtos/ListaLinhaProdutoBase";
import CadastroLinhaProdutoBase from "@/components/cadastro_grupo_aplicacao/CadastroLinhaProdutoBase";
import TipoBase from "@/components/tipos_de_cadastros/cadastro_tipos/TipoBase";
import FichaFisicaFinanceira from "@/views/estoque/FichaFisicaFinanceira";
import ListaInventarios from "@/components/estoque/inventario/ListaInventarios";
import QuadroView from "@/views/kanban/QuadroView";
import ImporteProdutoBase from "@/components/importacao/produtos/ImporteProdutoBase";
import ReservaDeEstoque from "@/views/estoque/ReservaDeEstoque"
import AdicionarReservaEstoque from "@/components/estoque/AdicionarReservaEstoque"
import ListaProdutosReservados from "@/components/estoque/ListaProdutosReservados"
import OrcamentosDashboard from '@/components/dashboards_components/OrcamentosDashboard.vue';
import CadastroOrcamentoView from '@/views/cadastro_orcamentos/CadastroOrcamentoView.vue'
import AlterarOrcamento from '@/components/cadastros_orcamentos_componentes/alterar_orcamento/AlterarOrcamento.vue';
import ListaOrcamentosView from '@/views/lista_orcamentos/ListaOrcamentosView.vue'
import AlterarItemOrcamento from '@/components/cadastros_orcamentos_componentes/alterar_orcamento/AlterarItemOrcamento.vue'
import IncluirItemOrcamento from '@/components/cadastros_orcamentos_componentes/IncluirItemOrcamento.vue'
import SelecionarProjetoParaOrcamento from '@/components/cadastros_orcamentos_componentes/SelecionarProjetoParaOrcamento.vue';
import Precificacao from '@/views/configuracoes/Precificacao.vue';
import AlterarVidro from '@/components/configuracoes_vidro/AlterarVidro.vue';
import AlterarComponente from '@/components/configuracoes_componentes/AlterarComponente.vue';
import AlterarPerfil from '@/components/configuracoes_perfil/AlterarPerfil.vue';
import ListarObras from '@/views/obras/ListarObras.vue';
import CriarNovaObra from '@/views/obras/CriarNovaObra.vue';
import ListarVaos from '@/views/obras/ListarVaos.vue';
import CriarNovoVao from '@/views/obras/CriarNovoVao.vue';
import TipologiaVao from '@/views/obras/TipologiaVao.vue';
import AdicionarTipologia from '@/views/obras/AdicionarTipologia.vue';
import EditarVao from '@/views/obras/EditarVao.vue';
import EditarObra from '@/views/obras/EditarObra.vue';
import FinanceiroDashboard from '@/views/financeiro/FinanceiroDashboard.vue';
import ContasAReceber from '@/views/financeiro/ContasAReceber/ContasAReceber.vue';
import ContasAPagar from '@/views/financeiro/ContasAPagar/ContasAPagar.vue';
import NovaContaAReceber from '@/views/financeiro/ContasAReceber/NovaContaAReceber.vue';
import NovaContaAPagar from '@/views/financeiro/ContasAPagar/NovaContaAPagar.vue';
import ListaMetodosPagamento from '@/views/tipos/ListaMetodosPagamento.vue';
import TipoMetodoPagamento from '@/components/tipos_de_cadastros/cadastro_tipos/TipoMetodoPagamento.vue';
import ListaLocaisVao from '@/components/lista_cadastro_locais/lista_cadastro_local_vao/ListaLocaisVao.vue';
import CadastroLocalVao from '@/components/lista_cadastro_locais/lista_cadastro_local_vao/CadastroLocalVao.vue';
import ListaLocaisVidro from '@/components/lista_cadastro_locais/lista_cadastro_local_vidro/ListaLocaisVidro.vue';
import CadastroLocalVidro from '@/components/lista_cadastro_locais/lista_cadastro_local_vidro/CadastroLocalVidro.vue';
import ListaFasesEsquadria from '@/views/listas_projetos/ListaFasesEsquadria.vue';
import CadastroFasesEsquadria from '@/components/cadastros_projetos_componentes/CadastroFasesEsquadria.vue';
import CadastroPessoa from '@/views/cadastros_pessoa/CadastroPessoa.vue';
import ConfiguracoesRelacionamentoEmpresarial from '@/components/dashboards_configuracao_admin/ConfiguracoesRelacionamentoEmpresarial.vue';
import ConfiguracoesProduto from '@/components/dashboards_configuracao_admin/ConfiguracoesProduto.vue';
import ConfiguracoesObra from '@/components/dashboards_configuracao_admin/ConfiguracoesObra.vue';
import CadastroPerfilBase from '@/components/cadastros_produtos_componentes/CadastroPerfilBase.vue';
import ListaPerfilBase from '@/views/listas_produtos/ListaPerfilBase.vue';
import ListaPatentes from '@/views/listas_produtos/ListaPatentes.vue';
import CadastroPatentes from '@/components/cadastros_produtos_componentes/CadastroPatentes.vue';
import ListaMedidas from '@/views/listas_produtos/ListaMedidas.vue';
import CadastroMedidas from '@/components/cadastros_produtos_componentes/CadastroMedidas.vue';
import ListaComponenteBase from '@/views/listas_produtos/ListaComponenteBase.vue';
import CadastroComponenteBase from '@/components/cadastros_produtos_componentes/CadastroComponenteBase.vue';
import ListaVidroBase from '@/views/listas_produtos/ListaVidroBase.vue';
import CadastroVidroBase from '@/components/cadastros_produtos_componentes/CadastroVidroBase.vue';
import Administracao from '@/views/dashboard_views/Administracao.vue';
import ListaChapasBase from '@/views/listas_produtos/ListaChapasBase.vue';
import CadastroChapaBase from '@/components/cadastros_produtos_componentes/CadastroChapaBase.vue';
import ConfiguracoesProjeto from '@/components/dashboards_configuracao_admin/ConfiguracoesProjeto.vue';
import ControleGestor from '@/components/controle_gestor_projeto_componentes/ControleGestor.vue';
import IntegracaoGestor from '@/components/integracao_gestor_admin/IntegracaoGestor.vue';
import ConfiguracoesAdministracao from '@/components/dashboards_configuracao_admin/ConfiguracoesAdministracao.vue';
import ListaFuncoesColaboradores from '@/components/configuracoes_administracao/listagem/ListaFuncoesColaboradores.vue';
import CadastroFuncoesColaboradores from '@/components/configuracoes_administracao/cadastro/CadastroFuncoesColaboradores.vue';
import DashboardRH from '@/components/dashboards_configuracao_rh/DashboardRH.vue';
import ListaColaboradores from '@/components/dashboards_configuracao_rh/RH/listagem/ListaColaboradores.vue';

const routes = [
    {
        path: '/',
        name: 'Ladingpage',
        component: LandingPage,

    },
    // Rota para o dashboard
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiredLogin: true,
            description: 'INÍCIO',
        }
    },

    // Rota para o dashboard de administração
    {
        path: '/administracao',
        name: 'administracao',
        component: Administracao,
        meta: {
            requiredLogin: true,
            description: 'ADMINISTRAÇÃO',
        }
    },

    // Rota para o dashboard de RH
    {
        path: '/rh',
        name: 'administracao-rh',
        component: DashboardRH,
        meta: {
            requiredLogin: true,
            description: 'ADMINISTRAÇÃO RH',
        }
    },

    {
        path: '/rh/colaboradores',
        name: 'lista-colaboradores',
        component: ListaColaboradores,
        meta: {
            requiredLogin: true,
            description: 'LISTA DE COLABORADORES',
        }
    },

    // Rota para tipos de métodos de pagamento
    {
        path: '/metodos-pagamento',
        name: 'metodo-pagamento',
        component: ListaMetodosPagamento,
        meta: {
            requiredLogin: true,
            description: 'LISTA DE MÉTODOS DE PAGAMENTO',
        }
    },
    {
        path: '/metodos-pagamento/cadastro',
        name: 'cadastro-metodo-pagamento',
        component: TipoMetodoPagamento,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO DE MÉTODOS DE PAGAMENTO',
        }
    },
    // Rota para relatórios
    {
        path: '/relatorios',
        name: 'relatorios',
        component: Relatorios,
        meta: {
            requiredLogin: true,
            description: 'RELATÓRIOS',
        }
    },
    // Rota para as configurações do sistema ADMIN
    {
        path: '/configuracoes-sistema',
        name: 'configuracoes-sistema',
        component: ConfiguracoesSistemaAdmin,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/configuracoes-relacionamento',
        name: 'configuracoes-relacionamento',
        component: ConfiguracoesRelacionamentoEmpresarial,
        meta: {
            requiredLogin: true,
        }
    },
    {
        path: '/configuracoes-produto',
        name: 'configuracoes-produto',
        component: ConfiguracoesProduto,
        meta: {
            requiredLogin: true,
        }
    },
    {
        path: '/configuracoes-projeto',
        name: 'configuracoes-projeto',
        component: ConfiguracoesProjeto,
        meta: {
            requiredLogin: true,
        }
    },
    {
        path: '/configuracoes-obra',
        name: 'configuracoes-obra',
        component: ConfiguracoesObra,
        meta: {
            requiredLogin: true,
        }
    },
    {
        path: '/configuracoes-administracao',
        name: 'configuracoes-obra',
        component: ConfiguracoesAdministracao,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/integracao-gestor',
        name: 'integracao-gestor',
        component: IntegracaoGestor,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/funcao-colaborador',
        name: 'funcao-colaborador',
        component: ListaFuncoesColaboradores,
        meta: {
            requiredLogin: true,
        }
    },
    {
        path: '/funcao-colaborador/cadastro',
        name: 'cadastro-funcao-colaborador',
        component: CadastroFuncoesColaboradores,
        meta: {
            requiredLogin: true,
        }
    },
    // Rota para as configurações
    {
        path: '/configuracoes',
        name: 'configuracoes',
        component: Configuracoes,
        meta: {
            requiredLogin: true,
            description: 'CONFIGURACOES',
        }
    },
    // Rota para a precificação
    {
        path: '/precificacao',
        name: 'precificacao',
        component: Precificacao,
        meta: {
            requiredLogin: true,
            description: 'PRECIFICACAO',
        }
    },
    // Rota para a precificação de Vidros
    {
        path: '/precificacao/vidros',
        name: 'AlterarVidro',
        component: AlterarVidro,
        meta: {
            requiredLogin: true,
            // description: 'ALTERAR VIDRO'
        }
    },
    // Rota para a precificação de Componentes
    {
        path: '/precificacao/componentes',
        name: 'AlterarComponente',
        component: AlterarComponente,
        meta: {
            requiredLogin: true,
            // description: 'ALTERAR COMPONENTE'
        }
    },
    // Rota para a precificação de Perfis
    {
        path: '/precificacao/perfis',
        name: 'perfil',
        component: AlterarPerfil,
        meta: {
            requiredLogin: true,
            description: 'PERFIL',
        }
    },

    // Listagem de Obras
    {
        path: '/obras',
        name: 'ListaObras',
        component: ListarObras,
        meta: {
            requiredLogin: true,
            description: 'LISTAGEM DE OBRAS',
        }
    },

    // Criar uma nova Obra
    {
        path: '/criar-obra',
        name: 'CriarObra',
        component: CriarNovaObra,
        meta: {
            requiredLogin: true,
            description: 'CRIAR NOVA OBRA',
        }
    },

    // Editar Obra
    {
        path: '/editar-obra/:obra',
        name: 'EditarObra',
        component: EditarObra,
        meta: {
            requiredLogin: true,
            // description: 'EDITAR OBRA',
        }
    },

    // Mostrar vãos da obra
    {
        path: '/obra/:id',
        name: 'ListarVaos',
        component: ListarVaos,
        meta: {
            requiredLogin: true,
            // description: 'LISTAR VÃOS DA OBRA',
        }
    },

    // Criar um novo Vão
    {
        path: '/criar-vao/:obra',
        name: 'CriarVao',
        component: CriarNovoVao,
        meta: {
            requiredLogin: true,
            // description: 'CRIAR NOVO VÃO',
        }
    },

    // Editar Vão
    {
        path: '/editar-vao/:id_obra/:vao',
        name: 'EditarVao',
        component: EditarVao,
        meta: {
            requiredLogin: true,
            // description: 'EDITAR VÃO',
        }
    },

    // Tipologias para vão
    {
        path: '/vao/:id_obra/:id',
        name: 'TipologiaVao',
        component: TipologiaVao,
        meta: {
            requiredLogin: true,
            // description: 'LISTAR TIPOLOGIA NO VÃO',
        }
    },

    // Adicionar tipologias para vão
    {
        path: '/adicionar-tipologia/:id_obra/:id',
        name: 'AdicionarTipologia',
        component: AdicionarTipologia,
        meta: {
            requiredLogin: true,
            // description: 'ADICIONAR TIPOLOGIA NO VÃO',
        }
    },

    // Rota para listagem das pessoas
    {
        path: '/pessoa',
        name: 'pessoa',
        component: ListaDePessoas,
        meta: {
            requiredLogin: true,
            description: 'LISTA DE PESSOAS',
        }
    },

    {
        path: '/pessoa/cadastro',
        name: 'pessoa-cadastro',
        component: CadastroPessoa,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO DE PESSOAS',
        }
    },

    // Rota para o formulário de cadastro pessoa física
    {
        path: '/pessoa/pessoa-fisica',
        name: 'pessoa-fisica',
        component: PessoaFisica,
        meta: {
            requiredLogin: true,
        }
    },
    {
        path: '/pessoa-fisica/lista',
        name: 'pessoa-fisica-lista',
        component: ListaPessoaFisica,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO PESSOA FÍSICA',
        }
    },
    // Rota para o formulário de cadastro pessoa jurídica
    {
        path: '/pessoa/pessoa-juridica',
        name: 'pessoa-juridica',
        component: PessoaJuridica,
        meta: {
            requiredLogin: true
        }
    },
    {
        path: '/pessoa-juridica/lista',
        name: 'pessoa-juridica-lista',
        component: ListaPessoaJuridica,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO PESSOA JURIDICA',
        }
    },

    // fronecedor
    {
        path: '/fornecedor',
        name: 'lista-fornecedores',
        component: ListaFornecedor,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO FORNECEDOR',
        }
    },

    {
        path: '/cadastro-fornecedor',
        name: 'cadastro-fornecedores',
        component: Fornecedor,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/lista-tipo-fornecedor',
        name: 'lista-tipo-fornecedores',
        component: ListaTipoFornecedor,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO FORNECEDOR'
        }
    },

    {
        path: '/tipo-fornecedor/cadastro',
        name: 'cadastro-tipo-fornecedores',
        component: TipoFornecedor,
        meta: {
            requiredLogin: true,
        }
    },

    // Rota para o login
    {
        path: '/login',
        name: 'login',
        component: Login
    },

    {
        path: '/agenda',
        name: 'agenda',
        component: AgendaView,
        meta: {
            requiredLogin: true,
            description: 'AGENDA',
        }
    },

    {
        path: '/tipo-pessoa',
        name: 'tipo-pessoa',
        component: ListaTipoPessoa,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO PESSOA',
        }
    },

    {
        path: '/tipo-pessoa/cadastro',
        name: 'tipo-pessoa-cadastro',
        component: TipoPessoa,
        meta: {
            requiredLogin: true
        }
    },

    {
        path: '/pessoas',
        name: 'pessoas',
        component: PessoasDashboard,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO PESSOA',
        }
    },

    // tipos para pessoas
    {
        path: '/tipo-email',
        name: 'tipo-email',
        component: ListaTipoEmail,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO E-MAIL',
        }
    },
    {
        path: '/tipo-email/cadastro',
        name: 'tipo-email-cadastro',
        component: TipoEmail,
        meta: {
            requiredLogin: true,
        }
    },

    // tipos para pessoas
    {
        path: '/tipo-interacao',
        name: 'tipo-interacao',
        component: ListaTipoInteracao,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO INTERAÇÃO ONLINE',
        }
    },
    {
        path: '/tipo-Interacao/cadastro',
        name: 'tipo-Interacao-cadastro',
        component: TipoInteracao,
        meta: {
            requiredLogin: true,
        }
    },

    // tipos de endereco
    {
        path: '/tipo-endereco',
        name: 'tipo-endereco',
        component: ListaTipoEndereco,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO ENDEREÇO',
        }
    },
    {
        path: '/tipo-endereco/cadastro',
        name: 'tipo-endereco-cadastro',
        component: TipoEndereco,
        meta: {
            requiredLogin: true
        }
    },

    // tipos de telefone
    {
        path: '/tipo-telefone',
        name: 'tipo-telefone',
        component: ListaTipoTelefone,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO TELEFONE',
        }
    },
    {
        path: '/tipo-telefone/cadastro',
        name: 'tipo-telefone-cadastro',
        component: TipoTelefone,
        meta: {
            requiredLogin: true
        }
    },


    {
        path: '/produtos',
        name: 'produtos',
        component: ProdutosDashboard,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO PRODUTO',
        }
    },

    // produto base
    {
        path: '/produtos/produto-base',
        name: 'lista-produto-base',
        component: ListaProdutoBase,
        meta: {
            requiredLogin: true,
            description: 'PRODUTO BASE'
        }
    },

    {
        path: '/produto-base/cadastro',
        name: 'cadastro-produto-base',
        component: CadastroProdutoBase,
        meta: {
            requiredLogin: true,
        }
    },

    // Perfil Base
    {
        path: '/produtos/perfil-base',
        name: 'listagem-perfil-base',
        component: ListaPerfilBase,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/perfil-base/cadastro',
        name: 'cadastro-perfil-base',
        component: CadastroPerfilBase,
        meta: {
            requiredLogin: true,
        }
    },

    // Componente Base
    {
        path: '/produtos/componente-base',
        name: 'listagem-componente-base',
        component: ListaComponenteBase,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/componente-base/cadastro',
        name: 'cadastro-componente-base',
        component: CadastroComponenteBase,
        meta: {
            requiredLogin: true,
        }
    },
    
    // Vidro Base
    {
        path: '/produtos/vidro-base',
        name: 'listagem-vidro-base',
        component: ListaVidroBase,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/vidro-base/cadastro',
        name: 'cadastro-vidro-base',
        component: CadastroVidroBase,
        meta: {
            requiredLogin: true,
        }
    },

    // Chapa Base
    {
        path: '/produtos/chapa-base',
        name: 'listagem-chapa-base',
        component: ListaChapasBase,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/chapas-base/cadastro',
        name: 'cadastro-chapa-base',
        component: CadastroChapaBase,
        meta: {
            requiredLogin: true,
        }
    },

    // produto por forncedor
    {
      path: '/produtos/produto-por-fornecedor',
      name: 'lista-produto-por-fornecedor',
      component: ListaProdutoPorFornecedor,
      meta: {
          requiredLogin: true,
          description: 'PRODUTO POR FORNECEDOR'
      }
    },

    {
      path: '/produto-por-fornecedor/cadastro',
      name: 'cadastro-produto-por-fornecedor',
      component: CadastroProdutoPorFornecedor,
      meta: {
          requiredLogin: true
      }
    },

    // unidade medida
    {
        path: '/produtos/unidade-medida',
        name: 'lista-unidade-medida',
        component: ListaUnidadeMedida,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO UNIDADES DE MEDIDA'
        }
    },

    {
        path: '/unidade-medida-cadastro',
        name: 'cadastro-unidade-medida',
        component: UnidadeMedida,
        meta: {
            requiredLogin: true,
        }
    },

    // patente
    {
        path: '/produtos/patente',
        name: 'lista-patente',
        component: ListaPatentes,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO PATENTES'
        }
    },

    {
        path: '/patente-cadastro',
        name: 'cadastro-patente',
        component: CadastroPatentes,
        meta: {
            requiredLogin: true,
        }
    },

    // medidas
    {
        path: '/produtos/medidas',
        name: 'lista-medidas',
        component: ListaMedidas,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO MEDIDAS'
        }
    },

    {
        path: '/medidas-cadastro',
        name: 'cadastro-medida',
        component: CadastroMedidas,
        meta: {
            requiredLogin: true,
        }
    },
    // espessuras
    {
        path: '/produtos/espessuras',
        name: 'lista-espessuras',
        component: ListaEspessura,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO ESPESSURAS'
        }
    },

    {
        path: '/espessura-cadastro',
        name: 'cadastro-espessura',
        component: CadastroEspessura,
        meta: {
            requiredLogin: true,
        }
    },
    // linha aplicacao
    {
        path: '/produtos/linha-aplicacao',
        name: 'lista-linha-aplicacao',
        component: ListaLinhaAplicacao,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO LINHAS APLICAÇÃO'
        }
    },

    {
        path: '/linha-aplicacao-cadastro',
        name: 'cadastro-linha-aplicacao',
        component: CadastroLinhaAplicacao,
        meta: {
            requiredLogin: true,
        }
    },
    // chapa
    {
        path: '/produtos/chapas',
        name: 'produtos-chapas',
        component: ListaChapas,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO CHAPA',
        }
    },

    {
        path: '/chapa/cadastro',
        name: 'chapas-cadastro',
        component: CadastroChapa,
        meta: {
            requiredLogin: true
        }
    },

    // tipo chapa
    {
        path: '/tipo-chapa',
        name: 'tipo-chapa',
        component: ListaTipoChapas,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO CHAPA',
        }
    },
    {
        path: '/tipo-chapa/cadastro',
        name: 'tipo-chapa-cadastro',
        component: TipoChapa,
        meta: {
            requiredLogin: true
        }
    },

    // componente-acessorio
    {
        path: '/produtos/componente-acessorio',
        name: 'componente-acessorio',
        component: ListaComponentes,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO COMPONENTE/ACESSÓRIO',
        }
    },

    {
        path: '/componente-acessorio/cadastro',
        name: 'componente-acessorio-cadastro',
        component: CadastroComponenteAcessorio,
        meta: {
            requiredLogin: true
        }
    },
    // tipo componente
    {
        path: '/tipo-componente',
        name: 'tipo-componente',
        component: ListaTipoComponentes,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO COMPONENTE/ACESSÓRIO',
        }
    },
    {
        path: '/tipo-componente/cadastro',
        name: 'tipo-componente-cadastro',
        component: TipoComponente,
        meta: {
            requiredLogin: true
        }
    },

    // servicos
    {
        path: '/produtos/servico',
        name: 'servico',
        component: ListaServico,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO SERVIÇO',
        }
    },

    {
        path: '/servico/cadastro',
        name: 'servico-cadastro',
        component: CadastroServico,
        meta: {
            requiredLogin: true
        }
    },
    // tipo servico
    {
        path: '/tipo-servico',
        name: 'tipo-servico',
        component: ListaTipoServicos,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO SERVIÇO',
        }
    },
    {
        path: '/tipo-servico/cadastro',
        name: 'tipo-servico-cadastro',
        component: TipoServico,
        meta: {
            requiredLogin: true
        }
    },

    // uso consumo
    {
        path: '/produtos/uso-consumo',
        name: 'uso-cunsumo',
        component: ListaUsoComsumo,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO USO CONSUMO',
        }
    },

    {
        path: '/uso-cunsumo/cadastro',
        name: 'uso-cunsumo-cadastro',
        component: CadastroUsoConsumo,
        meta: {
            requiredLogin: true
        }
    },
    // tipo uso consumo
    {
        path: '/tipo-uso-consumo',
        name: 'tipo-consumo',
        component: ListaTipoConsumo,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO USO CONSUMO',
        }
    },
    {
        path: '/tipo-uso-consumo/cadastro',
        name: 'tipo-consumo-cadastro',
        component: TipoConsumo,
        meta: {
            requiredLogin: true
        }
    },

    // vidro
    {
        path: '/produtos/vidro',
        name: 'vidro',
        component: ListaVidro,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO VIDRO',
        }
    },

    {
        path: '/vidro/cadastro',
        name: 'vidro-cadastro',
        component: CadastroVidro,
        meta: {
            requiredLogin: true
        }
    },
    // tipo vidro
    {
        path: '/tipo-vidro',
        name: 'tipo-vidro',
        component: ListaTipoVidro,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO VIDRO',
        }
    },
    {
        path: '/tipo-vidro/cadastro',
        name: 'tipo-vidro-cadastro',
        component: TipoVidro,
        meta: {
            requiredLogin: true
        }
    },


    // perfil
    {
        path: '/produtos/perfil',
        name: 'produtos-perfil',
        component: ListaPerfil,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO PERFIL',
        }
    },

    {
        path: '/perfil/cadastro',
        name: 'perfil-cadastro',
        component: CadastroPerfil,
        meta: {
            requiredLogin: true
        }
    },
    // tipo perfil
    {
        path: '/tipo-perfil',
        name: 'tipo-perfil',
        component: ListaTipoPerfis,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPO PERFIL',
        }
    },
    {
        path: '/tipo-perfil/cadastro',
        name: 'tipo-perfil-cadastro',
        component: TipoPerfis,
        meta: {
            requiredLogin: true
        }
    },

    // Rotas GED
    {
        path: '/ged',
        name: 'dashboard-ged',
        component: DashboardGedView,
        meta: {
            requiredLogin: true,
            description: 'DOCUMENTOS',
        }
    },
    {
        path: '/ged/lista-imagens',
        name: 'ged-lista-imagem',
        component: ListaImagem,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO FOTO DOCUMENTO',
        }
    },
    {
        path: '/imagem/cadastro',
        name: 'ged-cadastro-imagem',
        component: CadastroImagem,
        meta: {
            requiredLogin: true
        }
    },

    {
        path: '/ged/categoria',
        name: 'ged-lista-categoria',
        component: ListaCategorias,
        meta: {
            requiredLogin: true,
            description: 'CATEGORIAS GED'
        }
    },

    {
        path: '/ged/cadastro-categoria',
        name: 'ged-cadastro-categoria',
        component: CategoriasCadastro,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/ged/subcategoria',
        name: 'ged-lista-subcategoria',
        component: ListaSubcategorias,
        meta: {
            requiredLogin: true,
            description: 'SUBCATEGORIA GED'
        }
    },

    {
        path: '/ged/cadastro-subcategoria',
        name: 'ged-cadastro-subcategoria',
        component: SubcategoriasCadastro,
        meta: {
            requiredLogin: true,
        }
    },

    //urls para orçamento

    {
      path: '/orcamentos',
      name: 'dashboard-orcamentos',
      component: OrcamentosDashboard,
      meta: {
        requiredLogin: true,
        description: 'ORÇAMENTOS',
      }
    },

    {
      path: '/lista-orcamentos',
      name: 'lista-orcamentos',
      component: ListaOrcamentosView,
      meta: {
        requiredLogin: true,
        description: 'LISTA ORCAMENTOS',
      }
    },

    {
      path: '/orcamentos/cadastro',
      name: 'cadastro-orcamentos',
      component: CadastroOrcamentoView,
      meta: {
        requiredLogin: true,
        description: 'CADASTRO ORÇAMENTOS',
      }
    },

    {
      path: '/orcamento/:id',
      name: 'alterar-orcamento',
      component: AlterarOrcamento,
      params: true,
      meta: {
        requiredLogin: true,
        // description: 'ALTERAR ORÇAMENTOS',
      }
    },

    {
      path: '/orcamento/selecionar-projeto/',
      name: 'selecionar-projeto-orcamento',
      component: SelecionarProjetoParaOrcamento,
      meta: {
        requiredLogin: true,
        description: 'SELECIONAR PROJETO ORCAMENTO',
      }
    },

    {
      path: '/item-orcamento/:id',
      name: 'item-orcamento',
      component: AlterarItemOrcamento,
      params: true,
      meta: {
        requiredLogin: true,
        // description: 'ITEM ORÇAMENTO',
      }
    },

    {
      path: '/incluir-item-orcamento/',
      name: 'incluir-item-orcamento',
      component: IncluirItemOrcamento,
      meta: {
        requiredLogin: true,
        description: 'INCLUIR ITEM ORÇAMENTO',
      }
    },

    // urls para projeto
    {
        path: '/projetos',
        name: 'dashboard-projetos',
        component: ProjetosDashboard,
        meta: {
            requiredLogin: true,
            description: 'PROJETOS',
        }
    },
    {
        path: '/lista-projetos',
        name: 'lista-projetos',
        component: ListaProjetos,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO PROJETOS',
        }
    },
    {
        path: '/projeto/cadastro',
        name: 'cadastro-projetos',
        component: CadastrosProjetos,
        meta: {
            requiredLogin: true,
            description: 'LISTA DE PROJETOS',
        }
    },
    {
        path: '/projeto/controle-gestor',
        name: 'controle-gestor-projeto',
        component: ControleGestor,
        meta: {
            requiredLogin: true,
            description: 'CONTROLE DO GESTOR DE PROJETOS',
        }
    },
    {
        path: '/alterar-projeto/:id',
        name: 'alterar-projeto',
        component: AlterarProjeto,
        meta: {
            requiredLogin: true
        }
    },
    {
        path: '/lista-modelo-variacao',
        name: 'lista-modelo-variacao',
        component: ListaModelos,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO MODELO/VARIAÇÃO',
        }
    },
    {
        path: '/modelo-variacao/cadastro',
        name: 'modelo-variacao-cadastro',
        component: CasdastroModelosVariacoes,
        meta: {
            requiredLogin: true,
            description: '',
        }
    },

    {
        path: '/lista-tipologia',
        name: 'lista-tipologia',
        component: ListaTipologia,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO TIPOLOGIA',
        }
    },

    {
        path: '/tipologia/cadastro',
        name: 'cadastro-tipologia',
        component: CadastroTipologia,
        meta: {
            requiredLogin: true
        }
    },

    {
        path: '/lista-projetista-sistemista',
        name: 'lista-projetista-sistemista',
        component: ListaProjetistaSistemista,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO PROJETISTA SISTEMISTA'
        }
    },

    {
        path: '/projetista-sistemista/cadastro',
        name: 'projetista-sistemista-cadastro',
        component: CadastroProjetistaSistemista,
        meta: {
            requiredLogin: true
        }
    },

    {
        path: '/linha-sistema',
        name: 'lista-linhas-sistemas',
        component: ListaLinhasSistemas,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO LINHAS SISTEMAS'
        }
    },

    {
        path: '/linha-sistema/cadastro',
        name: 'cadastro-linhas-sistemas',
        component: CadastroLinhasSistemas,
        meta: {
            requiredLogin: true
        }
    },

    {
        path: '/fase-esquadria',
        name: 'lista-fases-esquadria',
        component: ListaFasesEsquadria,
        meta: {
            requiredLogin: true,
            description: 'LISTA DE FASES DA ESQUADRIA'
        }
    },

    {
        path: '/fase-esquadria/cadastro',
        name: 'cadastro-fases-esquadria',
        component: CadastroFasesEsquadria,
        meta: {
            requiredLogin: true
        }
    },

    // cores
    {
        path: '/lista-cores-base',
        name: 'lista-cores-base',
        component: ListaCoresPadraoSistema,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO CORES BASE'
        }
    },
    {
        path: '/cadastro-cores-padrao',
        name: 'cadastro-cores-padrao',
        component: CadastroCoresProdutoBase,
        meta: {
            requiredLogin: true,
        }
    },
    {
        path: '/cores',
        name: 'dashboard-cadastro-cores',
        component: DashboardCadastroCores,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO CORES'
        }
    },

    {
        path: '/lista-cores-chapas',
        name: 'cadastro-cores-chapas',
        component: ListaCadastroCoresChapa,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO CORES CHAPAS'
        }
    },

    {
        path: '/cadastro-cores-chapas',
        name: 'chapas-cores-cadastro',
        component: CadastroCoresChapa,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/lista-cores-componentes',
        name: 'cadastro-cores-componentes',
        component: ListaCadastroCoresComponentes,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO CORES COMPONENTES'
        }
    },

    {
        path: '/cadastro-cores-componentes',
        name: 'componentes-cores-cadastro',
        component: CadastroCoresComponentes,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/lista-cores-uso-consumo',
        name: 'cadastro-cores-uso-consumo',
        component: ListaCadastroUsoConsumo,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO CORES USO CONSUMO'
        }
    },

    {
        path: '/cadastro-cores-uso-consumo',
        name: 'uso-consumo-cores-cadastro',
        component: CadastroCoresUsoConsumo,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/lista-cores-perfil',
        name: 'cadastro-cores-perfil',
        component: ListaCadastroPerfil,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO CORES PERFIL'
        }
    },

    {
        path: '/cadastro-cores-perfil',
        name: 'perfil-cores-cadastro',
        component: CadastroCoresPerfil,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/lista-cores-vidro',
        name: 'cadastro-cores-vidro',
        component: ListaCadastroVidro,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO CORES VIDRO'
        }
    },

    {
        path: '/cadastro-cores-vidro',
        name: 'vidro-cores-cadastro',
        component: CadastroCoresVidro,
        meta: {
            requiredLogin: true,
        }
    },

    // grupo aplicacao
    {
        path: '/grupo-aplicacao',
        name: 'dashboard-cadastro-grupo-aplicacao',
        component: DashboardCadastroGrupoAplicacao,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO GRUPO APLICACAO'
        }
    },

    {
        path: '/lista-grupo-aplicacao-chapa',
        name: 'cadastro-grupo-aplicacao-chapa',
        component: ListaGrupoAplicacaoChapa,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO GRUPO APLICACAO CHAPA'
        }
    },

    {
        path: '/cadastro-grupo-aplicacao-chapa',
        name: 'chapa-grupo-aplicacao-cadastro',
        component: CadastroGrupoAplicacaoChapa,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/lista-grupo-aplicacao-componente',
        name: 'cadastro-grupo-aplicacao-componente',
        component: ListaGrupoAplicacaoComponente,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO GRUPO APLICACAO COMPONENTE'
        }
    },

    {
        path: '/cadastro-grupo-aplicacao-componente',
        name: 'componente-grupo-aplicacao-cadastro',
        component: CadastroGrupoAplicacaoComponente,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/lista-grupo-aplicacao-servico',
        name: 'cadastro-grupo-aplicacao-servico',
        component: ListaGrupoAplicacaoServico,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO GRUPO APLICACAO SERVICO'
        }
    },

    {
        path: '/cadastro-grupo-aplicacao-servico',
        name: 'servico-grupo-aplicacao-cadastro',
        component: CadastroGrupoAplicacaoServico,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/lista-grupo-aplicacao-uso-consumo',
        name: 'uso-consumo-grupo-aplicacao-servico',
        component: ListaGrupoAplicacaoUsoConsumo,
        meta: {
            requiredLogin: true,
            description: 'CADASTRO GRUPO APLICACAO USO CONSUMO'
        }
    },

    {
        path: '/cadastro-grupo-aplicacao-uso-consumo',
        name: 'uso-consumo-grupo-aplicacao-cadastro',
        component: CadastroGrupoAplicacaoUsoConsumo,
        meta: {
            requiredLogin: true,
        }
    },


    {
        path: '/estoque',
        name: 'estoque',
        component: EstoqueDashboard,
        meta: {
            requiredLogin: true,
            description: 'ESTOQUE'
        }
    },
    {
        path: '/estoque/lista-entrada-nota-fiscal',
        name: 'lista-entrada-nota-fiscal',
        component: ListaEntradaNotaFiscal,
        meta: {
            requiredLogin: true,
            description: 'ENTRADA NOTA FISCAL'
        }
    },

    {
        path: '/estoque/entrada-nota-fiscal',
        name: 'entrada-nota-fiscal',
        component: ImportacaoXmlEntrada,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/importacao-perfil',
        name: 'importacao-perfil',
        component: ImportacaoPerfilPage,
        meta: {
            requiredLogin: true,
            description: 'IMPORTAÇÃO PERFIL'
        }
    },

    {
        path: '/estoque/entrada-saida',
        name: 'entrada-saida-lista',
        component: ListaEntradaSaidaEstoque,
        meta: {
            requiredLogin: true,
            description: 'ENTRADA SAIDA ESTOQUE'
        }
    },

    {
        path: '/estoque/entrada-saida-estoque',
        name: 'entrada-saida-estoque',
        component: EntradaSaidaEstoque,
        meta: {
            requiredLogin: true
        }
    },

    {
        path: '/estoque/pre-inventario',
        name: 'estoque-pre-inventario',
        component: Inventario,
        meta: {
            requiredLogin: true
        }
    },

    {
        path: '/estoque/inventarios',
        name: 'estoque-inventarios',
        component: ListaInventarios,
        meta: {
            requiredLogin: true,
            description: 'INVENTARIO'
        }
    },

    {
      path: '/estoque/reservas',
      name: 'reservas-de-estoque',
      component: ReservaDeEstoque,
      meta: {
        requiredLogin: true,
        description: 'RESERVAS DE ESTOQUE'
      }
    },

    {
      path: '/estoque/reservas/adicionar',
      name: 'adicionar-reserva',
      component: AdicionarReservaEstoque,
      meta: {
        requiredLogin: true,
        description: 'ADICIONAR RESERVA DE ESTOQUE'
      }

    },

    {
      path: '/estoque/reservas/:id',
      name: 'reserva',
      component: ListaProdutosReservados,
      params: true,
      meta: {
        requiredLogin: true,
        // description: 'TESTE RESERVA'
      }
    },

    {
        path: '/tipo-produto-base',
        name: 'lista-tipo-produto-base',
        component: ListaTipoProdutoBase,
        meta: {
            requiredLogin: true,
            description: 'TIPO PRODUTO BASE'
        }
    },

    {
        path: '/produtos/linha-produto-base',
        name: 'lista-linha-produto-base',
        component: ListaLinhaProdutoBase,
        meta: {
            requiredLogin: true,
            description: 'LINHA PRODUTO BASE'
        }
    },

    {
        path: '/linha-base/cadastro',
        name: 'linha-base-cadastro',
        component: CadastroLinhaProdutoBase,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/tipo-produto-base/cadastro',
        name: 'tipo-base-cadastro',
        component: TipoBase,
        meta: {
            requiredLogin: true,
        }
    },

    {
        path: '/estoque/ficha-fisica-financeira',
        name: 'ficha-fisica-financeira',
        component: FichaFisicaFinanceira,
        meta: {
            requiredLogin: true,
            description: 'FICHA FISICA FINANCEIRA'
        }
    },


    // Rotas de Local do Vão
    {
        path: '/local-vao',
        name: 'local-vao',
        component: ListaLocaisVao,
        meta: {
            requiredLogin: true,
            description: 'LOCAIS DE VÃO'
        }
    },

    {
        path: '/local-vao/cadastro',
        name: 'local-vao-cadastro',
        component: CadastroLocalVao,
        meta: {
            requiredLogin: true,
            description: 'CADASTRAR LOCAL DE VÃO'
        }
    },

    // Rotas de Local do Vidro
    {
        path: '/local-vidro',
        name: 'local-vidro',
        component: ListaLocaisVidro,
        meta: {
            requiredLogin: true,
            description: 'LOCAIS DE VIDRO'
        }
    },
    
    {
        path: '/local-vidro/cadastro',
        name: 'local-vidro-cadastro',
        component: CadastroLocalVidro,
        meta: {
            requiredLogin: true,
            description: 'CADASTRAR LOCAL DE VIDRO'
        }
    },

    // Rotas de Vendas
    {
        path: '/financeiro',
        name: 'financeiro-dashboard',
        component: FinanceiroDashboard,
        meta: {
            requiredLogin: true,
            description: 'VENDAS'
        }
    },

    // Rotas de contas a receber
    {
        path: '/contas-a-receber',
        name: 'contas-a-receber',
        component: ContasAReceber,
        meta: {
            requiredLogin: true,
            description: 'CONTAS A RECEBER'
        }
    },

    {
        path: '/criar-conta-receber',
        name: 'criar-conta-receber',
        component: NovaContaAReceber,
        meta: {
            requiredLogin: true,
            description: 'CRIAR CONTA A RECEBER'
        }
    },

    // Rotas de contas a pagar
    {
        path: '/contas-a-pagar',
        name: 'contas-a-pagar',
        component: ContasAPagar,
        meta: {
            requiredLogin: true,
            description: 'CONTAS A PAGAR'
        }
    },

    {
        path: '/criar-conta-pagar',
        name: 'criar-conta-pagar',
        component: NovaContaAPagar,
        meta: {
            requiredLogin: true,
            description: 'CRIAR CONTA A PAGAR'
        }
    },

    // kanban
    {
        path: '/kanban',
        name: 'quadro-kanban',
        component: QuadroView,
        meta: {
            requiredLogin: true,
            description: 'KANBAN'
        }
    },

    // importe de produtos
    {
        path: '/produto-base/importacao-base',
        name: 'importacao-produto-base',
        component: ImporteProdutoBase,
        meta: {
            requiredLogin: true,
            description: 'IMPORTE PRODUTO BASE'
        }
    }


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const currentUrl = window.location.hostname + window.location.pathname;
    if (currentUrl === 'www.managerglass.com.br') {
        next('/');
    } else if (to.matched.some(record => record.meta.requiredLogin) && !store.state.isAuthenticated) {
        next('/login');
    } else {
        next();
    }
});

export default router