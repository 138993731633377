<template>
  <div class="container-formulario">

    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Orçamentos</h1>

      <router-link to="/orcamentos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <div class="block">

      <div class="mb-4">
        <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
          <div class="px-2 w-1/2">
            <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
              <option value="cliente">Cliente</option>
              <option value="vendedor">Vendedor</option>
              <option value="status">Status</option>
            </select>
          </div>

          <div class="w-full">

            <v-select v-if="filtro.campo === 'cliente'" v-model="filtro.valor" :options="lista_clientes"
              label="nome_razao" :reduce="cliente => cliente.id" placeholder="Escolha uma opção" />

            <v-select v-else-if="filtro.campo === 'vendedor'" v-model="filtro.valor" :options="lista_vendedores"
              label="nome_razao" :reduce="cliente => cliente.id" placeholder="Escolha uma opção" />

            <v-select v-else-if="filtro.campo === 'status'" v-model="filtro.valor" :options="lista_status"
              placeholder="Escolha uma opção" />

            <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Digite o desejado" />
          </div>

          <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
        </div>

        <div class="flex w-full justify-center">
          <button class="text-ativo" @click.prevent="adicionarFiltro">
            <i class="fas fa-plus-circle"></i>
          </button>
        </div>
      </div>

    </div>

    <div class="flex">

      <div class="flex flex-wrap gap-2 mb-2">

        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
      text-white hover:text-corBase py-2 px-4" @click.prevent="mudarPagina(1)">Pesquisar
        </button>

        <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
        text-white hover:text-ativo py-2 px-4" @click.prevent="$router.push(`orcamentos/cadastro`)">Adicionar
        </button>

      </div>
    </div>


    <div class="container-modelo-tabela">
      <table class="container-tabela">
        <thead class="cabecalho-tabela">
          <tr class="border-b">
            <th class="px-2 py-3">
              #
            </th>
            <th class="px-2 py-3 text-center">
              Ações
            </th>
            <th class="px-6 py-3 text-center">
              Status
            </th>
            <th class="px-12 py-3 text-center">
              Nº Orçamento
            </th>
            <th class="px-6 py-3 text-center">
              Valor ($)
            </th>
            <th class="px-6 py-3 text-center">
              Pessoa
            </th>
            <th class="px-6 py-3 text-center">
              Cliente
            </th>
            <th class="px-6 py-3 text-center">
              Obra
            </th>
            <th class="px-6 py-3 text-center">
              Referência
            </th>
            <th class="px-6 py-3 text-center">
              Stakeholders
            </th>
            <th class="px-6 py-3 text-center">
              Endereço(s)
            </th>
            <th class="px-6 py-3 text-center">
              Telefone(s)
            </th>
            <th class="px-10 py-3 text-center">
              Email(s)
            </th>
            <th class="px-6 py-3 text-center">
              Vendedor
            </th>
            <th class="px-6 py-3 text-center">
              Data Cadastro
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(orcamento, index) in orcamentos" :key="index" class="escopo-tabela">
            <td class="px-2 py-3 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </td>
            <td class="px-2 py-3 text-xs text-center">
              <div class="flex justify-center items-center gap-2">
                <i class="fa-solid fa-paperclip cursor-pointer"
                  @click.prevent="$router.push(`/orcamento/${orcamento.id}`)" title="Alterar Orçamento">
                </i>

                <i class="fa-solid fa-arrows-rotate cursor-pointer" title="Alterar Status">
                </i>

                <i class="fa-solid fa-print cursor-pointer" title="Imprimir Orçamento">
                </i>
              </div>
            </td>
            <td class="px-6 py-3 text-xs text-center">
              {{ orcamento.status || '-' }}
            </td>
            <td class="py-3 text-xs text-center text-gray-900">
              {{ orcamento.codigo || '-' }}
            </td>
            <td class="px-6 py-3 text-xs text-center">
              {{ orcamento.valor || '-val' }}
            </td>
            <td class="px-6 py-3 text-center">
              <div class="flex justify-center items-center">
                <i v-if="orcamento.cliente?.identificacao && orcamento.cliente.identificacao.length > 14" class="fa-solid fa-building"></i>
                <i v-else class="fa-solid fa-person"></i>
              </div>
            </td>
            <td class="px-6 py-3 text-xs text-center whitespace-nowrap">
              {{ orcamento.cliente?.nome_razao ? orcamento.cliente.nome_razao : '-' }}
            </td>
            <td class="px-6 py-3 text-xs text-center">
              {{ orcamento.obra?.descricao || '-' }}
            </td>
            <td class="px-6 py-3 text-xs text-center">
              {{ orcamento.referencia || '-' }}
            </td>
            <td class="px-6 py-3 text-center">
              <p v-if="orcamento.obra" @click.prevent="listarStakeholders(orcamento.obra)" class="cursor-pointer text-blue-500">Ver Lista</p>
              <p v-else>-</p>
            </td>
            <td class="px-6 py-3 text-center">
              <p @click.prevent="listarEnderecos(orcamento)" class="cursor-pointer text-blue-500">Ver lista</p>
            </td>
            <td class="px-6 py-3 text-center">
              <p @click.prevent="listarTelefones(orcamento.cliente?.id || '')" class="cursor-pointer text-blue-500">Ver lista</p>
            </td>
            <td class="px-8 py-3 text-center">
              <p @click.prevent="listarEmails(orcamento.cliente?.id || '')" class="cursor-pointer text-blue-500">Ver lista</p>
            </td>
            <td class="px-6 py-3 text-xs text-center">
              {{ orcamento.vendedor?.nome_razao ? orcamento.vendedor.nome_razao : '-' }}
            </td>
            <td class="px-6 py-3 text-xs text-center">
              {{ formataData(orcamento.criado_em) }}
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination v-if="orcamentos.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

  <ListarStakeholders v-if="mostrarStakeholders" :obraSelecionada="obraSelecionada" @fecharModal="toggleStakeholders" />
  <ListarTodosEnderecos v-if="mostrarEnderecos" :orcamentoSelecionado="orcamentoSelecionado" @fecharModal="toggleEnderecos()" />
  <ListarTelefones v-if="mostrarTelefones" :pessoa="pessoaSelecionada" @fecharModal="toggleTelefones()" />
  <ListarEmails v-if="mostrarEmails" :pessoa="pessoaSelecionada" @fecharModal="toggleEmails()" />
</template>

<script>
import axios from 'axios'

import ListarStakeholders from './modais/ListarStakeholders.vue';
import ListarTodosEnderecos from './modais/ListarTodosEnderecos.vue';
import ListarTelefones from '@/views/cadastros_pessoa/ListarTelefones.vue';
import ListarEmails from '@/views/cadastros_pessoa/ListarEmails.vue';

import Pagination from '@/utils/Pagination.vue';
import vSelect from 'vue-select';
import formatarData from '@/utils/FormatarData';

export default {
  name: 'ListaOrcamento',

  components: {
    Pagination,
    ListarStakeholders,
    ListarTodosEnderecos,
    ListarTelefones,
    ListarEmails,
    vSelect,
    formatarData
  },

  data() {
    return {
      orcamentos: [],

      filtros: [{ campo: "cliente", valor: "" }],
      lista_clientes: [],
      lista_vendedores: [],
      lista_status: ['Orçamento', 'Agendado', 'Venda', 'Faturado', 'Cancelado'],

      pessoaSelecionada: [],
      orcamentoSelecionado: [],
      obraSelecionada: [],

      mostrarStakeholders: false,
      mostrarEnderecos: false,
      mostrarTelefones: false,
      mostrarEmails: false,

      paginaAtual: 1,
      countItens: '',
    }
  },

  created() {
    // this.getOrcamentos(1)
    this.getListaClientes()
    this.getListaVendedores()
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },
    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    async getListaClientes() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`pessoa/pessoas-fisica-juridica/?&atividade=Cliente&ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.lista_clientes = response.data.results.map((cliente) => {
          return {
            id: cliente.pessoa.id,
            nome_razao: cliente.nome || cliente.razao_social
          }
        })
      } catch (error) {
        console.error(error)
      }
    },

    async getListaVendedores() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`pessoa/pessoas-fisica-juridica/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.lista_vendedores = response.data.results.map((vendedor) => {
          return {
            id: vendedor.pessoa.id,
            nome_razao: vendedor.nome || vendedor.razao_social
          }
        })
      } catch (error) {
        console.error(error)
      }
    },

    async getOrcamentos(pagina) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        this.$store.dispatch("setLoading", true);

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        const response = await axios.get(`orcamentos/?${params}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.orcamentos = response.data.results
        this.countItens = response.data.count
        console.log(this.orcamentos)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getOrcamentos(pagina);
    },

    listarStakeholders(obra) {
      this.obraSelecionada = obra
      this.toggleStakeholders()
    },

    toggleStakeholders() {
      this.mostrarStakeholders = !this.mostrarStakeholders
    },

    listarEnderecos(orcamento) {
      this.orcamentoSelecionado = orcamento
      this.toggleEnderecos()
    },

    toggleEnderecos() {
      this.mostrarEnderecos = !this.mostrarEnderecos
    },

    listarTelefones(pessoaId) {
      this.pessoaSelecionada = pessoaId
      this.toggleTelefones()
    },

    toggleTelefones() {
      this.mostrarTelefones = !this.mostrarTelefones
    },

    listarEmails(pessoaId) {
      this.pessoaSelecionada = pessoaId
      this.toggleEmails()
    },

    toggleEmails() {
      this.mostrarEmails = !this.mostrarEmails
    },

    formataData(data) {
      return formatarData(data)
    }
  }
}
</script>

<style scoped lang="scss"></style>