<template>
    <div>
        <div class="container-modal-editar">
            <div class="sombra-modal">
                <div class="conteudo-modal-editar">
                    <form class="form-editar text-white">
                        <div class="flex justify-end">
                            <button @click.prevent="$emit('fecharModal')">
                                <i class="fas fa-close text-2xl text-corBase"></i>
                            </button>
                        </div>
                        <h2 class="text-lg text-corBase font-bold mb-2">Editar Patente</h2>

                        <div class="container-campos">
                            <div class="campos campos-mobile label-campos-popup">
                                <label class="label-campos label-campos-popup">Patente:</label>
                                <input type="text" class="input-campos" v-model="patente.descricao">
                            </div>

                            <div class="flex flex-col mt-2 w-full justify-start">
                                <label class="text-base text-corBase font-bold mt-2">Produtos:</label>
                                <v-select v-model="produtos_selecionados" :options="lista_produtos_sistema" label="nome"
                                    :multiple="true" class="w-full bg-white" />
                            </div>

                            <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                            <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl cursor-pointer">
                                <i v-if="patente.ativo == true" class="fa-solid fa-circle-check text-ativo"></i>
                                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                            </span>
                        </div>

                        <!--Botão-->
                        <div class="btn-formulario-modal">
                            <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                                Salvar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <SucessoRapido v-if="sucesso" mensagem="Patente atualizada com sucesso!" />
            <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao atualizar patente." />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import vSelect from "vue-select";

export default {
    name: "EditarChapas",
    data() {
        return {
            statusModal: false,
            sucesso: false,
            erro: false,

            lista_produtos_sistema: [],
            produtos_selecionados: []
        }
    },
    components: { SucessoRapido, ErroRapido, vSelect, },
    props: ["patente"],
    emits: ["fecharModal", "atualizarLista"],

    created() {
        console.log(this.patente)
        this.produtos_selecionados = this.patente.produto_aplicado?.map(produto => {
            return { id: produto.id, nome: produto.nome }
        })

        this.getProdutosSistema()
    },

    methods: {

        async getProdutosSistema() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const response = await axios.get('produtos/produtos-sistema', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                this.lista_produtos_sistema = response.data.results.map(item => ({
                    id: item.id,
                    nome: item.nome
                }));
            } catch (error) {
                console.error("Erro ao buscar produtos do sistema:", error);
            }
        },

        toggleAtivo() {
            this.patente.ativo = !this.patente.ativo
        },


        async putDadosEditado() {

            const produtoIds = Array.isArray(this.produtos_selecionados)
                ? this.produtos_selecionados.map(produto => produto.id)
                : [];

            const dadosAtualizados = {
                ativo: this.patente.ativo,
                descricao: this.patente.descricao,
                produto_aplicado: produtoIds
            };

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest')
                const response = await axios.put(`produtos/patente/${this.patente.id}/`, dadosAtualizados, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                });
                console.log(response);
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal')
                    this.$emit('atualizarLista')
                }, 3000);

            } catch (error) {
                console.error(error)
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        }
    }
}
</script>

<style scoped>
.container-modal-editar {
    @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
    @apply bg-screenCor p-6 w-[75%] shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
    @apply text-corBase
}
</style>