<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[9998]">
            <div class="bg-white rounded-lg max-w-[90%] shadow-lg p-6 max-h-[80vh] overflow-y-auto ">
                <!-- Cabeçalho do modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-orange-600">Ativar/Desativar Linhas</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <div class="flex gap-2">
                    <div>
                        <label class="text-corBase">Ação:</label>
                        <v-select :options="lista_opcao_mudanca" v-model="opcao_mudanca" label="nome"
                            :reduce="mudanca => mudanca.value" placeholder="Escolha uma ação" :clearable="false"
                            class="mb-2 w-48" />
                    </div>
                </div>

                <div class="mb-2 flex gap-2">

                    <button @click.prevent="atualizarLinhas()"
                        class="btn-modal-ativar-desativar bg-ativo text-white border hover:bg-screenCor hover:text-ativo hover:border-ativo">
                        Atualizar
                    </button>
                </div>

                <div class="container-modelo-tabela">
                    <table class="container-tabela">
                        <thead class="cabecalho-tabela">
                            <tr class="border-b">
                                <th class="px-6 py-3">
                                    #
                                </th>
                                <th class="px-6 py-3">
                                    Descrição
                                </th>
                                <th class="px-6 py-3">
                                    Sistemista
                                </th>
                                <th class="px-6 py-2 text-center">
                                    Linha Ativa
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(linha, index) in lista_linha_copia" :key="index"
                                class="escopo-tabela list-checkbox">

                                <th scope="row"
                                    class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ index + 1 }}
                                </th>
                                <td class="px-6 py-2 text-xs">
                                    {{ linha.linha || '-' }}
                                </td>
                                <td class="px-6 py-2 text-xs">
                                    {{ linha.projetistasistemistas?.nome || '-' }}
                                </td>
                                <td class="px-6 py-2 text-center">
                                    <i v-if="linha.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                                    <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" />
        </div>
    </div>

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

</template>

<script>
import axios from "axios"
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import vSelect from "vue-select";

export default {
    name: 'AlterarValorCustoVidro',

    components: {
        ListagemErros,
        SucessoRapido,
        vSelect,
    },

    props: ['lista_linhas'],
    emits: ['fecharModal', 'atualizarLista'],

    data() {
        return {
            checkAll: false,

            lista_linha_copia: [],

            lista_opcao_mudanca: [
                { value: true, nome: 'Ativar' },
                { value: false, nome: 'Desativar' },
            ],

            opcao_mudanca: '',

            exibirErros: false,
            erros: [],

            sucesso: false,
            sucessoMessage: '',
        }
    },

    created() {
        this.lista_linha_copia = JSON.parse(JSON.stringify(this.lista_linhas));
        console.log('lista cópia: ', this.lista_linha_copia)
    },

    methods: {

        async atualizarLinhas() {
            if (this.opcao_mudanca === '' || this.opcao_mudanca === null) {
                this.erros = { ação: 'Selecione uma opção antes de atualizar linhas.' };
                this.toggleErros();
                return;
            }

            if (!this.lista_linha_copia.length) {
                this.erros = { atualização: 'Não há itens para atualizar.' };
                this.toggleErros();
                return;
            }

            try {
                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const promessas = this.lista_linha_copia.map(async (linha) => {
                    const payloadLinha = { ativo: this.opcao_mudanca };

                    // Atualizar a linha
                    const atualizarLinha = axios.patch(`/projeto/linha-sistema/${linha.id}/`, payloadLinha, {
                        headers: { Authorization: `Token ${token}` }
                    });

                    // Se estiver ativando a linha, também ativa o projetista-sistemista
                    const atualizarProjetista =
                        this.opcao_mudanca === true && linha.projetistasistemistas?.id
                            ? axios.patch(`projeto/projetista-sistemista/${linha.projetistasistemistas.id}/`, { ativo: true }, {
                                headers: { Authorization: `Token ${token}` }
                            })
                            : Promise.resolve(); // Caso contrário, retorna uma Promise resolvida para não atrapalhar o fluxo

                    return Promise.all([atualizarLinha, atualizarProjetista]);
                });

                await Promise.all(promessas);

                this.sucesso = true;
                this.sucessoMessage = "Linhas atualizadas com sucesso!";
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = "";
                    this.$emit("atualizarLista");
                    this.$emit("fecharModal");
                }, 3000);

            } catch (error) {
                console.error("Erro ao atualizar linhas:", error);
                this.erros = error.response?.data || { linhas: ["Erro ao atualizar linhas."] };
                this.toggleErros();
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

    }
}
</script>

<style scoped>
.btn-modal-ativar-desativar {
    @apply px-4 py-1 cursor-pointer
}

input[type=text] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}

input[type=number] {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>