<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>

            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Componente</h2>

            <div>
              <div class="w-full flex gap-5">

                <div class="flex flex-col w-full md:w-[35%] ">
                  <label class="text-corBase text-xl">Imagem:</label>
                  <div class="imagePreviewWrapper"
                    :style="{ 'background-image': `url(${dadosComponente.imagem?.url_documento})` }"
                    @click.prevent="toggleGaleria">
                  </div>
                  <div class="flex gap-3 mt-4">
                    <button class="selecionar-arquivo" @click.prevent="toggleGaleria">Pesquisar</button>
                  </div>
                </div>

                <div class="md:w-[65%] flex flex-col">

                  <div class="w-full flex">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Código Base:</label>
                      {{ dadosComponente.componente_base?.codigo_base || '-' }}
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Descrição Base:</label>
                      {{ dadosComponente.componente_base?.nome_base || '-' }}
                    </div>
                  </div>

                  <div class="w-full flex">
                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Fornecedor:</label>
                      {{ dadosComponente.fornecedor.nome_razao || '-' }}
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Unidade Medida:</label>
                      {{ dadosComponente.componente_base?.unidade_medida?.nome || '-' }}
                    </div>
                  </div>

                  <div class="container-campos">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Tipo:</label>
                      {{ dadosComponente.componente_base?.produto?.descricao || '-' }}
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Cor:</label>
                      {{ dadosComponente.cor_componentes?.descricao || '-' }}
                    </div>
                  </div>

                  <div class="container-campos">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Descrição:</label>
                      <input type="text" v-model="dadosComponente.descricao">
                    </div>

                  </div>

                  <div class="container-campos">

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Unidade Medida Embalagem:</label>
                      <v-select :options="lista_unidade_medida_embalagem" label="nome"
                        v-model="dadosComponente.unidade_medida_embalagem" class="bg-white" />
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Itens na Embalagem:</label>
                      <input type="number" min="1" v-model="dadosComponente.quantidade_embalagem">
                    </div>
                  </div>

                </div>

                <!--Botão-->
              </div>
            </div>

            <div class="flex justify-end mt-2">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso" mensagem="Componente atualizado com sucesso." />
    </div>
  </div>

  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

  <Galeria v-if="showGaleria === true" @fechar_galeria="toggleGaleria" @objetoClicado="atualizarFileInput" />
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import Galeria from "../../ged/Galeria.vue";
import vSelect from "vue-select";

export default {
  name: "EditarComponenteAcessorio",
  data() {
    return {
      sucesso: false,
      showGaleria: false,

      erros: [],
      exibirErros: false,

    }
  },
  components: {
    SucessoRapido,
    ListagemErros,
    Galeria,
    vSelect,
  },

  props: ["dadosComponente", "lista_unidade_medida_embalagem"],
  emits: ["fecharModal", "atualizarLista"],

  created() {
  },
  
  methods: {
    alterarModalEditar() {
      this.statusModal = !this.statusModal;
    },

    async putDadosEditado() {

      if (this.dadosComponente.unidade_medida_embalagem &&
        (!this.dadosComponente.quantidade_embalagem || this.dadosComponente.quantidade_embalagem <= 0)) {
        this.erros = { embalagem: `O produto possui Unidade de Medida da Embalagem, mas não possui quantidade válida de Itens na Embalagem.` }
        this.toggleErros()
        return;
      }

      const formData = new FormData();
      formData.append('descricao', this.dadosComponente.descricao);
      formData.append('unidade_medida_embalagem', this.dadosComponente.unidade_medida_embalagem.id);
      formData.append('quantidade_embalagem', this.dadosComponente.quantidade_embalagem);
      formData.append('imagem', this.dadosComponente.imagem?.id);
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.patch(`produtos/componente-acessorio/${this.dadosComponente.id}/`, formData, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response);
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
          this.$emit('atualizarLista')
        }, 3000);

      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
      }
    },

    toggleGaleria() {
      this.showGaleria = !this.showGaleria
    },

    atualizarFileInput(objeto) {
      this.dadosComponente.imagem = objeto;
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    },

  }
}
</script>

<style scoped>
input[type=text],
input[type=number] {
  @apply w-full p-1 border border-[#ccc] rounded-md
}
</style>