<template>


    <div class="container-formulario">
  
      <div class="cabecalho-dashboard">
        <h1>Financeiro</h1>
  
        <button @click="$router.push(`/dashboard`)" class="text-2xl">
          <i class="fas fa-chevron-left mr-1 "></i>
        </button>
      </div>
  
      <div class="flex flex-wrap">

        <button class="escopo-dashboard-desktop" @click="$router.push('/metodos-pagamento')">
          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Métodos de Pagamento</h2>
              <i class="fa-solid fa-money-bill-wave"></i>
            </div>
            <div class="descricao-opcao">
              <p class="mt-6">Cadastre os Métodos de Pagamento presentes em seu sistema!</p>
            </div>
          </div>
        </button>
  
        <button class="escopo-dashboard-desktop" @click="$router.push(`/contas-a-pagar`)">
  
          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Contas a Pagar</h2>
              <i class="fas fa-chart-line"></i>
            </div>
  
            <div class="descricao-opcao">
              <p class="mt-6">Veja as Contas a Pagar em tempo real!</p>
            </div>
          </div>
  
        </button>
  
        <button class="escopo-dashboard-desktop" @click="$router.push(`/contas-a-receber`)">
  
          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Contas a Receber</h2>
              <i class="fas fa-user-edit"></i>
            </div>
  
            <div class="descricao-opcao">
              <p class="mt-6">Veja as Contas a Receber em tempo real!</p>
            </div>
          </div>
  
        </button>
  
  
  
      </div>
  
  
    </div>
  
  
  </template>
  
  <script>
  export default {
    name: "Dashboard",
  
    computed: {
      isadmin() {
        return this.$store.state.isAdminUser
      },
    }
  
  }
  </script>
  
  <style lang="scss">
  /*estilo dachboard mobile ate 767 pixels*/
  .dashboard {
    @apply flex justify-center mt-4 md:mt-0
  }
  
  .container-dashboard {
    @apply w-[94%] bg-screenCor p-4 rounded-3xl
  }
  
  .cabecalho-dashboard {
    @apply flex text-xl font-bold text-corBase justify-between mt-2 items-center
  }
  
  .escopo-dashboard {
    @apply bg-white mt-10 p-2 rounded-xl shadow-lg shadow-corBase/20 w-full
  }
  
  .opcao-dashboard {
    @apply flex flex-col justify-center text-corBase text-base
  }
  
  .cacebalho-opcao {
    @apply flex justify-between mx-4 text-xl items-center
  }
  
  .descricao-opcao {
    @apply flex justify-center border-t border-corBase/30 mt-6 mb-6 text-center
  }
  
  /* estilo dashboard dasktop a partir de 768 pixels */
  .dashboard-dasktop {
    @apply absolute ml-24 mt-24 p-6 bg-white rounded-3xl w-[90%]
  }
  
  .escopo-dashboard-desktop {
    @apply bg-white mt-10 p-2 rounded-xl shadow-lg shadow-corBase/20 w-60 mx-auto xl:mx-auto xl:w-[30%] lg:mx-auto lg:w-[30%]
  }
  </style>