<template>
    <div>
        <div class="container-modal-editar">
            <div class="sombra-modal">
                <div class="conteudo-modal-editar">
                    <form class="form-editar text-white">
                        <div class="flex justify-end">
                            <button @click.prevent="$emit('fecharModal')">
                                <i class="fas fa-close text-2xl text-corBase"></i>
                            </button>
                        </div>
                        <h2 class="text-lg text-corBase font-bold mb-2">Editar Medida</h2>

                        <div class="container-campos">
                            <div class="campos campos-mobile label-campos-popup">
                                <label class="label-campos label-campos-popup">Medida:</label>
                                <input type="text" class="input-campos" v-model="medida.descricao">
                            </div>

                            <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                            <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl cursor-pointer">
                                <i v-if="medida.ativo == true" class="fa-solid fa-circle-check text-ativo"></i>
                                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                            </span>
                        </div>

                        <!--Botão-->
                        <div class="btn-formulario-modal">
                            <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                                Salvar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <SucessoRapido v-if="sucesso" mensagem="Medida atualizada com sucesso!" />
            <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao atualizar medidas." />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";

export default {
    name: "EditarMedidas",
    data() {
        return {
            statusModal: false,
            sucesso: false,
            erro: false,
        }
    },
    components: { SucessoRapido, ErroRapido },
    props: ["medida"],
    emits: ["fecharModal", "atualizarLista"],

    created() {
        console.log(this.medida)
    },

    methods: {

        toggleAtivo() {
            this.medida.ativo = !this.medida.ativo
        },


        async putDadosEditado() {
            const dadosAtualizados = new FormData();
            dadosAtualizados.append('ativo', this.medida.ativo)
            dadosAtualizados.append('descricao', this.medida.descricao)


            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest')
                const response = await axios.put(`produtos/medidas/${this.medida.id}/`, dadosAtualizados, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                });
                console.log(response);
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal')
                    this.$emit('atualizarLista')
                }, 3000);

            } catch (error) {
                console.error(error)
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        }
    }
}
</script>

<style scoped>
.container-modal-editar {
    @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
    @apply bg-screenCor p-6 w-[75%] shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
    @apply text-corBase
}
</style>