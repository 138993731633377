<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="px-3 py-">
            #
          </th>
          <th scope="col" class="px-3 py-2">
            Editar
          </th>
          <th class="px-3 py-2">
            Perfil
          </th>
          <th scope="col" class="px-3 py-2">
            Descrição
          </th>
          <th scope="col" class="px-3 py-2">
            Peso
          </th>
          <th scope="col" class="px-3 py-2">
            Corte
          </th>
          <th scope="col" class="px-3 py-2">
            Quantidade
          </th>
          <th scope="col" class="px-3 py-2">
            Medida
          </th>
          <th scope="col" class="px-3 py-2">
            Posição
          </th>
          <th scope="col" class="px-3 py-2">
            Observação
          </th>
          <th scope="col" class="px-3 py-2">
            Cor
          </th>
          <th scope="col" class="px-3 py-2">
            Fase
          </th>
          <th scope="col" class="px-3 py-2">
            Aplicação
          </th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(perfil, index) in perfis" :key="index" class="escopo-tabela">
        <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
          {{ index + 1 }}
        </th>
        <td class="px-3 py-2 text-xs">
          <button>
            <i class="fa-solid fa-paperclip"></i>
          </button>
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.perfil.codigo_base }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.perfil.nome_base }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.perfil.peso_base }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.corte }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.qtd_formula }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.medida_formula }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.posicao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.observacao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.cor?.descricao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.fase_esquadria?.descricao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.aplicacao }}
        </td>

      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'AlterarPerfilOrcamento',

  data() {
    return {
      
    }
  },

  props: ['perfis']
}
</script>

<style scoped lang="scss">

</style>
