<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
        <div class="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full sm:w-full md:w-2/3 lg:w-1/3 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Componente Base</h2>
            </div>

            <div class="space-y-4">
                <div class="w-full flex gap-2">

                    <div class="text-left w-full">
                        <label for="corte" class="block text-sm font-medium text-corBase mb-1">Tipo:</label>
                        <v-select v-model="tipo" :options="lista_tipo_produto" label="descricao" class="bg-white w-full"
                            placeholder="Escolha uma opção" :reduce="tipo => tipo.id" />
                    </div>

                    <div class="text-left w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Unidade Medida:</label>
                        <v-select :options="lista_unidade_medida" v-model="unidade_medida" label="nome"
                            placeholder="Escolha uma opção" :reduce="unidade_medida => unidade_medida.id" />
                    </div>

                </div>

                <div class="w-full flex gap-2">

                    <div class="text-left w-full">
                        <label for="posicao" class="block text-sm font-medium text-corBase mb-1">Cor:</label>
                        <v-select v-model="cor" :options="lista_cores" label="descricao" class="bg-white w-full"
                            placeholder="Escolha uma opção" :reduce="cor => cor.id" :multiple="true" />
                    </div>

                    <div class="text-left w-full">
                        <label for="posicao" class="block text-sm font-medium text-corBase mb-1">Bloquear:</label>
                        <v-select v-model="bloquear_cor" :options="lista_bloquear" label="label" class="bg-white w-full"
                            placeholder="Escolha uma opção" :reduce="bloquear => bloquear.value" />
                    </div>

                </div>

                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Unidade Medida Embalagem:</label>
                        <v-select :options="lista_unidade_medida" v-model="unidade_medida_embalagem" label="nome"
                            placeholder="Escolha uma opção" :reduce="unidade_medida => unidade_medida.id" />
                    </div>

                    <div class="text-left w-full">
                        <label for="aplicacao" class="block text-sm font-medium text-corBase mb-1">Itens na
                            Emabalagem:</label>
                        <input type="number" v-model="quantidade_embalagem" min="0" maxlength="50">
                    </div>

                </div>

                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Patente:</label>
                        <v-select :options="lista_patente" v-model="patente" label="descricao"
                            placeholder="Escolha uma opção" :reduce="patente => patente.id" />
                    </div>

                    <div class="text-left w-full">
                        <label for="aplicacao" class="block text-sm font-medium text-corBase mb-1">Linha:</label>
                        <v-select :options="lista_linha_aplicacao" v-model="linha" label="linha"
                            placeholder="Escolha uma opção" :multiple="true" :reduce="linha => linha.linha" />
                    </div>

                </div>

                <div class="w-full flex gap-2">
                    <div class="text-center flex flex-col items-center w-full">
                        <label class="block text-sm font-medium text-corBase mb-1">Status:</label>
                        <v-select :options="lista_status" label="label" placeholder="Escolha uma opção" v-model="ativo"
                            :reduce="status => status.value" class="w-52" />
                    </div>
                </div>

            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>

            <SucessoRapido v-if="sucesso === true" mensagem="Produtos atualizados com sucesso!" />
            <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar Produtos." />
            <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'AlterarSelecionadosProdutoBase',
    data() {
        return {
            tipo: '',
            cor: [],
            bloquear_cor: '',
            unidade_medida: '',
            unidade_medida_embalagem: '',
            quantidade_embalagem: '',
            linha: '',
            linhaId: '',
            patente: '',
            medida: '',
            ativo: '',

            lista_status: [
                { label: 'Ativo', value: true },
                { label: 'Inativo', value: false },
            ],

            lista_bloquear: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
            ],

            // lista_tipo_produto: [],
            // lista_cores: [],
            // lista_unidade_medida: [],
            // lista_linha_aplicacao: [],

            sucesso: false,
            erro: false,
            erros: [],
            exibirErros: false,
        };
    },

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
        ListagemErros
    },

    props: ['produtos', 'tipo_produto', 'lista_tipo_produto', 'lista_cores', 'lista_unidade_medida', 'lista_linha_aplicacao', 'lista_patente',],

    emits: ['fecharModal', 'atualizarLista'],

    created() {
        // this.getListaCores()
        // this.getListaLinhaAplicacao()
        // this.getListaUnidadeMedida()
        // this.getListaTipoProdutoBase()
    },

    methods: {

        async salvarAlteracoes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                this.$store.dispatch("setLoading", true);
                console.log('PRODUTOS')
                console.log(this.produtos)

                let linhaIds = [];
                if (this.linha) {
                    linhaIds = await this.getLinhaID(this.linha); // Obtém os IDs das linhas
                }

                const patchPromises = this.produtos.map(produto => {

                    const camposAtualizados = {};

                    if (this.tipo) {
                        camposAtualizados.produto = this.tipo;
                        console.log(camposAtualizados.produto)
                    }
                    if (this.unidade_medida) {
                        camposAtualizados.unidade_medida = this.unidade_medida;
                        console.log(camposAtualizados.unidade_medida)
                    }
                    if (this.cor.length > 0) {
                        camposAtualizados.cor_base = this.cor;
                        console.log(camposAtualizados.cor_base)
                    }
                    if (this.bloquear_cor) {
                        camposAtualizados.bloquear_cor = this.bloquear_cor;
                        console.log(camposAtualizados.bloquear_cor)
                    }
                    if (this.unidade_medida_embalagem) {
                        if (this.quantidade_embalagem && this.quantidade_embalagem > 0) {
                            camposAtualizados.quantidade_embalagem = this.quantidade_embalagem;
                            console.log(camposAtualizados.quantidade_embalagem)
                            camposAtualizados.unidade_medida_embalagem = this.unidade_medida_embalagem;
                        console.log(camposAtualizados.unidade_medida_embalagem)
                        } else {
                            this.erros = {itens_embalagem: 'Digite um valor de Itens na Embalagem válido.'}
                            this.toggleErros()
                            throw new Error("Valor inválido para Itens na Embalagem.");
                        }
                    }
                    if (linhaIds.length > 0) {
                        camposAtualizados.linha = linhaIds;
                        console.log('LINHAS:', camposAtualizados.linha);
                    }
                    if (this.patente) {
                        camposAtualizados.patente = this.patente;
                        camposAtualizados.produto_especializado = true;
                        console.log(camposAtualizados.patente)
                    }
                    if (this.medida) {
                        camposAtualizados.medida = this.medida;
                        console.log(camposAtualizados.medida)
                    }
                    if (this.ativo !== '') {
                        camposAtualizados.ativo = this.ativo;
                    }


                    if (Object.keys(camposAtualizados).length === 0) {
                        return Promise.resolve();
                    }

                    return axios.patch(
                        `produtos/produto/${produto.id}/`,
                        camposAtualizados,
                        {
                            headers: {
                                'Authorization': `Token ${token}`,
                            },
                        }
                    );
                });

                // Aguarda todas as requisições serem resolvidas
                await Promise.all(patchPromises);

                // Exibe mensagem de sucesso
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal');
                    this.$emit('atualizarLista');
                }, 3000);
            } catch (error) {
                // Tratamento de erros
                console.error('Erro ao salvar alterações:', error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getLinhaID(linhas) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            const linhaIds = []; // Lista para armazenar os IDs das linhas

            for (const linha of linhas) {
                console.log(linha)
                const nome = linha
                try {
                    // Verificar se a linha existe
                    const response = await axios.get(`produtos/linha-produto-base/?nome=${nome}`, {
                        headers: {
                            'Authorization': `Token ${token}`,
                        },
                    });

                    if (response.data.results.length > 0) {
                        // Se a linha já existe, pega o ID
                        linhaIds.push(response.data.results[0].id);
                    } else {
                        // Caso contrário, cria a linha e pega o ID
                        const createdResponse = await axios.post(
                            '/produtos/linha-produto-base/',
                            { nome },
                            {
                                headers: {
                                    'Authorization': `Token ${token}`,
                                },
                            }
                        );
                        linhaIds.push(createdResponse.data.id);
                    }
                } catch (error) {
                    console.error(`Erro ao processar a linha ${nome}:`, error);
                }
            }

            return linhaIds; // Retorna a lista de IDs das linhas
        },

        toggleErros(){
            this.exibirErros = !this.exibirErros
        }

    },

};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}

input[type=number] {
    @apply w-full p-1 border border-[#ccc] rounded-md
}
</style>