<template>
    <div>

        <div class="flex w-full">
            <div class="w-48 pb-2 ml-2">
                <label class="text-xl text-corBase">$ Custo:</label>
                <v-select v-model="custo" class="w-full z-10" :options="lista_custo" :reduce="custo => custo.value"
                    label="nome" :clearable="false" />
            </div>

            <div class="w-full pb-2 ml-2">
                <label class="text-xl text-corBase">Fornecedor:</label>
                <v-select v-model="fornecedor" :options="lista_fornecedores" label="nome_razao"
                    :reduce="fornecedor => fornecedor.id" />
            </div>
        </div>

        <div class="mb-4">
            <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
                <div class="px-2 w-1/2">
                    <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
                        <option value="codigo">Código</option>
                        <option value="perfil">Descrição</option>
                        <option value="tipo">Tipo</option>
                        <option value="cor_base">Cor</option>
                        <option value="espessura">Espessura</option>
                        <!-- <option value="acrescimo_tamanho">Acréscimo Tamanho</option> -->
                    </select>
                </div>

                <div class="w-full">

                    <v-select v-if="filtro.campo === 'tipo'" v-model="filtro.valor" :options="lista_tipo_produto"
                        label="descricao" :reduce="tipo => tipo.id" placeholder="Escolha uma opção" />

                    <v-select v-else-if="filtro.campo === 'cor_base'" v-model="filtro.valor" :options="lista_cores"
                        label="descricao" :reduce="cor => cor.id" placeholder="Escolha uma opção" />

                    <v-select v-else-if="filtro.campo === 'espessura'" v-model="filtro.valor"
                        :options="lista_espessuras" label="espessura" :reduce="espessura => espessura.espessura"
                        placeholder="Escolha uma opção" />

                    <v-select v-else-if="filtro.campo === 'acrescimo_tamanho'" v-model="filtro.valor"
                        :options="lista_acrescimo_tamanho" label="label" :reduce="acrescimo => acrescimo.value"
                        placeholder="Escolha uma opção" />

                    <input v-else v-model="filtro.valor" type="text" class="input-form-chapa"
                        placeholder="Digite o desejado" />
                </div>

                <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
            </div>

            <div class="flex w-full justify-center">
                <button class="text-ativo" @click.prevent="adicionarFiltro">
                    <i class="fas fa-plus-circle"></i>
                </button>
            </div>
        </div>

        <div class="flex flex-wrap gap-2 mb-6 ">
            <button
                class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
                @click.prevent="mudarPagina(1)">
                Pesquisar
            </button>

            <button @click.prevent="toggleImportarPerfis()" class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
  text-white hover:text-yellow-500 py-2 px-4">Importar
            </button>

            <button @click.prevent="alterarValorCusto()"
                class="bg-blue-500 text-white border hover:bg-screenCor hover:text-blue-500 hover:border-blue-500 py-2 px-4">
                Atualizar $ Custo
            </button>

            <button @click.prevent="editarValores()"
                class="bg-orange-500 text-white border hover:bg-screenCor hover:text-orange-500 hover:border-orange-500 py-2 px-4">
                {{ modoEdicaoGeral ? "Salvar Alterações" : "Editar Valores" }}
            </button>

        </div>

        <!-- <p class="text-xs text-gray-500">Lógica dos cálculos:</p>
        <p class="text-xs text-gray-500">Custo final = custo + adicional%</p>
        <p class="text-xs text-gray-500">Preço venda projeto = praticado projeto ou custo final + (lucro projeto +
            adicional projeto)%</p>
        <p class="text-xs text-gray-500">Preço venda item = praticado item ou custo final + (lucro item + adicional
            item)%</p>
        <p class="text-xs text-gray-500">Lucro = $ venda - $ custo final</p> -->


        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr class="border-b">
                        <th scope="col" class="p-4">
                            <div class="flex items-center">
                                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th class="px-6 py-3">#</th>
                        <th class="px-10 py-3">Código</th>
                        <th class="px-10 py-3">Descrição</th>
                        <th class="px-10 py-3">Unidade Medida</th>
                        <th class="px-16 py-3">Patente</th>
                        <th class="px-16 py-3">Fornecedor</th>
                        <th class="px-10 py-3 text-center">$ Custo</th>
                        <th class="px-10 py-3 text-center">% Adicional Custo</th>
                        <th class="px-6 py-3 text-center">$ Custo Final</th>

                        <th class="px-6 py-3 text-center">$ Custo Médio</th>

                        <th class="px-6 py-3 text-center bg-gray-300">% Tabela Venda Projeto</th>
                        <th class="px-6 py-3 text-center">% Tabela Venda do Item</th>

                        <th class="px-6 py-3 text-center bg-gray-300">% Adicional Venda Projeto</th>
                        <th class="px-6 py-3 text-center">% Adicional Venda Item</th>
                        <th class="px-6 py-3 text-center bg-gray-300">$ Praticado Venda Projeto</th>
                        <th class="px-6 py-3 text-center">$ Praticado Venda do Item</th>

                        <th class="px-6 py-3 text-center bg-gray-300">$ Venda Projeto</th>
                        <th class="px-6 py-3 text-center">$ Venda do Item</th>

                        <th class="px-16 py-3 text-center whitespace-nowrap">
                            Lucro Venda <br> Projeto
                        </th>
                        <th class="px-16 py-3 text-center whitespace-nowrap">
                            Lucro Venda <br> do Item
                        </th>


                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(produto, index) in produtosTabela" :key="produto.id" class="border-b hover:bg-gray-50">
                        <td class="w-4 p-4">
                            <div class="flex items-center">
                                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                    v-model="produto.selected">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </td>
                        <th class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                            {{ index + 1 }}
                        </th>
                        <td class="px-10 py-3 whitespace-nowrap">{{ produto.perfil.codigo }}</td>
                        <td class="px-10 py-3 whitespace-nowrap">{{ produto.perfil.descricao }}</td>
                        <td class="px-10 py-3 whitespace-nowrap">{{ produto.perfil.perfil_base.unidade_medida?.nome || '-' }}</td>
                        <td class="px-16 py-3 whitespace-nowrap">{{ produto.perfil.perfil_base.patente?.descricao || '-' }}</td>
                        <td class="px-16 py-3 whitespace-nowrap">{{ produto.perfil.fornecedor?.nome_razao }}</td>
                        <td class="text-center whitespace-nowrap">
                            <input v-if="produto.editando || modoEdicaoGeral" v-model="produto.custo" type="number"
                                min="0" />
                            <span v-else-if="produto.custo" class="px-6 py-3">
                                R$ {{ produto.custo }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="text-center whitespace-nowrap">
                            <input v-if="produto.editando || modoEdicaoGeral" v-model="produto.adicional_custo"
                                type="number" min="0" />
                            <span v-else-if="produto.adicional_custo" class="px-6 py-3">{{ produto.adicional_custo
                                }}%</span>
                            <span v-else>-</span>
                        </td>
                        <td class="px-6 py-3 text-center whitespace-nowrap">
                            {{ calcularCustoFinal(produto) }}
                        </td>

                        <td class="px-6 py-3 text-center whitespace-nowrap">x</td>

                        <td class="px-6 py-3 text-center whitespace-nowrap bg-gray-300">
                            <span v-if="produto.lucro_projeto" class="px-6 py-3">{{ produto.lucro_projeto }}%</span>
                            <span v-else>-</span>
                        </td>

                        <td class="px-6 py-3 text-center whitespace-nowrap">
                            <span v-if="produto.lucro_item" class="px-6 py-3">{{ produto.lucro_item }}%</span>
                            <span v-else>-</span>
                        </td>

                        <td class="text-center whitespace-nowrap bg-gray-300">
                            <input v-if="produto.editando || modoEdicaoGeral" v-model="produto.adicional_venda_projeto"
                                type="number" min="0" />
                            <span v-else-if="produto.adicional_venda_projeto" class="px-6 py-3">{{
                                produto.adicional_venda_projeto }}%</span>
                            <span v-else>-</span>
                        </td>
                        <td class="text-center whitespace-nowrap">
                            <input v-if="produto.editando || modoEdicaoGeral" v-model="produto.adicional_venda_item"
                                type="number" min="0" />
                            <span v-else-if="produto.adicional_venda_item" class="px-6 py-3">{{
                                produto.adicional_venda_item }}%</span>
                            <span v-else>-</span>
                        </td>
                        <td class="text-center whitespace-nowrap bg-gray-300">
                            <input v-if="produto.editando || modoEdicaoGeral"
                                v-model="produto.preco_praticado_venda_projeto" type="number" min="0" />
                            <span v-else class="px-6 py-3">
                                {{ produto.preco_praticado_venda_projeto ?
                                    ` ${formatarNumero(produto.preco_praticado_venda_projeto)}` : '-' }}
                            </span>
                        </td>

                        <td class="text-center whitespace-nowrap">
                            <input v-if="produto.editando || modoEdicaoGeral"
                                v-model="produto.preco_praticado_venda_item" type="number" min="0" />
                            <span v-else class="px-6 py-3">
                                {{ produto.preco_praticado_venda_item ? `
                                ${formatarNumero(produto.preco_praticado_venda_item)}` : '-' }}
                            </span>
                        </td>
                        <td class="text-center whitespace-nowrap bg-gray-300">
                            <span v-if="produto.preco_praticado_venda_projeto">
                                {{ produto.preco_praticado_venda_projeto }}
                            </span>
                            <span v-else>{{ calcularPrecoFinalProjeto(produto) }}</span>
                        </td>

                        <td class="text-center whitespace-nowrap">
                            <span v-if="produto.preco_praticado_venda_item">
                                {{ produto.preco_praticado_venda_item }}
                            </span>
                            <span v-else>{{ calcularPrecoFinalItem(produto) }}</span>
                        </td>
                        <td class="text-center whitespace-nowrap">
                            {{ calcularLucroProjeto(produto) }}
                        </td>

                        <td class="text-center whitespace-nowrap">
                            {{ calcularLucroItem(produto) }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <Pagination v-if="produtosTabela.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
        </div>

    </div>

    <AlterarValorCustoPerfil v-if="modalAlterarValorCusto" :lista_perfis="perfisSelecionados"
        @fecharModal="toggleAlterarValorCusto" @atualizarLista="mudarPagina(paginaAtual)" />

    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
    <ImportarPerfis v-if="importarPerfis" @fecharModal="toggleImportarPerfis" @atualizarLista="mudarPagina(paginaAtual)"
        :lista_cores="lista_cores" :lista_espessuras="lista_espessuras" :lista_fornecedores="lista_fornecedores"
        :lista_tipo_produto="lista_tipo_produto" />
    <SucessoRapido v-if="sucesso === true" mensagem="Alteração feita com sucesso." />
</template>

<script>
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import ImportarPerfis from "./ImportarPerfis.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import AlterarValorCustoPerfil from "./AlterarValorCustoPerfil.vue";
import axios from 'axios'

export default {
    name: "ProdutosTabela",
    components: {
        vSelect,
        Pagination,
        SucessoRapido,
        ErroRapido,
        ImportarPerfis,
        ListagemErros,
        AlterarValorCustoPerfil,
    },

    data() {
        return {
            checkAll: false,


            filtros: [{ campo: "codigo", valor: "" }],
            lista_custo: [
                { value: 'Todos', nome: "Todos" },
                { value: 'Com Preço', nome: "Com Preço" },
                { value: 'Sem Preço', nome: "Sem Preço" },
            ],
            lista_cores: [],
            lista_espessuras: [],
            lista_tipo_produto: [],
            lista_fornecedores: [],

            custo: 'Todos',
            fornecedor: '',

            importarPerfis: false,

            modoEdicaoGeral: false,

            modalAlterarValorCusto: false,
            perfisSelecionados: [],

            produtosTabela: [],

            paginaAtual: 1,
            countItens: 0,

            sucesso: false,
            erros: [],
            exibirErros: false,
        };
    },
    computed: {
        checkSome() {
            return this.produtosTabela.some(produto => produto.selected)
        }

    },

    created() {
        this.getListaTipoProdutoBase()
        this.getListaCores()
        this.getListaEspessura()
        this.getListaFornecedores()
    },

    methods: {

        atualizarFiltro(index) {
            this.filtros[index].valor = null;
        },
        adicionarFiltro() {
            this.filtros.push({ campo: "", valor: "" });
        },
        removerFiltro(index) {
            this.filtros.splice(index, 1);
        },

        selectAll() {
            // Atualiza o estado dos demais checkboxes
            const checkboxes = document.querySelectorAll('.list-checkbox input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = this.checkAll;
            });

            this.produtosTabela.forEach((perfil) => {
                perfil.selected = this.checkAll;
            });
        },

        async getListaTipoProdutoBase() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            // this.$store.dispatch("setLoading", true);

            try {
                const response = await axios.get(`/produtos/tipo-produto-base/?produto=Perfil&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                this.lista_tipo_produto = response.data.results;
                console.log(this.lista_tipo_produto);

            } catch (error) {
                console.error("Erro ao buscar tipos de produto base:", error);
            }
        },

        async getListaCores() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            axios.get(`produtos/cor-produto-base/?produto_nome=Perfil&ativo=True&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.lista_cores = response.data.results;
                })
                .catch(error => {
                    console.error(error);
                })
        },

        async getListaEspessura() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            axios.get(`produtos/espessura/?ativo=True&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data.results);
                    this.lista_espessuras = response.data.results;
                })
                .catch(error => {
                    console.error(error);
                })
        },


        async getListaFornecedores() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?produto_fornecedor=Perfil&atividade=Fornecedor&page=1`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_fornecedores = response.data.results.map(fornecedor => {
                    return {
                        id: fornecedor.pessoa.id,
                        nome_razao: fornecedor.nome || fornecedor.razao_social,
                    }
                })
                this.getSemFornecedor()
            } catch (error) {
                console.error(error)
            }
        },

        async getSemFornecedor() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const nome_razao = encodeURIComponent('Fornecedor Indefinido')
                const response = await axios.get(`pessoas/?nome_razao=${nome_razao}&atividade=Fornecedor`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                const semFornecedor = response.data.results[0];
                if (semFornecedor) {
                    // Adicionar "Fornecedor Indefinido" à lista de fornecedores
                    this.lista_fornecedores.unshift({
                        id: semFornecedor.id,
                        nome_razao: semFornecedor.nome_razao
                    });
                }
            } catch (error) {
                console.error(error)
            }
        },

        async buscarProdutosTabelaPerfil(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {

                this.checkAll = false

                this.$store.dispatch("setLoading", true);

                const params = this.filtros
                    .filter((filtro) => filtro.campo && filtro.valor)
                    .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
                    .join("&");

                const response = await axios.get(`precificacao/itens-tabelas-perfil/?${params}&custo=${this.custo}&fornecedor=${this.fornecedor}&page=${pagina}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                this.produtosTabela = response.data.results;
                this.countItens = response.data.count
                console.log(this.produtosTabela)
            } catch (error) {
                console.error("Erro ao buscar produtos da tabela de perfil:", error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        formatarNumero(valor) {
            if (valor === null || valor === undefined || isNaN(valor)) return "-";
            return `R$ ${parseFloat(valor).toFixed(2).replace('.', ',')}`;
        },

        calcularCustoFinal(produto) {
            const custo = parseFloat(produto.custo) || null;
            const adicional_custo = parseFloat(produto.adicional_custo) || null;

            if (custo === null || adicional_custo === null) return "-";

            return this.formatarNumero(custo + (custo * adicional_custo / 100));
        },

        calcularPrecoFinalProjeto(produto) {
            if (produto.preco_praticado_venda_projeto) {
                return this.formatarNumero(produto.preco_praticado_venda_projeto);
            }

            const custo_final = parseFloat(this.calcularCustoFinal(produto).replace('R$ ', '').replace(',', '.')) || null;
            const adicional_venda_projeto = parseFloat(produto.adicional_venda_projeto) || null;
            const lucro_projeto = parseFloat(produto.lucro_projeto) || null;

            if (custo_final === null || adicional_venda_projeto === null) return "-";

            return this.formatarNumero(custo_final + (custo_final * (adicional_venda_projeto + lucro_projeto) / 100));
        },

        calcularPrecoFinalItem(produto) {
            if (produto.preco_praticado_venda_item) {
                return this.formatarNumero(produto.preco_praticado_venda_item);
            }

            const custo_final = parseFloat(this.calcularCustoFinal(produto).replace('R$ ', '').replace(',', '.')) || null;
            const adicional_venda_item = parseFloat(produto.adicional_venda_item) || null;
            const lucro_item = parseFloat(produto.lucro_item) || null;

            if (custo_final === null || adicional_venda_item === null) return "-";

            return this.formatarNumero(custo_final + (custo_final * (adicional_venda_item + lucro_item) / 100));
        },

        calcularLucroProjeto(produto) {
            const custo_final = parseFloat(this.calcularCustoFinal(produto).replace('R$ ', '').replace(',', '.')) || null;
            const venda_projeto = parseFloat(this.calcularPrecoFinalProjeto(produto).replace('R$ ', '').replace(',', '.')) || null;

            if (custo_final === null || venda_projeto === null) return "-";

            const lucro = venda_projeto - custo_final;
            const percentual = custo_final > 0 ? (lucro / custo_final) * 100 : 0;

            return lucro ? `R$ ${lucro.toFixed(2)} (${percentual.toFixed(2)}%)` : "-";
        },

        calcularLucroItem(produto) {
            const custo_final = parseFloat(this.calcularCustoFinal(produto).replace('R$ ', '').replace(',', '.')) || null;
            const venda_item = parseFloat(this.calcularPrecoFinalItem(produto).replace('R$ ', '').replace(',', '.')) || null;

            if (custo_final === null || venda_item === null) return "-";

            const lucro = venda_item - custo_final;
            const percentual = custo_final > 0 ? (lucro / custo_final) * 100 : 0;

            return lucro ? `R$ ${lucro.toFixed(2)} (${percentual.toFixed(2)}%)` : "-";
        },

        editarValores() {
            if (this.produtosTabela.length === 0) {
                this.erros = { alterar_valores: 'Busque os itens da tabela para alterar os valores.' };
                this.toggleErros();
                return;
            }

            this.modoEdicaoGeral = !this.modoEdicaoGeral;

            if (this.modoEdicaoGeral) {
                this.produtosTabela = this.produtosTabela.map(produto => ({
                    ...produto,
                    editando: true,
                }));
            } else {
                this.produtosTabela = this.produtosTabela.map(produto => {
                    // Converter strings para números
                    produto.custo = parseFloat(produto.custo) || 0;
                    produto.adicional_custo = parseFloat(produto.adicional_custo) || 0;
                    produto.adicional_venda_projeto = parseFloat(produto.adicional_venda_projeto) || 0;
                    produto.adicional_venda_item = parseFloat(produto.adicional_venda_item) || 0;
                    produto.preco_praticado_venda_projeto = produto.preco_praticado_venda_projeto ? parseFloat(produto.preco_praticado_venda_projeto) : null;
                    produto.preco_praticado_venda_item = produto.preco_praticado_venda_item ? parseFloat(produto.preco_praticado_venda_item) : null;

                    // Cálculo do custo final
                    produto.custo_final = produto.custo + (produto.custo * produto.adicional_custo / 100);

                    // Preço Final Projeto com prioridade no preço praticado
                    produto.preco_final_projeto = produto.preco_praticado_venda_projeto !== null
                        ? produto.preco_praticado_venda_projeto
                        : produto.custo_final + (produto.custo_final * produto.adicional_venda_projeto / 100);

                    // Preço Final Item com prioridade no preço praticado
                    produto.preco_final_item = produto.preco_praticado_venda_item !== null
                        ? produto.preco_praticado_venda_item
                        : produto.custo_final + (produto.custo_final * produto.adicional_venda_item / 100);

                    return {
                        ...produto,
                        editando: false,
                    };
                });

                this.salvarAlteracoes();
            }
        },

        async salvarAlteracoes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                this.$store.dispatch("setLoading", true);

                const promessas = this.produtosTabela.map(async (produto) => {
                    const dadosEditados = {
                        custo: parseFloat(produto.custo) || 0,
                        adicional_custo: parseFloat(produto.adicional_custo) || 0,
                        adicional_venda_projeto: parseFloat(produto.adicional_venda_projeto) || 0,
                        adicional_venda_item: parseFloat(produto.adicional_venda_item) || 0,
                        preco_praticado_venda_projeto: produto.preco_praticado_venda_projeto ? parseFloat(produto.preco_praticado_venda_projeto) : null,
                        preco_praticado_venda_item: produto.preco_praticado_venda_item ? parseFloat(produto.preco_praticado_venda_item) : null,
                    };

                    return axios.patch(`/precificacao/itens-tabelas-perfil/${produto.id}/`, dadosEditados, {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    });
                });

                await Promise.all(promessas);

                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);

                this.buscarProdutosTabelaPerfil(this.paginaAtual);
            } catch (error) {
                console.error('Erro ao salvar alterações:', error);
                this.erro = true;
                this.erroMessage = 'Erro ao salvar alterações. Verifique os valores.';
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },


        alterarValorCusto() {
            if (this.checkSome || this.checkAll) {
                this.perfisSelecionados = this.produtosTabela.filter(perfil => perfil.selected);
                this.toggleAlterarValorCusto();
            } else {
                this.erros = { perfil: ["Selecione pelo menos um Perfil para ser atualizado."] };
                this.toggleErros();
            }
        },

        toggleAlterarValorCusto() {
            this.modalAlterarValorCusto = !this.modalAlterarValorCusto;
        },

        toggleImportarPerfis() {
            this.importarPerfis = !this.importarPerfis
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina
            this.buscarProdutosTabelaPerfil(pagina)
        },


    },
};
</script>

<style scoped>
input[type=number] {
    @apply w-full p-2 border border-[#ccc] rounded-md
}
</style>