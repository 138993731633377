<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
            <div
                class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                <!-- Cabeçalho do Modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-red-600">Lista de Stakeholders da Obra</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <!-- Lista de Stakeholders -->

                <div class="mb-2 flex flex-col">
                    <div class="text-base text-gray-700">
                        <p>
                            {{ obraSelecionada.cliente?.nome_razao || '-' }} - Cliente
                        </p>
                        <p>
                            {{ obraSelecionada.responsavel?.nome_razao || '-' }} - Responsável
                        </p>
                        <p>
                            {{ obraSelecionada.patrocinador?.nome_razao || '-' }} - Patrocinador
                        </p>
                    </div>

                    <p class="text-base text-gray-700" v-for="pessoa_alocada in lista_pessoas_alocadas" :key="pessoa_alocada.id">
                        {{ pessoa_alocada.pessoa.nome_razao }} - {{ pessoa_alocada.responsabilidade.descricao }}
                    </p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "ListarEnderecos",
    props: ['obraSelecionada'],
    emits: ['fecharModal'],

    data() {
        return {
            lista_pessoas_alocadas: []
        }
    },


    created() {
        console.log(this.obraSelecionada)
        this.getListaPessoasAlocadas()
    },

    methods: {

        async getListaPessoasAlocadas() {
            try {

                this.$store.dispatch("setLoading", true);

                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get(`obra/pessoa-alocada/?obra=${this.obraSelecionada.id}&page_size=9999`, {
                    headers: { 'Authorization': `Token ${token}` }
                })

                console.log(response.data.results)
                this.lista_pessoas_alocadas = response.data.results

            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        }

    }
};
</script>

<style scoped></style>