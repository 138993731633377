<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Perfil</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-center w-full gap-2 mb-2">

      <div class="ml-2 w-[47%]">
        <label class="text-xl text-corBase">Status:</label>
        <v-select v-model="status" class="w-full z-10" :options="lista_status" :reduce="status => status.value"
          label="nome" :clearable="false" />
      </div>

      <div class="w-[98%]">
        <label class="text-xl text-corBase">Fornecedor:</label>
        <v-select v-model="fornecedor" :options="lista_fornecedores" label="nome_razao"
          :reduce="fornecedor => fornecedor.id" placeholder="Escolha uma opção" />
      </div>
    </div>

    <div class="mb-4">
      <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
        <div class="px-2 w-1/2">
          <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
            <option value="codigo">Código</option>
            <option value="descricao">Descrição</option>
            <option value="codigo_base">Código Base</option>
            <option value="descricao_base">Descrição Base</option>
            <option value="tipo">Tipo</option>
            <option value="cor_base">Cor</option>
          </select>
        </div>

        <div class="w-full">

          <v-select v-if="filtro.campo === 'tipo'" v-model="filtro.valor" :options="lista_tipo_perfil" label="descricao"
            :reduce="tipo => tipo.id" placeholder="Escolha uma opção" />

          <v-select v-else-if="filtro.campo === 'cor_base'" v-model="filtro.valor" :options="lista_cor_perfil"
            label="descricao" :reduce="cor => cor.id" placeholder="Escolha uma opção" />

          <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Digite o desejado" />
        </div>

        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
      </div>

      <div class="flex w-full justify-center">
        <button class="text-ativo" @click.prevent="adicionarFiltro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>

    <div class="flex flex-wrap items-end gap-2 mb-6">
      <button
        class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
        @click.prevent="mudarPagina(1)">
        Pesquisar
      </button>

      <!-- <button @click.prevent="alterarFornecedor()"
        class="bg-orange-500 text-white border hover:bg-screenCor hover:text-orange-500 hover:border-orange-500 py-2 px-4">
        Alterar Fornecedor
      </button> -->

      <button @click.prevent="toggleImportarPerfis()" class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
  text-white hover:text-yellow-500 py-2 px-4">Importar
      </button>

      <button @click.prevent="alterarSelecionados()"
        class="bg-blue-500 text-white border hover:bg-screenCor hover:text-blue-500 hover:border-blue-500 py-2 px-4">
        Alterar Selecionados
      </button>

    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th scope="col" class="px-6 py-3">
              Ações
            </th>
            <th class="px-6 py-3">
              Imagem
            </th>
            <th class="px-16 py-3 text-center">
              Código
            </th>
            <th class="px-16 py-3 text-center">
              Descrição
            </th>
            <th scope="col" class="px-12 py-3 text-center">
              Código Base
            </th>
            <th scope="col" class="px-12 py-3 text-center">
              Descrição Base
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Tipo
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Cor
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Peso (KG/M)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Unidade Medida
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Medida
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Patente
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Fornecedor
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Tamanho Mínimo Corte (mm)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Perda Tratamento (mm)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Tamanho Máximo Sucata (mm)
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(perfil, index) in lista_perfil" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="perfil.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs text-center">
              <a @click="selecionarPerfilEditar(perfil)" href="#" class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square"></i>
              </a>
            </td>
            <td class="px-6 py-2 text-xs">
              <button v-if="perfil.imagem && perfil.imagem.url_documento"
                @click.prevent="openPreviewImage(perfil.imagem.url_documento)">
                <img class="w-12" :src="perfil.imagem.url_documento" :alt="perfil.imagem.descricao" />
              </button>
                <img v-else class="w-12"
                  src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                  alt="Sem foto">
            </td>
            <td class="py-2 text-xs text-center">
              {{ perfil.codigo || '-' }}
            </td>
            <td class="py-2 text-xs text-center">
              {{ perfil.descricao || '-' }}
            </td>
            <td class="py-2 text-xs text-center">
              {{ perfil.perfil_base?.codigo_base || '-' }}
            </td>
            <td class="py-2 text-xs text-center">
              {{ perfil.perfil_base?.nome_base || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ perfil.perfil_base?.produto?.descricao || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ perfil.cor_perfil?.descricao || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ perfil.peso || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ perfil.perfil_base?.unidade_medida?.nome || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ perfil.tamanho || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center whitespace-nowrap">
              {{ perfil.perfil_base.patente?.descricao || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center whitespace-nowrap">
              {{ perfil.fornecedor?.nome_razao || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ perfil.tamanho_minimo_corte || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ perfil.perda_tratamento || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ perfil.sucata_maxima || '-' }}
            </td>
            <td class="px-6 py-2 text-center">
              <div class="flex flex-col items-center">
                <i v-if="perfil.ativo" class="fa-solid fa-circle-check text-ativo mb-1" title="Ativo"></i>
                <i v-else class="fa-solid fa-circle-xmark text-inativo mb-1"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination v-if="lista_perfil.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

  <EditarPerfil v-if="mostrarModal" :dadosPerfil="perfilSelecionado" @fecharModal="toggleModal" @atualizarLista="mudarPagina(paginaAtual)" />

  <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

  <AlterarSelecionadosPerfil v-if="modalAlterarSelecionados" :perfis="perfisSelecionados"
        @fecharModal="toggleAlterarSelecionados" @atualizarLista="mudarPagina(paginaAtual)" />

  <ModalImportacaoPerfil v-if="modalImportarPerfis" @fecharModal="toggleImportarPerfis"
    @atualizarLista="mudarPagina(paginaAtual)" :lista_tipo_produto="lista_tipo_perfil"
    :lista_cores="lista_cor_perfil" />

</template>

<script>
import axios from "axios";
import EditarPerfil from "@/components/midais/Editar/produtos/EditarPerfil";
import ModalImportacaoPerfil from "./ModalImportacaoPerfil.vue";
import AlterarSelecionadosPerfil from "@/components/midais/Editar/produtos/AlterarSelecionadosPerfil.vue";
import ImportarcaoPerfil from "@/components/midais/importacoes/ImportarcaoPerfil";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import PreviewImage from "@/components/midais/ged/PreviewImage";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaPeril",

  components: {
    EditarPerfil,
    ModalImportacaoPerfil,
    AlterarSelecionadosPerfil,
    ListagemErros,
    ImportarcaoPerfil,
    PreviewImage,
    vSelect,
    Pagination
  },

  data() {
    return {

      filtros: [{ campo: "codigo", valor: "" }],

      lista_status: [
        { value: 'True', nome: "Ativo" },
        { value: 'False', nome: "Inativo" },
      ],

      lista_cor_perfil: [],
      lista_tipo_perfil: [],
      lista_linha_perfil: [],
      lista_fornecedores: [],

      status: 'True',
      fornecedor: '',

      perfisSelecionados: [],
      modalAlterarSelecionados: false,

      checkAll: false,

      modalImportarPerfis: false,

      paginaAtual: 1,
      countItens: '',

      perfilSelecionado: null,
      mostrarModal: false,

      preview_imagem: '',

      lista_perfil: [],

      erros: [],
      exibirErros: false,
    }
  },

  created() {
    this.getListaCorPerfil()
    this.getListaTipoPerfil()
    this.getListaFornecedores()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    checkSome() {
      return this.lista_perfil.some(produto => produto.selected)
    }
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },
    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },
    openPreviewImage(url) {
      this.preview_imagem = url
    },

    toggleImportarPerfis() {
      this.modalImportarPerfis = !this.modalImportarPerfis
    },

    alterarSelecionados() {
      if (this.checkSome || this.checkAll) {
        this.perfisSelecionados = this.lista_perfil.filter(perfil => perfil.selected);
        this.toggleAlterarSelecionados();
      } else {
        this.erros = { perfil: ["Selecione pelo menos um Perfil para ser alterado."] };
        this.toggleErros();
      }
    },

    toggleAlterarSelecionados() {
      this.modalAlterarSelecionados = !this.modalAlterarSelecionados;
    },

    toggleErros(){
      this.exibirErros = !this.exibirErros
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });

      this.lista_perfil.forEach((perfil) => {
        perfil.selected = this.checkAll;
      });
    },

    selecionarPerfilEditar(perfil) {
      this.perfilSelecionado = {...perfil}
      this.toggleModal()
    },

    toggleModal() {
      this.mostrarModal = !this.mostrarModal
    },

    async getListaPerfil(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        const response = await axios.get(`produtos/perfil/?${params}&ativo=${this.status}&fornecedor=${this.fornecedor || ''}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_perfil = response.data.results;
        this.countItens = response.data.count
        console.log(this.lista_perfil);

      } catch (error) {
        console.error("Erro ao buscar perfis:", error);

      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.checkAll = false
      this.paginaAtual = pagina;
      this.getListaPerfil(pagina);
    },

    // Requisição para obter a cor do perfil
    async getListaCorPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/cor-produto-base/?tipo_produto=Perfil&ativo=True&page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data.results);
        this.lista_cor_perfil = response.data.results.map(item => ({
          id: item.id,
          descricao: item.descricao
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de cores de Perfil:", error);
      }
    },

    // Requisição para obter o tipo de Perfil
    async getListaTipoPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/tipo-produto-base/?produto=Perfil&ativo=True&page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data.results);
        this.lista_tipo_perfil = response.data.results.map(item => ({
          id: item.id,
          descricao: item.descricao
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de tipos de Perfil:", error);
      }
    },

    // Requisição para obter os fornecedores
    async getListaFornecedores() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`pessoa/pessoas-fisica-juridica/?produto_fornecedor=Perfil&atividade=Fornecedor&page=1`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_fornecedores = response.data.results.map(fornecedor => {
          return {
            id: fornecedor.pessoa.id,
            nome_razao: fornecedor.nome || fornecedor.razao_social,
          }
        })
        this.getSemFornecedor()
      } catch (error) {
        console.error(error)
      }
    },

    async getSemFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      try {
        const nome_razao = encodeURIComponent('Fornecedor Indefinido')
        const response = await axios.get(`pessoas/?nome_razao=${nome_razao}&atividade=Fornecedor`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        const semFornecedor = response.data.results[0];
        if (semFornecedor) {
          // Adicionar "Fornecedor Indefinido" à lista de fornecedores
          this.lista_fornecedores.unshift({
            id: semFornecedor.id,
            nome_razao: semFornecedor.nome_razao
          });
        }
      } catch (error) {
        console.error(error)
      }
    },

    // Requisição para obter a linha de aplicação dos perfis
    async getLinhaPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/linha-aplicacao/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // Mapear os dados recebidos a partir de response.data.results
        console.log(response.data.results);
        this.lista_linha_perfil = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));

      } catch (error) {
        console.error("Erro ao buscar linhas de aplicação dos perfis:", error);
      }
    },

  }
}
</script>

<style lang="scss"></style>